import React, { useEffect } from 'react';
import WSConnection from './mqttconnection';


export default function WebSocks(props) {
  useEffect(() => {
    // attach onMessage handler
    if (props.onMessage) {
      attachListener();
    }

    // remove handler on close
    return function cleanup() {
      if (props.onMessage) {
        let wsConnection = WSConnection.getInstance();
        wsConnection.removeListener(onMessage);
      }
    }; 
  }, []);

  const attachListener = () => {
    let wsConnection = WSConnection.getInstance();

    // if there's no connection yet, wait 3 seconds
    if (!wsConnection.isConnected()) {
      setTimeout(attachListener, 3000);
      return;
    }

    wsConnection.addListener(onMessage);
  }

  const onMessage = (topic, message) => {
    let obj = JSON.parse(message.toString());
    let socketData = obj.data;

    if (props.entityFilter) {
      let i = props.entityFilter.indexOf(socketData.entity);
      if (i===-1) {
        return;
      }
    }
    
    props.onMessage(socketData);
  }

  const wsStatus = () => {
    // this doesn't seem to get updated
    // - probably used the wrong function type
    let wsConnection = WSConnection.getInstance();
  
    return wsConnection.status;
  }

  if (props.showStatus==='true') {
    return (
      <span>WebSocket Status: { wsStatus() }</span>
    );
  }

  return (
    <span />
  )
}

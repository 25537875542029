import React, { Component } from 'react';
import { Button } from 'reactstrap';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Icon from './Icon';

export default function DatePickerButton(props) {

  //const [isOpen, setIsOpen] = useState(false);

  const handleChange = (date) => {
    const formatted = moment(date).format('YYYY-MM-DD');
    props.onChange(formatted);
  }

  return (
    <DatePicker
      dateFormat="cccc, dd/MM/yyyy"
      customInput={<CustomInput size={props.size} color={props.color} />}
      selected={new Date(props.selected)}
      onChange={handleChange}
      popperPlacement={props.popperPlacement || 'auto'}
    />
  );

}

class CustomInput extends Component {
  render() {
    return (
      <Button
        size={this.props.size || "lg"}
        className="example-custom-input"
        onClick={this.props.onClick}
        color={this.props.color || "secondary"}
      >
        <Icon icon="calendar-alt" />{' '}
        {this.props.value}
      </Button>
    );
  }
}
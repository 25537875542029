import React, { useContext, useState } from 'react';
import { common } from 'plato-js-client';
import {
  Badge,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table } from 'reactstrap';
import Icon from './Icon';
import { Store } from "../Store";
import moment from 'moment';
import { isTest } from '../mixins/EnvironmentMixin';

export default function Documents(props) {

  const { dispatch } = useContext(Store);

  const [addModal, setAddModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [saving, setSaving] = useState(false);

  const downloadDocument = (doc) => {
    doc.file.get().then((res) => {
      var link = document.createElement('a');
      link.setAttribute('href', res.url);
      link.setAttribute('download', doc.filename);
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  const toggleAddModal = () => {
    setAddModal(!addModal);
  }

  const handleSelectedFile = (event) => {
    if (event.target.files[0]) {
      let newFiles = [...files];
      newFiles.push(event.target.files[0]);
      setFiles(newFiles);
    }
  }

  const removeFile = (name) => {
    let newFiles = [...files];
    newFiles.splice(newFiles.findIndex((file) => {return file.name === name}), 1);
    setFiles(newFiles);
  }

  const addDocuments = () => {

    setSaving(true);

    let promises = [];

    files.forEach(file => {

      var documentBase = undefined;

      if (file.type.startsWith('image')) {
        documentBase = new common.Image();
      } else {
        documentBase = new common.Document();
        documentBase.name = file.name;
        documentBase.description = '';
      }

      documentBase.data = file;

      promises.push(
        documentBase.upload()
      );
    });

    Promise.all(promises).then(values => {

      let wodPromises = [];

      values.forEach(documentBase => {
        var workOrderDocument = new common.WorkOrderDocument();
        workOrderDocument.parent = props.workOrder;
        workOrderDocument.document = documentBase;

        wodPromises.push(
          workOrderDocument.create()
        );
      });

      Promise.all(wodPromises).then(() => {
        // we're done!
        setFiles([]);
        setSaving(false);
        toggleAddModal();
      });

    });
  }

  const removeDocument = (da) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      let wod = new common.WorkOrderDocument(da.id);
      wod.parent = props.workOrder;
      wod.delete();
    }
  }

  const documentRows = props.workOrder.documents.map(da => {
    if (da.document.id) {
      return(
        <tr key={da.document.id}>
          <td>{da.document.name}</td>
          <td>{da.document.filename}</td>
          <td>{da.document.description ? da.document.description : 'N/A'}</td>
          <td>{moment(da.document.dateadded).format('DD/MM/YY HH:ss')}</td>
          <td>
            <Icon icon="eye" onClick={() => {downloadDocument(da.document)}} />{' '}
            <Icon icon="trash" onClick={() => {removeDocument(da)}} />
          </td>
        </tr>
      );
    } else if (da.image.id) {
      return (
        <tr key={da.image.id}>
          <td>{da.image.name}</td>
          <td>{da.image.filename}</td>
          <td>{da.image.description ? da.image.description : 'N/A'}</td>
          <td>{moment(da.image.dateadded).format('DD/MM/YY HH:ss')}</td>
          <td>
            <a
              href={"https://" + (isTest() ? 'test.plato.hc-dev.' : 'toccl.api.tabs-software.') + 'co.uk/v2/image/' + da.image.id + "/resize/width/1000/1000"}
              alt={da.image.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="eye" />
            </a>{' '}
            <Icon icon="trash" onClick={() => {removeDocument(da)}} />
          </td>
        </tr>
      );
    }
    return null;
  });

  return (
    <React.Fragment>
      <Table size="sm" striped>
        <thead>
          <tr>
            <th>title</th>
            <th>filename</th>
            <th>description</th>
            <th>date uploaded</th>
            <th>actions</th>
          </tr>
        </thead>
        <tbody>
          {documentRows.length === 0 &&
          <tr>
            <td colSpan={5}>
              No documents found.
            </td>
          </tr>
          }
          {documentRows}
        </tbody>
      </Table>
      <Button onClick={toggleAddModal} >add document(s)</Button>
      <Modal isOpen={addModal} toggle={toggleAddModal} size="lg">
        <ModalHeader toggle={toggleAddModal}>add document(s)</ModalHeader>
        <ModalBody>
          <FormGroup>
            {files.length > 0
            ?
            <span>Documents: </span>
            :
            <span>No documents to add.</span>
            }
            {files.map((file) => {
              return (
                <React.Fragment>
                  <Badge color="secondary">
                    {file.name}
                    <Button close onClick={() => { removeFile(file.name) }} />
                  </Badge>{' '}
                </React.Fragment>
              );
            })}
          </FormGroup>
          <FormGroup>
            <Label className="btn btn-primary">
              add file <input type="file" onChange={handleSelectedFile} hidden />
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          {!saving && <Button color="primary" onClick={addDocuments}>save</Button>}
          {saving && <Button color="primary" disabled>saving...</Button>}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );

}
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import Admin from './Admin';
import Home from './Home';
import WhatsNew from './WhatsNew';
import LogOut from './LogOut';
import NavBar from './NavBar';
import { client, common } from 'plato-js-client';
import { Container } from 'reactstrap';
import { Store, StoreProvider } from "./Store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadUser, loadGroupAction } from "./Actions";
import { Row, Col } from 'reactstrap';
import Loader from 'react-loader-spinner';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { loadSetting } from './components/TabsUserSetting';
import WebSocks from './WebSocks';
import { isTest } from './mixins/EnvironmentMixin';

// http://localhost/plato/public/index.php OR https://toccl.api.tabs-software.co.uk OR https://toccl.test.api.tabs-software.co.uk

var token = localStorage.getItem('plato-js-client:defaultToken');

let authPath = '/oauth/v2/auth';

if (window.location.hostname.includes('originalcottages')) {
  authPath = '/saml';
}

if (window.location.search) {
  // stash any query string in local storage in case the auth process loses this info
  localStorage.querystring = window.location.search;
  window.location.search = '';
}

let apiUrl;

if (sessionStorage.apiHost) {
  apiUrl = sessionStorage.apiHost;
} else {
  apiUrl = isTest() ? 'https://test.plato.hc-dev.co.uk' : 'https://toccl.api.tabs-software.co.uk';
}

client.getInstance().setInstance({
  apiRoot: apiUrl,
  apiPrefix: '/v2',
  oAuthRedirectUrl: window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/oauth',
  clientId: '17_4fbsnim2lpmogsccs48ssgo80gcgggos8ooosgg8c40sww4s8s',
  token: token,
  authPath
});

function App() {

  const [user, setUser] = useState();

  useEffect(() => {
    if (window.location.hash) {
      // synchronously save the token to localStorage
      client.getInstance().oAuthCallback(window.location.hash);
      window.history.pushState({}, document.title, "/" + "");
    }

    whoAmI();

    /* STOLEN - https://cameronjonesweb.com.au/blog/fix-google-maps-including-overriding-roboto-fonts/
     * the Google map rather unhelpfully re-imports the Roboto font with different weights, which makes
     * every bold element on the page more chunky! */
    var head = document.getElementsByTagName('head')[0];
    var insertBefore = head.insertBefore;

    head.insertBefore = function(newElement, referenceElement) {
      if (newElement.href && newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto') === 0) {
        return;
      }
      insertBefore.call(head, newElement, referenceElement);
    };

    library.add(fas);
  }, []);

  const whoAmI = () => {
    client.getInstance().whoAmi().then((response) => {
      var actor = common.ActorInstance.call(this, response.entity.type.toLowerCase());
      var a = new actor(response.entity.id);
      a.mutateResponse(response.entity);
      setUser(a);
    });
  }

  const logOut = () => {
    setUser();
    client.getInstance().oAuthLogout();
  };

  /**
   * This reacts to all workorder entities
   * - this is at the app root so can make global updates
   *
   * @param {*} data
   */
  const onMessage = data => {
    // check message contents and react accordingly
  }

  document.body.style.backgroundColor = "#f4f4f4";

  return (
    <BrowserRouter>
      <StoreProvider>
        <WebSocks onMessage={ onMessage } />
        <ToastContainer />
        <Container fluid={true} className="p-0">
          <NavBar />
          <React.Fragment>
            {user &&
            <GroupContainer user={user}>
              <Route
                path='/'
                exact
                render={(routeProps) => <Home { ...routeProps } />}

              />
              <Route
                path='/admin'
                exact
                render={(routeProps) => <Admin { ...routeProps } />}
              />
              <Route
                path='/whatsnew'
                exact
                render={(routeProps) => <WhatsNew { ...routeProps } />}
              />
            </GroupContainer>
            }
            <Route path='/logout' render={ (routeProps) => {
              return <LogOut { ...routeProps } logOut={ logOut } whoAmI={ whoAmI } />;
            } } />
            <Route path='/oauth' render={ () => {
              // redirect AFTER window.location.hash has been used
              if (user) {
                var previousPath = sessionStorage['tabs2:previousPath'];
                if (previousPath && previousPath.indexOf('/logout') !== 0) {
                  return <Redirect to={ previousPath } />;
                }
                return <Redirect to='/' />;
              }
              return null;
            } } />
          </React.Fragment>
        </Container>
      </StoreProvider>
    </BrowserRouter>
  );

}

function GroupContainer(props) {

  const {
    state: {
      user,
      groups,
      currentGroup,
      fieldWorkers,
      properties,
      workOrderSubStatuses,
      vatRates
    },
    dispatch
  } = useContext(Store);

  useEffect(() => {
    loadUser(props.user, dispatch);
  }, [props.user, dispatch]);

  useEffect(() => {
    if (user) {
      const groupId = loadSetting(user, 'PMSGroup', "1");
      loadGroupAction(parseInt(groupId, 10), dispatch);
    }
  }, [user, dispatch]);

  if (!currentGroup) {
    return null;
  }

  if (!groups ||
      !currentGroup ||
      !fieldWorkers ||
      !properties ||
      !workOrderSubStatuses ||
      !vatRates) {

    return (
      <React.Fragment>
        <Container>
          <Row style={{marginTop: '40px', marginBottom: '40px'}} className="text-center">
            <Col>
              <Loader
                type="Puff"
                color="#fd6413"
                height={100}
                width={100}
              />
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <p>loading statuses...{ workOrderSubStatuses ? ' done' : ''}</p>
              <p>loading properties...{ properties ? ' done' : ''}</p>
              <p>loading field workers...{ fieldWorkers ? ' done' : ''}</p>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );

}

export default App;

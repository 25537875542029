import React, { useContext, useEffect, useReducer, useState } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import { 
  Button,
  ButtonDropdown,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  Tooltip } from 'reactstrap';
import { Store } from './Store';
import Icon from './components/Icon';
import Link from './components/Link';
import DatePickerButton from './components/DatePickerButton';
import DiscreteCard from './components/DiscreteCard';
import JobExpenseModal from './components/JobExpenseModal';
import PrettyLoader from './components/PrettyLoader';
import SelectJobModal from "./components/SelectJobModal";
import SelectPersonModal from "./components/SelectPersonModal";
import { addTab, updateJobSubStatus } from './Actions';
import Date from "./fields/Date";
import Text from "./fields/Text";
import moment from 'moment';

export default function TabSupplierInvoices() {

  const [activeTab, setActiveTab] = useState('recent invoices');
  const [openInvoices, setOpenInvoices] = useState([]);
  const [newInvoiceModal, setNewInvoiceModal] = useState(false);

  const viewInvoice = invoice => {
    if (!openInvoices.map(oi => oi.id).includes(invoice.id)) {
      let invoices = [...openInvoices];
      invoices.push(invoice);
      setOpenInvoices(invoices);
    }
    
    setActiveTab('invoice ' + invoice.id);
  }

  const closeInvoice = id => {
    let invoices = [...openInvoices];
    invoices.splice(
      invoices.findIndex(i => i.id === id),
      1
    )
    setOpenInvoices(invoices);
    setActiveTab('recent invoices');
  }

  const toggleNewInvoiceModal = () => {
    setNewInvoiceModal(!newInvoiceModal);
  }

  return (
    <Row className="">
      <Col>
        <h3>
          field worker invoices{' '}
          <Button color="primary" size="sm" onClick={toggleNewInvoiceModal}>new invoice</Button>
          {newInvoiceModal &&
          <NewInvoiceModal 
            isOpen={newInvoiceModal} 
            toggle={toggleNewInvoiceModal}
            handleSubmit={id => {
              viewInvoice({ id });
              toggleNewInvoiceModal();
            }}
          />
          }
        </h3>
        <Card>
          <CardBody>
            <Nav className="mb-2" tabs>
              <NavItem>
                <NavLink
                  className={activeTab === 'recent invoices' ? 'active' : ''}
                  onClick={() => { setActiveTab('recent invoices'); }}
                >
                  recent invoices
                </NavLink>
              </NavItem>
              {openInvoices.map(invoice => 
              <NavItem key={invoice.id}>
                <NavLink
                  className={activeTab === 'invoice ' + invoice.id ? 'active' : ''}
                  onClick={() => { setActiveTab('invoice ' + invoice.id); }}
                >
                  invoice {invoice.id}{' '}
                  <Icon icon="times" className="clickable-icon" onClick={e => {
                    e.stopPropagation();
                    closeInvoice(invoice.id);
                  }} />
                </NavLink>
              </NavItem>
              )}
            </Nav>
            <TabContent className="mb-3 mt-3" activeTab={activeTab}>
              <TabPane tabId="recent invoices">
                <RecentInvoicesPane viewInvoice={viewInvoice} />    
              </TabPane>
              {openInvoices.map(invoice => 
              <TabPane key={invoice.id} tabId={'invoice ' + invoice.id}>
                <ViewInvoicePane invoiceId={invoice.id} />
              </TabPane>
              )}
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Row>    
  );

}

function RecentInvoicesPane({ viewInvoice }) {

  const globalDispatch = useContext(Store).dispatch;

  const {
    state: {
      currentGroup,
      fieldWorkers,
    }
  } = useContext(Store);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'PREV_PAGE':
        return {...state, page: state.page - 1};
      case 'NEXT_PAGE':
        return {...state, page: state.page + 1};
      case 'SET_LIMIT':
        return {...state, limit: action.payload};
      case 'SET_INVOICES':
        return {...state, invoices: action.payload, selected: []};
      case 'TOGGLE_LIMIT_DROPDOWN':
        return {...state, limitDropdown: !state.limitDropdown};
      case 'REFRESH':
        return {...state, refresh: state.refresh + 1};
      case 'TOGGLE_MODAL':
        return {...state, modal: !state.modal};
      case 'SET_LOADING':
        return {...state, loading: action.payload};
      case 'SET_FIELD_WORKER_FILTER_TOGGLE':
        return {...state, fieldWorkerFilterToggle: action.payload};
      case 'SET_FIELD_WORKER_FILTER_VALUE':
        return {...state, fieldWorkerFilterValue: action.payload};  
      case 'SET_INVOICE_DATE_FILTER_TOGGLE':
        return {...state, invoiceDateFilterToggle: action.payload};
      case 'SET_INVOICE_DATE_FILTER_VALUE':
        return {...state, invoiceDateFilterValue: action.payload};
      case 'SET_INVOICE_NUMBER_FILTER_TOGGLE':
        return {...state, invoiceNumberFilterToggle: action.payload};
      case 'SET_INVOICE_NUMBER_FILTER_VALUE':
        return {...state, invoiceNumberFilterValue: action.payload};           
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    page: 1,
    limit: 10,
    loading: false,
    invoices: {},
    limitDropdown: false,
    refresh: 0,
    modal: false,
    loading: false,
    fieldWorkerFilterToggle: undefined,
    fieldWorkerFilterValue: undefined,
    invoiceDateFilterToggle: undefined,
    invoiceDateFilterValue: moment().format('YYYY-MM-DD'),
    invoiceNumberFilterToggle: undefined,
    invoiceNumberFilterValue: undefined,
  });

  const removeInvoice = invoice => {
    if (window.confirm('Are you sure you want to remove this invoice?')) {
      invoice.delete().then(() => refresh());
    }
  }

  const refresh = async () => {
    dispatch({type: 'SET_LOADING', payload: true});

    let invoices = new FilterCollection({
      path: 'supplierinvoice',
      object: common.SupplierInvoice,
    });
    invoices.limit = state.limit;
    invoices.page = state.page;
    invoices.orderBy = 'id_desc';

    let filters = {
      supplierprovided: true
    };

    if (state.fieldWorkerFilterToggle && state.fieldWorkerFilterValue) {
      filters.supplierid = state.fieldWorkerFilterValue.id;
    } else {
      filters.supplierid = currentGroup.fieldWorkers.join('|');
    }

    if (state.invoiceDateFilterToggle && state.invoiceDateFilterValue) {
      filters.invoicedate = state.invoiceDateFilterValue;
    }

    if (state.invoiceNumberFilterToggle && state.invoiceNumberFilterValue && state.invoiceNumberFilterValue.length > 0) {
      filters.invoicenumber = '~' + state.invoiceNumberFilterValue;
    }

    invoices.addFilters(filters);

    await invoices.fetch();

    dispatch({type: 'SET_INVOICES', payload: invoices});
    dispatch({type: 'SET_LOADING', payload: false});
  }  

  useEffect(() => {
    refresh();
  }, [
    state.page, 
    state.limit, 
    state.refresh,
    state.fieldWorkerFilterToggle,
    state.fieldWorkerFilterValue,
    state.invoiceDateFilterToggle,
    state.invoiceDateFilterValue,
    state.invoiceNumberFilterToggle,
    state.invoiceNumberFilterValue,
  ]);

  var filters = [
    {
      name: 'field worker',
      active: state.fieldWorkerFilterToggle,
      toggle: value => dispatch({ type: 'SET_FIELD_WORKER_FILTER_TOGGLE', payload: value }),
      control: (
        <React.Fragment>
          {state.fieldWorkerFilterValue ? state.fieldWorkerFilterValue.title + ' ' + state.fieldWorkerFilterValue.firstname + ' ' + state.fieldWorkerFilterValue.surname + ', or ' : ''}
          <SelectPersonModal
            button={onClick => {
              return (
                <Button size="sm" color="primary" onClick={onClick}>select field worker</Button>
              );
            }}
            onSubmit={supplierId => {
              const supplier = fieldWorkers.find(fw => fw.id === supplierId);
              dispatch({ type: 'SET_FIELD_WORKER_FILTER_VALUE', payload: supplier });
            }}
            oneOnly={true}
            payOnly={true}
          />
        </React.Fragment>
      )
    },
    {
      name: 'invoice date',
      active: state.invoiceDateFilterToggle,
      toggle: value => dispatch({ type: 'SET_INVOICE_DATE_FILTER_TOGGLE', payload: value }),
      control: (
        <DatePickerButton 
          color="primary"
          selected={state.invoiceDateFilterValue} 
          onChange={date => dispatch({ type: 'SET_INVOICE_DATE_FILTER_VALUE', payload: date })} 
          size="sm"
        />
      )
    },
    {
      name: 'invoice number',
      active: state.invoiceNumberFilterToggle,
      toggle: value => dispatch({ type: 'SET_INVOICE_NUMBER_FILTER_TOGGLE', payload: value }),
      control: (
        <Input 
          type="text" 
          value={state.invoiceNumberFilterValue || ''} 
          onChange={e => dispatch({ type: 'SET_INVOICE_NUMBER_FILTER_VALUE', payload: e.target.value })}
        />
      )
    }    
  ];

  return (
    <React.Fragment>

      {filters.map((filter, i) => 
      <Row key={i} className="mb-1 align-items-center">
        <Col></Col>
        <Col sm="auto">
          <ButtonGroup>
            <Button 
              size="sm" 
              active={!filter.active}
              onClick={() => filter.toggle(false)}
            >
              any {filter.name}
            </Button>
            <Button 
              size="sm"
              active={filter.active}
              onClick={() => filter.toggle(true)}
            >
              choose {filter.name}
            </Button>
          </ButtonGroup>
        </Col>
        {filter.active &&
        <Col sm="auto">
        {filter.control}
        </Col>
        }
      </Row> 
      )}

      <Row className="mb-2">
        <Col>
          <div style={{display: 'inline-flex', verticalAlign: 'middle'}}>
            <div className="mr-2 p-1 float-right">
              results per page:
            </div>           
            <ButtonDropdown 
              size="sm" 
              isOpen={state.limitDropdown} 
              toggle={() => { dispatch({type: 'TOGGLE_LIMIT_DROPDOWN'}) }}
              disabled={state.busy}
            >
              <DropdownToggle caret>
                {state.limit} 
              </DropdownToggle>
              <DropdownMenu>
                {[10, 20, 30, 50].map(quantity => 
                <DropdownItem key={quantity} onClick={() => {
                  dispatch({type: 'SET_LIMIT', payload: quantity})
                }}>
                  {quantity}
                </DropdownItem>  
                )}
              </DropdownMenu>
            </ButtonDropdown>
          </div>         
        </Col>
        <Col sm="auto">
          {state.loading &&
          <PrettyLoader className="mr-2" size={30} />
          }
          <Button size="sm" onClick={() => { dispatch({type: 'REFRESH'}) }} disabled={state.loading}>
            refresh
          </Button>{' '}
          <ButtonGroup size="sm">
            <Button
              onClick={() => { dispatch({type: 'PREV_PAGE'}) }}
              disabled={!state.invoices.previous || state.loading}
            >
              previous page
            </Button>
            <Button 
              onClick={() => { dispatch({type: 'NEXT_PAGE'}) }}
              disabled={!state.invoices.next || state.loading}
            >
              next page
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Table size="sm" striped>
        <thead>
          <tr>
            <th>id</th>
            <th>field worker</th>
            <th>date</th>
            <th>number</th>
            <th>description</th>
            <th>net total</th>
            <th>status</th>
            <th>{''}</th>
          </tr>
        </thead>
        <tbody>
          {state.invoices.collection && state.invoices.collection.length === 0 &&
          <tr>
            <td colSpan={8}>No invoices found.</td>
          </tr>
          }
          {state.invoices.collection && state.invoices.collection.map(invoice => {
            const supplier = invoice.workordersupplier.supplier;

            let status = 'new';
            if (invoice.invoiceitems.collection.length > 0) {
              status = 'processed (partial)';
            }
            let totalProcessed = invoice.invoiceitems.collection.reduce(
              (a, c) => a + parseFloat(c.amountnet, 10),
              0
            );
            if (totalProcessed === invoice.totalamountnet) {
              status = 'processed';

              const supplierpaids = invoice.invoiceitems.map(ii => ii.supplierpaid);
              if (supplierpaids.includes(true) && !supplierpaids.includes(false)) {
                status = 'field worker paid';
              }
            }

            return (
              <tr key={invoice.id}>
                <td>{invoice.id}</td>
                <td>
                  {[supplier.title, supplier.firstname, supplier.surname].join(' ')}{' '}
                  <Icon 
                    icon="eye" 
                    onClick={() => addTab('showFieldWorker', {supplierId: supplier.id}, globalDispatch)} 
                  />
                </td>
                <td>{moment(invoice.invoicedate).format('DD/MM/YYYY')}</td>
                <td>{invoice.invoicenumber}</td>
                <td>{invoice.description || 'N/A'}</td>
                <td>{invoice.totalamountnet ? invoice.totalamountnet.toFixed(2) : 'N/A'}</td>
                <td>{status}</td>
                <td className="align-middle text-center">
                  <Icon
                    icon="eye" 
                    className="text-primary cursorPointer" 
                    onClick={() => viewInvoice(invoice)}
                  />{' '}
                  <Icon
                    icon="trash" 
                    className="text-primary cursorPointer" 
                    disabled={invoice.invoiceitems.collection.length > 0}
                    onClick={() => removeInvoice(invoice)}
                  />
                </td>
              </tr>            
            );

          })}
        </tbody>
      </Table>
    </React.Fragment>
  );

}

function ViewInvoicePane({ invoiceId }) {

  const dispatch = useContext(Store).dispatch;

  const {
    state: {
      vatRates,
    }
  } = useContext(Store);

  const [invoice, setInvoice] = useState();
  const [jobs, setJobs] = useState();
  const [chosenJobIds, setChosenJobIds] = useState([]);
  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const [selectJobModal, setSelectJobModal] = useState(false);
  const [busy, setBusy] = useState(false);

  const loadData = async () => {
    setBusy(true);

    let inv = new common.SupplierInvoice(invoiceId);
    await inv.get();

    // retrieve the jobs associated with this invoice
    let jobIds = [];
    for (const supplierInvoiceItem of inv.invoiceitems.collection) {
      const workOrderId = supplierInvoiceItem.workorder.id;
      if (!jobIds.includes(workOrderId)) {
        jobIds.push(workOrderId);
      }
    }

    // add on any other job ids we're looking at
    for (const jobId of chosenJobIds) {
      if (!jobIds.includes(jobId)) {
        jobIds.push(jobId);
      }
    }

    if (jobIds.length > 0) {
      let jobs = new FilterCollection({
        path: 'workorder',
        object: common.WorkOrder,
      });
      jobs.limit = 999;
      jobs.page = 1;
      jobs.fields = 'id:expenses:propertynano:shortdescription:status:workordersupplier';

      jobs.addFilters({ id: jobIds.join('|') });

      await jobs.fetch();

      setJobs(jobs.collection);
    } else {
      setJobs([]);
    }

    setInvoice(inv);
    setBusy(false);
  };

  useEffect(() => {
    loadData();
  }, [chosenJobIds]);

  const toggleSelectJobModal = () => {
    setSelectJobModal(!selectJobModal);
  }

  const toggleSelectedJob = (id, checked) => {
    const selected = [...selectedJobIds];

    if (checked) {
      selected.push(id);
    } else {
      selected.splice(
        selected.indexOf(id),
        1
      );
    }

    setSelectedJobIds(selected);
  }

  const updateJobItem = async (job, expense) => {

    let e = undefined;

    if (expense.id) {
      e = new common.WorkOrderExpense(expense.id);
      e.parent = job;

      if (expense.deleted === true) {

        const ownercharges = Array.isArray(expense.ownercharges) ? 
          expense.ownercharges : 
          expense.ownercharges.collection;

        for (const ownercharge of ownercharges) {
          if (ownercharge.id) {
            let oc = new common.WorkOrderOwnerCharge(ownercharge.id);
            oc.parent = e;
            await oc.delete();
          }
        }
      
        await e.delete();
        return;

      } else if (expense.updated === true) {
        await e.update(expense);
      }

    } else if (!expense.deleted) {
      e = new common.WorkOrderExpense();
      e.parent = job;

      await e.create(expense);
    }

    const ownercharges = Array.isArray(expense.ownercharges) ? 
      expense.ownercharges : 
      expense.ownercharges.collection;

    for (const ownercharge of ownercharges) {

      let oc = undefined;

      let ownerChargeDeleted = ownercharge.deleted;
      if (expense.invoiceto === 'Agency') {
        // automatically delete (or don't create/update) owner charges 
        // if the agency is paying for the job
        ownerChargeDeleted = true;
      }

      if (ownercharge.id) {
        oc = new common.WorkOrderOwnerCharge(ownercharge.id);
        oc.parent = e;

        if (ownerChargeDeleted === true) {
          await oc.delete();
          continue;
        } else if (ownercharge.updated === true) {
          await oc.update(ownercharge);
        }

      } else if (!ownerChargeDeleted) {
        oc = new common.WorkOrderOwnerCharge();
        oc.parent = e;
        await oc.create(ownercharge);
      }

    }

    loadData();
  
  }

  const deleteJobItem = async (job, expense) => {
    if (!(window.confirm('Are you want to delete this job item? This cannot be undone.'))) {
      return;
    }

    const ownercharges = Array.isArray(expense.ownercharges) ? 
      expense.ownercharges : 
      expense.ownercharges.collection;

    for (const ownercharge of ownercharges) {
      if (ownercharge.id) {
        let oc = new common.WorkOrderOwnerCharge(ownercharge.id);
        oc.parent = expense;
        await oc.delete();
      }
    }
      
    await expense.delete();

    loadData();
  }

  const processInvoice = async () => {

    if (!(window.confirm('Are you want to process this invoice? This cannot be undone.'))) {
      return;
    }

    setBusy(true);

    // 1. update owner charges with the invoice number/date
    // 2. financially complete jobs

    let ownerChargePromises = [];

    for (const job of jobs) {
      if (selectedJobIds.includes(job.id)) {

        for (const expense of job.expenses.collection) {
          if (!expense.donotpaysupplier && !(parseFloat(expense.agencycostnet) >= 0)) {
            if (!(expense.supplierinvoiceitem && expense.supplierinvoiceitem.id)) { 
              for (const ownercharge of expense.ownercharges.collection) {
                ownerChargePromises.push(
                  ownercharge.update({
                    invoicenumber: invoice.invoicenumber, 
                    invoicedate: invoice.invoicedate 
                  })
                );
              }
            }
          }
        }

        await Promise.all(ownerChargePromises);

        let woi = new common.WorkOrderInvoice(job.id);
        woi.supplierinvoice_id = invoiceId;
        woi.supplierinvoice_invoicedate = moment().format('YYYY-MM-DD');
  
        try {
          await woi.create();
  
          // third, update the sub-status to match whatever the status the job is on now
          await job.get();
          const newStatus = job.status.toLowerCase().replace(' ', '_');
          await updateJobSubStatus(job.id, newStatus, dispatch);
        } catch(error) {
          // todo
        }
      }
    }

    setBusy(false);
    loadData();
  }

  const downloadDocument = () => {
    let doc = invoice.document;

    doc.file.get().then((res) => {
      var link = document.createElement('a');
      link.setAttribute('href', res.url);
      link.setAttribute('download', doc.filename);
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  const getVatRate = () => {
    for (const vr of vatRates) {
      if (moment().isBetween(vr.fromdate, vr.todate, 'day', '[]')) {
        return vr;
      }
    }
  }  

  if (!invoice || !jobs) {
    return <h4>loading...</h4>;
  }

  let rows = [];
  let alreadyAllocatedTotal = 0;
  let toBeAllocatedTotal = 0;

  for (const job of jobs) {
    for (const expense of job.expenses.collection) {
      if (!expense.donotpaysupplier) {
        if (expense.supplierinvoiceitem && expense.supplierinvoiceitem.id) {
          if (expense.supplierinvoiceitem.supplierinvoiceid === invoiceId) {
            alreadyAllocatedTotal = +((alreadyAllocatedTotal + (expense.agencycostnet || expense.amountnet || expense.amountnetestimate)).toFixed(2));
          }
        } else {
          if (selectedJobIds.includes(job.id)) {
            toBeAllocatedTotal = +((toBeAllocatedTotal + (expense.agencycostnet || expense.amountnet || expense.amountnetestimate)).toFixed(2));
          }
        }
      }
    }
  }

  const remaining = +((invoice.totalamountnet - alreadyAllocatedTotal - toBeAllocatedTotal).toFixed(2));

  let status = 'new';
  if (alreadyAllocatedTotal > 0) { 
    status = 'processed'; 
    if (alreadyAllocatedTotal !== invoice.totalamountnet) { 
      status += ' (partial)'; 
    } else {
      const supplierpaids = invoice.invoiceitems.map(ii => ii.supplierpaid);
      if (supplierpaids.includes(true) && !supplierpaids.includes(false)) {
        status = 'field worker paid';
      }
    }
  }

  for (const job of jobs) {
    let canBeAllocated = 0;
    let isAllocated = 0;
    let paySupplierFlagWrong = false;
    let allocatedToWrongInvoice = false;

    for (const expense of job.expenses.collection) {
      if (!expense.donotpaysupplier) {
        canBeAllocated++;
        if (expense.supplierinvoiceitem && expense.supplierinvoiceitem.id) {
          if (expense.supplierinvoiceitem.supplierinvoiceid === invoiceId) {
            isAllocated++;
          } else {
            // !!!!
            allocatedToWrongInvoice = true;
          }
        }
      } else {
        // donotpaysupplier = false, should it be?
        if  (!(parseFloat(expense.agencycostnet) >= 0)) {
          paySupplierFlagWrong = true;
        }
      }
    }

    if (job.expenses.collection.length > 0) {
      for (let i = 0; i < job.expenses.collection.length; i++) {
        const expense = job.expenses.collection[i];

        rows.push(
          <ViewInvoicePaneRow
            key={expense.id}
            job={job}
            expense={expense}
            expensesCount={job.expenses.collection.length}
            firstRow={i === 0}
            invoiceId={invoiceId}
            selectedJobIds={selectedJobIds}
            toggleSelectedJob={toggleSelectedJob}
            canBeAllocated={canBeAllocated > 0 && !paySupplierFlagWrong}
            isAllocated={canBeAllocated === isAllocated}
            updateJobItem={updateJobItem}
            deleteJobItem={deleteJobItem}
            disabled={status.includes('processed') || status === 'field worker paid'}
          />
        );
      }
    } else {
      rows.push(
        <ViewInvoicePaneEmptyRow 
          key={'J' + job.id}
          job={job}
          updateJobItem={updateJobItem}
          disabled={status.includes('processed') || status === 'field worker paid'}
        />
      );
    }
  }

  const supplier = invoice.workordersupplier.supplier;

  let showvat = false;
  let totalamountgross = 0;
  let totalamountvat = 0;

  if (supplier.vatnumber.length > 0) {
    showvat = true;
    const vr = getVatRate();
    totalamountgross = parseFloat((invoice.totalamountnet * (1 + (vr.percentage / 100))).toFixed(2));
    totalamountvat = totalamountgross - invoice.totalamountnet;
  }

  const h2Style = { fontSize: "2rem" }; 

  return (
    <React.Fragment>
      <Row className="mb-1">
        <Col>
          <Card>
            <CardBody>
              <h3>
                invoice { invoice.id }{' '}
                {busy &&
                <PrettyLoader className="mr-2" size={30} />
                }
              </h3>
              <Row>
                <DiscreteCard title='invoice number'>
                  {invoice.invoicenumber}
                </DiscreteCard>
                <DiscreteCard title='invoice date'>
                  {moment(invoice.invoicedate).format('DD/MM/YY')}
                </DiscreteCard>
                <DiscreteCard title='field worker'>
                  {[supplier.title, supplier.firstname, supplier.surname].join(' ')}{' '}
                  <Icon 
                    icon="eye" 
                    onClick={() => addTab('showFieldWorker', {supplierId: invoice.workordersupplier.supplier.id}, dispatch)} 
                  />
                </DiscreteCard>
                <DiscreteCard title='status'>
                  {status}
                </DiscreteCard>
                <DiscreteCard title='document'>
                  <Button size="sm" onClick={downloadDocument}>download</Button>
                </DiscreteCard>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* Allocate costs */}
      <Row className="mb-1">
        <Col>
          <Card>
            <CardBody>
              <h3>
                allocate costs{' '}
                <Button 
                  size="sm" 
                  onClick={toggleSelectJobModal} 
                  disabled={busy || status.includes('processed') || status === 'field worker paid'}
                >
                  add job(s)
                </Button>
                {selectJobModal &&
                <SelectJobModal 
                  onSubmit={jobIds => setChosenJobIds([...chosenJobIds, ...jobIds])}
                  supplierId={invoice.workordersupplier.supplier.id}
                  toggle={toggleSelectJobModal}
                  subStatus="awaiting_invoice"
                  excludeIds={jobs.map(j => j.id)}
                />
                }
              </h3>
              <Table size="sm" striped>
                <thead>
                  <tr>
                    <th colSpan={3}>job</th>
                    <th colSpan={7}>cost</th>
                    <th colSpan={2}>&nbsp;</th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>id</th>
                    <th>description</th>
                    <th>property</th>
                    <th>description</th>
                    <th>code</th>
                    <th>estimate</th>
                    <th>actual</th>
                    <th>stock cost</th>
                    <th>pay supplier</th>
                    <th>charges</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.length === 0 &&
                  <tr>
                    <td colSpan="12">no costs yet.</td>
                  </tr>
                  }
                  {rows.length > 0 &&
                  rows
                  }
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <h3>review</h3>
              <Row>
                <DiscreteCard title='invoice total'>
                  <Row>
                    <Col>
                      net: <span style={h2Style}>{invoice.totalamountnet.toFixed(2)}</span>
                    </Col>
                    {showvat &&
                    <React.Fragment>
                      <Col>
                        VAT: <span style={h2Style}>{totalamountvat.toFixed(2)}</span>
                      </Col>
                      <Col>
                        gross: <span style={h2Style}>{totalamountgross.toFixed(2)}</span>
                      </Col>
                    </React.Fragment>
                    }
                  </Row>
                </DiscreteCard>
                {/* <DiscreteCard title='already allocated'>
                  <h2>{alreadyAllocatedTotal.toFixed(2)}</h2>
                </DiscreteCard> */}
                <DiscreteCard title='allocated (net)'>
                  <span style={h2Style}>{toBeAllocatedTotal.toFixed(2)}</span>
                </DiscreteCard>
                <DiscreteCard title='remaining (net)'>
                  <span style={h2Style} className={remaining !== 0 ? "text-danger" : "text-success"}>
                    {remaining.toFixed(2)}
                  </span>
                </DiscreteCard>
                <DiscreteCard title='actions'>
                  {(remaining === 0 && toBeAllocatedTotal > 0) ?
                  <Button color="primary" onClick={processInvoice} disabled={busy}>process invoice</Button>
                  :
                  <span style={h2Style}>N/A</span>
                  }
                </DiscreteCard>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

function ViewInvoicePaneEmptyRow({ 
  job,
  updateJobItem,
  disabled
}) {

  const dispatch = useContext(Store).dispatch;

  const [newExpenseModal, setNewExpenseModal] = useState(false);

  const toggleNewExpenseModal = () => {
    setNewExpenseModal(!newExpenseModal);
  }

  return (
    <tr key={'J' + job.id} className="table-info">
      <React.Fragment>
        <td className="align-middle">
          <Link 
            style={{ display: 'inline' }}
            onClick={() => { addTab('editJob', {workOrderId: job.id}, dispatch); }}
          >
            {job.id}
          </Link>
          {' '}
          <Button size="sm" style={{ padding: ".1rem .25rem" }} onClick={toggleNewExpenseModal} disabled={disabled}>
            add item
          </Button>
        </td>
        <td className="align-middle">{job.shortdescription}</td>
        <td className="align-middle">{job.property.name}</td>
      </React.Fragment>
      <td colSpan={9}>This job has no costs.</td>
      {newExpenseModal &&
      <JobExpenseModal
        workOrder={job}
        workOrderFields={{}}
        toggle={toggleNewExpenseModal}
        handleExpense={expense => updateJobItem(job, expense)}
      />
      }
    </tr>
  );
}

function ViewInvoicePaneRow({ 
  job, 
  expense, 
  expensesCount, 
  firstRow,  
  selectedJobIds, 
  toggleSelectedJob,
  canBeAllocated,
  isAllocated,
  updateJobItem,
  deleteJobItem,
  disabled
}) {

  const dispatch = useContext(Store).dispatch;

  const [chargesTool, setChargesTool] = useState(false);
  const [editExpenseModal, setEditExpenseModal] = useState(false);
  const [newExpenseModal, setNewExpenseModal] = useState(false);

  const toggleEditExpenseModal = () => {
    setEditExpenseModal(!editExpenseModal);
  }

  const toggleNewExpenseModal = () => {
    setNewExpenseModal(!newExpenseModal);
  }

  var amountNetEstimate = parseFloat(
    expense.agencycostnet || expense.amountnet || expense.amountnetestimate
  );

  let ownerChargesCount = 0;
  let ownerChargesTool = [];
  let ownerChargesTotal = 0;

  expense.ownercharges.collection.forEach((oc, i) => {

    var ocamount = parseFloat(oc.amount);
    var ownerCharge = 0;

    if (oc.ownerchargeamounttype) {
      switch (oc.ownerchargeamounttype.ownerchargeamounttype) {
        case 'Fixed':
          ownerCharge = ocamount;
          break;
        case 'Percentage':
          var multiplier = ocamount / 100;
          ownerCharge = (amountNetEstimate * multiplier);
          break;
        case 'Amount':
          ownerCharge = amountNetEstimate + ocamount;
          break;
        default:
      }
    } else {
      ownerCharge = amountNetEstimate;
    }

    ownerChargesCount += 1;
    ownerChargesTotal += ownerCharge;

    ownerChargesTool.push(
      <span className="d-block" key={i}>
        {oc.ownerchargecode.ownerchargecode + ' - ' + (oc.description || oc.ownerchargecode.description) + ' @ ' + ownerCharge.toFixed(2)}
      </span>
    );
  
  });

  const isStock = parseFloat(expense.agencycostnet) >= 0;

  return (
    <tr key={expense.id}>
      {firstRow && 
      <React.Fragment>
        <td className="align-middle" rowSpan={expensesCount}>
          <Link 
            style={{ display: 'inline' }}
            onClick={() => { addTab('editJob', {workOrderId: job.id}, dispatch); }}
          >
            {job.id}
          </Link>
          {' '}
          <Button size="sm" style={{ padding: ".1rem .25rem" }} onClick={toggleNewExpenseModal} disabled={disabled}>
            add item
          </Button>
        </td>
        <td className="align-middle" rowSpan={expensesCount}>{job.shortdescription}</td>
        <td className="align-middle" rowSpan={expensesCount}>
          <Link onClick={() => { addTab('showProperty', {propertyId: job.property.id}, dispatch); }}>
            {job.property.name}
          </Link>
        </td>
      </React.Fragment>
      }
      <td className="align-middle">{expense.description}</td>
      <td className="align-middle">{expense.costitemcode.costitemcode}</td>
      <td className="align-middle">{parseFloat(expense.amountnetestimate) >= 0 ? expense.amountnetestimate.toFixed(2) : 'N/A'}</td>
      <td className="align-middle">{parseFloat(expense.amountnet) >= 0 ? expense.amountnet.toFixed(2) : 'N/A'}</td>
      <td className="align-middle">{isStock ? expense.agencycostnet.toFixed(2) : 'N/A'}</td>
      <td className="align-middle">
        { isStock && 'N/A' }
        { !isStock && !expense.donotpaysupplier && <Icon icon="check" color="green" />}
        { !isStock && expense.donotpaysupplier && <Icon icon="times" color="red" />}
      </td>
      <td className="align-middle">
        <span id={"charges" + expense.id}>
          {expense.invoiceto === 'Agency' &&
          <span>agency</span>
          }
          {expense.invoiceto === 'None (zero cost)' &&
          <span>none</span>
          }
          {expense.invoiceto === 'Owner' &&
          <span className="text-primary">
            {ownerChargesTotal.toFixed(2)}{' '}
            <Icon icon="info-circle" />
          </span>
          }
        </span>
        {ownerChargesCount > 0 &&
        <Tooltip 
          placement="bottom" 
          isOpen={chargesTool} 
          target={"charges" + expense.id} 
          toggle={() => { setChargesTool(!chargesTool) }}
        > 
          {ownerChargesTool}
        </Tooltip>
        }
      </td>
      <td className="align-middle">
        <Icon icon="pencil-alt" onClick={toggleEditExpenseModal} disabled={disabled} />{' '}
        <Icon icon="trash" onClick={() => deleteJobItem(job, expense)} disabled={disabled} />
      </td>
      {firstRow &&
      <td className="align-middle text-center" rowSpan={expensesCount}>
        {!canBeAllocated && !isAllocated && <Icon icon="times" color="red" />}
        {isAllocated && <Icon icon="check" color="green" />}
        {!(!canBeAllocated || isAllocated) &&
        <CustomInput
          className="d-inline"
          disabled={!canBeAllocated || isAllocated}
          type="switch"
          id={"sw" + job.id}
          label=""
          checked={selectedJobIds.includes(job.id)}
          onChange={e => toggleSelectedJob(job.id, e.target.checked)}
        />
        }
      </td>
      }
      {editExpenseModal &&
      <JobExpenseModal 
        workOrder={job}
        workOrderFields={{}}
        toggle={toggleEditExpenseModal}
        expense={expense} 
        handleExpense={expense => updateJobItem(job, expense)}
      />
      }
      {newExpenseModal &&
      <JobExpenseModal
        workOrder={job}
        workOrderFields={{}}
        toggle={toggleNewExpenseModal}
        handleExpense={expense => updateJobItem(job, expense)}
      />
      }
    </tr>
  );
}

function NewInvoiceModal(props) {

  const {
    state: {
      vatRates,
      fieldWorkers
    }
  } = useContext(Store);

  const [validate, setValidate] = useState({});

  const [fields, setFields] = useState({
    invoicenumber: '',
    invoicedate: '',
    totalamountnet: 0,
    supplier: undefined,
    document: undefined,
    showvat: false,
  });

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let f = {...fields, [name]: value};
    f = {...f, ...getVats(f)};

    setFields(f);
  }

  const getVats = fields => {
    let totalamountvat = 0;
    let totalamountgross = 0;
    let showvat = false;

    if (fields.supplier && fields.supplier.vatnumber.length > 0) {
      showvat = true;

      const vr = getVatRate();

      totalamountgross = parseFloat((fields.totalamountnet * (1 + (vr.percentage / 100))).toFixed(2));
      totalamountvat = totalamountgross - fields.totalamountnet;
    }

    return {
      totalamountvat,
      totalamountgross,
      showvat
    }
  }

  const getVatRate = () => {
    for (const vr of vatRates) {
      if (moment().isBetween(vr.fromdate, vr.todate, 'day', '[]')) {
        return vr;
      }
    }
  }  

  const handleSelectedFile = (event) => {
    if (event.target.files[0]) {
      setFields({
        ...fields, 
        document: event.target.files[0]
      });
    }
  }

  const validateForm = () => {
    let v = {...validate};

    v.invoicenumber = fields.invoicenumber ? true : false;
    v.invoicedate = fields.invoicedate ? true : false;
    v.totalamountnet = fields.totalamountnet ? true : false;
    v.supplier = fields.supplier && fields.supplier.id ? true : false;
    v.document = fields.document ? true : false;

    setValidate(v);

    return !Object.values(v).includes(false);
  }

  const save = async () => {
    if (!validateForm()) {
      return;
    }

    // get the WorkOrderSupplier (annoying!)
    let workOrderSuppliers = new FilterCollection({
      path: 'workordersupplier',
      object: common.WorkOrderSupplier,
    });
    workOrderSuppliers.limit = 1;
    workOrderSuppliers.page = 1;
    workOrderSuppliers.addFilters({ supplierid: fields.supplier.id });
    await workOrderSuppliers.fetch();

    // create the Document
    let documentBase = new common.Document();
    documentBase.name = fields.document.name;
    documentBase.description = '';
    documentBase.data = fields.document;

    await documentBase.upload();

    // create the ActorDocument
    let actorDocument = new common.ActorDocument();
    actorDocument.parent = fields.supplier;

    await actorDocument.create({
      document: documentBase
    });

    let supplierInvoice = new common.SupplierInvoice();

    await supplierInvoice.create({
      invoicenumber: fields.invoicenumber,
      invoicedate: fields.invoicedate,
      totalamountnet: fields.totalamountnet,
      workordersupplier: workOrderSuppliers.collection[0],
      supplierprovided: true,
      document: documentBase
    });

    await documentBase.update({
      name: fields.invoicenumber + '-' + fields.supplier.id + '-' + supplierInvoice.id + '-' +  fields.invoicedate
    });

    props.handleSubmit(supplierInvoice.id);
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>
        New Invoice
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label sm={4}>field worker</Label>
            <Col sm={8}>
              {fields.supplier ? fields.supplier.title + ' ' + fields.supplier.firstname + ' ' + fields.supplier.surname + ', or ' : ''}
              <SelectPersonModal
                button={onClick => {
                  return (
                    <Button size="sm" onClick={onClick}>choose field worker</Button>
                  );
                }}
                onSubmit={supplierId => {
                  const supplier = fieldWorkers.find(fw => fw.id === supplierId);

                  let f = {...fields, supplier};
                  f = {...f, ...getVats(f)};
                  setFields(f);
                }}
                oneOnly={true}
                payOnly={true}
              />
              {validate.supplier === false &&
              <div className="invalid-feedback d-block">
                You must choose a field worker.
              </div>
              }
            </Col>
          </FormGroup>
          <Text
            name="invoicenumber"
            label="invoice number"
            onChange={handleChange}
            value={fields.invoicenumber}
            invalid={validate.invoicenumber === false}
          />
          <Date
            name="invoicedate"
            label="invoice date"
            onChange={handleChange}
            value={fields.invoicedate}
            invalid={validate.invoicedate === false}
          />

          <FormGroup row className="align-items-center">
            <Label sm={4}>amount</Label>
            <Col sm={8}>
              <Row>
                <Col>
                  net<br />
                  <Input
                    type="number"
                    name="totalamountnet"
                    value={fields.totalamountnet}
                    onChange={handleChange}
                    invalid={fields.totalamountnet === false}
                  />
                </Col>
                <Col>
                  {fields.showvat &&
                  <React.Fragment>
                    VAT<br />
                    <Input
                      type="number"
                      name="totalamountvat"
                      disabled={true}
                      value={fields.totalamountvat.toFixed(2)}
                    />
                  </React.Fragment>
                  }
                </Col>
                <Col>
                  {fields.showvat &&
                  <React.Fragment>
                    gross<br />
                    <Input
                      type="number"
                      name="totalamountgross"
                      disabled={true}
                      value={fields.totalamountgross.toFixed(2)}
                    />
                  </React.Fragment>
                  }
                </Col>
              </Row>
              <Row>
                <Col>
                  {validate.amountnetestimate === false &&
                  <div className="invalid-feedback d-block mb-2">
                    You must enter an amount.
                  </div>
                  }                  
                </Col>
              </Row>                
            </Col>
          </FormGroup>  








          {/* <Number
            name="totalamountnet"
            label="invoice total (net)"
            onChange={handleChange}
            value={fields.totalamountnet}
            defaultValue={""}
            invalid={validate.totalamountnet === false}
          /> */}





          <FormGroup row>
            <Label sm={4}>invoice document</Label>
            <Col sm={8}>
              {fields.document ? fields.document.name + ', or ' : ''}
              <Label className="btn btn-primary">
                add file <input type="file" onChange={handleSelectedFile} hidden />
              </Label>
              {validate.document === false &&
              <div className="invalid-feedback d-block">
                You must upload a document.
              </div>
              } 
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={save}>create invoice</Button>{' '}
        <Button color="secondary" onClick={props.toggle}>cancel</Button>
      </ModalFooter>
    </Modal>
  );
}
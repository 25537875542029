import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { 
  Alert,
  Button, 
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, 
  Row } from 'reactstrap';
import SelectList from '../fields/SelectList';
import Number from '../fields/Number';
import Text from '../fields/Text';
import moment from 'moment';
import { hasVatNumber } from '../mixins/MiscMixin';

export default function OwnerChargeModal({
  expense, 
  toggle, 
  ownerCharge, 
  handleOwnerCharge, 
  costItemCodeId, 
  descriptionAddendum,
  workOrder,
  inGroup
}) {

  const {
    state: {
      costItemCodes,
      ownerChargeCodes,
      ownerChargeAmountTypes,
      vatRates,
    }
  } = useContext(Store);

  const [codes, setCodes] = useState();
  const [validate, setValidate] = useState({});

  const [fields, setFields] = useState({
    ownerchargecode: ownerCharge && ownerCharge.ownerchargecode.id,
    ownerchargeamounttype: ownerCharge && ownerCharge.ownerchargeamounttype.id,
    amount: (ownerCharge && ownerCharge.amount) || 0,
    description: (ownerCharge && ownerCharge.description) || '',
    invoicenumber: (ownerCharge && ownerCharge.invoicenumber) || '',
    invoicedate: (ownerCharge && ownerCharge.invoicedate) || '',
    requiresinvoice: (ownerCharge && ownerCharge.ownerchargecode.id && ownerCharge.ownerchargecode.recharge === true) ? true : false,
    amountnet: 0,
    amountvat: 0,
    amountgross: 0,
    costitemcode: undefined,
  });

  useEffect(() => {
    const costItemCode = costItemCodes.find(cic => cic.id === parseInt(costItemCodeId, 10));

    if (costItemCode.ownerchargecodes.length === 0) {
      setCodes(ownerChargeCodes);
    } else {
      setCodes(costItemCode.ownerchargecodes);
    }

    let newFields = {
      ...fields,
      ownerChargeDescription: getHelpfulDescription(fields),
      ownerChargeDescriptionGross: getHelpfulDescription(fields, true),
      costitemcode: costItemCode,
    };

    newFields = {
      ...newFields,
      ...getVats(newFields),
    }

    setFields(newFields);

  }, []);

  const onKeyDown = event => {
    //if ( === 8) {
        console.log(event);
    //}
  }
  
  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let newFields = {...fields, [name]: value};

    if (name === 'ownerchargecode') {
      const ownerChargeCode = ownerChargeCodes.find(
        occ => occ.id === parseInt(value, 10)
      );

      let description = descriptionAddendum || '';
      let requiresinvoice = false;
      let ownerchargeamounttype = newFields.ownerchargeamounttype;

      if (ownerChargeCode) {
        description = (descriptionAddendum ? (descriptionAddendum + ' - ') : '') + ownerChargeCode.description;
        requiresinvoice = ownerChargeCode.recharge === true;

        if (requiresinvoice) {
          if (fields.costitemcode && fields.costitemcode.recharge) {
            ownerchargeamounttype = '';
          } else {
            ownerchargeamounttype = '1';
          }
        }
      }

      newFields = {
        ...newFields, 
        description,
        requiresinvoice,
        ownerchargeamounttype,
      };
    }

    //when the value in amount field is deleted it is set to 0.
    //the following removes the 0 when a new value is entered
    if (name === 'amount' && newFields.amount.startsWith(0)) {
      newFields.amount = newFields.amount.slice(1);
    }

    newFields = {
      ...newFields, 
      ownerChargeDescription: getHelpfulDescription(newFields),
      ownerChargeDescriptionGross: getHelpfulDescription(newFields, true)
    };

    newFields = {
      ...newFields,
      ...getVats(newFields),
    }

    setFields(newFields);
  }

  const getVats = (fields) => {
    var ownerChargeNet = 0;
    var ownerChargeVat = 0;
    var ownerChargeGross = parseFloat(fields.amount);

    const ownerChargeCode = ownerChargeCodes.find(
      occ => occ.id === parseInt(fields.ownerchargecode, 10)
    );

    if (ownerChargeCode && 
        ownerChargeCode.vatband && 
        ownerChargeCode.vatband.vatband === 'Standard') {

      const vr = getVatRateFromBand(ownerChargeCode.vatband);

      ownerChargeNet = ownerChargeGross / (1 + (vr.percentage / 100));
      ownerChargeVat = ownerChargeGross - ownerChargeNet;
    }

    return {
      amountnet: ownerChargeNet,
      amountvat: ownerChargeVat,
      amountgross: ownerChargeGross,
    };
  }

  const getVatRateFromBand = (vb) => {
    var vr;

    vatRates.forEach(vRate => {
      if (vRate.vatband && 
          vRate.vatband.id === vb.id && 
          moment().isBetween(vRate.fromdate, vRate.todate, 'day', '[]')
      ) {
        vr = vRate;
      }
    });

    return vr;
  }  

  const getHelpfulDescription = (fields, useGross = false) => {
    var ownerChargeDescription = '';
    var amountNetEstimate = 0;

    if (expense.agencycostnet) {
      amountNetEstimate += expense.agencycostnet;
      amountNetEstimate += useGross ? expense.agencycostvat : 0;
    } else if (expense.amountnet) {
      amountNetEstimate += expense.amountnet;
      amountNetEstimate += useGross ? expense.amountvat : 0;
    } else {
      amountNetEstimate += expense.amountnetestimate;
      amountNetEstimate += useGross ? expense.amountvatestimate : 0;
    }

    var amount = (fields.amount) ? parseFloat(fields.amount) : 0;
    var ownerCharge = 0;
    var addendum = expense.amountnetestimate ? true : false;

    const ownerChargeAmountType = ownerChargeAmountTypes.find(
      ocat => ocat.id === parseInt(fields.ownerchargeamounttype, 10)
    );

    const ownerChargeCode = ownerChargeCodes.find(
      occ => occ.id === parseInt(fields.ownerchargecode, 10)
    );

    if (ownerChargeAmountType) {
      switch (ownerChargeAmountType.ownerchargeamounttype) {
        case 'Fixed':
          ownerCharge = amount;
          addendum = false;
          break;
        case 'Percentage':
          var multiplier = amount / 100;
          ownerCharge = (amountNetEstimate * multiplier).toFixed(2);
          break;
        case 'Amount':
          ownerCharge = amountNetEstimate + amount;
          break;
        default:
      }
    } else {
      ownerCharge = amountNetEstimate;
    }

    ownerChargeDescription = 'the owner will be charged £' + ownerCharge;

    if (ownerChargeCode && 
      ownerChargeCode.vatband && 
      ownerChargeCode.vatband.vatband === 'Standard') {

      ownerChargeDescription += ' inc. VAT'
    }

    if (addendum) {
      ownerChargeDescription += ' (if the final expense is £' + amountNetEstimate.toFixed(2) + ')';
    }

    return ownerChargeDescription;
  }

  const save = () => {

    if (!validateForm()) {
      return;
    }

    const ownerchargecode = ownerChargeCodes.find(
      occ => occ.id === parseInt(fields.ownerchargecode, 10)
    );

    let ownerchargeamounttype = ownerChargeAmountTypes.find(
      ocat => ocat.id === parseInt(fields.ownerchargeamounttype, 10)
    );

    let amount = fields.amount;

    if (!ownerchargeamounttype) {
      // owner to be charged at cost, e.g. 100% of the WOE amount
      ownerchargeamounttype = ownerChargeAmountTypes.find(
        ocat => ocat.ownerchargeamounttype === 'Percentage'
      );
      amount = 100;
    }

    let obj = {
      new: !ownerCharge ? true : false,
      updated: true,
      ownerchargecode,
      ownerchargeamounttype,
      amount,
      description: fields.description,
      invoicenumber: fields.invoicenumber,
      invoicedate: fields.invoicedate,
    }

    handleOwnerCharge({...ownerCharge, ...obj});
    toggle();
  }

  const validateForm = () => {
    let v = {...validate};

    v.description = fields.description.length > 0 ? true : false;
    v.ownerchargecode = fields.ownerchargecode ? true : false;

    v.invoicenumber = true;
    v.invoicedate = true;
    // if (fields.requiresinvoice) {
    //   v.invoicenumber = fields.invoicenumber ? true : false;
    //   v.invoicedate = fields.invoicedate ? true : false;
    // }
    
    setValidate(v);

    return !Object.values(v).includes(false);
  }


  if (!codes) {
    return null;
  }

  const ownerChargeAmountType = ownerChargeAmountTypes.find(
    ocat => ocat.id === parseInt(fields.ownerchargeamounttype, 10)
  );

  const ownerChargeCode = ownerChargeCodes.find(
    occ => occ.id === parseInt(fields.ownerchargecode, 10)
  );

  const vatable = (ownerChargeCode && ownerChargeCode.vatband && ownerChargeCode.vatband.vatband === 'Standard') ? true : false;

  return (
    <Modal isOpen toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{ownerCharge ? 'edit ' : 'add '} owner charge</ModalHeader>
      <ModalBody>
        <SelectList
          name="ownerchargecode"
          label="owner charge code"
          values={codes.map(occ => {
            return {
              id: occ.id,
              toString: () => occ.description + ' (' + occ.ownerchargecode + ')'
            }
          })}
          emptyValue="Please select"
          value={fields.ownerchargecode}
          onChange={handleChange}
          invalid={validate.ownerchargecode === false}
          disabled={inGroup}
        />

        <Text
          name="description"
          value={fields.description}
          onChange={handleChange}
          maxLength={255}
          invalid={validate.description === false}
        />

        <Alert color="warning">
          Please note that the description above will appear on owner invoices and statements!
        </Alert>
        
        {/* {fields.requiresinvoice &&
        <React.Fragment>
          <Text
            name="invoicenumber"
            label="third-party invoice number"
            value={fields.invoicenumber}
            onChange={handleChange}
            invalid={validate.invoicenumber === false}
          />
          <Date
            name="invoicedate"
            label="third-party invoice date"
            value={fields.invoicedate}
            onChange={handleChange}
            invalid={validate.invoicedate === false}
          />   
        </React.Fragment>
        } */}
             
        <SelectList
          name="ownerchargeamounttype"
          label="amount type"
          values={ownerChargeAmountTypes.map(ocat => {
            return {
              id: ocat.id,
              toString: () => ocat.ownerchargeamounttype + ' (' + ocat.description + ')'
            }
          })}
          emptyValue="Owner charged at cost"
          value={fields.ownerchargeamounttype}
          onChange={handleChange}
          disabled={fields.requiresinvoice}
        />
        {fields.ownerchargeamounttype && ownerChargeAmountType.ownerchargeamounttype !== 'Fixed' &&
        <Number
          name="amount"
          label={"owner charge " + (ownerChargeAmountType.ownerchargeamounttype === 'Percentage' ? 'percentage' : 'amount')} 
          value={fields.amount}
          onChange={handleChange}
          onKeyDown={onKeyDown}
        />
        }
        {fields.ownerchargeamounttype && ownerChargeAmountType.ownerchargeamounttype === 'Fixed' &&
        <FormGroup row className="align-items-center">
          <Label sm={4}>amount</Label>
          <Col sm={8}>
            {vatable &&
            <Row>
              <Col>
                net<br />
                <Input
                  type="number"
                  name="amountnet"
                  value={fields.amountnet.toFixed(2)}
                  disabled={true}
                />
              </Col>
              <Col>
                VAT<br />
                <Input
                  type="number"
                  name="amountvat"
                  value={fields.amountvat.toFixed(2)}
                  disabled={true}
                />    
              </Col>
              <Col>
                gross<br />
                <Input
                  type="number"
                  name="amount"
                  value={fields.amount}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            }
            {!vatable &&
            <Row>
              <Col>
                net<br />
                <Input
                  type="number"
                  name="amount"
                  value={fields.amount}
                  onChange={handleChange}
                  disabled={vatable}
                />
              </Col>
              <Col>
                VAT<br />
                <Input
                  type="number"
                  name="amountvat"
                  value={fields.amountvat.toFixed(2)}
                  disabled={true}
                />    
              </Col>
              <Col>
                gross<br />
                <Input
                  type="number"
                  name="amountgross"
                  value={fields.amountgross.toFixed(2)}
                  disabled={true}
                />
              </Col>
            </Row>
            }
          </Col>
        </FormGroup>
        }
        {parseInt(fields.ownerchargeamounttype, 10) !== 1 &&
        <FormGroup row>
          <Col sm={12}>
            <Alert color="primary">
              {!workOrder &&
              <React.Fragment>
                If the field worker is VAT registered, {fields.ownerChargeDescriptionGross}<br /><br />
                If the field worker is not VAT registered, {fields.ownerChargeDescription}
              </React.Fragment>
              }
              {workOrder &&
              <React.Fragment>
                {hasVatNumber(workOrder) ?
                <React.Fragment>
                  The field worker is VAT registered, so {fields.ownerChargeDescriptionGross}
                </React.Fragment>
                :
                <React.Fragment>
                  The field worker is not VAT registered, so {fields.ownerChargeDescription}
                </React.Fragment>
                }
              </React.Fragment>
              }
            </Alert>
          </Col>
        </FormGroup>
        }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={save}>OK</Button>
      </ModalFooter>
    </Modal>
  );
}
import React, { useCallback, useContext, useEffect, useRef, useReducer, useState } from 'react';
import ReactDOM from 'react-dom';
import { DayPilot, DayPilotScheduler } from "daypilot-pro-react";
import { common, FilterCollection } from 'plato-js-client';
import { 
  Button, 
  ButtonGroup, 
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopoverBody,
  Row,
  UncontrolledButtonDropdown,
  UncontrolledPopover } from 'reactstrap';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Store } from "./Store";
import { 
  addTab, 
  updateJob, 
  updateJobBegin,
  updateJobEnd,
  updateJobSubStatus } from './Actions';
import DatePickerButton from './components/DatePickerButton';
import SelectWorkTypeModal from './components/SelectWorkTypeModal';
import Icon from './components/Icon';
import PrettyLoader from './components/PrettyLoader';
import { infoToast } from './components/Toasties';
import SelectProperty from './fields/SelectProperty';
import { stringToColour } from './mixins/MiscMixin';
import './css/cdscheduler.css';


const moment = extendMoment(Moment);

const blankCanvas = document.createElement('canvas');

export default function TabScheduler() {

  const {
    dispatch,
    state: {
      activeTab,
      currentGroup,
      fieldWorkers,
      highlightJob,
      properties,
      change
    }
  } = useContext(Store);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_SELECTED_JOB':
        let selectedJob = undefined;

        if (Number.isInteger(action.payload)) {
          // field worker job

          // FLAT > MAP > FLAT > FIND > LOL. I'm so sorry
          selectedJob = Object.values(
            state.jobs
          ).flat().map(
            obj => obj.jobs
          ).flat().find(
            job => job.id === action.payload
          );
        } else {
          selectedJob = action.payload;
        }

        if (!selectedJob) {
          return {...state};
        }

        const selectedJobProperty = properties.find(
          property => property.id === selectedJob.property.id
        );

        return {
          ...state,
          selectedJob, 
          selectedJobProperty: selectedJobProperty.length > 0 ? selectedJobProperty[0] : undefined
        };
      case 'SET_RESOURCES':
        return {...state, resources: action.resources};
      case 'SET_EVENTS':
        return {...state, events: action.events};
      case 'SET_JOB_EVENTS':
        return {...state, jobEvents: action.jobEvents};
      case 'SET_BOOKING_EVENTS':
        return {...state, bookingEvents: action.bookingEvents};
      case 'SET_DAY':
        return {...state, day: action.payload};
      case 'SET_SCALE':
        return {...state, scale: action.payload};
      case 'RELEVANT_SKILLS':
        return {...state, relevantSkills: action.relevantSkills};
      case 'SEND_COMMUNICATIONS':
        return {...state, sendCommunications: action.payload};
      case 'BUCKET_FILTER':
        return {...state, bucketFilter: action.payload};
      case 'REQUIRED_BY_FROM':
        return {...state, requiredByFrom: action.payload};
      case 'REQUIRED_BY_TO':
        return {...state, requiredByTo: action.payload};
      case 'SET_WORK_TYPE':
        return {...state, workType: action.payload};
      case 'SET_PROPERTY':
        return {...state, property: action.payload};
      case 'SET_FILTER_TEXT':
        return {...state, filterText: action.payload};
      case 'SET_LOCATION_TEXT':
        return {...state, locationText: action.payload};
      case 'SET_JOB_PILLS':
        return {...state, jobPills: action.payload};
      case 'SET_DATE_JOBS':
        let jobs = {...state.jobs};

        Object.keys(action.payload).forEach(date => {
          jobs[date] = action.payload[date];
        });

        return {...state, jobs};
      case 'ADD_FILTER':
        let filters = [...state.filters];

        let obj = {
          filter: state.filterOption,
        };

        switch (state.filterOption) {
          case 'requiredBy':
            // only one 'required by' filter at a time
            filters = filters.filter(filter => {
              return filter.filter !== 'requiredBy';
            });

            obj.name = 'required by';
            obj.value = state.filterRequiredByOption.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
            obj.type = state.filterRequiredByOption;
            
            if (state.filterRequiredByOption === 'specifyRange') {
              obj.from = state.requiredByFrom;
              obj.to = state.requiredByTo;
            }
            break;
          case 'workType':
            obj.name = 'work type';
            obj.value = state.workType.worktype;
            obj.workType = state.workType;
            break;
          case 'property':
            obj.name = 'property';
            obj.value = state.property[0].name;
            obj.property = state.property[0];
            break;
          case 'description':
            obj.name = 'description';
            obj.value = state.filterText;
            obj.description = state.filterText;
            break;
          case 'town':
              obj.name = 'town';
              obj.value = state.locationText;
              obj.description = state.locationText;
              break;
        }

        filters.push(obj);

        return {...state, filters};
      case 'REMOVE_FILTER': {
        let filters = [...state.filters];
        filters.splice(action.payload, 1);

        if (filters.length === 0) {
          // go back to the default 'this week' view
          filters.push({
            filter: 'requiredBy',
            name: 'required by',
            type: 'nextWeek',
            value: 'next week',
          });
        }

        return {...state, filters};
      }
      case 'SET_FILTER_OPTION':
        return {...state, filterOption: action.payload};      
      case 'SET_FILTER_REQUIRED_BY_OPTION':
        return {...state, filterRequiredByOption: action.payload};
      case 'SET_REFS':
        return {...state, refs: action.payload};
      case 'SET_LOADING':
        return {...state, loading: action.payload};
      case 'SET_FIRST_LOAD':
        return {...state, firstLoad: action.payload};
      case 'TOGGLE_NEW_JOB_MODAL':
        return {...state, newJobModal: !state.newJobModal};
      case 'SET_NEW_JOB_ARGS':
        return {...state, newJobArgs: action.payload};
      case 'SET_UNSCHEDULED_JOBS':
        return {...state, unscheduledJobs: action.payload};
      case 'SET_BUCKET_LOADING':
        return {...state, bucketLoading: action.payload};
      case 'CREATE_EVENT':
        let unscheduledJobs = [...state.unscheduledJobs];
        let unscheduledJobIndex = unscheduledJobs.findIndex(uj => uj.id === parseInt(action.payload.id, 10));
        let unscheduledJob = unscheduledJobs[unscheduledJobIndex];

        let jobEvents = [...state.jobEvents];

        let html = `
          <div style="height: 100%; width: 100%; display: table; white-space: nowrap;">
            <div style="display: table-cell; vertical-align: middle;">
              ${unscheduledJob.shortdescription}<br />
              ${unscheduledJob.property.name} 
            </div>
          </div>
        `; // todo: put in function

        let event = {
          id: 'J' + action.payload.id,
          duration: unscheduledJob.durationminutes,
          title: html,
          resource: action.payload.resource,
          start: moment(action.payload.start)
        };

        jobEvents.push(event);
        unscheduledJobs.splice(unscheduledJobIndex, 1);

        return {...state, jobEvents, unscheduledJobs};
      case 'UPDATE_EVENT': {
        let jobEvents = [...state.jobEvents];
        let event = jobEvents.find(e => e.id === action.payload.id);

        if (event) {
          if (action.payload.hasOwnProperty('start')) { event.start = action.payload.start; }
          if (action.payload.hasOwnProperty('resource')) { event.resource = action.payload.resource; }
          if (action.payload.hasOwnProperty('duration')) { event.duration = action.payload.duration; }
        }

        return {...state, jobEvents};
      }
      case 'UNSCHEDULE_EVENT': {
        let jobEvents = [...state.jobEvents];
        jobEvents.splice(
          jobEvents.findIndex(e => e.id === action.payload.id),
          1
        );

        return {...state, jobEvents};
      }
      default:
    }
  };

  const [state, localDispatch] = useReducer(reducer, {
    selectedJob: undefined,
    selectedJobProperty: undefined,
    resources: [],
    events: [],
    bookingEvents: [],
    jobEvents: [],
    relevantSkills: undefined,
    sendCommunications: undefined,
    day: moment(),
    scale: "quarter",
    bucketFilter: undefined,
    requiredByFrom: moment().format('YYYY-MM-DD'),
    requiredByTo: moment().add(7, 'days').format('YYYY-MM-DD'),
    workType: undefined,
    property: undefined,
    filterText: '',
    locationText: '',
    jobPills: undefined,
    jobs: {},
    filters: [{
      filter: 'requiredBy',
      name: 'required by',
      type: 'nextWeek',
      value: 'next week',
    }],
    filterOption: '',
    filterRequiredByOption: 'overdue',
    refs: undefined,
    loading: false,
    newJobModal: false,
    newJobArgs: undefined,
    unscheduledJobs: undefined,
    bucketLoading: false,
  });

  // onload, get jobs & plans for the current week +/- 1
  useEffect(() => {
    const start = moment(state.day).subtract(1, 'weeks').startOf('isoweek');
    const end = moment(state.day).add(1, 'weeks').endOf('isoweek');

    getJobsAndPlans(start, end, true);
  }, [dispatch]);

  // [state.jobs, state.selectedJob] - turn jobs into an array of events
  useEffect(() => {
    if (!state.jobs) {
      return;
    }

    const getJobEvents = () => {
      let jobEvents = [];

      Object.values(state.jobs).forEach(date => {
        date.plans.forEach(plan => {
          const parsed = JSON.parse(plan.dayplan);

          parsed.plan.forEach((ele, i) => {
            if (ele.type === 'job') {

              let journeyTo = 0;
              if (parsed.plan[i - 1] && parsed.plan[i - 1].type === 'travel') {
                journeyTo = parsed.plan[i - 1].durationValue;
                if (journeyTo > 0) { journeyTo = Math.ceil(journeyTo / 60); }
              }

              let journeyAway = 0;
              if (i === parsed.plan.length - 2) {
                journeyAway = parsed.plan[parsed.plan.length - 1].durationValue;
                if (journeyAway > 0) { journeyAway = Math.ceil(journeyAway / 60); }
              }

              const job = date.jobs.find(j => j.id === parseInt(ele.id, 10));

              if (!job) {
                return;
              }

              const supplier = job.workordersupplier.supplier;
              const association = plan.supplierid === supplier.id ? false : true;

              // default job to 2 hours just in case duration ain't set
              let duration = 120;
              if (job.durationminutes && job.durationminutes > 0) {
                duration = job.durationminutes;
              }
     
              // if a job is scheduled for midnight, bosh it on 9am
              let preferredstartdatetime = job.preferredstartdatetime.replace('00:00:00', '09:00:00');
              const start = moment(preferredstartdatetime);

              let html = `
                <div style="height: 100%; width: 100%; display: table; white-space: nowrap;">
                  <div style="display: table-cell; vertical-align: middle;">
                    ${(association ? 'Assoc. ' : '') + job.shortdescription}<br />
                    ${job.property.name} 
                  </div>
                </div>
              `;

              let eventId = 'J' + job.id;
              if (association) {
                eventId = `JA${job.id}_${plan.supplierid}`;
              }

              let event = {
                id: eventId,
                duration,
                title: html,
                resource: 'R' + plan.supplierid,
                start,
                active: state.selectedJob && state.selectedJob.id === job.id
              };

              if (journeyTo > 0) { event.travelTo = journeyTo; }
              if (journeyAway > 0) { event.travelFrom = journeyAway; }

              let status = job.status;
              status = (status.charAt(0).toLowerCase() + status.slice(1)).replace(' ', ''); 

              if (status !== 'new') {
                // disallow moving/resizing events if they aren't 'new'
                event.readOnly = true;
              }

              const color = currentGroup.colors && currentGroup.colors[status] 
                ? currentGroup.colors[status] 
                : undefined;
              if (color) { event.color = color.background; }

              jobEvents.push(event);              
            }
          });
        });
      });
  
      return jobEvents;
    };

    localDispatch({type: 'SET_JOB_EVENTS', jobEvents: getJobEvents()});
  }, [state.jobs, state.selectedJob]);

  // [fieldWorkers, state.relevantSkills, state.selectedJob]
  // turn field workers (and selected job property) into an array of resources
  useEffect(() => {
    const getResources = () => {

      let resources = [];

      const selectedJob = state.selectedJob;
  
      fieldWorkers.forEach(fieldWorker => {
        let name = fieldWorker.firstname + ' ' + fieldWorker.surname;
        let shortName = fieldWorker.firstname + fieldWorker.surname;
 
        if (state.selectedJob) {
          const hasWorkType = (workType) => {
            let found = fieldWorker.worktypes.find(wt => {
              return wt.worktype.id === workType.id;
            });
  
            if (!found && workType.parentworktype && workType.parentworktype.id) {
              found = hasWorkType(workType.parentworktype);
            }
  
            return found;
          }
  
          const hwt = hasWorkType(selectedJob.worktype);
          const hwtParent = hwt && hwt.worktype.id === selectedJob.worktype.id;
  
          if (state.relevantSkills && !hwt) {
            // we're only showing field workers with relevant skills (work types)
            return;
          }
  
          if (hwt) {
            // todo
          }
        }

        let html = `
          <div style="height: 100%; width: 100%; display: table;">
            <div style="display: table-cell; vertical-align: middle;">
              <div class="avatar-circle avatar-circle-scheduler" style="float: left; background-color: ${stringToColour(shortName)}; margin-left: 2px; margin-right: 4px;">
                <span class="initials initials-scheduler">
                  ${fieldWorker.firstname.substr(0, 1).toUpperCase() + fieldWorker.surname.substr(0, 1).toUpperCase()}
                </span>
              </div>
                <span style="line-height: 30px;">${name}</span>
            </div>
          </div>
        `;

        resources.push({
          id: 'R' + fieldWorker.id,
          name: html,
          workingDays: fieldWorker.workingdays,
          holidays: fieldWorker.holidays,
        });
      });
  
      if (selectedJob) {
        const property = selectedJob.property;

        let propertyHtml = `
          <div style="height: 100%; width: 100%; display: table;">
            <div style="display: table-cell; vertical-align: middle;">
              <div class="avatar-circle avatar-circle-scheduler" style="float: left; background-color: grey; margin-left: 2px; margin-right: 4px;">
                <span class="initials initials-scheduler" style="top: 5px !important;">
                  <img alt="home" height="20px" width="20px" src="${process.env.PUBLIC_URL + "/home.png"}" />
                </span>
              </div>
                <span style="line-height: 30px;">${property.name}</span>
            </div>
          </div>
        `;
  
        resources.unshift({
          id: 'Rprop' + property.id,
          name: propertyHtml,
        });
      }
  
      return resources;
    };

    localDispatch({ type: 'SET_RESOURCES', resources: getResources() });
  }, [dispatch, fieldWorkers, state.relevantSkills, state.selectedJob]);

  // activeTab - fire a window resize 
  useEffect(() => {
    if (activeTab === 'scheduler') {
      let event = new CustomEvent("resize");
      window.dispatchEvent(event);
    }
  }, [activeTab]);

  // state.day - get plans & jobs for new day
  useEffect(() => {
    if (state.day && !state.jobs[state.day.format('YYYY-MM-DD')] && state.firstLoad) {
      getJobsAndPlans(moment(state.day), moment(state.day));
    }
  }, [state.day]);

  // state.selectedJob - get bookings for selected job property
  useEffect(() => {
    if (state.selectedJob) {
      const job = state.selectedJob;
      const propertyId = job.property.id;

      getBookingEvents(propertyId).then(bookingEvents => {
        localDispatch({type: 'SET_BOOKING_EVENTS', bookingEvents});
      });
    }
  }, [state.selectedJob]);

  // [state.jobEvents, state.bookingEvents] - combine job events and booking events
  useEffect(() => {
    localDispatch({
      type: 'SET_EVENTS', 
      events: [...state.jobEvents, ...state.bookingEvents]
    });
  }, [state.jobEvents, state.bookingEvents]);

  // state.filters - getUnscheduledJobs
  useEffect(() => {
    getUnscheduledJobs();
  }, [state.filters]);

  // [state.unscheduledJobs, state.scale, state.selectedJob] - turn unscheduled jobs into job 'pills'
  useEffect(() => {
    if (state.unscheduledJobs) {
      let pills = state.unscheduledJobs.map(job => 
        <UnscheduledJob 
          key={'unscheduled' + job.id} 
          id={'unscheduled' + job.id} 
          job={job} 
          clickHandler={j => localDispatch({type: 'SET_SELECTED_JOB', payload: job})}
          scale={state.scale}
          active={state.selectedJob && state.selectedJob.id === job.id}
        />
      );

      // add in some invisible pills so the ones at the bottom don't get stretched (which imo looks weird)
      for (let i = 0; i < 7; i++) {
        pills.push(
          <div key={'placeholder' + pills.length} className="job-pill-placeholder">&nbsp;</div>
        );
      }

      localDispatch({type: 'SET_JOB_PILLS', payload: pills});
    }
  }, [state.unscheduledJobs, state.scale, state.selectedJob]);

  // highlightJob
  useEffect(() => {
    if (highlightJob) {
      var pill = document.getElementById("unscheduled" + highlightJob);

      if (pill) {
        pill.style.animation = 'blinker 1s linear infinite';

        setTimeout(() => {
          pill.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 1000);
  
        setTimeout(() => {
          pill.style.animation = '';
        }, 5000);
      } else {
        infoToast(
          <div>
            Job {highlightJob} is excluded from the current scheduler filter(s). 
          </div>, 
          false
        );   
      }
    }
  }, [highlightJob]);

  // change
  useEffect(() => {
    if (!change) { return; }

    let oldDate = undefined;

    if (change.data.old.preferredstartdatetime) {
      oldDate = moment(change.data.old.preferredstartdatetime);
      getJobsAndPlans(oldDate, oldDate);
    }

    if (change.data.new.preferredstartdatetime) {
      const newDate = moment(change.data.new.preferredstartdatetime);
      if (!oldDate || (oldDate.format('YYYY-MM-DD') !== newDate.format('YYYY-MM-DD'))) {
        getJobsAndPlans(newDate, newDate);
      }
    }

    if (state.unscheduledJobs) {
      if ((state.unscheduledJobs && state.unscheduledJobs.map(uj => uj.id).includes(change.jobId)) ||
          change.data.new.substatus === 'awaitingscheduling'
      ) {
        getUnscheduledJobs();
      }
    }
  }, [change]);


  const getUnscheduledJobs = () => {
    localDispatch({type: 'SET_BUCKET_LOADING', payload: true});

    let jobs = new FilterCollection({
      path: 'workorder',
      object: common.WorkOrder,
    });
    jobs.limit = 9999;

    let filters = {
      type: 'Instance',
      status: 'New',
      substatus: 'awaitingscheduling',
    }

    if (currentGroup.propertyIds && currentGroup.propertyIds.length > 0) {
      filters.propid = currentGroup.propertyIds.join('|');
    } else {
      filters.propertybrandingid = currentGroup.brandingIds.join('|');
      filters.livepropertiesonly = '1';
    }

    state.filters.forEach(filter => {
      switch (filter.filter) {
        case 'requiredBy':
          switch (filter.type) {
            case 'overdue':
              filters.requiredbydate = '<' + moment().subtract(1, 'days').format('YYYY-MM-DD');
              break;
            case 'today':
              filters.requiredbydate = '<' + moment().format('YYYY-MM-DD');
              break;
            case 'thisWeek':
              filters.requiredbydate = '<' + moment().endOf('isoweek').format('YYYY-MM-DD');
              break;
            case 'nextWeek':
              filters.requiredbydate = '<' + moment().add(7, 'days').endOf('isoweek').format('YYYY-MM-DD');
              break;
            case 'thisMonth':
              filters.requiredbydate = '<' + moment().add(7, 'days').endOf('month').format('YYYY-MM-DD');
              break;
            case 'nextMonth':
              filters.requiredbydate = '<' + moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
              break;
            case 'specifyRange':
              filters.requiredbydate = moment(filter.from).format('YYYY-MM-DD') + '/' + moment(filter.to).format('YYYY-MM-DD');
              break;
            default:
          }

          break;
        case 'workType':
          filters.worktypeid = state.workType.id;
          break;
        case 'property':
          filters.propertyid = state.property[0].id;
          break;
        case 'description':
          filters.shortdescription = '~' + state.filterText.toLowerCase();
          break;
        case 'town':
            filters.property_town = '*' + state.locationText.toLowerCase();
            break;
      }
    });

    jobs.addFilters(filters);

    jobs.fields = [
      'id',
      'durationminutes',
      'fromtemplate',
      'fromrecurringtemplate',
      'bookingserviceworkorder',
      'propertynano',
      'requiredbydate',
      'source',
      'substatusmini',
      'shortdescription',
      'status',
      'worktypemini',
    ].join(':');

    jobs.fetch().then(() => {
      jobs.sort((a, b) => (a.requiredbydate > b.requiredbydate) ? 1 : -1);
      localDispatch({type: 'SET_UNSCHEDULED_JOBS', payload: jobs.collection});
      localDispatch({type: 'SET_BUCKET_LOADING', payload: false});
    });
  }

  const getBookingEvents = async propertyId => {

    let bookings = new FilterCollection({
      path: 'booking',
      object: common.Booking,
    });

    bookings.limit = 101;
    bookings.addFilters([
      {
        propid: propertyId,
        fromdate: '>2019-01-01',
        cancelledbooking: false,
        transferredbooking: false,
        //potentialbooking: false,
      }
    ]);

    await bookings.fetch();

    let events = [];

    bookings.forEach(booking => {
      if (booking.status !== 'Potential - Enquiry') {

        const bookingCustomers = booking.customers.collection;
        const customer = bookingCustomers.length > 0 ? bookingCustomers[0].name : booking.guesttype;

        const start = moment(booking.fromdate).add(16, 'hours');
        const end = moment(booking.todate).add(10, 'hours');
        const duration = end.diff(start, 'minutes');

        const fromFormatted = moment(booking.fromdate).format('DD/MM/YY');
        const toFormatted = moment(booking.todate).format('DD/MM/YY');

        let html = `
          <div style="height: 100%; width: 100%; display: table; white-space: nowrap;">
            <div style="display: table-cell; vertical-align: middle; padding-left: 5px; padding-right: 5px;">
              booking ${booking.id}<br />
              from: ${fromFormatted}, to: ${toFormatted}, customer: ${customer}
            </div>
          </div>
        `;

        events.push({
          id: 'B' + booking.id,
          duration,
          title: html,
          resource: 'Rprop' + booking.property.id,
          start,
          readOnly: true
        });
      }
    });

    return events;
  }

  const getJobsAndPlans = (start, end, firstLoad) => {
    if (currentGroup.fieldWorkers.length === 0) {
      localDispatch({type: 'SET_DATE_JOBS', payload: []});
      return;
    }

    localDispatch({type: 'SET_LOADING', payload: true});

    let jobs = new FilterCollection({
      path: 'workorder',
      object: common.WorkOrder,
    });
    jobs.limit = 9999;
    jobs.addFilters([{
      type: 'Instance',
      supplierid: currentGroup.fieldWorkers.join('|'),
      preferredstartdatetime: [
        start.format('YYYY-MM-DDT00.00.00'), 
        end.format('YYYY-MM-DDT23.59.59')
      ].join('/'),
      status: [
        'Approved', 
        'Completed', 
        'Financially Completed', 
        'Invoice Approved', 
        'Invoice Rejected', 
        'Invoiced', 
        'New', 
        'Owner Charged', 
        'Started', 
        'Supplier Paid'
      ].join('|'),
    }]);
  
    jobs.fields = [
      'id',
      'actors',
      'durationminutes',
      'preferredstartdatetime',
      'propertynano',
      'shortdescription',
      'status',
      'substatusmini',
      'workordersuppliermini',
      'worktypemini',
    ].join(':');

    let promises = [];

    promises.push(jobs.fetch());

    var range = moment.range(start, end);

    for (let date of range.by('days')) {
      const dateStr = date.format('YYYY-MM-DD');

      let supplierDayPlans = new FilterCollection({
        path: 'dayplan',
        object: common.SupplierDayPlan,
      });
      supplierDayPlans.addFilters([{
        supplierid: fieldWorkers.map(fw => fw.id).join('|'),
        plandate: dateStr
      }]);
      supplierDayPlans.limit = 999;

      promises.push(
        supplierDayPlans.fetch().catch(() => {
          // prevent dayplan fault from showing jobs on scheduler
          return;
        })
      );
    }

    Promise.all(promises).then(result => {

      let payload = {};

      var range = moment.range(start, end);

      for (let date of range.by('days')) {
        const dateStr = date.format('YYYY-MM-DD');
        if (!payload[dateStr]) {
          payload[dateStr] = {
            jobs: [],
            plans: [],
          };
        }
      }

      result.forEach(obj => {
        if (obj) {
          if (obj.options.path === 'workorder') {
            obj.forEach(job => {
              const preferredStart = moment(job.preferredstartdatetime).format('YYYY-MM-DD');
              payload[preferredStart].jobs.push(job);
            });
          } else {
            obj.forEach(sdp => {
              const planDate = sdp.plandate;
              payload[planDate].plans.push(sdp);
            });
          }
        }
      });

      localDispatch({type: 'SET_DATE_JOBS', payload});
      localDispatch({type: 'SET_LOADING', payload: false});

      if (firstLoad) {
        localDispatch({type: 'SET_FIRST_LOAD', payload: true});
      }
    });
  }
 
  const navigate = where => {
    let newDay = moment(state.day);

    let unit = 'days';
    if (state.scale === 'hour') { unit = 'weeks'; }
    if (state.scale === 'day') { unit = 'months'; }

    switch (where) {
      case 'prev': newDay.subtract(1, unit); break;
      case 'now': newDay = moment(); break;
      case 'next': newDay.add(1, unit); break;
      default: newDay = moment(where); break;
    }

    localDispatch({type: 'SET_DAY', payload: newDay});
  }

  const gridChangeHandler = async e => {
    switch (e.action) {
      case 'create': { // the job has been scheduled
        const jobId = parseInt(e.id, 10);
        let job = state.unscheduledJobs.find(j => j.id === jobId);

        if (!job) {
          console.log('create - job not found', e);
          return;
        }

        localDispatch({ type: 'CREATE_EVENT', payload: e });

        const resourceId = parseInt(e.resource.replace('R', ''), 10);
        let resource = fieldWorkers.find(fw => fw.id === resourceId);

        const start = moment(e.start);
        const end = moment(e.start).add(job.durationminutes, 'minutes');
        const day = start.format('dddd');
        const dmy = end.format('YYYY-MM-DD');

        let workingDay = resource.workingdays.collection.find(wd => {
          return wd.dayofweek === day;
        });

        if (workingDay) {
          const wdStart = moment(dmy + 'T' + workingDay.fromtime);
          const wdEnd = moment(dmy + 'T' + workingDay.totime);
    
          if (start.isBetween(wdStart, wdEnd, null, '[]') &&
              end.isBetween(wdStart, wdEnd, null, '[]')) {
              
            // check holidays
            for (const h of resource.holidays.collection) {
              if (start.isBetween(h.fromdate, h.todate, 'days', '[]')) {
                // shit they are on holiday
                infoToast(
                  <div>
                    <strong>Warning!</strong><br />
                    Job has been scheduled when the field worker is on holiday!
                  </div>, 
                  false
                );                
    
                break;
              }
            }
          } else {
            infoToast(
              <div>
                <strong>Warning!</strong><br />
                Job has been scheduled outside the field worker's working hours!
              </div>, 
              false
            );
          }
        } else {
          //not a working day
          infoToast(
            <div>
              <strong>Warning!</strong><br />
              Job has been scheduled for a non-working day!
            </div>, 
            false
          );
        }

        await updateJobBegin(job, dispatch);

        job = await updateJob(
          jobId, 
          {
            preferredstartdatetime: e.start.format('YYYY-MM-DD HH:mm:ss'),
            supplier: {id: resourceId},
          },
          dispatch,
          false,
          true
        );
    
        if (job.substatus.id && job.substatus.workordersubstatus.substatusreference !== 'scheduled') {
          job = await updateJobSubStatus(
            jobId, 
            'scheduled', 
            dispatch,
            undefined,
            undefined,
            undefined,
            state.sendCommunications,
            true
          );
        }
        
        await updateJobEnd(job, dispatch);

        break;
      }
      case 'update': // the job has been moved or changed in length
        const jobId = parseInt(e.id.replace('J', ''), 10);
        let job = state.jobs[state.day.format('YYYY-MM-DD')].jobs.find(j => j.id === jobId);

        if (!job) {
          console.log('update - job not found', e);
          return;
        }

        localDispatch({ type: 'UPDATE_EVENT', payload: e });

        await updateJobBegin(job, dispatch);

        let obj = {};
        if (e.hasOwnProperty('start')) { obj.preferredstartdatetime = e.start.format('YYYY-MM-DD HH:mm:ss'); }
        if (e.hasOwnProperty('resource')) { obj.supplier = {id: parseInt(e.resource.replace('R', ''), 10)}; }
        if (e.hasOwnProperty('duration')) { obj.durationminutes = e.duration; }

        job = await updateJob(
          jobId, 
          obj,
          dispatch,
          false,
          true
        );
        
        await updateJobEnd(job, dispatch);

        break;
      case 'remove': { // the job has been unscheduled
        const jobId = parseInt(e.id.replace('J', ''), 10);
        let job = state.jobs[state.day.format('YYYY-MM-DD')].jobs.find(j => j.id === jobId);

        if (!job) {
          console.log('remove - job not found', e);
          return;
        }

        localDispatch({ type: 'UNSCHEDULE_EVENT', payload: e });

        await updateJobBegin(job, dispatch);

        job = await updateJob(
          job.id, 
          { supplier: { id: currentGroup.holdingSupplier } },
          dispatch,
          false,
          true
        );

        job = await updateJobSubStatus(
          job.id, 
          'awaitingscheduling', 
          dispatch,
          undefined,
          undefined,
          undefined,
          state.sendCommunications,
          true
        );

        await updateJobEnd(job, dispatch);

        break;
      }
      case 'select': {
        const jobId = parseInt(e.id.replace('J', ''), 10);

        localDispatch({ type: 'SET_SELECTED_JOB', payload: jobId });

        break;
      }
      case 'select_period': 
        let payload = undefined;
        let resource = fieldWorkers.find(fw => fw.id === e.resource);
        if (e.start) {
          payload = {
            preferredstartdatetime: moment(e.start),
            durationminutes: e.end.diff(e.start, 'minutes'),
            name: resource.title + ' ' + resource.firstname + ' ' + resource.surname + ' ' + resource.id,
            supplierid: e.resource,
          };
        }
  
        localDispatch({ type: 'SET_NEW_JOB_ARGS', payload });

        break;
      default:
        alert('unrecognised action');
        break;
    }
  }

  let selectedJobStatus = undefined;
  if (state.selectedJob) {
    selectedJobStatus = state.selectedJob.status.toLowerCase();

    let substatus = undefined;
    if (state.selectedJob.substatus.id) {
      if (selectedJobStatus.replace(' ', '_') !== state.selectedJob.substatus.workordersubstatus.substatusreference) {
        substatus = state.selectedJob.substatus.workordersubstatus.substatusname;
      }
    }

    if (selectedJobStatus === 'completed') {
      selectedJobStatus = 'practically completed';
    }

    if (substatus) {
      selectedJobStatus += ' - ' + substatus;
    }
  }

  const preview = {
    height: '20px',
    width: '50px',
    display: 'inline-block',
    border: '1px solid black',
  }

  return (
    <React.Fragment>
      <div className="d-flex ml-3 mr-3 mt-3 mb-1">
        <div className="flex-grow-1">
          <h3 className="m-0">
            <Icon icon="box-open" />{' '}Job Bucket{' '}
            <ButtonGroup size="sm">
              <Button color="primary" size="sm" onClick={() => { addTab('createJob', {}, dispatch); }}>new job</Button>
              <UncontrolledButtonDropdown>
                <DropdownToggle color="primary" size="sm" style={{borderLeft: "1px solid"}} caret />
                <DropdownMenu>
                  <DropdownItem onClick={() => { addTab('createJob', {}, dispatch); }}>new job</DropdownItem>
                  <DropdownItem onClick={() => { addTab('createJob', {templatejob: true}, dispatch); }}>new template</DropdownItem>
                  <DropdownItem onClick={() => { addTab('createJob', {task: true}, dispatch); }}>new task</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </ButtonGroup>{' '}
            {state.bucketLoading &&
            <PrettyLoader className="mr-2" size={30} />
            }
          </h3>
        </div>
        <div>
          <div className="d-flex flex-column">
            <div>
              <span className="p-2">
                add filter:
              </span>
              <Input 
                type="select"
                className="d-inline w-auto"
                style={{width: "auto"}}
                value={state.filterOption}
                onChange={e => {
                  localDispatch({type: 'SET_FILTER_OPTION', payload: e.target.value});
                }}
              >
                <option key="noval" value="">choose filter</option>
                <option key="requiredBy" value="requiredBy">required by</option>
                <option key="workType" value="workType">work type</option>
                <option key="property" value="property">property</option>
                <option key="description" value="description">description</option>
                <option key="town" value="town">town</option>
              </Input>
              {state.filterOption === "requiredBy" &&
              <React.Fragment>
                <Input 
                  type="select"
                  className="d-inline w-auto ml-2"
                  style={{width: "auto"}}
                  value={state.filterRequiredByOption}
                  onChange={e => {
                    localDispatch({type: 'SET_FILTER_REQUIRED_BY_OPTION', payload: e.target.value});
                  }}
                >
                  <option key="all" value="all">all</option>
                  <option key="overdue" value="overdue">overdue</option>
                  <option key="today" value="today">today</option>
                  <option key="thisWeek" value="thisWeek">this week</option>
                  <option key="nextWeek" value="nextWeek">next week</option>
                  <option key="thisMonth" value="thisMonth">this month</option>
                  <option key="nextMonth" value="nextMonth">next month</option>
                  <option key="specifyRange" value="specifyRange">specify range</option>
                </Input>
                {state.filterRequiredByOption === "specifyRange" &&
                <React.Fragment>
                  <span> between </span>
                  <Input
                    type="date" 
                    className="d-inline w-auto"
                    value={state.requiredByFrom}
                    onChange={e => localDispatch({type: 'REQUIRED_BY_FROM', payload: e.target.value})}
                  />
                  <span> and </span>
                  <Input 
                    type="date" 
                    className="d-inline w-auto"
                    value={state.requiredByTo}
                    onChange={e => localDispatch({type: 'REQUIRED_BY_TO', payload: e.target.value})}
                  />
                </React.Fragment>            
                }
              </React.Fragment>
              }
              {state.filterOption === "workType" &&
              <React.Fragment>
                {state.workType &&
                <span>{' '}{state.workType.worktype}, or</span>
                }
                <div className="d-inline w-auto ml-2">
                  <SelectWorkTypeModal 
                    buttonText="choose work type" 
                    onChoose={wt => localDispatch({type: 'SET_WORK_TYPE', payload: wt})} 
                  />
                </div>
              </React.Fragment>
              }
              {state.filterOption === 'property' &&
              <div className="d-inline w-auto ml-2">
                <SelectProperty 
                  className="d-inline-block"
                  onChange={value => localDispatch({type: 'SET_PROPERTY', payload: value})} 
                />
              </div>
              }
              {state.filterOption === 'description' &&
              <Input 
                type="text" 
                className="d-inline w-auto ml-2"
                value={state.filterText}
                placeholder="description search"
                onChange={e => localDispatch({type: 'SET_FILTER_TEXT', payload: e.target.value})}
              />
              }
              {state.filterOption === "town" &&
              <Input 
                type="text" 
                className="d-inline w-auto ml-2"
                value={state.locationText}
                placeholder="town search"
                onChange={e => localDispatch({type: 'SET_LOCATION_TEXT', payload: e.target.value})}
              />
              }
              <Button 
                color="primary" 
                size="sm" 
                className="ml-2"
                disabled={
                  state.filterOption === '' ||
                  (state.filterOption === 'workType' && !state.workType) ||
                  (state.filterOption === 'property' && (!state.property || state.property.length === 0)) || 
                  (state.filterOption === 'description' && !state.filterText) ||
                  (state.townOption === 'property_town' && !state.locationText)
                }
                onClick={() => localDispatch({type: 'ADD_FILTER'})}
              >
                add
              </Button>          
              {state.jobPills &&
              <span className="p-2 font-weight-bold">
                {state.jobPills.length - 7}{' job(s)'}
                {state.filters.length === 1 && 
                 state.filters[0].filter === 'requiredBy' && 
                 state.filters[0].type === 'thisWeek' && 
                 
                 <span> this week</span>
                 }
              </span>
              }
            </div>
            <div className="text-right mt-1">
              {state.filters.map((filter, i) => {
                if (filter.filter === 'requiredBy' && filter.type === 'thisWeek' && state.filters.length === 1) {
                  return null;
                }

                return (
                  <React.Fragment key={i}>
                    <Button size="sm">
                      {filter.name}{': '}{filter.value}{' '}
                      <Icon icon="times" className="clickable-icon" onClick={() => {
                        localDispatch({type: 'REMOVE_FILTER', payload: i});
                      }} />
                    </Button>{' '}
                  </React.Fragment>
                );
              })} 
            </div>
          </div>
        </div>
      </div>
      
      <div className="d-flex">
        <div className="flex-grow-1">
          <div className="bucket">
            <div style={{display: "flex", flexFlow: "wrap"}}>
              {state.jobPills}
            </div>
          </div>        
        </div>
        {state.selectedJob &&
        <div className="text-nowrap">
          <div className="d-flex h-100 align-items-center">
            <div className="flex-grow-1 ml-2 mr-2">
              <strong>{state.selectedJob.id}</strong> {state.selectedJob.shortdescription}
              <Button
                size="sm"
                color="secondary"
                className="ml-2"
                onClick={() => { addTab('editJob', {workOrderId: state.selectedJob.id, brand: currentGroup}, dispatch); }}
              >
                <Icon icon="pencil-alt" />
              </Button>
              <br />
              <Icon icon="hammer" fixedWidth />{' '}
              {state.selectedJob.worktype.worktype}{' '}
              <Icon icon="stopwatch" />{' '}
              {state.selectedJob.durationminutes} mins{' '}
              <Icon icon="calendar-alt" />{' '}
              {moment(state.selectedJob.requiredbydate).format('DD/MM')}<br />
              {state.selectedJobProperty &&
              <React.Fragment>
                <Icon icon="home" fixedWidth />{' '}
                {[
                  state.selectedJobProperty.name,
                  state.selectedJobProperty.address.town,
                  state.selectedJobProperty.address.postcode
                ].join(', ')}<br />
              </React.Fragment>
              }
              <Icon icon="thermometer-half" fixedWidth />{' '}
              {selectedJobStatus}
            </div>
          </div>
        </div>
        }
      </div>

      <div className="bg-dark p-2">
        <Row className="align-items-center m-0">
          <Col>
            <FormGroup className="d-inline" check>
              <Label check className="text-light">
                <Input type="checkbox" onClick={(e) => localDispatch({type: 'RELEVANT_SKILLS', relevantSkills: e.target.checked})} />
                {' '}relevant skills only
                </Label>
            </FormGroup>
            {currentGroup.colors &&
            <React.Fragment>
              <Button color="light" size="sm" id="keyPopover" className="ml-3">show key</Button>
              <UncontrolledPopover 
                trigger="focus"
                placement="bottom" 
                target="keyPopover" 
              >
                <PopoverBody>
                  <Container>
                    {Object.keys(currentGroup.colors).map(key => {
                      const status = key.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
                      const color = currentGroup.colors[key];

                      return (
                        <Row className="align-items-center" key={status}>
                          <Col className="text-nowrap">
                            {status}
                          </Col>
                          <Col md="auto">
                            <div style={{...preview, background: color.background}} className="mr-2"></div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Container>
                </PopoverBody>
              </UncontrolledPopover>
            </React.Fragment>
            }
            {state.newJobArgs &&
            <Button 
              color="primary" 
              size="sm" 
              className="ml-3"
              onClick={() => {
                localDispatch({type: 'TOGGLE_NEW_JOB_MODAL'});
              }}  
            >
              schedule job
            </Button>
            }
          </Col>
          <Col xs="auto">
            <ButtonGroup className="mr-2">
              {state.loading &&
              <PrettyLoader className="mr-2" size={30} />
              }
              <Button color="light" size="sm" onClick={() => { localDispatch({type: 'SET_SCALE', payload: "quarter"}) }} active={state.scale === "quarter"}>quarter hour</Button>
              <Button color="light" size="sm" onClick={() => { localDispatch({type: 'SET_SCALE', payload: "half"}) }} active={state.scale === "half"}>half hour</Button>
              <Button color="light" size="sm" onClick={() => { localDispatch({type: 'SET_SCALE', payload: "hour"}) }} active={state.scale === "hour"}>hour</Button>
              {/* <Button color="light" size="sm" onClick={() => { localDispatch({type: 'SET_SCALE', payload: "day"}) }} active={state.scale === "day"}>day</Button> */}
            </ButtonGroup>
            <ButtonGroup className="mr-2">
              <Button color="light" size="sm" onClick={() => { navigate('prev') }}>
                previous{' '}
                {state.scale === "quarter" && <span>day</span>}
                {state.scale === "half" && <span>day</span>}
                {state.scale === "hour" && <span>week</span>}
                {state.scale === "day" && <span>month</span>}
              </Button>
              <Button color="light" size="sm" onClick={() => { navigate('now') }}>today</Button>
              <Button color="light" size="sm" onClick={() => { navigate('next') }}>
                next{' '}
                {state.scale === "quarter" && <span>day</span>}
                {state.scale === "half" && <span>day</span>}
                {state.scale === "hour" && <span>week</span>}
                {state.scale === "day" && <span>month</span>}
              </Button>
            </ButtonGroup>
            <DatePickerButton 
              color="light" 
              selected={state.day} 
              onChange={date => navigate(date)} 
              size="sm"
            />
          </Col>
        </Row>
      </div>

      <Grid 
        scale={state.scale}
        day={state.day}
        resources={state.resources}
        events={state.events}
        emitChanges={gridChangeHandler}
        gridDragEnterEvent={() => {
          var ghost = document.getElementById('ghost');
          if (ghost) {
            ghost.style.display = 'none';
          }
        }}  
        gridDragLeaveEvent={() => {
          var ghost = document.getElementById('ghost');
          if (ghost) {
            ghost.style.display = 'block';
          }
        }}
        viewEvent={eventId => {
          addTab('editJob', { workOrderId: eventId }, dispatch);
        }}
      /> 

      <Modal isOpen={state.newJobModal} toggle={() => localDispatch({type: 'TOGGLE_NEW_JOB_MODAL'})}>
        <ModalHeader toggle={() => localDispatch({type: 'TOGGLE_NEW_JOB_MODAL'})}>
          new job
        </ModalHeader>
        <ModalBody>
          {state.newJobArgs &&
          <React.Fragment>
            Create new job for {state.newJobArgs.title} {state.newJobArgs.firstname} {state.newJobArgs.name} on {moment(state.newJobArgs.preferredstartdatetime).format('DD/MM/YY HH:mm')}, duration {state.newJobArgs.durationminutes} minutes?
          </React.Fragment>
          }
        </ModalBody>
        <ModalFooter>
          <Button 
            color="primary" 
            onClick={() => { 
              addTab('createJob', state.newJobArgs, dispatch); 
              localDispatch({type: 'TOGGLE_NEW_JOB_MODAL'});
              localDispatch({type: 'SET_NEW_JOB_ARGS', payload: undefined});
            }}
          >
              yes
          </Button>{' '}
          <Button 
            color="secondary" 
            onClick={() => {
              localDispatch({type: 'TOGGLE_NEW_JOB_MODAL'});
              localDispatch({type: 'SET_NEW_JOB_ARGS', payload: undefined});
            }}
          >
            cancel
          </Button>
        </ModalFooter>
      </Modal>

    </React.Fragment>           
  );

}

function Grid(props) {

  const gridRef = useRef();
  const blockHover = useRef(false);
  const hoveredCell = useRef(undefined);
  const selectedCells = useRef(undefined);

  const getdayOrWeekOrMonth = () => {
    switch (props.scale) {
      case 'quarter':
        return 'day';
      case 'half':
        return 'day';
      case 'hour':
        return 'week';
      case 'day':
        return 'month';
    }
  }

  const getMinutesPerCell = () => {
    switch (props.scale) {
      case 'quarter':
        return 15;
      case 'half':
        return 30;
      case 'hour':
        return 60;
      case 'day':
        return 1440;
    }
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'CREATE_EVENT': {
        let start = moment(props.day).startOf(getdayOrWeekOrMonth());
        let minutes = (action.left / 40) * getMinutesPerCell();
        start.add(minutes, 'minutes');
  
        const resource = state.resourceMeta.find(
          resource => action.top >= resource.yStart && action.top <= resource.yEnd
        );

        let changes = {
          id: action.eventId,
          start,
          resource: resource.id,
          action: 'create',
        };

        // todo: handle removing ghost image some other way..? 
        const ghost = document.getElementById('ghost');
        if (ghost) {
          ghost.remove();
        }

        return {...state, changes};
      }
      case 'UPDATE_EVENT': {
        let event = props.events.find(
          event => event.id === action.eventId
        );

        let changes = { 
          id: event.id,
          start: event.start,
          resource: event.resource,
          action: 'update'
        };

        if (action.hasOwnProperty('left')) {
          let start = moment(props.day).startOf(getdayOrWeekOrMonth());
          let minutes = (action.left / 40) * getMinutesPerCell();
          start.add(minutes, 'minutes');
          changes.start = start;
        }

        if (action.hasOwnProperty('top')) {
          const resource = state.resourceMeta.find(
            resource => action.top >= resource.yStart && action.top <= resource.yEnd
          );
          changes.resource = resource.id
        }

        if (action.hasOwnProperty('width')) {
          const minutes = (action.width / 40) * getMinutesPerCell();
          changes.duration = minutes; 
        }

        return {...state, changes};
      }
      case 'REMOVE_EVENT': {
        let event = props.events.find(
          event => event.id === action.payload
        );

        let changes = {
          id: action.payload,
          duration: event.duration,
          title: event.title,
          action: 'remove',
        }

        return {...state, changes};
      }
      case 'SET_RESOURCE_META':
        return {...state, resourceMeta: action.payload};
      case 'SET_EVENT_META':
        return {...state, eventMeta: action.payload};           
      case 'SET_RENDERED':
        return {...state, rendered: action.payload}; 
      case 'SET_SELECTED_EVENT': {
        let changes = {
          id: action.payload.id,
          action: 'select',
        }

        return {
          ...state, 
          selectedEvent: action.payload, 
          changes
        };
      }
      case 'SET_CONTEXT_MENU_EVENT':
        return {...state, contextMenuEvent: action.payload};
      case 'SELECT_PERIOD':
        let changes = { action: 'select_period' };

        if (action.start) {
          changes.start = moment(props.day).startOf(getdayOrWeekOrMonth()).add(action.start * getMinutesPerCell(), 'minutes');
          changes.end = moment(props.day).startOf(getdayOrWeekOrMonth()).add((action.end + 1) * getMinutesPerCell(), 'minutes');
          changes.resource = action.resource;
        }

        return {...state, changes};
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    resourceMeta: undefined,
    eventMeta: undefined,
    rendered: undefined,
    selectedEvent: undefined,
    contextMenuEvent: undefined,
    changes: undefined,
  });

  const [isVisible, setIsVisible] = useState(false);
  const hasLoaded = useRef(false);

  const observer = new IntersectionObserver(
    ([entry]) => setIsVisible(entry.isIntersecting)
  );

  useEffect(() => {
    if (gridRef.current) {
      observer.observe(gridRef.current);
    }

    return () => { observer.disconnect() };
  }, [gridRef.current]);

  useEffect(() => {
    if (isVisible && !hasLoaded.current) {
      scrollToMiddle();
      hasLoaded.current = true;
    }
  }, [isVisible]);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    document.body.addEventListener('click', onBodyClick);

    return () => {
      window.removeEventListener('resize', onWindowResize);
      document.body.removeEventListener('click', onBodyClick);
    };
  }, []);

  useEffect(() => {
    getMeta();
  }, [props.resources, props.events, props.day, props.scale]);

  useEffect(() => {
    if (props.day && props.scale) {
      // reset the left offset and width of the event 'inners', otherwise
      // weird things will happen if the event spans multiple days
      const events = document.getElementsByClassName('csdscheduler-event');
      Array.from(events).forEach(event => {
        const parentWidth = parseInt(event.style.width.replace('px', ''), 10);
        event.firstChild.style.left = '0px';
        event.firstChild.style.width = (parentWidth - 2) + 'px';
      });

      // todo: this is firing too early when scale changes - sad face
      scrollToMiddle();
    }
  }, [props.day, props.scale]);

  useEffect(() => {
    if (state.changes) {
      props.emitChanges(state.changes);
    }
  }, [state.changes]);

  const onWindowResize = () => {
    const windowHeight = window.innerHeight;

    if (!gridRef.current) {
      console.log('onWindowResize fault', gridRef);
      return;
    }

    const gridOffset = gridRef.current.offsetTop;

    if (windowHeight > 0 && gridOffset > 0 && windowHeight > gridOffset) {
      const newHeight = windowHeight - gridOffset;
      gridRef.current.style.height = `${newHeight}px`;
    }
  };

  const onBodyClick = () => {
    const contextMenu = document.getElementById('context-menu');
    if (contextMenu) { contextMenu.style.display = "none"; }
  }

  const styleTime = (x, highlighted) => {
    const timeDiv = document.getElementById('times' + x);

    if (highlighted) {
      timeDiv.classList.add('csdscheduler-time-highlight');
    } else {
      timeDiv.classList.remove('csdscheduler-time-highlight');
    }
  }

  const styleResource = (y, highlighted) => {
    const resourceDiv = document.getElementById('resourceR' + y); // todo: R???

    if (highlighted) {
      resourceDiv.classList.add('csdscheduler-resource-highlight');
    } else {
      resourceDiv.classList.remove('csdscheduler-resource-highlight');
    }
  }

  const styleSlot = (x, y, highlighted) => {
    const slotDiv = document.getElementById('slotR' + y + '-' + x); // todo: R???

    if (highlighted) {
      slotDiv.classList.add('csdscheduler-slot-highlight');
    } else {
      slotDiv.classList.remove('csdscheduler-slot-highlight');
    }
  }

  const selectSlot = (x, y, selected) => {
    const slotDiv = document.getElementById('slotR' + y + '-' + x); // todo: R???

    if (selected) {
      slotDiv.classList.add('csdscheduler-slot-selected');
    } else {
      slotDiv.classList.remove('csdscheduler-slot-selected');
    }
  }

  const getMeta = () => {
    let yCounter = 0;

    let dayOrWeekOrMonth = getdayOrWeekOrMonth();
    let minutesPerCell = getMinutesPerCell();

    const startOfPeriod = moment(props.day).startOf(dayOrWeekOrMonth);
    const endOfPeriod = moment(props.day).endOf(dayOrWeekOrMonth);
    const minutesInPeriod = endOfPeriod.diff(startOfPeriod, 'minutes') + 1;
    const horizontalSlots = minutesInPeriod / minutesPerCell;

    let eventMeta = [];
    props.events.forEach(e => {
      // check whether event falls within current day/week/month
      let eventStart = moment(e.start);
      let eventEnd = moment(eventStart).add(e.duration, 'minutes');

      if (eventStart.isBefore(endOfPeriod) && eventEnd.isAfter(startOfPeriod)) {
        eventMeta.push({...e});
      }
    });

    let resourceMeta = props.resources.map(r => {
      return {...r};
    });
    resourceMeta.forEach(resource => {
      const eventsFiltered = eventMeta
        .filter(event => event.resource === resource.id)
        .sort((a, b) => (a.start > b.start) ? 1 : -1);

      let mostClashes = 0;
  
      eventsFiltered.forEach(event => {
        let clashes = 0;

        let rangeA = moment.range(
          event.start, 
          moment(event.start).add(event.duration, 'minutes')
        );
  
        eventsFiltered.forEach(otherEvent => {
          if (event.id === otherEvent.id || otherEvent.hasOwnProperty('yOffset')) { return; }

          const rangeB = moment.range(
            otherEvent.start,
            moment(otherEvent.start).add(otherEvent.duration, 'minutes')
          );

          if (rangeA.overlaps(rangeB)) { 
            rangeA = moment.range(
              otherEvent.start, 
              moment(otherEvent.start).add(otherEvent.duration, 'minutes')
            );

            clashes += 1;
            otherEvent.yOffset = clashes;
            otherEvent.yLocation = yCounter + (clashes * 40);
          }
        });

        if (!event.yOffset) { 
          event.yOffset = 0;
          event.yLocation = yCounter;
        }

        let startMinutesIntoPeriod = event.start.diff(startOfPeriod, 'minutes');
        let endMinutesIntoPeriod = startMinutesIntoPeriod + event.duration;

        let startOffset = 0;
        let endOffset = 0;

        if (startMinutesIntoPeriod < 0) { 
          startOffset = startMinutesIntoPeriod; 
          event.startOverflow = true;
        }
        if (endMinutesIntoPeriod > minutesInPeriod) { 
          endOffset = endMinutesIntoPeriod - minutesInPeriod; 
          event.endOverflow = true;
        }

        event.xLocation = Math.round(((startMinutesIntoPeriod - startOffset) / minutesPerCell) * 40);
        event.startOffset = Math.round((startMinutesIntoPeriod / minutesPerCell) * 40) - event.xLocation; // used by drag shadow
        event.width = (event.duration + startOffset - endOffset) / minutesPerCell * 40;
        event.originalWidth = event.duration / minutesPerCell * 40; // used by drag shadow

        if (event.travelTo) { Math.round(event.travelToWidth = event.travelTo / minutesPerCell * 40); }
        if (event.travelFrom) { Math.round(event.travelFromWidth = event.travelFrom / minutesPerCell * 40); }

        if (clashes > mostClashes) { mostClashes = clashes; }
      });

      resource.ySize = mostClashes += 1;
      resource.height = resource.ySize * 40;
      resource.yStart = yCounter;
      yCounter += resource.height;
      resource.yEnd = yCounter - 1;

      let nonWorkingSlots = [];

      if (resource.workingDays) {
        for (let i = 0; i < horizontalSlots; i++) {

          let isWorking = false;
  
          const start = moment(startOfPeriod).add(i * minutesPerCell, 'minutes');
          const end = moment(start).add(minutesPerCell - 1);
          const dayOfWeek = start.format('dddd');
          const dmy = start.format('YYYY-MM-DD');
  
          let workingDay = resource.workingDays.collection.find(wd => {
            return wd.dayofweek === dayOfWeek;
          });
  
          if (workingDay) {
            const wdStart = moment(dmy + 'T' + workingDay.fromtime);
            const wdEnd = moment(dmy + 'T' + workingDay.totime);
  
            if (start.isBetween(wdStart, wdEnd, null, '[]') 
                && end.isBetween(wdStart, wdEnd, null, '[]')
            ) {    
              isWorking = true;
            }
  
            if (isWorking) {
              //check holidays
              for (const h of resource.holidays.collection) {
                if (start.isBetween(h.fromdate, h.todate, 'days', '[]')) {
                  isWorking = false; // shit they are on holiday
                  break;
                }
              }
            }
          }
  
          if (!isWorking) {
            nonWorkingSlots.push(i);
          }
        }
      }

      resource.nonWorkingSlots = nonWorkingSlots;
    });

    dispatch({ type: 'SET_RESOURCE_META', payload: resourceMeta });
    dispatch({ type: 'SET_EVENT_META', payload: eventMeta });

    var timeDivs = [];
    var timeGroupDivs = [];
    var resourceDivs = [];
    var leftHeader = [];

    for (var slot = 0; slot < horizontalSlots; slot++) {

      if (props.scale === 'quarter') {
        const hour = slot / 4;
        const timeInHour = (hour % 1) * 60;
        
        if (slot % 4 === 0) {
          const hourFormatted = (hour <= 12 ? hour : (hour % 12)) + (hour <= 11 ? 'AM' : 'PM');

          timeGroupDivs.push(
            <div key={'timesgroup' + slot} className="csdscheduler-time-group-cell" style={{ flex: "0 0 160px" }}>
              {hourFormatted}
            </div>
          );
        }
  
        timeDivs.push(
          <div key={'times' + slot} id={'times' + slot} className="csdscheduler-time-cell">
              {timeInHour.toString().padStart(2, '0')}
          </div>
        );
      }

      if (props.scale === 'half') {
        const hour = slot / 2;
        const timeInHour = (hour % 1) * 60;
  
        if (slot % 2 === 0) {
          const hourFormatted = (hour <= 12 ? hour : (hour % 12)) + (hour <= 11 ? 'AM' : 'PM');

          timeGroupDivs.push(
            <div key={'timesgroup' + slot} className="csdscheduler-time-group-cell" style={{ flex: "0 0 80px" }}>
              {hourFormatted}
            </div>
          );
        }
  
        timeDivs.push(
          <div key={'times' + slot} id={'times' + slot} className="csdscheduler-time-cell">
              {timeInHour.toString().padStart(2, '0')}
          </div>
        );
      }

      if (props.scale === 'hour') {
        const slotAdjusted = slot % 24;
        const hourFormatted = (slotAdjusted <= 12 ? slotAdjusted : (slotAdjusted % 12)) + (slotAdjusted <= 11 ? 'AM' : 'PM');
  
        if (slot % 24 === 0) {
          const dayNumber = Math.floor(slot / 24);
          const dayFormatted = moment(startOfPeriod).add(dayNumber, 'days').format('DD/MM/YYYY');

          timeGroupDivs.push(
            <div key={'timesgroup' + slot} className="csdscheduler-time-group-cell" style={{ flex: "0 0 960px" }}>
              {dayFormatted}
            </div>
          );
        }
  
        timeDivs.push(
          <div key={'times' + slot} id={'times' + slot} className="csdscheduler-time-cell">
              {hourFormatted}
          </div>
        );
      }

      if (props.scale === 'day') {
        if (slot === 0) {
          const flexWidth = (horizontalSlots * 40) + 'px';

          timeGroupDivs.push(
            <div key={'timesgroup' + slot} className="csdscheduler-time-group-cell" style={{ flex: `0 0 ${flexWidth}` }}>
              {startOfPeriod.format('MMMM YYYY')}
            </div>
          );
        }
  
        timeDivs.push(
          <div key={'times' + slot} id={'times' + slot} className="csdscheduler-time-cell">
              {slot}
          </div>
        );
      }

    }

    resourceMeta.forEach(resource => {
      leftHeader.push(
        <div 
          key={'resource' + resource.id} 
          id={'resource' + resource.id} 
          className="csdscheduler-resource-cell"
          style={{ height: resource.height + 'px' }}
        >
          <div 
            style={{ height: resource.height + 'px', width: "100%" }} 
            dangerouslySetInnerHTML={{__html: resource.name}}>
          </div>
        </div>
      );

      var slotDivs = [];
  
      for (var slot = 0; slot < horizontalSlots; slot++) {
        let classes = ["csdscheduler-grid-cell"];
        if (resource.nonWorkingSlots.includes(slot)) {
          classes.push("csdscheduler-grid-cell-non-working"); // todo: I don't understand CSS!
        }

        slotDivs.push(
          <div 
            key={'slot' + resource.id + '-' + slot} 
            id={'slot' + resource.id + '-' + slot}
            className={classes.join(' ')}
            style={{ height: resource.height + 'px' }}
          ></div>
        );
      };
  
      resourceDivs.push(
        <div key={'resourcerow' + resource.id} className="csdscheduler-grid-row">
          {slotDivs}
        </div>
      );
    });

    dispatch({
      type: 'SET_RENDERED', 
      payload: {
       timeDivs,
       timeGroupDivs,
       resourceDivs,
       leftHeader,
      }
    });
  };

  const getSlotFromX = x => {
    const slot = Math.floor(x / 40);
    return slot < state.rendered.timeDivs.length ? slot : -1;
  }

  const getResourceFromY = y => {
    const resource = state.resourceMeta.find(
      resource => y >= resource.yStart && y <= resource.yEnd
    );
    return resource;
  }

  const scrollHandler = e => {
    const resourcesDiv = document.getElementById("resources");
    const timesDiv = document.getElementById("times");
    const timesGroupDiv = document.getElementById("times-group")
    const gridDiv = document.getElementById("grid");
    const bottomLeftCornerDiv = document.getElementById("bottom-left-corner");
    const topRightCornerDiv = document.getElementById("top-right-corner");
    const topRightCornerDiv2 = document.getElementById("top-right-corner-2");

    const scrollBarSize = gridDiv.offsetWidth - gridDiv.clientWidth;

    resourcesDiv.scrollTop = e.target.scrollTop;
    timesDiv.scrollLeft = e.target.scrollLeft;
    timesGroupDiv.scrollLeft = e.target.scrollLeft;
    bottomLeftCornerDiv.style.flex = `0 0 ${scrollBarSize}px`;
    topRightCornerDiv.style.flex = `0 0 ${scrollBarSize}px`;
    topRightCornerDiv2.style.flex = `0 0 ${scrollBarSize}px`;

    const events = document.getElementsByClassName('csdscheduler-event');

    Array.from(events).forEach(event => {
      const oldLeft = parseInt(event.firstChild.style.left.replace('px', ''), 10);
      const parentWidth = parseInt(event.style.width.replace('px', ''), 10);

      let leftCompensation = gridDiv.getBoundingClientRect().left - event.getBoundingClientRect().left;
      let newWidth = parentWidth - leftCompensation;

      if (leftCompensation > parentWidth) {
        // don't allow event contents to get placed out of bounds
        leftCompensation = parentWidth;
      }

      // take off a couple of pixels to account for the 1px border around the events fucking stuff up
      newWidth -= 2;

      if (leftCompensation >= 0 && leftCompensation !== oldLeft) {
        event.firstChild.style.left = leftCompensation + 'px';
        event.firstChild.style.width = newWidth + 'px';
      }
    });
  }

  const dragEnterHandler = e => {
    e.preventDefault();

    props.gridDragEnterEvent();
  }

  const dragOverHandler = e => {
    e.preventDefault();
    
    const rect = e.currentTarget.getBoundingClientRect();
    const shadow = document.getElementById('drag-shadow');
    const leftOffset = parseInt(shadow.getAttribute('leftOffset'), 10);

    let y = snapToGrid(e.clientY - rect.y + e.currentTarget.scrollTop);
    if (y < 0) { y = 0; }

    const resource = getResourceFromY(y);

    if (resource) {
      let x = snapToGrid(e.clientX - rect.x + e.currentTarget.scrollLeft - leftOffset);

      shadow.style.left = x + 'px';
      shadow.style.top = resource.yStart + 'px';
      shadow.style.height = resource.height + 'px';
      shadow.style.display = "block";
    }
  }

  const dragLeaveHandler = e => {
    e.preventDefault();

    props.gridDragLeaveEvent();

    // event has been dragged outside of the grid
    const shadow = document.getElementById('drag-shadow');
    shadow.style.display = 'none';
  }

  const dropHandler = e => {
    const shadow = document.getElementById('drag-shadow');
    shadow.style.display = 'none';

    const data = e.dataTransfer.getData("text").split(',');

    if (data.length !== 2) {
      return;
    }

    const eventId = data[0];
    const leftOffset = parseInt(data[1]);

    const event = document.getElementById(eventId);
    const rect = e.currentTarget.getBoundingClientRect();

    const left = snapToGrid(e.clientX - rect.x + e.currentTarget.scrollLeft - leftOffset);
    const top = snapToGrid(e.clientY - rect.y + e.currentTarget.scrollTop);

    // event.style.left = left + 'px';
    // event.style.top = top + 'px';

    if (event.id.startsWith('unscheduled')) {
      dispatch({
        type: 'CREATE_EVENT',
        eventId: eventId.replace('unscheduled', ''),
        left,
        top,
      });
    } else {
      dispatch({ 
        type: 'UPDATE_EVENT', 
        eventId: eventId.replace('event', ''),
        left,
        top
      });
    }

    // TODO: REMOVE THIS HORRIBLENESS
    [
      'csdscheduler-time-highlight', 
      'csdscheduler-resource-highlight', 
      'csdscheduler-slot-highlight'
    ].forEach(cls => {
      const elements = document.getElementsByClassName(cls);
      Array.from(elements).forEach(element => {
        element.classList.remove(cls);
      });
    });

    blockHover.current = true;

    setTimeout(() => {
      blockHover.current = false;
    }, 400);

  }

  const snapToGrid = val => {
    return Math.floor(val / 40) * 40;
  }

  const mouseMoveHandler = e => {
    const rect = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - rect.x + e.currentTarget.scrollLeft;
    const mouseY = e.clientY - rect.y + e.currentTarget.scrollTop;

    const slotX = getSlotFromX(mouseX);

    const resource = getResourceFromY(mouseY);
    const slotY = resource ? parseInt(resource.id.replace('R', ''), 10) : -1; // todo: R???
    const slotXY = (slotX >= 0 && slotY >= 0) ? (slotX + '-' + slotY) : undefined;

    if (hoveredCell.current && hoveredCell.current !== slotXY) {
      const current = hoveredCell.current.split('-');
      
      styleTime(current[0], false);
      styleResource(current[1], false);
      styleSlot(current[0], current[1], false);

      hoveredCell.current = undefined;
    }

    if (!hoveredCell.current && slotXY) {
      styleTime(slotX, true);
      styleResource(slotY, true);
      styleSlot(slotX, slotY, true);

      hoveredCell.current = slotXY;
    }
  }

  const mouseLeaveHandler = e => {
    if (hoveredCell.current) {
      const current = hoveredCell.current.split('-');
      
      styleTime(current[0], false);
      styleResource(current[1], false);
      styleSlot(current[0], current[1], false);

      hoveredCell.current = undefined;
    }
  }

  const mouseDownHandler = e => {
    e.stopPropagation();

    const rect = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - rect.x + e.currentTarget.scrollLeft;
    const mouseY = e.clientY - rect.y + e.currentTarget.scrollTop;

    const slotX = getSlotFromX(mouseX);

    const resource = getResourceFromY(mouseY);
    const slotY = resource ? parseInt(resource.id.replace('R', ''), 10) : -1; // todo: R???

    selectSlot(slotX, slotY, true);

    var grid = document.getElementById("grid");

    if (selectedCells.current) {
      // unselect previous selection
      if (selectedCells.current.end) {
        const start = selectedCells.current.start;
        const end = selectedCells.current.end;
        const startX = start.x <= end.x ? start.x : end.x;
        const endX = start.x <= end.x ? end.x : start.x;

        for (var x = startX; x < endX + 1; x++) {
          selectSlot(x, start.y, false);
        }
      } else {
        selectSlot(selectedCells.current.start.x, selectedCells.current.start.y, false);
      }
    }

    selectedCells.current = {
      start: { x: slotX, y: slotY, scrollLeft: grid.scrollLeft, scrollTop: grid.scrollTop },
      end: undefined
    };
  }

  const mouseUpHandler = e => {
    const rect = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - rect.x + e.currentTarget.scrollLeft;
    const mouseY = e.clientY - rect.y + e.currentTarget.scrollTop;

    const slotX = getSlotFromX(mouseX);

    const resource = getResourceFromY(mouseY);
    const slotY = resource ? parseInt(resource.id.replace('R', ''), 10) : -1; // todo: R???

    var grid = document.getElementById("grid");

    if (selectedCells.current 
        && selectedCells.current.start 
        && selectedCells.current.start.scrollLeft === grid.scrollLeft
        && selectedCells.current.start.scrollTop === grid.scrollTop
        && selectedCells.current.start.y === slotY
    ) {
      selectedCells.current.end = { x: slotX, y: slotY };

      const start = selectedCells.current.start;
      const end = selectedCells.current.end;
      const startX = start.x <= end.x ? start.x : end.x;
      const endX = start.x <= end.x ? end.x : start.x;

      for (var x = startX; x < endX + 1; x++) {
        selectSlot(x, slotY, true);
      }

      // todo: notify grid caller that a selection has been finalised
      dispatch({
        type: 'SELECT_PERIOD',
        start: startX,
        end: endX,
        resource: selectedCells.current.start.y
      });
    } else {
      // the selection has ended on a different resource, so we'll disregard the selection entirely
      if (selectedCells.current && selectedCells.current.start) {
        selectSlot(selectedCells.current.start.x, selectedCells.current.start.y, false);
        selectedCells.current = undefined;

        dispatch({ type: 'SELECT_PERIOD' });
      }
    }
  }

  const scrollToMiddle = () => {
    var grid = document.getElementById("grid");

    if (grid) {
      grid.scrollLeft = 1000000; //scroll to max
      var scrollWidth = grid.scrollWidth;
      var diff = (scrollWidth - grid.scrollLeft) / 2;
      var middle = scrollWidth / 2 - diff;
      grid.scrollLeft = middle;
    }
  }

  if (!state.rendered) {
    return null;
  }

  return (
    <div id="cdscheduler" className="cdscheduler" ref={gridRef}>
      {/* resource column */}
      <div className="cdscheduler-resource-column">
        <div id="top-left-corner" className="cdscheduler-resource-column-top"></div>
        <div id="resources" className="cdscheduler-resource-column-middle">
          {state.rendered.leftHeader}
        </div>
        <div id="bottom-left-corner" className="cdscheduler-resource-column-bottom"></div>
      </div>
      {/* grid column */}
      <div className="cdscheduler-grid-column">
        <div id="times-group" className="cdscheduler-grid-column-times">
          {state.rendered.timeGroupDivs}
          <div id="top-right-corner-2" className="cdscheduler-grid-column-times-corner"></div>
        </div>
        <div id="times" className="cdscheduler-grid-column-times">
          {state.rendered.timeDivs}
          <div id="top-right-corner" className="cdscheduler-grid-column-times-corner"></div>
        </div>
        <div 
          key="grid"
          id="grid"
          onScroll={scrollHandler}
          onDragEnter={dragEnterHandler}
          onDragOver={dragOverHandler}
          onDragLeave={dragLeaveHandler}
          onDrop={dropHandler}
          onMouseMove={mouseMoveHandler}
          onMouseLeave={mouseLeaveHandler}
          onMouseDown={mouseDownHandler}
          onMouseUp={mouseUpHandler}
        >
          {state.eventMeta.map(event => {
            return (
              <GridEvent 
                key={'event' + event.id}
                id={'event' + event.id}
                event={event}
                dispatch={dispatch}
                setSelectedEvent={() => {
                  dispatch({ type: 'SET_SELECTED_EVENT', payload: event })
                }}
                setContextMenuEvent={() => {
                  dispatch({ type: 'SET_CONTEXT_MENU_EVENT', payload: event })
                }}
              />
            );
          })}
          <div id="drag-shadow" className="cdscheduler-drag-shadow"></div>
          {state.rendered.resourceDivs}
        </div>
      </div>
      <DropdownMenu id={'context-menu'} style={{ position: "fixed" }}>
        <DropdownItem header>{state.contextMenuEvent && state.contextMenuEvent.id}</DropdownItem>
        <DropdownItem 
          onClick={() => {
            if (state.contextMenuEvent) {
              props.viewEvent(state.contextMenuEvent.id.replace('J', ''));
            }
          }}
          toggle={false}
        >
          view job
        </DropdownItem>
        <DropdownItem 
          onClick={() => dispatch({ 
            type: 'REMOVE_EVENT', payload: state.contextMenuEvent && state.contextMenuEvent.id 
          })}
          toggle={false}
        >
          unschedule job
        </DropdownItem>
      </DropdownMenu>        
    </div>
  );
}

function GridEvent(props) {

  const snapToGridUp = val => {
    return Math.ceil(val / 40) * 40;
  }

  const snapToGridDown = val => {
    return Math.floor(val / 40) * 40;
  }

  const [resize, setResize] = useState();

  const handleResize = e => {
    if (!resize) { return; }

    if (resize.side === 'left') {
      const newLeft = snapToGridDown(resize.left + (e.clientX - resize.clientX));
      const newWidth = resize.width + (resize.left - newLeft);

      resize.event.style.left = newLeft + 'px';
      resize.event.style.width = newWidth + 'px';      
    } else {
      const oldRight = resize.left + resize.width;
      const newRight = snapToGridUp(oldRight + (e.clientX - resize.clientX));
      const newWidth = (newRight - resize.left);

      resize.event.style.width = newWidth + 'px';
    }
  }

  const handleEndResize = e => {
    document.removeEventListener('mousemove', handleResize);
    document.removeEventListener('mouseup', handleEndResize);
  }

  useEffect(() => {
    if (resize) {
      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleEndResize);
    }
  }, [resize]);

  const resizerMouseDownHandler = e => {
    e.preventDefault();

    const side = e.target.getAttribute('side');

    setResize({ 
      clientX: e.clientX, 
      width: parseInt(e.target.parentElement.style.width),
      left: parseInt(e.target.parentElement.style.left),
      event: e.target.parentElement,
      side,
    });

    // temporarily make the resize div wider, to avoid ugly cursor pointer issues
    e.target.style.width = "80px";

    if (side === 'left') {
      e.target.style.left = "-40px";
    } else {
      e.target.style.right = "-40px";
    }
  }

  const resizerMouseUpHandler = e => {
    e.preventDefault();
    e.stopPropagation(); // stops job getting selected unintentionally, but stops the mouseup 
                         // EventListener firing, necessitating calling handleEndResize

    setResize();
    handleEndResize();

    props.dispatch({ 
      type: 'UPDATE_EVENT', 
      eventId: props.event.id,
      left: parseInt(e.target.parentElement.style.left),
      width: parseInt(e.target.parentElement.style.width)  
    });

    const side = e.target.getAttribute('side');
    e.target.style.width = "20px";

    if (side === 'left') {
      e.target.style.left = "-10px";
    } else {
      e.target.style.right = "-10px";
    }
  }

  const dragStartHandler = e => {
    if (props.event.readOnly) { return; }

    const rect = e.currentTarget.getBoundingClientRect();
    const leftOffset = snapToGridDown(e.clientX - rect.left - props.event.startOffset);

    e.dataTransfer.setData("text", [e.target.id, leftOffset].join(','));
    e.dataTransfer.setDragImage(blankCanvas, 0, 0);

    const shadow = document.getElementById('drag-shadow');
    shadow.setAttribute('leftOffset', leftOffset);
    shadow.style.width = props.event.originalWidth + 'px';
  }

  const dragEndHandler = e => {
    const shadow = document.getElementById('drag-shadow');
    shadow.style.display = 'none';
  }

  const contextMenuHandler = e => {
      if (props.event.readOnly) { return; }

      e.preventDefault();
      props.setContextMenuEvent();
      const contextMenu = document.getElementById('context-menu');
      contextMenu.style.display = "block";
      contextMenu.style.left = e.clientX + 'px';
      contextMenu.style.top = e.clientY + 'px';
  }

  const mouseDownHandler = e => {
    if (e.button === 0) {
      e.stopPropagation();
    }
  }

  const mouseUpHandler = e => {
    if (e.button === 0) {
      e.preventDefault();
      e.stopPropagation();
      props.setSelectedEvent();
    }
  }

  let style = {
    width: props.event.width + "px", 
    left: props.event.xLocation + "px", 
    top: props.event.yLocation + "px",
  };

  if (props.event.color) {
    style.borderTop = `6px solid ${props.event.color}`;
  }

  return (
    <div
      id={props.id}
      className={"csdscheduler-event" + (props.event.active ? " csdscheduler-event-selected" : "")}
      style={style}
      draggable 
      onDrag={e => { }}
      onDragOver={e => { e.preventDefault(); }}
      onDragStart={dragStartHandler}
      onDragEnd={dragEndHandler}
      onContextMenu={contextMenuHandler}
      onMouseDown={mouseDownHandler}
      onMouseUp={mouseUpHandler}
      onDragLeave={e => { }}
    >
      {!props.event.startOverflow && !props.event.readOnly &&
      <div 
        side="left"
        className="csdscheduler-event-resizer"
        style={{ left: "-10px" }}
        onMouseDown={resizerMouseDownHandler}
        onMouseUp={resizerMouseUpHandler}
      ></div>
      }
      {!props.event.endOverflow && !props.event.readOnly &&
      <div 
        side="right"
        className="csdscheduler-event-resizer"
        style={{ right: "-10px" }}
        onMouseDown={resizerMouseDownHandler}
        onMouseUp={resizerMouseUpHandler}
      ></div>
      }
      {props.event.travelToWidth && !props.event.startOverflow &&
      <div 
        className="csdscheduler-event-travel-to" 
        style={{ 
          width: props.event.travelToWidth + 'px',
          left: -(props.event.travelToWidth) + 'px'
        }}
      ></div>
      }
      {props.event.travelFromWidth && !props.event.endOverflow &&
      <div 
        className="csdscheduler-event-travel-from" 
        style={{ 
          width: props.event.travelFromWidth + 'px',
          right: -(props.event.travelFromWidth) + 'px',
        }}
      ></div>
      }
      <div
        id={props.id + '-inner'}
        style={{ 
          height: "100%", 
          width: (props.event.width - 2) + "px",
          left: "0px",
          position: "absolute",
          overflow: "hidden",
          whiteSpace: "nowrap",
          zIndex: 1
        }} 
        dangerouslySetInnerHTML={{__html: props.event.title}}>
      </div>
    </div>
  );
}

function UnscheduledJob({ id, job, clickHandler, scale, active }) {
  
  const dragHandler = e => {
    const ghost = document.getElementById('ghost');
    const x = e.clientX - e.currentTarget.scrollLeft;
    const y = e.clientY - e.currentTarget.scrollTop;

    if (x > 0 && y > 0) {
      ghost.style.left = x + 'px';
      ghost.style.top = y + 'px';
    };
  }

  const dragStartHandler = e => {
    e.dataTransfer.setData("text", [e.target.id, 0].join(','));
    e.dataTransfer.setDragImage(blankCanvas, 0, 0);

    // todo: drag-shadow belongs to grid so should only be handled in there
    const shadow = document.getElementById('drag-shadow');
    shadow.setAttribute('leftOffset', 0);

    let minutesPerCell = 15;

    switch (scale) {
      case 'half': minutesPerCell = 30; break;
      case 'hour': minutesPerCell = 60; break;
      case 'day': minutesPerCell = 1440; break;
    }

    shadow.style.width = (job.durationminutes / minutesPerCell * 40) + 'px';

    // create a 'ghost' element for the drag image which is a clone of the unscheduled job
    if (document.getElementById('ghost')) {
      document.getElementById('ghost').remove(); 
    }

    var ghost = e.target.cloneNode(true);
    ghost.id = 'ghost';
    ghost.style.position = 'absolute';
    ghost.style.pointerEvents = 'none';
    ghost.style.opacity = .5;
    ghost.style.left = (e.clientX - e.currentTarget.scrollLeft) + 'px';
    ghost.style.top = (e.clientY - e.currentTarget.scrollTop) + 'px';
    document.body.appendChild(ghost);
  }

  const dragEndHandler = e => {
    // console.log('drag end')

    // todo: drag-shadow belongs to grid so should only be handled in there
    const shadow = document.getElementById('drag-shadow');
    shadow.style.display = 'none';

    const ghost = document.getElementById('ghost');
    if (ghost) {
      ghost.remove();
    }
  }

  let buttonProps = {
    className: "mb-1",
    color: "secondary",
    size: "sm",
    onClick: () => { }, // todo
    active: active
  };

  const getIcon = wt => {
    // todo: move this into mixin?
    if (wt.icon) {
      return wt.icon;
    }

    if (wt.parentworktype) {
      return getIcon(wt.parentworktype);
    }
    
    return;
  }

  const icon = getIcon(job.worktype);

  //When the job was scheduled
  const unscheduledDateTime = moment(job.substatus.fromdatetime);

  //when the job is required
  const requiredByDateTime = moment(job.requiredbydate + ' 23:59:59');

  //the next 7 days
  const sevenDaysGo = moment().add(7, 'days');

  //red if the required by date is in the past
  if (requiredByDateTime <= moment()) {
    buttonProps.color = "danger";
  }
  //amber if the requiredByDateTime is in the next 7 days
  if (requiredByDateTime > moment() && requiredByDateTime < sevenDaysGo) {
    buttonProps.color = "warning";
  }

  return (
    <Button 
      {...buttonProps}
      id={id}
      className="job-pill"
      draggable
      onDragOver={e => {
        e.preventDefault();
      }}
      onDrag={dragHandler}
      onDragStart={dragStartHandler}
      onDragEnd={dragEndHandler}
      onClick={clickHandler}
    >
      <Row>
        <Col>
          <div className="pill-overflow">
            <strong>
              {job.requiredbydate ? moment(job.requiredbydate).format('DD/MM') : ''}
            </strong>
            {' '}{job.shortdescription}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="pr-0 pill-overflow">
            <strong>
            {job.durationminutes}{' mins'}
            </strong>
            {' '}
            {job.property ? job.property.name : ''}
        </Col>
        <Col xs="auto" className="pl-0">
          {job.fromrecurringtemplate &&
          <React.Fragment>{' '}<Icon icon="sync" className="ml-1" /></React.Fragment>
          }
          {job.bookingserviceworkorder &&
          <React.Fragment>{' '}<Icon icon="umbrella-beach" className="ml-1" /></React.Fragment>
          }
          {icon &&
          <React.Fragment>{' '}<Icon icon={icon} className="ml-1" /></React.Fragment>
          }
        </Col>
      </Row>
    </Button>
  );
}
import React, { useState } from 'react';
import {
  Row,
  Tooltip,
  Col,
  Container } from 'reactstrap';
import moment from 'moment';
import './Calendar.css';
import Icon from '../Icon';
import TabsButton from '../TabsButton';

var DateRange = require('./DateRange.js');

export default function AvailabilityMonth(props) {
  var id = props.month.format('MMMM-YYYY').toLowerCase();

  var weeks = [],
    date = props.month.clone().startOf('month').day('Sunday'),
    count = 0;

  while (count < 6) {
    weeks.push(<AvailabilityMonthWeek key={count} date={date.clone()} {...props} />);
    date.add(1, 'w');
    count++;
  }

  return (
    <div id={ id } className='availMonth'>
      <div className='header'>{props.month.format('MMMM YYYY')}</div>
      <AvailabilityMonthDaysOfWeek />
      { weeks }
    </div>
  );
};

function AvailabilityMonthWeek(props) {

  const [prices, setPrices] = useState();
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [bookingFromDate, setBookingFromDate] = useState();
  const [bookingNumDays, setBookingNumDays] = useState();

  const toggleBookingModal = () => {
    setShowBookingModal(!showBookingModal);
  };

  const handleClick = e => {
    var dayItem = props.availability[e.target.dataset.index];

    if (dayItem.daysavailable == 0) {
      var lastBooking = dayItem.bookings[dayItem.length - 1];
      //this.context.router.push('/booking/' + lastBooking.bookingID);
    } else if (moment(dayItem.date).isAfter(moment().add(-1, 'days'))) {
      var numDays = dayItem.daysavailable > 7 ? 7 : dayItem.daysavailable;

      setShowBookingModal(true);
      setBookingFromDate(dayItem.date);
      setBookingNumDays(numDays);
    }
  };

  var days = [],
    date = props.date,
    month = props.month,
    now = moment(),
    bookingDefaults;

  var thisDate = date.format('YYYY-MM-DD');
  for (var availIndex = props.availability.length - 1; availIndex > -1; availIndex--) {
    if (props.availability[availIndex].date == thisDate) {
      break;
    }
  }
  // for (var soIndex = props.propertyBrandingSOCalendar.length - 1; soIndex > -1; soIndex--) {
  //   if (props.propertyBrandingSOCalendar[soIndex].date == thisDate) {
  //     break;
  //   }
  // }

  for (var i = 0; i < 7; i++) {

    //Add the day to the array of days
    days.push(
      <AvailabilityMonthDay
        key={ i }
        availIndex={ availIndex }
        dayItem={ props.availability[availIndex] }
        // daySOItem={ props.propertyBrandingSOCalendar[soIndex] }
        onClick={ handleClick }
        property={ props.property }
        propertyWarnings={ props.propertyWarnings }
        prices={ prices }
        date={ date }
        month={ month }
        fade={ date.isBefore(now, 'day') }
        today={ date.isSame(now, 'day') }
      />
    );

    //Move onto the next day
    date = date.clone().add(1, 'd');

    // Don't automatically increment availIndex if we've not found the start of the result array yet.
    if (availIndex != -1) {
      availIndex++;
    }

    // if (soIndex != -1) {
    //   soIndex++;
    // }
  }

  // If we're copying a booking, prepopulate the party size details etc
  if (props.copyFrom) {
    bookingDefaults = {
      guesttype: props.copyFrom.guesttype,
      adults: props.copyFrom.adults,
      children: props.copyFrom.children,
      infants: props.copyFrom.infants,
      pets: props.copyFrom.pets
    };
  }

  if (showBookingModal) {
    days.push(
      null
      // <SelectBookingDates
      //   key={8}
      //   isOpen
      //   onCancel={toggleBookingModal}
      //   fromDate={bookingFromDate}
      //   days={bookingNumDays}
      //   property={props.property}
      //   branding={props.branding}
      //   transferFrom={props.transferFrom}
      //   defaults={bookingDefaults}
      // />
    );
  }

  return (
    <div className='week'>
      { days }
    </div>
  );

}

function AvailabilityMonthDay(props) {

  var weekPrice;
  var isChangeOverDay = false;
  var isHolidayStart = false, isHolidayEnd = false;
  var hasPopup = true;
  var excludeFromDisplayBookingTypes = [
    'Provisional - Cancelled',
    'Potential - Enquiry',
    'Potential - BookingInProgress - Transferred',
    'Potential - Reservation - Transferred',
    'Potential - Hold - Transferred'
  ];

  var dayBookings = [];

  if (props.dayItem) {
    dayBookings = props.dayItem.bookings || [];
    for(var b in dayBookings) {
      if (excludeFromDisplayBookingTypes.indexOf(dayBookings[b].bookingType) != -1) {
        dayBookings.splice(b,1);
      }
    }
  }

  // Add a 'day' or 'blankDay' class depending on whether the day is in the month being rendered or not
  if (props.month && (props.date.month() != props.month.month())) {
    // is in a different month -- render a blank element
    return <span className='blankDay' />;
  }

  var classNames = ['day']
  var id = 'd' + props.date.format('DD');
  var tooltipId = 'tt' + props.date.format('YYYY-MM-DD');

  // Fade out dates that are in the past
  if (props.fade) {
    //hasPopup = false;
    classNames.push('fadee');
  }

  // Add a 'today' class for today's date
  if (props.today) {
    classNames.push('today');
  }

  if (props.dayItem) {
    /* Make sure the past and dates that aren't displayed don't have poopups or offers */
    /* Don't show offers that have advertise = false */
    if (!props.fade) {
      classNames.push('hasPopup');
      var SOs = [];
      // if (props.daySOItem && props.daySOItem.so && props.daySOItem.so.length > 0) {
      //   SOs = props.daySOItem.so.filter(function(so) {
      //     return so.advertise;
      //   });
      // }
      if (SOs.length > 0) {
        if (props.dayItem.offers == 'available') {
          classNames.push('specialofferavailable');
        } else if (props.dayItem.offers == 'unavailable') {
          classNames.push('specialofferunavailable');
        }
      }
    }

    //Check whether the day is a changeover day or not
    if (
      props.dayItem.isfromdate &&
      props.dayItem.showonavailability &&
      (!dayBookings || props.dayItem.daysavailable > 0)
    ) {
      isChangeOverDay = true;

      //Build a pricing element if it is a changeOverDay and there are no bookings

      //Look up the price from the store
      var selectedPrice = props.prices.findOne(function(price) {
        return props.date.isBetween(price.fromdate, price.todate, 'day', '[)') && price.type == 'Fixed';
      });

      //If there's a price record for this changeover day, render the price
      if (selectedPrice) {
        weekPrice = (<div className='weekPrice'>&pound;{ selectedPrice.price }</div>);
      }
    }

    //Loop over each booking and add the appropriate class
    var majorStatusMorning = '', minorStatusMorning = '', majorStatusAfternoon = '', minorStatusAfternoon = '';
    dayBookings.forEach(booking => {
      if (excludeFromDisplayBookingTypes.indexOf(booking.bookingType) != -1) {
        return;
      }

      var bookingType = booking.showAs;

      //Only add bookings that are either the only booking, or if there's more than one the one that blocks availability
      var availabilityBlockingBookingTypes = ['confirmed', 'unavailable', 'booking', 'owner'];

      if (availabilityBlockingBookingTypes.indexOf(bookingType) != -1) {
        //An availability blocking booking type
        if (booking.endBool) {
          majorStatusMorning = bookingType;
          isHolidayEnd = true;
        } else if (booking.startBool) {
          majorStatusAfternoon = bookingType;
          isHolidayStart = true;
        } else {
          majorStatusMorning = bookingType;
          majorStatusAfternoon = bookingType;
        }
      } else {
        //A non-availability blocking booking type
        if (booking.endBool) {
          minorStatusMorning = bookingType;
          isHolidayEnd = true;
        } else if (booking.startBool) {
          minorStatusAfternoon = bookingType;
          isHolidayStart = true;
        } else {
          minorStatusMorning = bookingType;
          minorStatusAfternoon = bookingType;
        }
      }
    });
  }

  //If there is not a booking that blocks availability, display a non blocking booking
  if (majorStatusMorning === '') {
    majorStatusMorning = minorStatusMorning;
  }

  //If there is not a booking that blocks availability, display a non blocking booking
  if (majorStatusAfternoon === ''  ) {
    majorStatusAfternoon = minorStatusAfternoon;
  }

  var morningClasses = [majorStatusMorning];
  var afternoonClasses = [majorStatusAfternoon];

  //Add a 'cod' class if a booking starts or ends on the current day
  if (isHolidayStart || isHolidayEnd) {
    afternoonClasses.push('cod');
  }

  //Add a 'cod' class if the current day is a designated changeover day
  if (!majorStatusAfternoon && isChangeOverDay) {
    afternoonClasses.push('cod');
  }

  morningClasses = morningClasses.join(' ');
  afternoonClasses = afternoonClasses.join(' ');
  if (morningClasses == afternoonClasses) {
    classNames.push(morningClasses);
  } else { // Add in an element indicating the morning and afternoon bookings
    var morningElement = <div className={ 'morning ' + morningClasses } ></div>;
    var afternoonElement = <div className={ 'afternoon ' + afternoonClasses } ></div>;
  }

  //Check for warnings
  var warningElement = null;
  if (props.propertyWarnings && props.propertyWarnings.findOne(function(note) {
    return note.note ?
      note.note.notetype.notetype == 'Warning' && note.showonavailability && props.date.isBetween(note.fromdate, note.todate, 'day', '[]') :
      note.notetype.notetype == 'Warning' && note.showonavailability && props.date.isBetween(note.fromdate, note.todate, 'day', '[]');
  })) {
    warningElement = <div className='warning' />;
  }

  var element = (
    <span id={ id } className={ classNames.join(' ') } data-index={ props.availIndex } onClick={ props.onClick }>
      { morningElement }
      { afternoonElement }
      { warningElement }
      { <span className='dayNum'>{ props.date.format('D')}</span> }
      { weekPrice }
    </span>
  );

  if (hasPopup) {
    var popupContent = undefined;

    if (dayBookings && dayBookings.length) {
      popupContent = (
        <BookingPopup
          bookings={ dayBookings }
          propertyWarnings={ props.propertyWarnings }
          details={ props.dayItem }
        />
      );
    } else if ((!props.fade || props.today) && props.dayItem) { // && props.prices
      popupContent = (
        <AvailabilityPopup
          propertyWarnings={ props.propertyWarnings }
          property={ props.property}
          details={ props.dayItem }
          // soItem={ props.daySOItem }
          // prices={ props.prices }
        />
      );
    }
    if (popupContent) {
      //return null;
      //return <Popup trigger={ element } content={ popupContent } hoverable hideOnScroll />;
      return (
        <Popup tooltipId={tooltipId} popupContent={popupContent}>
          {element}
        </Popup>
      );
    }
  }

  return element;
};

function AvailabilityMonthDaysOfWeek() {
  return (
    <div className='daysofweek'>
      <span className='title'>Sun</span>
      <span className='title'>Mon</span>
      <span className='title'>Tue</span>
      <span className='title'>Wed</span>
      <span className='title'>Thu</span>
      <span className='title'>Fri</span>
      <span className='title'>Sat</span>
    </div>
  );
};

function Popup(props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span id={props.tooltipId}>
      {props.children}
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        target={props.tooltipId}
        toggle={toggle}
        style={{ backgroundColor: "#4A7EFA" }}
        autohide={false}
      >
        {props.popupContent}
      </Tooltip>
    </span>
  );
}

function BookingPopup(props) {
  // static defaultProps = {
  //   bookings: [],
  //   propertyWarnings: []
  // };

  const generateWarningsMarkup = () => {
    return props.propertyWarnings.filter(function(note) {
      return note.note.notetype.notetype === 'Warning' && moment(props.details.date).isBetween(note.fromdate, note.todate, 'day', '[]');
    }, this).map(function(note) {

      return (
        <Row key={ note.note.id }>
          <Col xs="12">
            <Icon icon='exclamation-triangle' /> <span className='warning'>{ note.note.subject }</span>
          </Col>
          <Col xs="12">{ note.note.notetexts[0].notetext }</Col>
        </Row>
      );
    });
  };

  const handleClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.linkClick(e.target.href.split('/').pop());
  };

  const getBookings = () => {
    var bookings = props.bookings.map(function(b, i) {
      return (
        <div key={ i } className='bookingPopup withRowDividers'>
          <Container>
            <Row>
              <Col xs="12" className='popupHeader'>
                <DateRange start={b.fromDate} end={b.toDate} dayFormat='ddd D' />
              </Col>
            </Row>
            <Row>
              <Col xs="4" className="text-right text-nowrap">status</Col>
              <Col xs="8" className="text-nowrap">
                { b.bookingType }
                { b.showAs == 'cancelledpriority' &&
                  <p>Priority to rebook these dates</p>
                }
              </Col>
            </Row>
            <Row>
              <Col xs="4" className="text-right text-nowrap">ref</Col>
              <Col xs="8" className="text-nowrap">{b.bookingRef ? (b.bookingID + ' ('+ b.bookingRef +')') : b.bookingID}</Col>
            </Row>
            <Row>
              <Col xs="4" className="text-right text-nowrap">customer</Col>
              <Col xs="8" className="text-nowrap">{b.bookingCustomer}</Col>
            </Row>
            <Row>
              <Col xs="12" className="text-center">
                <TabsButton route={`booking/${b.bookingID}`} />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }.bind(this));

    return bookings;
  };

  return (
    <div className='popupInner'>
      { getBookings() }
      { generateWarningsMarkup() }
    </div>
  );
}

function AvailabilityPopup(props) {
  if (props.details && props.prices) {
    var date = props.details.date;
    var selectedPrice = props.prices.findOne(function(price) {
      return (price.fromdate <= date && price.todate > date && price.type == 'Fixed');
    });
  }

  var SOs = [];
  // if (props.soItem && props.soItem.so && props.soItem.so.length > 0) {
  //   SOs = props.soItem.so.filter(function(so) {
  //     //TABS2-4634
  //     if(so.advertise) {
  //       var startDateOfHolidayPeriod = props.details.date;

  //       if(so.minimumdaysbeforeholiday && so.minimumdaysbeforeholiday > 0 ){
  //         let dateToday = moment();
  //         let maxdate = moment().add(so.minimumdaysbeforeholiday, 'd');
  //         let range = moment().range(dateToday,maxdate);
  //         if(range.contains(moment(startDateOfHolidayPeriod))){
  //           return false;
  //         }
  //       }

  //       if(so.maximumdaysbeforeholiday && so.maximumdaysbeforeholiday > 0 && so.maximumdaysbeforeholiday < 999 ) {
  //         let dateToday = moment();
  //         let maxdate = moment().add(so.maximumdaysbeforeholiday, 'd');
  //         let range = moment().range(dateToday,maxdate);
  //         if(!range.contains(moment(startDateOfHolidayPeriod))){
  //           return false;
  //         }
  //       }
  //     }
  //     return so.advertise;
  //   }).map(function(so) {
  //     return (<p key={so.id}>{ so.description }</p>)
  //   });
  // }

  return (
    <div className='bookingPopup withRowDividers'>
      <Row>
        <Col xs="12" className='popupHeader'>
          <DateRange start={props.details.date} end={moment(props.details.date).add(6, 'd')} />
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          { selectedPrice ?
            <div>
              <Row>
                <Col xs="6" className="text-right"><label>Week</label></Col>
                <Col xs="6">&pound; { selectedPrice.price }</Col>
              </Row>
              <Row>
                <Col xs="3" className="text-right"><label>1 day</label></Col>
                <Col xs="3">&pound; { selectedPrice.getDayPrice(1, props.details.date) }</Col>
                <Col xs="3" className="text-right"><label>2 days</label></Col>
                <Col xs="3">&pound; { selectedPrice.getDayPrice(2, props.details.date) }</Col>
              </Row>
              <Row>
                <Col xs="3" className="text-right"><label>3 days</label></Col>
                <Col xs="3">&pound; { selectedPrice.getDayPrice(3, props.details.date) }</Col>
                <Col xs="3" className="text-right"><label>4 days</label></Col>
                <Col xs="3">&pound; { selectedPrice.getDayPrice(4, props.details.date) }</Col>
              </Row>
              <Row>
                <Col xs="3" className="text-right"><label>5 days</label></Col>
                <Col xs="3">&pound; { selectedPrice.getDayPrice(5, props.details.date) }</Col>
                <Col xs="3" className="text-right"><label>6 days</label></Col>
                <Col xs="3">&pound; { selectedPrice.getDayPrice(6, props.details.date) }</Col>
              </Row>
              <Row>
                <Col xs="12"><label>Change Day Rules</label></Col>
                {/* <Col width='twelve'>{ generateChangeDayRulesDesc(props.details) }</Col> */}
              </Row>
              { SOs.length > 0 &&
                <Row>
                  <Col xs="12"><label>Special Offers</label></Col>
                  <Col xs="12">{ SOs }</Col>
                </Row>
              }

            </div>
            :
            <div>
              <Row>
                <Col xs="12">{ (!props.prices || !props.prices.loaded) ? <div className='ui inline active mini loader' /> : 'No pricing has been set up yet for this period' }</Col>
              </Row>
            </div>
          }
        </Col>
      </Row>

      { props.propertyWarnings && props.propertyWarnings.filter(function(note) {
          return note.note.notetype.notetype === 'Warning' && moment(props.details.date).isBetween(note.fromdate, note.todate, 'day', '[]');
        }).map(function(note) {
          return (
            <Row key={ note.note.id }>
              <Col xs="12">
                <Icon icon='exclamation-triangle' /> <span className='warning'>{ note.note.subject }</span>
              </Col>
              <Col xs="12">{ note.note.notetexts[0].notetext }</Col>
            </Row>
          );
        }) }
    </div>
  );
};
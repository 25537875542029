import { common } from 'plato-js-client';

export function loadSetting(user, name, defaultValue = undefined) {
  for (var setting of user.settings.collection) {
    if (setting.name === name) {
      return setting.value;
    }
  }

  return defaultValue;
}

export function saveSetting(user, name, value) {

  let as = undefined;
  
  user.settings.forEach(setting => {
    if (setting.name === name) {
      as = setting;
    }
  });

  return new Promise((resolve, reject) => {
    if (!as) {
      as = new common.ActorSetting();
      as.parent = user;
      as.name = name;
      as.value = value;
      as.create().then(() => {
        resolve();   
      });
    } else {
      as.name = name;
      as.value = value;
      as.update().then(() => {
        resolve();
      });
    }
  });

}
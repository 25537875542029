import React, { useContext, useEffect, useReducer, useState } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import { 
  Alert,
  Button,
  ButtonDropdown,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  Table,
  Tooltip } from 'reactstrap';
import { Store } from './Store';
import pAll from 'p-all';
import Icon from './components/Icon';
import Link from './components/Link';
import { successToast } from './components/Toasties';
import { addTab, updateJobSubStatus } from './Actions';
import IconButton from './components/IconButton';
import moment from 'moment';
import PrettyLoader from './components/PrettyLoader';
import SelectProperty from './fields/SelectProperty';

export default function TabCompleteJobs() {
  const [toggleActualLimitNotesColumns, setToggleActualLimitNotesColumns] = useState(false);
  const [toggleChargeDetails, setToggleChargeDetails] = useState(true);

  const {
    dispatch: globalDispatch,
    state: {
      currentGroup,
      vatRates,
    },
  } = useContext(Store);

  const getVatRate = (vatBand) => {
    for (const vr of vatRates) {
      if (vr.vatband.id === vatBand.id && moment().isBetween(vr.fromdate, vr.todate, 'day', '[]')) {
        return vr;
      }
    }
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'TOGGLE_DROPDOWN':
        return {...state, dropdown: !state.dropdown};
      case 'PREV_PAGE':
        return {...state, page: state.page - 1};
      case 'NEXT_PAGE':
        return {...state, page: state.page + 1};
      case 'SET_LIMIT':
        return {...state, limit: action.payload};
      case 'SET_JOBS':
        return {
          ...state, 
          jobs: action.payload, 
          selected: [], 
          errors: {}, 
          successes: [], 
          runComplete: false
        };
      case 'TOGGLE_LIMIT_DROPDOWN':
        return {...state, limitDropdown: !state.limitDropdown};
      case 'SET_SELECTED':
        let selected = [...state.selected];

        if (action.value === true) {
          selected.push(action.id);
        } else {
          selected = selected.filter(id => id !== action.id);
        }
        
        return {...state, selected};
      case 'SELECT_ALL': {
        let selected = state.jobs.map(job => job.id);
        
        return {...state, selected};
      }
      case 'DESELECT_ALL':
        return {...state, selected: []};
      case 'SET_BUSY':
        return {...state, busy: action.payload};
      case 'SET_REFRESHING':
        return {...state, refreshing: action.payload};        
      case 'SET_RUN_COMPLETE':
        return {...state, runComplete: action.payload, selected: []};
      case 'SET_PROGRESS':
        return {...state, progress: action.payload};
      case 'REFRESH':
        return {...state, refresh: state.refresh + 1}
      case 'ADD_ERROR':
        let errors = {...state.errors};
        errors[action.jobId] = action.payload;

        return {...state, errors};
      case 'ADD_SUCCESS':
        let successes = [...state.successes];
        successes.push(action.jobId);

        return {...state, successes};
      case 'SET_PROPERTY':
        return {...state, property: action.payload};
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    page: 1,
    limit: 10,
    loading: false,
    refreshing: false,
    jobs: undefined,
    limitDropdown: false,
    selected: [],
    busy: false,
    progress: 0,
    refresh: 0,
    errors: {},
    successes: [],
    runComplete: false,
    property: undefined,
  });

  useEffect(() => {

    const refresh = async () => {
      let jobs = new FilterCollection({
        path: 'workorder',
        object: common.WorkOrder,
      });
      jobs.limit = state.limit;
      jobs.page = state.page;
      jobs.orderBy = 'instancecompleteddate_desc';
      jobs.fields = 'id:propertynano:invoiceapproveddatetime:workordersuppliermini:expenses:reporteddate:shortdescription:completeddate';

      let filters = {
        type: 'Instance',
        substatus: 'reviewed',
      };

      if (state.property && state.property.length > 0) {
        filters.propertyid = state.property[0].id;
      } else {
        if (currentGroup.propertyIds && currentGroup.propertyIds.length > 0) {
          filters.propid = currentGroup.propertyIds.join('|');
        } else {
          filters.propertybrandingid = currentGroup.brandingIds.join('|');
          filters.livepropertiesonly = '1';
        }
      }

      jobs.addFilters([filters]);
      await jobs.fetch();
      return jobs;
    }

    dispatch({type: 'SET_REFRESHING', payload: true});

    refresh().then(jobs => {
      dispatch({type: 'SET_JOBS', payload: jobs});
      dispatch({type: 'SET_REFRESHING', payload: false});
    });

  }, [state.page, state.limit, state.refresh, state.property]);

  if (!state.jobs) {
    return null;
  }

  const completeSelected = async () => {
    dispatch({ type: 'SET_BUSY', payload: true });
    dispatch({ type: 'SET_PROGRESS', payload: 0 });

    try {
      let completed = 0;
      const progress = (jobId) => {
        // eslint-disable-next-line no-console
        console.debug(jobId, `Completed all requests for this job`);

        const percent = ++completed / state.selected.length * 100;
        dispatch({ type: 'SET_PROGRESS', payload: percent });
      };

      const processes = state.selected.map((jobId) => {
        // Return a function to defer the API calls.
        return async () => {
          try {
            globalDispatch({ type: 'IGNORE_HOOK_JOB', payload: jobId });
            const job = new common.WorkOrder(jobId);
            await job.get();

            /*
             * Step 1. Update expense amounts.
             */
            const expenses = job.expenses?.collection ?? [];
            const expenseUpdates = expenses.map(async (expense) => {
              // Return a function to defer the API calls.
              return async () => {
                if (expense.amountnet) {
                  return Promise.resolve();
                }

                globalDispatch({ type: 'IGNORE_HOOK_JOB', payload: jobId });
                return expense.update({
                  amountnet: expense.agencycostnet ? expense.agencycostnet : expense.amountnetestimate,
                });
              };
            });

            await pAll(expenseUpdates, {
              concurrency: 2
            });

            /*
             * Step 2. Create an invoice.
             */
            globalDispatch({ type: 'IGNORE_HOOK_JOB', payload: jobId });
            const woi = new common.WorkOrderInvoice(jobId);
            woi.supplierinvoice_invoicedate = moment().format('YYYY-MM-DD');
            await woi.create();

            /*
             * Step 3. Update job status.
             */
            globalDispatch({ type: 'IGNORE_HOOK_JOB', payload: jobId });
            await job.get();
            const newStatus = job.status.toLowerCase().replace(' ', '_');
            await updateJobSubStatus(
                jobId,
                newStatus,
                globalDispatch,
                undefined,
                undefined,
                undefined,
                false,
                true,
            );

            progress(jobId);

            dispatch({ type: 'ADD_SUCCESS', jobId });
            globalDispatch({
              type: 'UPDATE_QUICK_FILTER_COUNT',
              title: 'needs financially completing',
              updater: (count) => count - 1,
            });
          } catch (error) {
            dispatch({ type: 'ADD_ERROR', jobId, payload: error });
          } finally {
            globalDispatch({ type: 'REMOVE_IGNORE_HOOK_JOB', payload: jobId });
          }

          return jobId;
        };
      });

      await pAll(processes, {
        concurrency: 2,
      });

      successToast(
          <React.Fragment>
            Done!
          </React.Fragment>,
      );
    } finally {
      dispatch({ type: 'SET_RUN_COMPLETE', payload: true });
      dispatch({ type: 'SET_BUSY', payload: false });
    }
  };

  const { jobs, page } = state; 
  const jobsFrom = jobs ? ((page - 1) * jobs.limit) + 1 : 0;
  const jobsTo = jobs ? Math.min((((page - 1) * jobs.limit) + jobs.limit), jobs.total) : 0;

  return (
    <Row className="m-3">
      <Col>
        <h3>financially complete jobs</h3>
        <Card>
          <CardBody>
            <Row className="mb-2 align-items-center">
              <Col>
                results per page:{' '}
                <ButtonDropdown 
                    size="sm" 
                    isOpen={state.limitDropdown} 
                    toggle={() => { dispatch({type: 'TOGGLE_LIMIT_DROPDOWN'}) }}
                    disabled={state.busy}
                    className="mr-2"
                  >
                    <DropdownToggle caret>
                      {state.limit} 
                    </DropdownToggle>
                    <DropdownMenu>
                      {[10, 20, 30, 50].map(quantity => 
                      <DropdownItem key={quantity} onClick={() => {
                        dispatch({type: 'SET_LIMIT', payload: quantity})
                      }}>
                        {quantity}
                      </DropdownItem>  
                      )}
                    </DropdownMenu>
                  </ButtonDropdown>
                  filter by property:{' '}
                  <SelectProperty 
                    style={{width: '350px'}}
                    className="d-inline-block"
                    selectedProperty={state.property}
                    onChange={p => dispatch({type: 'SET_PROPERTY', payload: p})} 
                  />
              </Col>
              <Col md="auto">
                {!state.refreshing && jobs && jobs.total > 0 && 
                  jobs && 'jobs ' + jobsFrom + ' to ' + jobsTo + ' of ' + jobs.total + ' '
                }
                {state.refreshing &&
                <PrettyLoader className="mr-2" size={30} />
                }
                <IconButton 
                  icon="refresh" 
                  className="mr-2" 
                  color="secondary"
                  onClick={() => dispatch({type: 'REFRESH'})}
                />
                <ButtonGroup size="sm" className="mr-2">
                  <Button 
                    onClick={() => { dispatch({type: 'PREV_PAGE'}) }}
                    disabled={!jobs.previous || state.busy}
                  >
                    previous page
                  </Button>
                  <Button 
                    onClick={() => { dispatch({type: 'NEXT_PAGE'}) }}
                    disabled={!jobs.next || state.busy}
                  >
                    next page
                  </Button>
                </ButtonGroup>
                <ButtonGroup size="sm">
                  <Button 
                    onClick={() => { dispatch({type: 'SELECT_ALL'}) }}
                    disabled={!jobs || jobs.collection.length === 0 || state.busy}
                  >
                    select all
                  </Button>
                  <Button 
                    onClick={() => { dispatch({type: 'DESELECT_ALL'}) }}
                    disabled={state.selected.length === 0 || state.busy}
                  >
                    deselect all
                  </Button>
                </ButtonGroup>                    
                {state.selected.length > 0 &&
                <Button 
                  className="ml-2" 
                  color="primary" 
                  size="sm"
                  onClick={completeSelected}
                  disabled={state.busy || state.runComplete}
                >
                  financially complete selected
                </Button>
                }
              </Col>
            </Row>
            {state.busy && 
            <Row className="mb-2">
              <Col>
                <div className="text-center">{Math.round(state.progress)}%</div>
                <Progress value={Math.round(state.progress)} />
              </Col>
            </Row>
            }
            {Object.keys(state.errors).length > 0 &&
            <Alert color="danger">
              <strong>Warning:</strong> the following jobs could not be financially completed:<br />
              {Object.keys(state.errors).join(', ')}<br />
              Scroll down to see the error for each job.
            </Alert>
            }
            <Table size="sm" striped>
              <thead>
                <tr>
                  <th>id</th>
                  <th>field worker</th>
                  <th>property</th>
                  <th>job</th>
                  <th>practically completed</th>
                  <th>items</th>
                  <th>
                  <div className="btn-toolbar float-right">
                    <Button className="btn btn-secondary btn-sm mr-1" onClick={() => { setToggleActualLimitNotesColumns(!toggleActualLimitNotesColumns); }}>
                    {toggleActualLimitNotesColumns ? 'hide amount, limit and notes' : 'show amount, limit and notes'}
                    </Button>
                    <Button className="btn btn-secondary btn-sm" onClick={() => { setToggleChargeDetails(!toggleChargeDetails); }}>
                    {toggleChargeDetails ? 'hide charge details' : 'show charge details'}
                    </Button>
                  </div>
                  </th>
                  <th>{''}</th>
                </tr>
              </thead>
              <tbody>
                {jobs.collection.length === 0 &&
                <tr>
                  <td colSpan={7}>No jobs found.</td>
                </tr>
                }                
                {jobs.collection.map((job, i) => 
                <CompleteJobRow 
                  key={'job' + i}
                  job={job} 
                  selected={state.selected.includes(job.id)}
                  setSelected={value => {
                    dispatch({type: 'SET_SELECTED', id: job.id, value});
                  }}
                  disabled={state.busy}
                  error={state.errors[job.id]}
                  success={state.successes.includes(job.id)}
                  runComplete={state.runComplete}
                  toggleActualLimitNotesColumns={toggleActualLimitNotesColumns}
                  toggleChargeDetails={toggleChargeDetails}
                  getVatRate = {getVatRate}
                />
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );

}

function CompleteJobRow({job, selected, setSelected, disabled, error, success, runComplete, toggleActualLimitNotesColumns, toggleChargeDetails, getVatRate}) {

  const {
    dispatch
  } = useContext(Store);

  const name = [
    job.workordersupplier.supplier.title, 
    job.workordersupplier.supplier.firstname,
    job.workordersupplier.supplier.surname,
  ].filter(Boolean).join(' ');


  // disable the switch if there are no expenses
  const noExpenses = job.expenses.collection.length === 0 ? true : false;

  let supplierPayExpenses = false;
  for (const expense of job.expenses.collection) {
    if (expense.donotpaysupplier === false) { supplierPayExpenses = true; }
  }

  return (
    <tr>
      <td className="align-middle">
        <Link onClick={() => { addTab('editJob', {workOrderId: job.id}, dispatch); }}>
          {job.id}
        </Link>
        </td>
      <td className="align-middle">
        <Link onClick={() => { addTab('showFieldWorker', {supplierId: job.workordersupplier.supplier.id}, dispatch); }}>
          {name}
        </Link>
      </td>
      <td className="align-middle">
        <Link onClick={() => { addTab('showProperty', {propertyId: job.property.id}, dispatch); }}>
          {job.property.name}
        </Link>
      </td>
      <td className="align-middle">
        {job.shortdescription}
      </td>
      <td className="align-middle">
        {moment(job.completeddate).format('DD/MM/YY')}
      </td>
      <td className="align-middle" colSpan="2">
        <Table size="sm" bordered className="mb-0">
          <thead>
            <tr>
              <th>{' '}</th>
              <th>description</th>
              <th>stock cost</th>
              <th>estimate</th>
              <th style={{display:toggleActualLimitNotesColumns ? 'table-cell' : 'none'}}>actual amount</th>
              <th style={{display:toggleActualLimitNotesColumns ? 'table-cell' : 'none'}}>limit</th>
              <th style={{display:toggleActualLimitNotesColumns ? 'table-cell' : 'none'}}>within limit</th>
              <th>charges</th>
              <th style={{display:toggleActualLimitNotesColumns ? 'table-cell' : 'none'}}>notes</th>
            </tr>  
          </thead>
          <tbody>
            {noExpenses &&
            <tr><td colSpan={9} className="bg-info text-white">no job items found</td></tr>
            }
            {supplierPayExpenses &&
            <tr><td colSpan={9} className="bg-danger text-white">'supplier pay' job items found</td></tr>
            }
            {job.expenses.map((expense, counter) => 
            <CompleteJobRowExpense expense={expense} counter={counter} toggleActualLimitNotesColumns={toggleActualLimitNotesColumns} toggleChargeDetails={toggleChargeDetails} key={'expense' + counter} getVatRate={getVatRate}  />  
            )}
            {error &&
            <tr><td colSpan={9} className="bg-danger text-white"><strong>Error:</strong>{' '}{error.message}</td></tr>
            }
            {success &&
            <tr><td colSpan={9} className="bg-success text-white"><strong>Job completed successfully</strong></td></tr>
            }
          </tbody>
        </Table>
      </td>
      <td className="align-middle text-center">
        <CustomInput 
          type="switch" 
          id={"sw" + job.id}
          label="" 
          checked={selected}
          onChange={e => {
            setSelected(e.target.checked);
          }}
          disabled={disabled || noExpenses || supplierPayExpenses || runComplete}
        />
      </td>
    </tr>
  )

}

function CompleteJobRowExpense({expense, counter, toggleActualLimitNotesColumns, toggleChargeDetails, getVatRate}) {

  const [chargesTool, setChargesTool] = useState(false);
  const [ownerChargesRows, setOwnerChargesRows] = useState([]);

  var meetsEstimate = false;
  var limit = expense.amountnetestimate;
  var amount = expense.amountnet || 0;

  if (expense.amountlimittype && expense.amountlimittype.id) {
    switch (expense.amountlimittype.amountlimittype) {
      case 'Fixed':
        limit = expense.amountnetlimit;
        break;
      case 'Percentage':
        var multiplier = 1 + (expense.amountnetlimit / 100);
        limit = (expense.amountnetestimate * multiplier).toFixed(2);
        break;
      case 'Amount':
        limit = expense.amountnetestimate + expense.amountnetlimit;
        break;
    }
  }

  if (amount <= limit) {
    meetsEstimate = true;
  }

  var amountNetEstimate = parseFloat(expense.agencycostnet || expense.amountnet || expense.amountnetestimate);

  let ownerChargesCount = 0;
  let ownerChargesTool = [];
  let ownerChargesTotal = 0;
  let ocRows = [];

  expense.ownercharges.collection.forEach(async  (oc, i) => {
    let vatRate = getVatRate(oc.ownerchargecode.vatband);
    var ocamount = oc.amount;
    var ownerCharge = 0;
    var ownerChargeVat = 0;
    var gross = 0;

    if (oc.ownerchargeamounttype) {
      switch (oc.ownerchargeamounttype.ownerchargeamounttype) {
        case 'Fixed':
          var vatdivider = 1 + '.' + vatRate.percentage;
          ownerCharge = ocamount/vatdivider;
          break;
        case 'Percentage':
          var multiplier = ocamount / 100;
          var vatdivider = 1 + '.' + vatRate.percentage;
          ownerCharge = ((amountNetEstimate  * multiplier)/vatdivider);
          break;
        case 'Amount':
          var vatdivider = 1 + '.' + vatRate.percentage;
          ownerCharge = ((amountNetEstimate + ocamount)/vatdivider);
          break;
        default:
      }
    } else {
      ownerCharge = amountNetEstimate;
    }

    ownerChargesCount += 1;
    ownerChargesTool.push(
      <span className="d-block" key={i}>
        {oc.ownerchargecode.ownerchargecode + ' - ' + (oc.description || oc.ownerchargecode.description) + ' @ ' + ownerCharge.toFixed(2)}
      </span>
    );

    ownerChargeVat = (ownerCharge / 100 * vatRate.percentage);
    gross = ownerCharge + ownerChargeVat;
    ocRows.push(
      <tr key={i}>
          <td>{oc.description}</td>
          <td>{ownerCharge.toFixed(2)}</td>
          <td>{ownerChargeVat.toFixed(2)}</td>
          <td>{gross.toFixed(2)}</td>
      </tr>
    );
    ownerChargesTotal += gross;
  });

  return (
    <tr>
      <td>{counter + 1}</td>
      <td>{expense.description}</td>
      <td>{parseFloat(expense.agencycostnet) >= 0 ? expense.agencycostnet.toFixed(2) : 'N/A'}</td>

      {expense.agencycostnet &&
      <td colSpan={toggleActualLimitNotesColumns ? '4' : ''} className="text-center">
        N/A
      </td>
      }
      {!expense.agencycostnet &&
      <React.Fragment>
        <td>{parseFloat(expense.amountnetestimate) >= 0 ? expense.amountnetestimate.toFixed(2) : 'N/A'} </td>
        <td style={{display:toggleActualLimitNotesColumns ? 'table-cell' : 'none'}}>{parseFloat(expense.amountnet) >= 0 ? expense.amountnet.toFixed(2) : 'N/A'}</td>
        <td style={{display:toggleActualLimitNotesColumns ? 'table-cell' : 'none'}}>{limit}</td>
        <td style={{display:toggleActualLimitNotesColumns ? 'table-cell' : 'none'}} className={'text-center ' + (meetsEstimate ? 'lightGreenBackground' : 'lightRedBackground')}>
          {meetsEstimate ? <Icon icon="check" /> : <Icon icon="times" />}
        </td>        
      </React.Fragment>
      }
      <td>
        <span id={"charges" + expense.id} className="mr-1">
          {expense.invoiceto === 'Agency' &&
          <span>agency</span>
          }
          {expense.invoiceto === 'None (zero cost)' &&
          <span>none</span>
          }
          {expense.invoiceto === 'Owner' &&
          <span>{ownerChargesTotal.toFixed(2)}</span>
          }
        </span>
        {ownerChargesCount > 0 &&
        <span id={"chargesToolip" + expense.id} className="text-primary">
          <Icon icon="info-circle" />
        </span>
        }
        {ownerChargesCount > 0 &&
        <Tooltip 
          placement="bottom" 
          isOpen={chargesTool} 
          target={"chargesToolip" + expense.id} 
          toggle={() => { setChargesTool(!chargesTool) }}
        > 
          {ownerChargesTool}
        </Tooltip>
        }
        {ocRows &&
          <table  style={{display:toggleChargeDetails ? 'table' : 'none'}}>
            <thead>
              <tr>
                <th>Description</th>
                <th>Net</th>
                <th>VAT</th>
                <th>Gross</th>
              </tr>
            </thead>
            <tbody>
              {ocRows}
            </tbody>
          </table>
          }
        
      </td> 
      <td style={{display:toggleActualLimitNotesColumns ? 'table-cell' : 'none'}}>{expense.notes}</td>
    </tr>
  );
}

import React from 'react';
import { 
  Col, 
  FormGroup, 
  Input,
  Label } from 'reactstrap';

export default function Check(props) {

  let obj = {
    type: "checkbox",
    name: props.name,
    invalid: props.invalid,
    onChange: props.onChange,
    disabled: props.disabled,
  };

  if (props.innerRef) {
    obj.innerRef = props.innerRef;
    obj.defaultChecked = props.defaultValue;
  } else {
    obj.checked = props.value || false;
  }

  const style = {marginBottom: "0"};


  const labelWidth = props.hasOwnProperty('labelWidth') ? props.labelWidth : 4;

  return (
    <FormGroup row style={style}>
      {labelWidth > 0 &&
      <Label for={props.name} sm={labelWidth}>&nbsp;</Label>
      }
      <Col sm={ 12 - (labelWidth)}>
        <FormGroup check>
          <Label check>
            <Input {...obj} />{' '}
            {props.label || props.name}
          </Label>
        </FormGroup>
      </Col>
    </FormGroup>
  );

}
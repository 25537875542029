import React from "react";

export const Store = React.createContext("");

const hostname = window.location.hostname;

const initialState = {
  groups: undefined,
  currentGroup: undefined,
  fieldWorkers: undefined,
  serviceManagers: undefined,
  serviceCoordinators: undefined,
  administrators: undefined,
  change: undefined,
  user: undefined,
  userRole: undefined,
  properties: undefined,
  workOrderSubStatuses: undefined,
  vatRates: undefined,
  costItemCodes: undefined,
  chargingPeriods: undefined,
  amountLimitTypes: undefined,
  ownerChargeCodes: undefined,
  ownerChargeAmountTypes: undefined,
  workTypes: undefined,
  brandings: undefined,
  jobItemTemplates: undefined,
  checklists: undefined,
  otherbrandchecklists: undefined,
  tabs: [],
  tabStack: ['dashboard'],
  activeTab: 'dashboard',
  mode: (hostname.includes('staging') || hostname.includes('localhost')) ? 'test' : 'live',
  quickFilterCounts: {},
  quickFilter: undefined,
  ignoreHookJobs: [],
  highlightJob: undefined,
  batchUpdate: {},
};

function reducer(state, action) {

  switch (action.type) {
    case "LOAD_FIELDWORKERS":
      return {...state, fieldWorkers: action.payload}
    case "LOAD_SERVICECOORDINATORS":
      return {...state, serviceCoordinators: action.payload}
    case "LOAD_SERVICEMANAGERS":
      return {...state, serviceManagers: action.payload}
    case "LOAD_ADMINISTRATORS":
      return {...state, administrators: action.payload}
    case "LOAD_PROPERTIES":
      return {...state, properties: action.payload};
    case "LOAD_BRANDINGS":
      return {...state, brandings: action.payload};
    case "LOAD_JOBITEMTEMPLATES":
      return {...state, jobItemTemplates: action.payload};      
    case "LOAD_CHECKLISTS":
      return {...state, checklists: action.payload};            
    case "LOAD_OTHERBRANDCHECKLISTS":
        return {...state, otherbrandchecklists: action.payload};            
    case "LOAD_WORKORDERSUBSTATUSES":
      return {...state, workOrderSubStatuses: action.payload};
    case "LOAD_VATRATES":
      return {...state, vatRates: action.payload};  
    case "LOAD_COSTITEMCODES":
      return {...state, costItemCodes: action.payload};
    case "LOAD_CHARGINGPERIODS":
      return {...state, chargingPeriods: action.payload};
    case "LOAD_AMOUNTLIMITTYPES":
      return {...state, amountLimitTypes: action.payload};
    case "LOAD_OWNERCHARGECODES":
      return {...state, ownerChargeCodes: action.payload};
    case "LOAD_OWNERCHARGEAMOUNTTYPES":
      return {...state, ownerChargeAmountTypes: action.payload};
    case "LOAD_WORKTYPES":
      return {...state, workTypes: action.payload};      
    case "LOAD_USER":
      return {...state, user: action.payload};
    case "LOAD_USER_ROLE":
      return {...state, userRole: action.payload};
    case "LOAD_GROUPS":
      return {...state, groups: action.payload};
    case "REFRESH_GROUPS":
      let currentGroup = action.payload.find(g => {
        return g.id === state.currentGroup.id;
      });

      return {...state, groups: action.payload, currentGroup };   
    case "IGNORE_HOOK_JOB":
      return {...state, ignoreHookJobs: [...state.ignoreHookJobs, parseInt(action.payload, 10)]};
    case "REMOVE_IGNORE_HOOK_JOB":
      let ihj = [...state.ignoreHookJobs];
      ihj.splice(ihj.indexOf(action.payload), 1);
      return {...state, ignoreHookJobs: ihj};
    case "RESET":
      saveTabs([]);

      return {
        ...state, 
        groups: undefined,
        currentGroup: undefined, 
        fieldWorkers: undefined,
        serviceManagers: undefined,
        serviceCoordinators: undefined,
        administrators: undefined,
        properties: undefined,
        brandings: undefined,
        jobItemTemplates: undefined,
        checklists: undefined,
        otherbrandchecklists: undefined,
        change: undefined,
        tabs: [],
        tabStack: ['dashboard'],
        activeTab: 'dashboard',
      };
    case "LOAD_GROUP":
      return {...state, currentGroup: action.payload };
    case "UPDATE_JOB_BEGIN":
      let batchUpdate = {...state.batchUpdate};
      batchUpdate[action.payload.id] = getChangeDataFromJob(action.payload);
      return {...state, batchUpdate };
    case "UPDATE_JOB":
    case "UPDATE_JOBSUBSTATUS":
    case "UPDATE_JOB_END": {

        let oldJob = action.type === 'UPDATE_JOB_END' 
          ? state.batchUpdate[action.payload.id]
          : action.oldJob;

        let job = action.payload;

        // emit a 'change' which can be consumed by various components
        let changeData = {
          'old': undefined,
          'new': undefined,
          'changes': [],
        };

        for (const age of ['old', 'new']) {
          const j = age === 'old' ? oldJob : job;

          if (j) {
            changeData[age] = age === 'old' & action.type === 'UPDATE_JOB_END' 
              ? j 
              : getChangeDataFromJob(j);
          } else {
            changeData[age] = {};
          }
        }

        for (const key of Object.keys(changeData['new'])) {
          if (changeData['new'][key] !== changeData['old'][key]) {
            changeData['changes'].push(key);
          }
        }

        let batchUpdate = {...state.batchUpdate};
        if (action.type === 'UPDATE_JOB_END') {
          delete batchUpdate[job.id];
        }

        return {
          ...state, 
          change: {
            id: Math.random().toString(36).substr(2, 9),
            jobId: job.id,
            job,
            data: changeData,
          },
          batchUpdate
        };
      }
    case "ADD_TAB": 
      {
        let tabs = [...state.tabs];

        const index = tabs.findIndex(tab => {
          return tab.ref === action.payload.ref;
        });

        let tabStack = [...state.tabStack];
        tabStack.push(action.payload.ref);

        if (index > -1) {
          return {
            ...state, 
            activeTab: action.payload.ref,
            tabStack,
          }; 
        } else {
          tabs.push(action.payload);
          saveTabs(tabs);

          return {
            ...state, 
            tabs,
            activeTab: action.payload.ref,
            tabStack,
          };
        }
      }
    case "TOGGLE_TAB": 
      {
        if (action.payload !== state.activeTab) {
          let tabStack = [...state.tabStack];
          tabStack.push(action.payload);
          
          return {
            ...state, 
            activeTab: action.payload,
            tabStack,
          }; 
        }
        
        return state;
      }
    case "CLOSE_TAB": 
      {
        let tabs = [...state.tabs];
        const index = tabs.findIndex(tab => {
          return tab.ref === action.payload;
        });

        if (index > -1) {
          tabs.splice(index, 1);
          saveTabs(tabs);
        }

        let tabStack = [...state.tabStack];
        tabStack.forEach((t, i) => {
          if (t === action.payload) {
            tabStack.splice(i, 1);
          }
        });

        return {
          ...state, 
          tabs,
          activeTab: tabStack.length > 1 ? tabStack[tabStack.length - 1] : tabStack[0],
          tabStack,
        };
      }
    case "RENAME_TAB":
      {
        let tabs = [...state.tabs];
        const index = tabs.findIndex(tab => {
          return tab.ref === action.payload;
        });

        if (index > -1) {
          tabs[index].ref = action.newRef;
          tabs[index].name = action.newName;
          tabs[index].type = action.newType;

          saveTabs(tabs);
        }

        let tabStack = [...state.tabStack];
        tabStack.forEach((t, i) => {
          if (t === action.payload) {
            tabStack.splice(i, 1);
          }
        });

        tabStack.push(action.newRef);
        
        return {
          ...state, 
          tabs,
          activeTab: action.newRef,
          tabStack,
        };
      }
    case "SET_QUICK_FILTER_COUNT":
      return {
        ...state, 
        quickFilterCounts: {
          ...state.quickFilterCounts, 
          [action.title]: {title: action.title, count: action.payload, order: action.order}
        }
      };
    case 'UPDATE_QUICK_FILTER_COUNT':
      if (!Object.hasOwn(state.quickFilterCounts, action.title)) {
        // It's possible that the quick filters on the dashboard haven't loaded in time
        // for this action to do useful stuff. In this case we'll just disregard the
        // update.
        console.debug(`Ignored updates to ${action.title} quick filter count. The dashboard is not ready yet.`)
        return state;
      }

      return {
        ...state,
        quickFilterCounts: {
          ...state.quickFilterCounts,
          [action.title]: {
            ...state.quickFilterCounts[action.title],
            // The action is expected to carry a callback
            count: action.updater(state.quickFilterCounts[action.title].count),
          },
        },
      };
    case "SET_QUICK_FILTER":
      return {...state, quickFilter: action.payload};
    case "SET_HIGHLIGHT_JOB":
      return {...state, highlightJob: action.payload};      
    default:
      return state;
  }

}

const saveTabs = (tabs) => {
  let tabsForStorage = tabs.map((tab) => {
    return {type: tab.type, options: tab.options}
  });

  localStorage.setItem('tabs', JSON.stringify(tabsForStorage));
}

const getChangeDataFromJob = job => {
  return {
    preferredstartdatetime: job.preferredstartdatetime,
    propertyid: job.property.id,
    substatus: job.substatus.id ? job.substatus.workordersubstatus.substatusreference : undefined,
    supplierid: job.workordersupplier ? job.workordersupplier.supplier.id : undefined,
    status: job.status,
    assignedtoactorid: (job.substatus.id && job.substatus.assignedto) ? job.substatus.assignedto : undefined,
    workorderactorid: job.actors.collection.map(a => a.id).join(','),
  };
}

export function StoreProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}

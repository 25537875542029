import React, { useContext, useEffect, useReducer } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import { 
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row, 
  Table } from 'reactstrap';
import Check from '../fields/Check';
import SelectProperty from '../fields/SelectProperty';
import Icon from './Icon';
import SelectWorkTypeModal from './SelectWorkTypeModal';
import PrettyLoader from './PrettyLoader';
import { addTab, updateJobSubStatus } from '../Actions';
import { Store } from "../Store";
import moment from 'moment';
import { getSupplierName } from '../mixins/MiscMixin';

export default function JobTemplatesModal(props) {

  const {
    state: {
      currentGroup,
      properties,
      workTypes
    }
  } = useContext(Store);
  const storeDispatch = useContext(Store).dispatch;

  const reducer = (state, action) => {
    switch (action.type) {
      case 'TOGGLE_MODAL':
        return {...state, modal: !state.modal};
      case 'PREV_PAGE':
        return {...state, page: state.page - 1};
      case 'NEXT_PAGE':
        return {...state, page: state.page + 1};
      case 'FILTER_MODE':
        let filters = {
          type: 'Template',
        };

        if (state.liveTemplatesOnly) {
          filters.status = 'Template';
        } 

        if (state.property) {
          filters.propertyid = state.property.id;
        } else if (Array.isArray(currentGroup.propertyIds) && currentGroup.propertyIds.length > 0) {
          filters.propertyid = currentGroup.propertyIds.join('|');
        } else {
          filters.propertybrandingid = currentGroup.brandingIds.join('|');
          filters.livepropertiesonly = '1';
        }

        if (state.workType) {
          filters.worktypeid = state.workType.id;
        }
        if (state.templateType !== 'any') {
          // TODO
        }

        return {...state, filters, page: 1};
      case 'JOBS':
        return {
          ...state, 
          jobs: action.jobs.collection,
          maxPage: Math.ceil(action.jobs.total / state.limit),
          selected: undefined,
        };
      case 'SET_ANY_PROPERTY':
        return {
          ...state, 
          anyProperty: action.payload,
          property: action.payload === true ? undefined : state.property
        };
      case 'SET_PROPERTY': {
        return {...state, property: action.payload, anyProperty: false};
      }
      case 'SET_ANY_WORK_TYPE':
        return {
          ...state, 
          anyWorkType: action.payload,
          workType: action.payload === true ? undefined : state.workType
        };        
      case 'SET_WORK_TYPE': {
        return {...state, workType: action.payload, anyWorkType: false};
      }
      case 'SET_LOADING':
        return {...state, loading: action.payload};
      case 'SET_SELECTED':
        return {
          ...state, 
          selected: action.payload,
          propertyForJob: state.property,
        };
      case 'SET_TEMPLATE_TYPE':
        return {...state, templateType: action.payload};
      case 'SET_SAVING':
        return {...state, saving: action.payload};
      case 'SET_PROPERTY_FOR_JOB':
        return {...state, propertyForJob: action.payload};
      case 'SET_LIVE_TEMPLATES_ONLY':
        return {...state, liveTemplatesOnly: action.payload};
      case 'SET_ASSIGN_TO_FIELD_WORKER':
        return {...state, assignToFieldWorker: action.payload};
      case 'SET_VALIDATE':
        return {...state, validate: action.payload};
      case 'SET_REQUIRED_BY_DATE':
        return {...state, requiredbydate: action.payload};
      default:
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    jobs: undefined,
    limit: 10,
    page: 1,
    maxPage: 1,
    orderBy: 'instancepreferredstartdatetime_asc:id',
    filters: undefined,
    anyProperty: true,
    property: undefined,
    anyWorkType: true,
    workType: undefined,
    loading: false,
    selected: undefined,
    templateType: 'any',
    saving: false,
    propertyForJob: undefined,
    liveTemplatesOnly: true,
    assignToFieldWorker: false,
    validate: {},
    requiredbydate: ''
  });

  useEffect(() => {
    if (props.propertyid) {
      const property = properties.find(p => p.id === props.propertyid);
      dispatch({type: 'SET_PROPERTY', payload: property});
    }
    if (props.worktypeid) {
      const workType = workTypes.find(wt => wt.id === props.worktypeid);
      dispatch({type: 'SET_WORK_TYPE', payload: workType});
    }
  }, []);

  useEffect(() => {
    let ignore = false;

    const getJobs = async () => {
      dispatch({type: 'SET_LOADING', payload: true});

      let jobs = new FilterCollection({
        path: 'workorder',
        object: common.WorkOrder,
      });
      jobs.limit = state.limit;
      jobs.page = state.page;
      //jobs.orderBy = state.orderBy;
      jobs.addFilters([state.filters]);
      
      await jobs.fetch();
      
      if (!ignore) {
        dispatch({type: 'JOBS', jobs});
        dispatch({type: 'SET_LOADING', payload: false});
      }
    }
  
    if (state.filters && state.page && state.orderBy) {
      getJobs();
    }

    return () => { ignore = true; }

  }, [state.filters, state.page, state.orderBy, state.limit]);

  useEffect(() => {
    dispatch({type: 'FILTER_MODE'});
  }, [state.property, state.workType, state.liveTemplatesOnly]);

  const createJob = async () => {
    if (!validateForm()) {
      return;
    }

    dispatch({type: 'SET_SAVING', payload: true});

    let job = state.selected;

    let obj = {
      type: 'Instance',
      shortdescription: job.shortdescription,
      worktype: job.worktype,
      invoiceto: job.invoiceto,
      accesscontacttype: 'N/A',
      blockavailability: job.blockavailability,
      addpropertywarning: job.addpropertywarning,
      rating: job.rating,
      property: new common.Property(state.propertyForJob.id),
      durationminutes: job.durationminutes,
      supplier: new common.Supplier((state.assignToFieldWorker && job.workordersupplier.id) ? job.workordersupplier.supplier.id : currentGroup.holdingSupplier),
      workordersource: {id: 1},
      noworkrequired: job.noworkrequired,
      requiredbydate: moment(state.requiredbydate).format('YYYY-MM-DD')
    };

    let newJob = new common.WorkOrder();

    await newJob.create(obj);

    for (const expense of job.expenses.collection) {

      let eObj = {
        description: expense.description,
        currency: expense.currency,
        vatband: expense.vatband,
        costitemcode: expense.costitemcode,
        amountnetestimate: expense.amountnetestimate,
        chargingperiod: expense.chargingperiod,
        chargingperiodsestimate: expense.chargingperiodsestimate,
        amountlimittype: expense.amountlimittype,
        amountnetlimit: expense.amountnetlimit,
        agencycostnet: expense.agencycostnet,
      }

      var newExpense = new common.WorkOrderExpense();
      newExpense.parent = newJob;

      await newExpense.create(eObj);

      for (const charge of expense.ownercharges.collection) {

        let cObj = {
          ownerchargecode: charge.ownerchargecode,
          ownerchargeamounttype: charge.ownerchargeamounttype,
          amount: charge.amount,
          description: charge.description,
        }

        var newCharge = new common.WorkOrderOwnerCharge();
        newCharge.parent = newExpense;

        await newCharge.create(cObj);

      }

    }

    // give new job the 'new' sub-status
    await updateJobSubStatus(newJob.id, 'new', storeDispatch);

    addTab('editJob', {workOrderId: newJob.id}, storeDispatch);
    props.toggle();
  }

  const validateForm = () => {
    let v = {...state.validate};

    v.propertyForJob = state.propertyForJob ? true : false;
    v.requiredbydate = state.requiredbydate ? true : false;

    dispatch({type: 'SET_VALIDATE', payload: v});

    return !Object.values(v).includes(false);
  }

  return (
    <Modal isOpen toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>job templates</ModalHeader>
      <ModalBody>

        <Row className="mb-2 align-items-center">
          <Col sm={4}>
            <ButtonGroup>
              <Button 
                size="sm" 
                active={state.anyProperty}
                onClick={() => dispatch({type: 'SET_ANY_PROPERTY', payload: true})}
              >
                any property
              </Button>
              <Button 
                size="sm"
                active={!state.anyProperty}
                onClick={() => dispatch({type: 'SET_ANY_PROPERTY', payload: false})}
              >
                choose property
              </Button>
            </ButtonGroup>
          </Col>
          <Col>
            {!state.anyProperty &&
            <SelectProperty 
              selectedProperty={state.property}
              onChange={val => dispatch({type: 'SET_PROPERTY', payload: val[0]})} 
            />
            }          
          </Col>
        </Row>

        <Row className="mb-2 align-items-center">
          <Col sm={4}>
            <ButtonGroup>
              <Button 
                size="sm" 
                active={state.anyWorkType}
                onClick={() => dispatch({type: 'SET_ANY_WORK_TYPE', payload: true})}
              >
                any work type
              </Button>
              <Button 
                size="sm"
                active={!state.anyWorkType}
                onClick={() => dispatch({type: 'SET_ANY_WORK_TYPE', payload: false})}
              >
                choose work type
              </Button>
            </ButtonGroup>
          </Col>
          <Col>
            {state.workType &&
            <React.Fragment>
              {state.workType.worktype}, <strong>or</strong>{' '}
            </React.Fragment>
            }
            {!state.anyWorkType &&
            <SelectWorkTypeModal 
              buttonText="choose work type" 
              onChoose={wt => dispatch({type: 'SET_WORK_TYPE', payload: wt})}
            />
            }
          </Col>
        </Row>

        <Row className="mb-2 align-items-center">
          <Col sm={4}>
            <ButtonGroup>
              <Button 
                size="sm" 
                active={state.liveTemplatesOnly}
                onClick={() => dispatch({type: 'SET_LIVE_TEMPLATES_ONLY', payload: true})}
              >
                live templates only
              </Button>
              <Button 
                size="sm"
                active={!state.liveTemplatesOnly}
                onClick={() => dispatch({type: 'SET_LIVE_TEMPLATES_ONLY', payload: false})}
              >
                include cancelled
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            &nbsp;
          </Col>
          <Col md="auto">
            {state.loading &&
            <PrettyLoader className="mr-2" size={30} /> 
            }
            <ButtonGroup size="sm">
              <Button 
                onClick={() => { dispatch({type: 'PREV_PAGE'}) }}
                disabled={state.page === 1}
              >previous page</Button>
              <Button 
                onClick={() => { dispatch({type: 'NEXT_PAGE'}) }}
                disabled={state.page >= state.maxPage}
              >next page</Button>
            </ButtonGroup>              
          </Col>
        </Row>

        <Table size="sm">
          <thead>
            <tr>
              <th>id</th>
              <th>status</th>
              <th>job</th>
              <th>created</th>
              <th>work type</th>
              <th>recurring</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {!(state.jobs && state.jobs.length > 0) &&
            <tr><td colSpan="6">No results found.</td></tr>
            }
            {state.jobs && state.jobs.length > 0 && state.jobs.map(job => {
              return (
                <tr key={job.id} className={state.selected && state.selected.id === job.id ? 'table-info' : ''}>
                  <td>{job.id}</td>
                  <td>{job.status === 'Template' ? 'Live' : job.status}</td>
                  <td>{job.shortdescription}</td>
                  <td>{moment(job.created).format('DD/MM/YY HH:mm')}</td>
                  <td>{job.worktype.id ? job.worktype.worktype : 'N/A'}</td>
                  <td>
                    <Icon icon={job.autogenerate && job.period !== 'As Required' ? 'check' : 'times'} />
                  </td>
                  <td>
                    <Icon 
                      icon="pencil-alt" 
                      onClick={() => {
                        addTab('editJob', {workOrderId: job.id}, storeDispatch);
                        props.toggle();
                      }} 
                    />{' '}
                    <Icon icon="check" onClick={() => dispatch({type: 'SET_SELECTED', payload: job})} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {state.selected &&
        <Card>
          <CardBody>
            <CardTitle>Create job from template {state.selected.id}</CardTitle>
            <FormGroup row>
              <Label sm={4}>property</Label>
              <Col sm={8}>
                <SelectProperty 
                  selectedProperty={state.propertyForJob}
                  onChange={val => dispatch({ type: 'SET_PROPERTY_FOR_JOB', payload: val[0] })} 
                />
                {state.validate.propertyForJob === false &&
                <div className="invalid-feedback d-block">
                  You must choose a property.
                </div>
                }
              </Col>
            </FormGroup>
            {state.selected.noworkrequired &&
             state.selected.workordersupplier.id && 
             state.selected.workordersupplier.supplier.id !== currentGroup.holdingSupplier &&
            <Check
              name="assigntofieldworker"
              label={"assign job to field worker " + getSupplierName(state.selected.workordersupplier.supplier) + '?'}
              onChange={e => dispatch({ type: 'SET_ASSIGN_TO_FIELD_WORKER', payload: e.target.checked })} 
              value={state.assignToFieldWorker}
              labelWidth={4}
            />
            }

            <FormGroup row>
              <Label sm={4}>required by date</Label>
              <Col sm={8}>
                <Input
                  type="date"
                  name="requiredbydate"
                  placeholder="required by date"
                  value={state.requiredbydate}
                  onChange={e => dispatch({ type: 'SET_REQUIRED_BY_DATE', payload: e.target.value })} 
                  invalid={state.validate.requiredbydate === false}
                />
                {state.validate.requiredbydate === false &&
                <div className="invalid-feedback d-block">
                  You must choose a required by date.
                </div>
                }
              </Col>
            </FormGroup>

            {state.saving &&
            <PrettyLoader className="mr-2" size={38} /> 
            }
            <Button color="secondary" onClick={createJob}>create</Button>
          </CardBody>
        </Card>        
        }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

var React = require('react');
var moment = require('moment');

var DateDisplay = require('./DateDisplay.js');

/*
 * Displays a date range
 */
var DateRange = function(props) {
  var start = moment(props.start);
  var end = moment(props.end);
  var startFormat = props.dayFormat + ' ' + props.monthFormat + ' YYYY';
  var endFormat = startFormat;

  if (start.year() === end.year()) {
    if (start.month() === end.month()) {
      if (start.date() === end.date()) {
        return <DateDisplay format={ startFormat }>{ props.start }</DateDisplay>;
      }
      startFormat = props.dayFormat;
    } else {
      startFormat = props.dayFormat + ' ' + props.monthFormat;
    }
  }

  return (
    <React.Fragment>
      <DateDisplay format={ startFormat }>{ props.start }</DateDisplay> - <DateDisplay format={ endFormat }>{ props.end }</DateDisplay>
    </React.Fragment>
  );
};

DateRange.defaultProps = {
  dayFormat: 'D',
  monthFormat: 'MMM',
};

module.exports = DateRange;

import React from 'react';

export default function Link(props) {
  
  return (
    <div className="cursorPointer text-primary" style={props.style} onClick={props.onClick}>
      {props.children}
    </div>
  );

}
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import { 
  Button, 
  ButtonDropdown,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table, 
  Row, 
  Col
} from 'reactstrap';
import Icon from './Icon';
import SelectWorkTypeModal from './SelectWorkTypeModal';
import moment from 'moment';
import { Store } from "../Store";
import { addTab, setQuickFilterCount } from '../Actions';
import PrettyLoader from './PrettyLoader';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

export default function FilteredJobs(props) {

  const {
    state: {
      change,
      currentGroup,
      user,
      quickFilter,
      properties
    },
    dispatch
  } = useContext(Store);

  const quickFilters = useRef([
    {
      title: 'upcoming', 
      order: 1,
      filters: {
        preferredstartdatetime: '>' + moment().format('YYYY-MM-DDTHH.mm.ss'),
        substatus: '¬cancelled'
      },
      visible: (props.supplierId || props.propertyId || props.templateId) ? true : false,
      fieldtype: 'button'
    },
    {
      title: 'upcoming cancelled', 
      order: 2,
      filters: {
        preferredstartdatetime: '>' + moment().format('YYYY-MM-DDTHH.mm.ss'),
        status: 'Cancelled'
      },
      visible: (props.supplierId || props.propertyId || props.templateId) ? true : false,
      fieldtype: 'button'
    },
    {
      title: 'associated with/assigned to me',
      order: 3,
      filters: [
        {
          assignedtoactorid: user.id,
        },
        {
          workorderactorid: user.id,
          status: [
            'Approved',
            'Completed',
            'Invoice Approved',
            'Invoice Rejected',
            'Invoiced',
            'New',
            'Started',
          ].join('|'),
        }
      ],
      visible: (!props.supplierId && !props.propertyId && !props.templateId) ? true : false,
      orderBy: 'instancerequiredbydate_asc',
      fieldtype: 'button'
    },
    {
      title: 'new',
      order: 4,
      filters: {
        status: 'New',
        substatus: '¬scheduled|cannot_start_job|task|awaitingscheduling',
      },
      visible: !props.templateId ? true : false,
      orderBy: 'instancerequiredbydate_asc',
      doNotShowOnDash: true,
      fieldtype: 'button'
    },
    {
      title: 'needs scheduling',
      order: 5,
      filters: {
        status: 'New',
        substatus: '¬scheduled|cannot_start_job|task',
        requiredbydate: '<' + moment().endOf('isoweek').format('YYYY-MM-DD'),
      },
      visible: !props.templateId ? true : false,
      orderBy: 'instancerequiredbydate_asc',
      fieldtype: 'button'
    },
    {
      title: 'work needs completing',
      order: 6,
      filters: {
        substatus: 'scheduled|started',
        preferredstartdatetime: '<' + moment().format('YYYY-MM-DDT00.00.00'),
      },
      visible: !props.templateId ? true : false,
      orderBy: 'instancepreferredstartdatetime_asc',
      fieldtype: 'button'
    },
    {
      title: 'to be reviewed',
      order: 7,
      filters: {
        substatus: 'completed',
        preferredstartdatetime: '<' + moment().add(1, 'days').format('YYYY-MM-DD00.00.00'),
      },
      visible: !props.templateId ? true : false,
      orderBy: 'instancecompleteddate_asc',
      fieldtype: 'button'
    },
    {
      title: 'needs financially completing',
      order: 8,
      filters: {
        substatus: 'reviewed',
      },
      visible: !props.templateId ? true : false,
      orderBy: 'instancecompleteddate_asc',
      fieldtype: 'button'
    },
    {
      title: 'awaiting an invoice',
      order: 9,
      filters: {
        substatus: 'awaiting_invoice',
      },
      visible: !props.templateId ? true : false,
      orderBy: 'instancecompleteddate_asc',
      fieldtype: 'button'
    },
    {
      title: 'jobs created by field workers',
      order: 10,
      filters: {
        substatus: 'field_worker_submitted_job',
      },
      visible: !props.templateId ? true : false,
      orderBy: 'createddatetime_asc',
      fieldtype: 'button'
    },
    {
      title: 'jobs today',
      order: 11,
      filters: {
        status: [
          'Approved', 
          'Completed', 
          'Financially Completed', 
          'Invoice Approved', 
          'Invoice Rejected', 
          'Invoiced', 
          'New', 
          'Owner Charged', 
          'Started', 
          'Supplier Paid'
        ].join('|'),
        supplierid: currentGroup.fieldWorkers.length > 0 ? currentGroup.fieldWorkers.join('|') : '1',
        preferredstartdatetime: [
          moment().startOf('day').format('YYYY-MM-DDTHH.mm.ss'),
          moment().endOf('day').format('YYYY-MM-DDTHH.mm.ss'),
        ].join('/'),
      },
      visible: !props.templateId ? true : false,
      orderBy: 'instancepreferredstartdatetime_asc',
      fieldtype: 'button'
    },
    {
      title: 'stuck jobs',
      order: 12,
      filters: {
        substatus: 'cannot_start_job|could_not_complete',
      },
      visible: !props.templateId ? true : false,
      orderBy: 'createddatetime_asc',
      fieldtype: 'button'
    },
    {
      title: 'tasks',
      order: 13,
      filters: {
        status: 'New',
        substatus: 'task',
      },
      visible: !props.templateId ? true : false,
      orderBy: 'createddatetime_asc',
      doNotShowOnDash: true,
      fieldtype: 'button'
    },    
    {
      title: 'all jobs',
      order: 14,
      filters: {

      },
      visible: !props.propertyId ? false : true,
      orderBy: 'createddatetime_asc',
      doNotShowOnDash: true,
      fieldtype: 'button'
    },    
    {
      title: 'completed jobs',
      order: 15,
      filters: {
        status: [
          'Completed', 
          'Financially Completed', 
          'Owner Charged'
        ].join('|'),
      },
      visible: !props.propertyId ? false : true,
      orderBy: 'createddatetime_asc',
      doNotShowOnDash: true,
      fieldtype: 'button'
    },    
    {
      title: 'short description',
      order: 16,
      filters: {},
      visible: true,
      orderBy: 'createddatetime_asc',
      doNotShowOnDash: false,
      fieldtype: 'typeahead'
    },   
  ]);


const AsyncExample = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = (query) => {
    setIsLoading(true);
    setOptions([]);    

    let qf = getBaseFilters();
    qf = {...qf, ...{shortdescription: '~' + query}};

    let sdjobs = new FilterCollection({
      path: 'workorder',
      object: common.WorkOrder,
    });

    sdjobs.limit = 100;
    sdjobs.page = 1;
    sdjobs.fields = 'shortdescription';
    sdjobs.distinctField = 'shortdescription';
    sdjobs.addFilters(qf);
    sdjobs.fetch().then(() => {
      const distinctShortDescriptions =  [...new Set( sdjobs.collection.map(j => j.shortdescription))];
      const options = distinctShortDescriptions.map((desc) => ({
        shortdescription: desc
      }));
      setOptions(options);
      setIsLoading(false);
    });
  };

  const handleClick = (query) => {
    setIsLoading(true);

    const qf = {
      filters: {
        shortdescription: '~' + query[0].shortdescription
      },
      orderBy: 'instancerequiredbydate_asc',
    };
    localDispatch({type: 'USE_QUICK_FILTER', payload: qf});
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <AsyncTypeahead
      filterBy={filterBy}
      id="search-by-description"
      isLoading={isLoading}
      labelKey="shortdescription"
      minLength={3}
      onSearch={handleSearch}
      options={options}
      placeholder="Search by description"
      onChange={handleClick} 
      renderMenuItemChildren={(option) => (
        <React.Fragment key={option.shortdescription}>
          <span>
            {option.shortdescription}
          </span>
        </React.Fragment>
      )}
    />
  );
};

  const orderBys = useRef([
    {
      title: 'job scheduled for ascending',
      order: 'instancepreferredstartdatetime_asc',
    },
    {
      title: 'job scheduled for descending',
      order: 'instancepreferredstartdatetime_desc',
    },
    {
      title: 'created date ascending',
      order: 'createddatetime_asc',
    },
    {
      title: 'created date descending',
      order: 'createddatetime_desc',
    },
    {
      title: 'required by date ascending',
      order: 'instancerequiredbydate_asc',
    },
    {
      title: 'required by date descending',
      order: 'instancerequiredbydate_desc',
    },
    {
      title: 'completed date ascending',
      order: 'instancecompleteddate_asc',
    },
    {
      title: 'completed date descending',
      order: 'instancecompleteddate_desc',
    }, 
  ]);

  const reducer = (localState, action) => {

    switch (action.type) {
      case 'PREV_PAGE':
        return {...localState, page: localState.page - 1};
      case 'NEXT_PAGE':
        return {...localState, page: localState.page + 1};
      case 'SET_ORDER_BY':
        return {...localState, orderBy: action.payload};
      case 'SET_CUSTOM_FILTERS':
        let orderBy = localState.orderBy;

        for (const a of action.payload) {
          if (a.option.filter === 'type') {
            if (a.value === 'Template') {
              orderBy = 'createddatetime_asc';
              break;
            } else {
              orderBy = 'instancepreferredstartdatetime_asc';
            }
          }
        }

        return {
          ...localState, 
          customFilters: action.payload,
          orderBy,
          page: 1,
        };
      case 'REMOVE_CUSTOM_FILTER':
        let customFilters = [...localState.customFilters];
        customFilters.splice(action.payload, 1);
        return {...localState, customFilters, page: 1};
      case 'USE_QUICK_FILTER': {
        const qf = action.payload;

        let orderBy = qf.orderBy || 'instancepreferredstartdatetime_asc';

        return {
          ...localState, 
          quickFilter: qf,
          customFilters: [],
          orderBy, 
          page: 1,
        };
      }
      case 'SET_JOBS':
        return {...localState, jobs: action.jobs};
      case 'SET_LOADING':
        return {...localState, loading: action.loading};
      case 'TOGGLE_MODAL':
        return {...localState, modal: !localState.modal};
      case 'SET_COUNT':
        return {...localState, counts: {...localState.counts, [action.name]: action.count}};
      case 'SET_REFRESH':
        return {...localState, refresh: localState.refresh + 1};
      case 'TOGGLE_LIMIT_DROPDOWN':
          return {...localState, limitDropdown: !localState.limitDropdown};
      case 'SET_LIMIT':
          return {...localState, limit: action.loading};
      default:
        return localState;
    }

  };

  const [localState, localDispatch] = useReducer(reducer, {
    quickFilter: undefined,
    customFilters: [],
    page: 1,
    limit: 10,
    orderBy: 'instancepreferredstartdatetime_asc',
    subStatus: undefined,
    loading: false,
    refresh: 0,
    modal: false,
    jobs: {},
    counts: {},
    limitDropdown: false,
  });

  const getCount = useCallback(quickFilter => {
    let additionalFilters = {
      type: 'Instance',
    };

    if (props.supplierId) {
      additionalFilters.supplierid = props.supplierId;
    }

    if (props.propertyId) { 
      additionalFilters.propertyid = props.propertyId; 
    } else if (Array.isArray(currentGroup.propertyIds) && currentGroup.propertyIds.length > 0) {
      additionalFilters.propertyid = currentGroup.propertyIds.join('|');
    } else {
      additionalFilters.propertybrandingid = currentGroup.brandingIds.join('|');
      additionalFilters.livepropertiesonly = '1';
    }

    if (props.templateId) {
      additionalFilters.templateid = props.templateId;
    }

    let actualFilters = undefined;

    if (Array.isArray(quickFilter.filters)) {
      actualFilters = [];
      quickFilter.filters.forEach(lsf => {
        actualFilters.push({...lsf, ...additionalFilters});
      });
    } else {
      actualFilters = {...additionalFilters, ...quickFilter.filters};
    }

    let jobs = new FilterCollection({
      path: 'workorder',
      object: common.WorkOrder,
    });
    jobs.limit = 1;
    jobs.page = 1;
    jobs.fields = 'id';
    jobs.addFilters(actualFilters);
    jobs.fetch().then(() => {
      localDispatch({type: 'SET_COUNT', name: quickFilter.title, count: jobs.total});

      if (!quickFilter.doNotShowOnDash) {
        setQuickFilterCount(quickFilter.title, quickFilter.order, jobs.total, dispatch);
      }
    });
  }, []);

  useEffect(() => {
    const initialQf = (!props.supplierId && !props.propertyId && !props.templateId) ? 
    quickFilters.current[2] : 
    quickFilters.current[0];

    localDispatch({
      type: 'USE_QUICK_FILTER',
      payload: initialQf
    });

    if (!props.supplierId && !props.propertyId && !props.templateId) {
      quickFilters.current.forEach(qf => {
        if (qf.visible && qf.title !== initialQf.title) {
          getCount(qf);
        }
      });
    }
  }, []);

  const getBaseFilters= () => {
    let baseFilters = {
      type: 'Instance'
    };

    if (props.supplierId) {
      baseFilters.supplierid = props.supplierId;
    }

    if (props.propertyId) { 
      baseFilters.propertyid = props.propertyId; 
    } else if (Array.isArray(currentGroup.propertyIds) && currentGroup.propertyIds.length > 0) {
      baseFilters.propertyid = currentGroup.propertyIds.join('|');
    } else {
      baseFilters.propertybrandingid = currentGroup.brandingIds.join('|');
      baseFilters.livepropertiesonly = '1';
    }

    if (props.templateId) {
      baseFilters.templateid = props.templateId;
    }      
    return baseFilters;
  }

  useEffect(() => {
    const getJobs = () => {
      localDispatch({type: 'SET_LOADING', loading: true});

      let baseFilters = getBaseFilters();

      let actualFilters = undefined;

      if (localState.customFilters.length > 0) {
        localState.customFilters.forEach(a => {
          baseFilters[a.option.filter] = a.value;
        });

        actualFilters = baseFilters;
      } else {
        if (Array.isArray(localState.quickFilter.filters)) {
          actualFilters = [];
          localState.quickFilter.filters.forEach(lsf => {
            actualFilters.push({...lsf, ...baseFilters});
          });
        } else {
          actualFilters = {...localState.quickFilter.filters, ...baseFilters};
        }
      }

      let jobs = new FilterCollection({
        path: 'workorder',
        object: common.WorkOrder,
      });
      jobs.limit = localState.limit;
      jobs.page = localState.page;
      jobs.orderBy = localState.orderBy + ':id';
      jobs.addFilters(actualFilters);
      jobs.fetch().then(() => {
        localDispatch({type: 'SET_JOBS', jobs});
        localDispatch({type: 'SET_LOADING', loading: false});

        if (localState.quickFilter && localState.customFilters.length === 0) {
          localDispatch({
            type: 'SET_COUNT', 
            name: localState.quickFilter.title, 
            count: jobs.total
          });
        }
      });
    }

    if ((localState.quickFilter || localState.customFilters.length > 0) && localState.page && localState.limit) {
      getJobs();
    }
  }, [localState.quickFilter, localState.customFilters, localState.orderBy, localState.page, localState.refresh, localState.limit]);

  useEffect(() => {
    if (!change) { return; }

    if (!props.supplierId && !props.propertyId && !props.templateId) {
      if (localState.jobs && localState.jobs.map(j => j.id).includes(change.jobId)) {
        localDispatch({type: 'SET_REFRESH'});
      } else {
        quickFilters.current.forEach(qf => {
          if (qf.visible) {
            for (const c of change.data.changes) {
              if (Object.keys(qf.filters).includes(c)) {
                if (localState.quickFilter.title === qf.title) {
                  setTimeout(() => {
                    localDispatch({type: 'SET_REFRESH'});
                  }, 10000);
                } else {
                  setTimeout(() => {
                    getCount(qf);
                  }, 10000);
                }
                break;
              }
            }
          }
        });
      }
    } else {
      if (props.supplierId) {
        if (change.data.old.supplierid == props.supplierId || change.data.new.supplierid == props.supplierId) {
          localDispatch({type: 'SET_REFRESH'});
        }
      }
  
      if (props.propertyId) {
        if (change.data.old.propertyid == props.propertyId || change.data.new.propertyid == props.propertyId) {
          localDispatch({type: 'SET_REFRESH'});
        }
      }
    }

  }, [change]);

  useEffect(() => {
    if (quickFilter) {
      const qf = quickFilters.current.find(qf => qf.title === quickFilter);
      localDispatch({type: 'USE_QUICK_FILTER', payload: qf});
    }
  }, [quickFilter]);

  const { jobs, page } = localState;
  const jobsFrom = jobs ? ((page - 1) * jobs.limit) + 1 : 0;
  const jobsTo = jobs ? Math.min((((page - 1) * jobs.limit) + jobs.limit), jobs.total) : 0;

  const orderBy = localState.orderBy.substring(0, localState.orderBy.indexOf('_'));
  let dateColumnName;
  let dateColumnValue;

  switch (orderBy) {
    case 'instancepreferredstartdatetime':
      dateColumnName = 'scheduled for';
      dateColumnValue = 'preferredstartdatetime';
      break;
    case 'createddatetime':
      dateColumnName = 'created';
      dateColumnValue = 'createddatetime';
      break;
    case 'instancerequiredbydate':
      dateColumnName = 'required by';
      dateColumnValue = 'requiredbydate';
      break;
    case 'instancecompleteddate':
      dateColumnName = 'completed';
      dateColumnValue = 'completeddate';
      break;
  }

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col>
          <div className="d-flex flex-wrap">
            {quickFilters.current.map(qf => 
            <React.Fragment key={qf.title}>
              {qf.fieldtype == 'button' &&
              <Button 
                size="sm"
                color="secondary" 
                onClick={() => localDispatch({type: 'USE_QUICK_FILTER', payload: qf})} 
                active={
                  localState.quickFilter && 
                  localState.quickFilter.title === qf.title && 
                  localState.customFilters.length === 0
                }
                className={qf.visible === false ? 'd-none mr-1 mb-1' : 'mr-1 mb-1'}
              >
                {qf.title}{Object.keys(localState.counts).includes(qf.title) ? ' (' + localState.counts[qf.title] + ')' : ''}
              </Button>
              }
              {qf.fieldtype == 'typeahead' &&
              <AsyncExample
              qf={qf}
              />
              }
            </React.Fragment>
            )}
          </div>
        </Col>
        <Col sm="auto">
          {localState.loading &&
          <PrettyLoader className="mr-2" size={30} />
          }
          {!localState.loading &&
          <span className="mr-2 p-1">
            {jobs && jobs.total > 0 && 
            jobs && 'jobs ' + jobsFrom + ' to ' + jobsTo + ' of ' + jobs.total
            }
          </span>
          }
          <ButtonGroup size="sm">
            <Button 
              onClick={() => { localDispatch({type: 'PREV_PAGE'}) }}
              disabled={!jobs.previous}
            >
              previous page
            </Button>
            <Button 
              onClick={() => { localDispatch({type: 'NEXT_PAGE'}) }}
              disabled={!jobs.next}
            >
              next page
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Button size="sm" onClick={() => { localDispatch({type: 'TOGGLE_MODAL'}) }}>
            advanced search
          </Button>{' '}        
          {localState.customFilters.map((filter, i) => {
            return (
              <React.Fragment key={i}>
                <Button size="sm">
                  {filter.option.name}{': '}{filter.value.replace("/", " - ")}{' '}
                  <Icon icon="times" className="clickable-icon" onClick={() => {
                    localDispatch({type: 'REMOVE_CUSTOM_FILTER', payload: i});
                  }} />
                </Button>{' '}
              </React.Fragment>
            );
          })}  

          <div style={{display: 'inline-flex', verticalAlign: 'middle'}}>
            <div className="mr-2 p-1 float-right">
              results per page:
            </div>           
            <ButtonDropdown 
              size="sm" 
              isOpen={localState.limitDropdown} 
              toggle={() => { localDispatch({type: 'TOGGLE_LIMIT_DROPDOWN'}) }}
              disabled={localState.busy}
            >
              <DropdownToggle caret>
                {localState.limit} 
              </DropdownToggle>
              <DropdownMenu>
                {[10, 20, 30, 50].map(quantity => 
                <DropdownItem key={quantity} onClick={() => {
                  localDispatch({type: 'SET_LIMIT', loading: quantity})
                }}>
                  {quantity}
                </DropdownItem>  
                )}
              </DropdownMenu>
            </ButtonDropdown>
          </div> 

        </Col>
        <Col sm="auto">
          

          <span className="mr-2 p-2">
            order by:
          </span>
          <Input 
            type="select"
            className="d-inline w-auto"
            style={{width: "auto"}} 
            value={localState.orderBy} 
            onChange={e => {
              localDispatch({type: 'SET_ORDER_BY', payload: e.target.value});
            }}
          >
            {orderBys.current.map(ob => 
            <option key={ob.order} value={ob.order}>{ob.title}</option>  
            )}
          </Input>        
        </Col>
      </Row>
      <Table size="sm" striped>
        <thead>
          <tr>
            <th>id</th>
            <th>job</th>
            <th>field worker</th>
            <th>property</th>
            <th>{dateColumnName}</th>
            <th>status</th>
            <th>{''}</th>
          </tr>
        </thead>
        <tbody>
          {!jobs &&
          <tr>
            <td colSpan="7">Loading...</td>
          </tr>
          }
          {jobs && jobs.total === 0 &&
          <tr>
            <td colSpan="7">No jobs found.</td>
          </tr>        
          }
          { jobs && jobs.total > 0 &&
            jobs.collection.map((job) => {

              let dateColumnValueFormated = job[dateColumnValue];
              if (job[dateColumnValue]) {
                dateColumnValueFormated = moment(dateColumnValueFormated).format(
                  dateColumnValue.includes('time') ? 'DD/MM/YY HH:mm' : 'DD/MM/YY'
                );
              } else {
                dateColumnValueFormated = 'N/A';
              }
              
              let status = job.status.toLowerCase();

              let substatus = undefined;
              if (job.substatus.id) {
                if (status.replace(' ', '_') !== job.substatus.workordersubstatus.substatusreference) {
                  substatus = job.substatus.workordersubstatus.substatusname;
                }
              }

              if (status === 'completed') {
                status = 'practically completed';
              }

              let fieldWorkerName = 'N/A';
              if (job.workordersupplier.supplier.id !== parseInt(currentGroup.holdingSupplier, 10)) {
                const fieldWorker = job.workordersupplier.supplier;
                fieldWorkerName = fieldWorker.title + ' ' + fieldWorker.firstname + ' ' + fieldWorker.surname;
              }

              let propertyName = 'N/A';
              const property = properties.find(property => property.id === job.property.id);
              if (property) {
                propertyName = property.name;
              }

              return (
                <tr key={job.id}>
                  <th className="align-middle" style={{fontSize: "0.875rem"}} scope="row">{job.id}</th>
                  <td>{job.shortdescription}</td>
                  <td>{fieldWorkerName}</td>
                  <td>{propertyName}</td>
                  <td>{dateColumnValueFormated}</td>
                  <td>{status + (substatus ? ' - ' + substatus : '')}</td>
                  <td>
                    <Icon icon="pencil-alt" onClick={() => addTab('editJob', {workOrderId: job.id}, dispatch)} />
                  </td>
                </tr>                        
              );

            })         
          }
        </tbody>
      </Table>
      {localState.modal &&
      <AdditionalFiltersModal 
        toggle={() => { localDispatch({type: 'TOGGLE_MODAL'}) }}
        dispatchFilters={(af) => { localDispatch({type: 'SET_CUSTOM_FILTERS', payload: af}) }}
        filters={localState.customFilters}
      />
      }
    </React.Fragment>
  );

}

function AdditionalFiltersModal(props) {

  const options = [
    {
      name: 'job type',
      filter: 'type',
      type: 'options',
      options: [
        {
          name: 'Instance',
          value: 'Instance',
        },        
        {
          name: 'Template',
          value: 'Template',
        },
      ],
      defaultValue: 'Template',
      unique: true,
    },
    {
      name: 'required by date',
      filter: 'requiredbydate',
      type: 'date',
      defaultValue: moment().format('YYYY-MM-DD')
    },
    {
      name: 'scheduled date and time',
      filter: 'preferredstartdatetime',
      type: 'datetime',
      defaultValue: undefined,
    },
    {
      name: 'status',
      filter: 'substatus',
      type: 'substatus',
      defaultValue: undefined,
      unique: true,
    }, 
    {
      name: 'work type',
      filter: 'worktypeid',
      type: 'worktype',
      defaultValue: undefined,
      unique: true,
    },
  ];

  const [filters, setFilters] = useState(
    props.filters.length > 0 ? props.filters : [{option: options[0], value: 'Instance'}]
  );
  const [newFilter, setNewFilter] = useState(options[1].filter);

  const [valid, setValid] = useState(false);

  const addFilter = () => {
    let f = [...filters];

    const option = options.find(o => o.filter === newFilter);

    f.push({
      option,
      value: option.defaultValue,
    });
    setFilters(f);
  }

  const removeFilter = i => {
    let f = [...filters];
    f.splice(i, 1);
    setFilters(f);
  }

  const updateFilterValue = (index, value) => {
    const f = [...filters];
    f[index].value = value;
    setFilters(f);

    let valid = true;
    f.forEach(filter => {
      if (!filter.value) {
        valid = false;
      }
    });
    setValid(valid);
  }

  const dispatchFilters = () => {
    props.dispatchFilters(filters);
    props.toggle();
  }

  const optionsFiltered = options.filter(option => {
    return !(filters.map(filter => filter.option.name).includes(option.name) && option.unique);
  });

  return (
    <Modal isOpen toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>advanced search</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Label sm={3}>add filter</Label>
          <Col sm={7}>
            <Input type="select" onChange={e => { setNewFilter(e.target.value); }}>
              {optionsFiltered.map(option =>
                <option key={option.filter} value={option.filter}>
                  {option.name}
                </option>
              )}
            </Input>
          </Col>
          <Col sm={2}>
              <Button block onClick={addFilter}>add</Button>
          </Col>
        </FormGroup>
        <hr />
        {filters.map((filter, i) => {
          const option = filter.option;

          switch (option.type) {
            case 'options':
              return (
                <OptionsFilter 
                  key={i}
                  {...option}
                  value={filter.value} 
                  onChange={val => { updateFilterValue(i, val) }}
                  handleDelete={() => removeFilter(i)}
                />
              );
            case 'date':
              return (
                <DateFilter 
                  key={i} 
                  {...option} 
                  value={filter.value} 
                  onChange={val => { updateFilterValue(i, val) }}
                  handleDelete={() => removeFilter(i)}
                />
              );
            case 'datetime':
              return (
                <DateTimeFilter 
                  key={i} 
                  {...option} 
                  value={filter.value} 
                  onChange={val => { updateFilterValue(i, val) }}
                  handleDelete={() => removeFilter(i)}
                />
              );
            case 'worktype':
              return (
                <WorkTypeFilter 
                  key={i} 
                  {...option} 
                  value={filter.value} 
                  onChange={val => { updateFilterValue(i, val) }}
                  handleDelete={() => removeFilter(i)}
                />
              );
            case 'substatus':
              return (
                <SubStatusFilter 
                  key={i} 
                  {...option} 
                  value={filter.value} 
                  onChange={val => { updateFilterValue(i, val) }}
                  handleDelete={() => removeFilter(i)}
                />
              );
          }
        })}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={dispatchFilters} disabled={!valid}>use filters</Button>
      </ModalFooter>      
    </Modal>
  );

}

function OptionsFilter(props) {
  return (
    <FormGroup row>
      <Label sm={3}>
        {props.name}{' '}
        <Icon icon="trash" onClick={props.handleDelete} />
        </Label>
      <Col sm={9}>
        <Input 
          type="select"
          name={props.name}
          value={props.value || props.options[0].name}
          onChange={e => { props.onChange(e.target.value) }}
        >
          {props.options.map(option =>
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          )}
        </Input>
      </Col>
    </FormGroup>
  )
}

function DateFilter(props) {

  const types = [
    {id: 'eq', name: 'date equal to'},
    {id: 'gt', name: 'date greater than'},
    {id: 'lt', name: 'date less than'},
    {id: 'btwn', name: 'date between'},
  ];

  const [type, setType] = useState('eq');

  const [values, setValues] = useState();

  useEffect(() => {
    let values = {
      eq: moment().format('YYYY-MM-DD'),
      from: moment().format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    };

    if (props.value) {
      const val = props.value;

      if (val.includes('<')) {
        setType('lt');
      } else if (val.includes('>')) {
        setType('gt');
      } else if (val.includes('/')) {
        setType('btwn');
      }

      if (val.includes('/')) {
        values.from = val.substring(0, val.indexOf("/"));
        values.to = val.substring(val.indexOf("/") + 1);
      } else {
        if (val.includes('<') || val.includes('>')) {
          values.eq = val.substring(1);
        } else {
          values.eq = val;
        }
      }
    }

    setValues(values);
  }, []);

  useEffect(() => {
    if (!values) { return; }

    let val = '';

    switch (type) {
      case 'eq': val = values.eq; break;
      case 'gt': val = '>' + values.eq; break;
      case 'lt': val = '<' + values.eq; break;
      case 'btwn': val = values.from + '/' + values.to; break;
    }

    props.onChange(val);
  }, [values, type]);

  const handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setValues({...values, [name]: value});
  }

  if (!values) {
    return null;
  }

  return (
    <FormGroup row className="align-items-center">
      <Label sm={3}>
        {props.name}{' '}
        <Icon icon="trash" onClick={props.handleDelete} />
      </Label>
      <Col sm={3}>
        <Input 
          type="select"
          name="type"
          value={type}
          onChange={e => { setType(e.target.value) }}
        >
          {types.map(t => 
          <option key={t.id} value={t.id}>{t.name}</option>
          )}
        </Input>
      </Col>
      {['eq', 'gt', 'lt'].includes(type) &&
      <Col sm={6}>
        <Input 
          type="date"
          name="eq"
          value={values.eq}
          onChange={handleChange}
        />
      </Col>
      }
      {type === 'btwn' &&
      <React.Fragment>
        <Col sm={6}>
          <Input type="date" name="from" value={values.from} onChange={handleChange} />
          <span style={{display: "block", textAlign: "center"}}>and</span>
          <Input type="date" name="to" value={values.to} onChange={handleChange} />
        </Col>
      </React.Fragment>
      }
    </FormGroup> 
  );
}

function DateTimeFilter(props) {

  const types = [
    {id: 'gt', name: 'date+time greater than'},
    {id: 'lt', name: 'date+time less than'},
    {id: 'btwn', name: 'date+time between'},
  ];

  const [type, setType] = useState('gt');

  const [values, setValues] = useState();

  useEffect(() => {

    const now = moment();
    const nowDate = now.format('YYYY-MM-DD');

    let values = {
      eqDate: nowDate,
      eqTime: '00:00',
      fromDate: nowDate,
      fromTime: '00:00',
      toDate: nowDate,
      toTime: '23:59'
    };

    if (props.value) {
      const val = props.value;
      let type = '';

      if (val.includes('<')) {
        type = 'lt';
      } else if (val.includes('>')) {
        type = 'gt';
      } else if (val.includes('/')) {
        type = 'btwn';
      }

      setType(type);

      if (val.includes('/')) {
        const valFrom = moment(val.substring(0, val.indexOf("/")));
        const valTo = moment(val.substring(val.indexOf("/") + 1));

        values.fromDate = valFrom.format('YYYY-MM-DD');
        values.fromTime = valFrom.format('HH:mm');
        values.toDate = valTo.format('YYYY-MM-DD');
        values.toTime = valTo.format('HH:mm');
      } else {
        let valEq = moment(val.substring(1));

        values.eqDate = valEq.format('YYYY-MM-DD');
        values.eqTime = valEq.format('HH:mm');
      }

    }

    setValues(values);

  }, []);

  useEffect(() => {
    if (!values) { return; }

    let val = '';

    if (type === 'gt' || type === 'lt') {
      const eq = moment(values.eqDate + ' ' + values.eqTime).format('YYYY-MM-DDTHH.mm.ss');
      val = (type === 'gt' ? '>' : '<') + eq;
    } else { 
      const from = moment(values.eqFrom + ' ' + values.eqFrom).format('YYYY-MM-DDTHH.mm.ss');
      const to = moment(values.eqTo + ' ' + values.eqTo).format('YYYY-MM-DDTHH.mm.ss');
      val = from + '/' + to;
    }

    props.onChange(val);
  }, [values, type]);

  const handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setValues({...values, [name]: value});
  }

  if (!values) {
    return null;
  }

  return (
    <FormGroup row className="align-items-center">
      <Label sm={3}>
        {props.name}{' '}
        <Icon icon="trash" onClick={props.handleDelete} />
      </Label>
      <Col sm={3}>
        <Input 
          type="select"
          name="type"
          value={type}
          onChange={e => { setType(e.target.value) }}
        >
          {types.map(t => 
          <option key={t.id} value={t.id}>{t.name}</option>
          )}
        </Input>
      </Col>
      {['gt', 'lt'].includes(type) &&
      <Col sm={6}>
        <Row>
            <Col>
              <Input type="date" name="eqDate" value={values.eqDate} onChange={handleChange} />            
            </Col>
            <Col>
              <Input type="time" name="eqTime" value={values.eqTime} onChange={handleChange} />            
            </Col>
        </Row>
      </Col>
      }
      {type === 'btwn' &&
      <React.Fragment>
        <Col sm={6}>
          <Row>
            <Col>
              <Input type="date" name="fromDate" value={values.fromDate} onChange={handleChange} />  
            </Col>
            <Col>
              <Input type="time" name="fromTime" value={values.fromTime} onChange={handleChange} />  
            </Col>
          </Row>
          <span style={{display: "block", textAlign: "center"}}>and</span>
          <Row>
            <Col>
              <Input type="date" name="toDate" value={values.toDate} onChange={handleChange} />  
            </Col>
            <Col>
            <Input type="time" name="toTime" value={values.toTime} onChange={handleChange} />  
            </Col>
          </Row>
          
        </Col>
      </React.Fragment>
      }
    </FormGroup> 
  );
}

function WorkTypeFilter(props) {

  const {
    state: {
      workTypes
    }
  } = useContext(Store);

  const [workType, setWorkType] = useState();

  useEffect(() => {
    if (props.value) {
      const wt = workTypes.find(wt => wt.id === parseInt(props.value, 10));
      setWorkType(wt);
    }
  }, []);

  useEffect(() => {
    props.onChange(workType ? workType.id.toString() : undefined);
  }, [workType]);

  return (
    <FormGroup row>
      <Label sm={3}>
        {props.name}{' '}
        <Icon icon="trash" onClick={props.handleDelete} />
      </Label>
      <Col sm={9}>
        {workType &&
        <React.Fragment>
          {workType.worktype}, <strong>or</strong>{' '}
        </React.Fragment>
        }
        <SelectWorkTypeModal 
          buttonText="choose work type" 
          onChoose={wt => setWorkType(wt)}
        />
      </Col>
    </FormGroup>
  )
}

function SubStatusFilter(props) {

  const {
    state: {
      workOrderSubStatuses
    }
  } = useContext(Store);

  const [subStatus, setSubStatus] = useState();
  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    if (props.value) {
      const ss = workOrderSubStatuses.find(woss => woss.substatusreference === props.value);
      setSubStatus(ss);
    }
  }, []);

  useEffect(() => {
    props.onChange(subStatus ? subStatus.substatusreference : undefined);
    setDropdown(false);
  }, [subStatus]);

  let parentStatuses = {};

  workOrderSubStatuses.forEach(woss => {
    let parentstatus = woss.parentstatus.toLowerCase();
    if (parentstatus === 'completed') {
      parentstatus = 'practically completed';
    } 

    if (!parentStatuses[parentstatus]) {
      parentStatuses[parentstatus] = [];
    }

    parentStatuses[parentstatus].push(
      <li 
        className="dropdown-item cursorPointer"
        key={woss.id}
        onClick={() => setSubStatus(woss)}
      >
        {woss.substatusname}
      </li>
    );
  });

  return (
    <FormGroup row>
      <Label sm={3}>
        {props.name}{' '}
        <Icon icon="trash" onClick={props.handleDelete} />
      </Label>
      <Col sm={9}>
        <ButtonDropdown size="sm" isOpen={dropdown} toggle={() => setDropdown(!dropdown)}>
          <DropdownToggle caret active={subStatus ? true : false}>
            { subStatus ? subStatus.substatusname : 'status'}
          </DropdownToggle>   
          <DropdownMenu>
            {Object.keys(parentStatuses).map(ps => 
              <li className="dropdown-submenu" key={ps}>
                <span className="dropdown-item cursorPointer" tabIndex="-1" href="#">{ps}</span>
                <ul className="dropdown-menu">
                {parentStatuses[ps]}
                </ul>
              </li>
            )}
          </DropdownMenu>                     
        </ButtonDropdown>
      </Col>
    </FormGroup>
  )
}
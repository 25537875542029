import React, { useContext, useEffect, useState } from 'react';
import { common, Collection, FilterCollection } from 'plato-js-client';
import { 
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table } from 'reactstrap';
import Icon from './Icon';
import { addTab, updateJob } from '../Actions';
import { Store } from "../Store";
import moment from 'moment';
import { errorToast } from './Toasties';
import TabsButton from './TabsButton';

export default function InvoiceModal(props) {

  const { 
    dispatch
  } = useContext(Store);

  const [busy, setBusy] = useState(false);
  const [invoice, setInvoice] = useState();
  const [properties, setProperties] = useState();
  const [totals, setTotals] = useState();

  useEffect(() => {

    const loadInvoice = async () => {
      setBusy(true);

      let pmsInvoice = new common.PmsInvoice(props.invoiceId);

      try {
        await pmsInvoice.get();
        await pmsInvoice.owner.get();
      } catch(error) {
        errorToast('invoice not found');
        props.toggle();
      }

      var pmsInvoiceOwnerCharges = new Collection({
        path: 'ownercharge',
        object: common.PmsInvoiceOwnerCharge,
        parent: pmsInvoice
      });
      
      await pmsInvoiceOwnerCharges.fetch();

      let jobs = new FilterCollection({
        path: 'workorder',
        object: common.WorkOrder,
      });
      jobs.limit = 999;
    
      let filters = {
        ownerchargeid: pmsInvoiceOwnerCharges.map(pioc => pioc.ownercharge.id).join('|')
      }

      jobs.addFilters(filters);

      await jobs.fetch();

      let properties = { 'ZZOther Charges': [] };

      let totals = {
        net: 0,
        vat: 0,
      };

      pmsInvoiceOwnerCharges.forEach(pioc => {
        if (pioc.ownercharge.type === 'OwnerChargeProperty') {
          const propertyName = pioc.ownercharge.property.name + ' - ' + pioc.ownercharge.property.id;

          if (!properties[propertyName]) {
            properties[propertyName] = [];
          }

          let job = undefined;
          for (const j of jobs.collection) {
            for (const e of j.expenses.collection) {
              for (const wooc of e.ownercharges.collection) {
                if (wooc.ownercharge.id === pioc.ownercharge.id) {
                  job = j;
                }
              }
            }
          }

          properties[propertyName].push({ ...pioc.ownercharge, job });
          
        } else {
          properties['ZZOther Charges'].push(pioc.ownercharge);
        }

        totals.net += pioc.ownercharge.amountnet;
        totals.vat += pioc.ownercharge.amountvat;
      });

      setInvoice(pmsInvoice);
      setProperties(properties);
      setTotals(totals);

      setBusy(false);
    };

    loadInvoice();

  }, []);

  const downloadDocument = (doc) => {
    doc.file.get().then((res) => {
      var link = document.createElement('a');
      link.setAttribute('href', res.url);
      link.setAttribute('download', doc.filename);
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  console.log('bonk', invoice);

  return (
    <Modal isOpen toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>invoice {props.invoiceId}</ModalHeader>
      <ModalBody>
        {busy && 'Loading...'}
        {invoice && properties && totals &&
        <React.Fragment>
          <Row>
            <Col>
              <strong>Owner:</strong> {invoice.owner.title} {invoice.owner.surname} ({invoice.owner.tabscode}){' '}
              <TabsButton route={"owner/" + invoice.owner.id} />
            </Col>
            <Col xs="auto">
              <Button color="primary" onClick={() => downloadDocument(invoice.document)} className="mb-2">download invoice</Button>
            </Col>
          </Row>
          <Table size="sm" striped>
            <thead>
              <tr>
                <th>charge id</th>
                <th>description</th>
                <th>job</th>
                <th>date</th>
                <th>net</th>
                <th>VAT</th>
                <th>total</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(properties).sort().map((property, i) => {
                if (properties[property].length === 0) {
                  return null;
                }

                return (
                  <React.Fragment key={i}>
                    <tr key={property}>
                      <th colSpan={7}>{property.replace('ZZOther', 'Other')}</th>
                    </tr>
                    {properties[property].map(oc => 
                    <tr key={oc.id}>
                      <td>{oc.id}</td>
                      <td>{oc.description}</td>
                      <td>
                        {oc.job ?
                        <React.Fragment>
                          {oc.job.id}{' '}
                          <Icon
                            icon="pencil-alt" 
                            onClick={() => {
                              addTab('editJob', {workOrderId: oc.job.id}, dispatch);
                              props.toggle();
                            }}
                          />
                        </React.Fragment>
                        :
                        <span>N/A</span>
                        }
                      </td>
                      <td>{moment(oc.workdonedate).format('DD/MM/YY')}</td>
                      <td>{oc.amountnet.toFixed(2)}</td>
                      <td>{oc.amountvat.toFixed(2)}</td>
                      <td>{(oc.amountnet + oc.amountvat).toFixed(2)}</td>
                    </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
          <Table style={{width: "auto", float: "right"}} size="sm">
            <tbody>
              <tr>
                <th>net</th>
                <td>{totals.net.toFixed(2)}</td>
              </tr>
              <tr>
                <th>VAT</th>
                <td>{totals.vat.toFixed(2)}</td>
              </tr>
              <tr>
                <th>total</th>
                <td>{(totals.net + totals.vat).toFixed(2)}</td>
              </tr>
            </tbody>
          </Table>
        </React.Fragment>
        }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={props.toggle}>close</Button>
      </ModalFooter>
    </Modal>
  );
}

import AWS from 'aws-sdk/global';
import mqtt from 'mqtt';
import { isTest } from './mixins/EnvironmentMixin';

/**
 * utilities to do sigv4
 * @class SigV4Utils
 */

function SigV4Utils() {}

SigV4Utils.getSignatureKey = function (key, date, region, service) {
      var kDate = AWS.util.crypto.hmac('AWS4' + key, date, 'buffer');
      var kRegion = AWS.util.crypto.hmac(kDate, region, 'buffer');
      var kService = AWS.util.crypto.hmac(kRegion, service, 'buffer');
      var kCredentials = AWS.util.crypto.hmac(kService, 'aws4_request', 'buffer');
      return kCredentials;
  }

  SigV4Utils.getSignedUrl = function(host, region) {

      var datetime = AWS.util.date.iso8601(new Date()).replace(/[:-]|\.\d{3}/g, '');
      var date = datetime.substr(0, 8);

      var method = 'GET';
      var protocol = 'wss';
      var uri = '/mqtt';
      var service = 'iotdevicegateway';
      var algorithm = 'AWS4-HMAC-SHA256';

      var credentialScope = date + '/' + region + '/' + service + '/aws4_request';
      var canonicalQuerystring = 'X-Amz-Algorithm=' + algorithm;
      canonicalQuerystring += '&X-Amz-Credential=' + encodeURIComponent(AWS.config.credentials.accessKeyId + '/' + credentialScope);
      canonicalQuerystring += '&X-Amz-Date=' + datetime;
      canonicalQuerystring += '&X-Amz-SignedHeaders=host';

      var canonicalHeaders = 'host:' + host + '\n';
      var payloadHash = AWS.util.crypto.sha256('', 'hex')
      var canonicalRequest = method + '\n' + uri + '\n' + canonicalQuerystring + '\n' + canonicalHeaders + '\nhost\n' + payloadHash;

      var stringToSign = algorithm + '\n' + datetime + '\n' + credentialScope + '\n' + AWS.util.crypto.sha256(canonicalRequest, 'hex');
      var signingKey = SigV4Utils.getSignatureKey(AWS.config.credentials.secretAccessKey, date, region, service);
      var signature = AWS.util.crypto.hmac(signingKey, stringToSign, 'hex');

      canonicalQuerystring += '&X-Amz-Signature=' + signature;
      if (AWS.config.credentials.sessionToken) {
          canonicalQuerystring += '&X-Amz-Security-Token=' + encodeURIComponent(AWS.config.credentials.sessionToken);
      }

      var requestUrl = protocol + '://' + host + uri + '?' + canonicalQuerystring;
      return requestUrl;
  }

/**
 * MQTT Connection
 */
export default class WSConnection {
  static instance = null;

  _ws = null;
  _connecting = false;

  status = 'Disconnected';


  /**
   * @returns {WSConnection}
   */
  static getInstance() {
    if (WSConnection.instance == null) {
      WSConnection.instance = new WSConnection();
    }

    return this.instance;
  }

  // get the connection
  getWS = () => {
    if (this._ws == null && !this._connecting) {
      this._connect();
    }
    return this._ws;
  }
  
  // connected?
  isConnected = () => {
    return this.getWS()!=null;
  }

  // get the status of the connection
  getStatus = () => {
    return this.status;
  }

  // connect to the message event
  addListener = onMessage => {
    this._ws.addListener('message', onMessage);
  }

  // disconnect from the message event
  removeListener = onMessage => {
    if (this.isConnected()) {
      this._ws.removeListener('message', onMessage);
    }
  }

  _connect = () => {
    // we don't want to connect more than once
    this._connecting = true;

    // Set the region where your identity pool exists (us-east-1, eu-west-1)
    AWS.config.region = 'eu-west-1';

    // Configure the credentials provider to use your identity pool
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'eu-west-1:d428447f-6ecf-404a-9879-e39ecdbd39d6',
    });

    // Make the call to obtain credentials
    AWS.config.credentials.get(() => {
      // Credentials will be available when this function is called.
      var wsEndpoint = SigV4Utils.getSignedUrl("a2nsncxsokohnh-ats.iot.eu-west-1.amazonaws.com", "eu-west-1");
      this._ws = mqtt.connect(wsEndpoint, {
        transformWsUrl: () => {
          return SigV4Utils.getSignedUrl("a2nsncxsokohnh-ats.iot.eu-west-1.amazonaws.com", "eu-west-1");
        },
        reconnectPeriod: 30000
      });
      this._ws.on('connect', () => {
        // this will need changing
        this._ws.subscribe('workorder.toccl.' + (isTest() ? 'test.' : '') + 'tabs2.co.uk');
        this._connecting = false;
        this.status = 'Connected';
      });
      this._ws.on('reconnect', () => {
        this._connecting = true;
        this.status = 'Reconnecting';
      });
      this._ws.on('close', () => {
        this.status = 'Closed';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: 'eu-west-1:d428447f-6ecf-404a-9879-e39ecdbd39d6',
        });
        AWS.config.credentials.get();
      });
    });
  }
}

import { toast } from 'react-toastify';

export function successToast(message, autoClose = true) {
  toast.success(
    message,
    { position: toast.POSITION.TOP_CENTER, autoClose }
  );
}

export function errorToast(message, autoClose = true) {
  toast.error(
    message,
    { position: toast.POSITION.TOP_CENTER, autoClose }
  );
}

export function infoToast(message, autoClose = true) {
  toast.info(
    message,
    { position: toast.POSITION.TOP_CENTER, autoClose }
  );
}
import React, { useContext, useState } from 'react';
import { common, Collection, FilterCollection, WorkOrderStatusHistorySubStatus, WorkOrderStatusHistory } from 'plato-js-client';
import { 
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, 
  Row } from 'reactstrap';
import { addTab, updateJobSubStatus } from "../Actions";
import { Store } from "../Store";
import PrettyLoader from '../components/PrettyLoader';
import moment from 'moment';

export default function RevisitModal(props) {

  const [modal, setModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fields, setFields] = useState({
    'requiredbydate':  moment().format('YYYY-MM-DD'),
    'duration': ![15,30,45,60,90,120,150,180].includes(props.job.durationminutes) ? 'other' : props.job.durationminutes,
    'durationotherunit': 'minutes',
    'durationother':  props.job.durationminutes
  });
  const [validate, setValidate] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const { 
    dispatch, 
    state: {
      currentGroup
    } 
  } = useContext(Store);

  const toggle = () => {
    setModal(!modal);
  }

  const createRevisit = async () => {

    //show the saving icon
    setSaving(true);
    
    //copy workorder
    let newJob =  await cloneWorkorder();

    //copy documents
    let docIds = await cloneDocuments(newJob);

    // copy notes
    cloneNotes(newJob, docIds);

    //copy tags
    cloneTags(newJob);

    //add a work association
    cloneWorkorderAssociations(newJob);

    //take them to a tab to edit the job further
    addTab('editJob', {workOrderId: newJob.id}, dispatch);
    toggle();
    
    setSaving(false);
  }

  const cloneWorkorder = async () => {
    let durationminutes = fields.duration;
    if (durationminutes === 'other') {
      let durationother = fields.durationother;

      switch (fields.durationotherunit) {
        case 'minutes':
            durationminutes = durationother;
          break;
        case 'hours':
            durationminutes = durationother * 60;
          break;
        case 'days':
            durationminutes = durationother * 60 * 24;
          break;
        default:
      }
    }

    //copy property, title, job description, 
    let job = props.job;
    let newjob = job;
    newjob.requiredbydate = fields.requiredbydate;
    newjob.duration = durationminutes;
    newjob.durationminutes = durationminutes;
    newjob.shortdescription = job.shortdescription;
    newjob.property = job.property;
    newjob.fulldescription = job.fulldescription;
    newjob.starteddate = null;
    newjob.completeddate = null;
    newjob.cancelleddatetime = null;
    newjob.invoiceapproveddatetime = null;
    newjob.invoicerejecteddatetime = null;
    newjob.preferredstartdatetime = null;
    newjob.closeddate = null;
    newjob.approveddate = null;
    newjob.workordersource = {'id' : 1};


    //create the job
    let newJob = new common.WorkOrder();
    await newJob.create(newjob);

    await updateJobSubStatus(newJob.id, 'new', dispatch);

    return newJob;
  }

  const cloneDocuments = async (newJob) => {
    //get the documents for the old job
    var documents = new Collection({
      path: 'document',
      object: common.Document,
      parent: props.job,
    });
    await documents.fetch();

    //loop over the documents and create a new one for the new workorder
    let docIds = [];
    let old_doc_id;
    for (const document of documents.collection) {
      old_doc_id = document.id;
      document.id = document.document !== undefined ? document.document.id : document.image.id;
      let docObj = {
        document: document,
        documentid: document.document !== undefined ? document.document.id : document.image.id,
        json: "{}"
      }

      let doc = new common.WorkOrderDocument();
      doc.parent = newJob;

      await doc.create(docObj).then(() => {
        //store old doc if and new doc id
        docIds[old_doc_id] = doc.id;
      });
    }

    //return the old and new doc ids so the notes can be updated
    return docIds;
  }

  const cloneTags = (newJob) => {
    //get the tags from the old workorder
    //get the documents for the old job
    var servicetags = new Collection({
      path: 'servicetag',
      object: common.ServiceTag,
      parent: props.job,
    });
    //await servicetags.fetch();

    servicetags.fetch().then(() => {
      console.log(servicetags);
      let promises = [];
      servicetags.collection.forEach(async (servicetag) => {
        console.log(servicetag);

        //create the servicetag
        var newServiceTag = new common.WorkOrderServiceTag();
        newServiceTag.parent = newJob;
        newServiceTag.servicetag = servicetag.servicetag;

        promises.push(newServiceTag.create());
        
      });
      Promise.all(promises);
    });

  }

  const cloneNotes = (newJob, docIds) => {
    //get the notes for the old job
    let notes = new FilterCollection({
      path: 'note',
      object: common.Note,
      parent: props.job
    });

    notes.addFilters([{
      archivedby: null
    }]);

    notes.fetch().then(() => {
      notes.collection.forEach(async (note) => {

        //create the note
        var newNote = new common.Note();
        newNote.notetype = note.note.notetype;
        newNote.subject = note.note.subject;
        newNote.createdby = {id: note.note.createdby.id};
        newNote.bookingid = note.bookingid;

        await newNote.create();

        //add the note texts
        let nt = '';
        note.note.notetexts.forEach(async (notetext) => {
          var noteText = new common.NoteText();

          nt = notetext.notetext;
          
          for(const docId in docIds) {
            nt = nt.replace(docId, docIds[docId]);
          }

          noteText.parent = newNote;
          noteText.notetext = nt;
          noteText.createdbyactorid = notetext.createdby.actorid;
          noteText.followupdatetime = notetext.followupdatetime;
          if(notetext.actionedby.length > 0) {
            noteText.actionedbytabsuserid = notetext.actionedby.actorid;
            noteText.actioneddatetime = notetext.actioned;
          }
          await noteText.create();
        });

        //add the actors
        note.note.noteactors.forEach(async (noteactor) => {
          var noteActor = new common.NoteActor();
          noteActor.parent = newNote;
          noteActor.actorid = noteactor.actorid;
          noteActor.notifychanges = noteactor.notifychanges;
          noteActor.reminderdate = noteactor.reminderdate;
          await noteActor.create();
        });
        //the note actor doesn't get created

        var workOrderNote = new common.WorkOrderNote();
        workOrderNote.workorder = newJob;
        workOrderNote.note = newNote;

        await workOrderNote.create();
        
      });
    });
  }

  const cloneWorkorderAssociations = async (newJob) => {
    let woa = new common.WorkOrderAssociation();
    woa.parent = props.job;
    woa.associatedworkorder = new common.WorkOrder(newJob.id);
    await woa.create();
  }

  const handleChange = event => {
    if (Array.isArray(event)) {
      const property = event.length > 0 ? event[0] : undefined;
      setFields({...fields, property});
      setUnsavedChanges(true);
      return;
    }

    const target = event.target;
    const value = target.value;
    const name = target.name;

    let f = {...fields, [name]: value};

    setFields(f);
    setUnsavedChanges(true);
  }


  // STOLEN - https://stackoverflow.com/a/13627586
  const ordinalSuffixOf = i => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

  let daysOfMonth = [];
  for (let i = 1; i <= 31; i++) {
    daysOfMonth.push(<option key={i} value={i}>{i}</option>);
  }
  daysOfMonth.push(<option key={99} value={99}>last day of month</option>);

  let weeksOfMonth = [];
  for (let i = 1; i <= 4; i++) {
    weeksOfMonth.push(<option key={i} value={i}>{i}</option>);
  }

  let daysOfWeek = [];
  ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].forEach(day =>
    daysOfWeek.push(<option key={day} value={day}>{day}</option>)
  );

  const months = ["January", "February", "March", "April", "May", "June",
                  "July", "August", "September", "October", "November", "December"].map(month => 
    <option key={month} value={month}>{month}</option>
  );

  return (
    <React.Fragment>
      <Button color="primary" size="sm" onClick={toggle}>
        revisit
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>create revist from job</ModalHeader>
        <ModalBody>
          <p>Use this facility to create a revisit job.</p>
          <p>Note that property, job title, Jjob description, notes, documents are copied</p>
          <FormGroup row>
            <Label sm={4}>required by date</Label>
            <Col sm={8}>
              <Input
                type="date"
                name="requiredbydate"
                placeholder="required by date"
                value={fields.requiredbydate}
                onChange={handleChange}
                invalid={validate.requiredbydate === false}
              />
              {validate.requiredbydate === false &&
              <div className="invalid-feedback d-block">
                You must choose a required by date.
              </div>
              }    
            </Col>
          </FormGroup>
          <FormGroup row>
              <Label sm={4}>duration (minutes)</Label>
              <Col sm={4}>
                <Input 
                  type="select" 
                  name="duration" 
                  value={fields.duration}
                  onChange={handleChange}
                >
                  <option value="15" key="15">15 minutes</option>
                  <option value="30" key="30">30 minutes</option>
                  <option value="45" key="45">45 minutes</option>
                  <option value="60" key="60">60 minutes (1 hour)</option>
                  <option value="90" key="90">90 minutes (1.5 hours)</option>
                  <option value="120" key="120">120 minutes (2 hours)</option>
                  <option value="150" key="150">150 minutes (2.5 hours)</option>
                  <option value="180" key="180">180 minutes (3 hours)</option>
                  <option value="other" key="other">Other (specify yourself)</option>
                </Input>
              </Col>
              {fields.duration === "other" && 
              <React.Fragment>
                <Col sm={2}>
                  <Input 
                    type="text"
                    name="durationother" 
                    value={fields.durationother}
                    onChange={handleChange}
                  />
                </Col>
                <Col sm={2}>
                  <Input
                    type="select" 
                    name="durationotherunit" 
                    value={fields.durationotherunit}
                    onChange={handleChange}
                  >
                    <option value="minutes" key="minutes">minutes</option>
                    <option value="hours" key="hours">hours</option>
                    <option value="days" key="days">days</option>
                  </Input>
                </Col>
              </React.Fragment>
              }
            </FormGroup>
        </ModalBody>
        <ModalFooter>
          {saving &&
            <PrettyLoader size={38} />
          }
          <Button color="primary" onClick={createRevisit}>create revisit</Button>
          <Button color="secondary" onClick={toggle}>cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

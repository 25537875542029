import React, { useCallback, useContext, useEffect, useState } from 'react';
import { common, Collection, FilterCollection } from 'plato-js-client';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row,
  Table
} from 'reactstrap';
import Icon from './components/Icon';
import {
  addGroupUser,
  removeGroupUser,
  setGroupInvoiceEmail,
  setGroupProperties,
  setGroupSchedulerColors,
  setGroupUsers
} from './GroupUtilities';
import moment from 'moment';
import SelectWorkTypeModal from './components/SelectWorkTypeModal';
import { Store } from './Store';
import { refreshGroup } from './Actions';
import Text from './fields/Text';
import SelectList from './fields/SelectList';
import { successToast, infoToast, errorToast } from './components/Toasties';
import OwnerChargeModal from './components/OwnerChargeModal';
import { ChromePicker } from 'react-color';
import ReactDragListView from 'react-drag-listview';
import Check from './fields/Check';

export default function Admin() {

  const {
    state: {
      user,
      userRole
    }
  } = useContext(Store);

  const [item, setItem] = useState('');

  if (userRole !== 'serviceManager' && userRole !== 'administrator' && user.tabsusername !== 'bev') {
    return (
      <Row className="m-3">
        <Col>
          <h3>Sorry!</h3>
          <h5>Only service managers and administrators may use the admin section.</h5>
        </Col>
      </Row>
    )
  }

  return (
    <Row className="m-3">
      <Col xs="12" lg="auto">
        <ListGroup className="mb-4">
          <ListGroupItem tag="button" action active={item === 'checklists'} onClick={() => setItem('checklists')}>
            checklists
          </ListGroupItem>
          <ListGroupItem tag="button" action active={item === 'invoices'} onClick={() => setItem('invoices')}>
            invoices
          </ListGroupItem>
          <ListGroupItem tag="button" action active={item === 'jobitemtemplates'} onClick={() => setItem('jobitemtemplates')}>
            job item templates
          </ListGroupItem>
          <ListGroupItem tag="button" action active={item === 'properties'} onClick={() => setItem('properties')}>
            properties
          </ListGroupItem>
          <ListGroupItem tag="button" action active={item === 'scheduler'} onClick={() => setItem('scheduler')}>
            scheduler
          </ListGroupItem>
          <ListGroupItem tag="button" action active={item === 'servicetags'} onClick={() => setItem('servicetags')}>
            service tags
          </ListGroupItem>
          <ListGroupItem tag="button" action active={item === 'statuses'} onClick={() => setItem('statuses')}>
            statuses
          </ListGroupItem>
          <ListGroupItem tag="button" action active={item === 'users'} onClick={() => setItem('users')}>
            users
          </ListGroupItem>
          <ListGroupItem tag="button" action active={item === 'worktypes'} onClick={() => setItem('worktypes')}>
            work types
          </ListGroupItem>
        </ListGroup>
      </Col>
      <Col>
        {item !== '' &&
        <Card>
          <CardBody>
            {item === 'checklists' && <Checklists />}
            {item === 'invoices' && <Invoices />}
            {item === 'jobitemtemplates' && <JobItemTemplates />}
            {item === 'properties' && <Properties />}
            {item === 'scheduler' && <Scheduler />}
            {item === 'servicetags' && <ServiceTags />}
            {item === 'statuses' && <Statuses />}
            {item === 'users' && <Users />}
            {item === 'worktypes' && <WorkTypes />}
          </CardBody>
        </Card>
        }
      </Col>
    </Row>
  );
}

function Checklists() {

  const {
    state: {
      currentGroup,
      groups
    },
  } = useContext(Store);

  const [checklists, setChecklists] = useState();
  const [otherbrandchecklists, setOtherBrandChecklists] = useState();
  const [checklist, setChecklist] = useState();
  const [json, setJson] = useState();
  const [jsonDecoded, setJsonDecoded] = useState();
  const [checklistName, setChecklistName] = useState();
  const [checklistInactive, setChecklistInactive] = useState(false);

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [validationDropdowns, setValidationDropdowns] = useState([]);
  const [newQuestionModal, setNewQuestionModal] = useState();

  const getChecklists = useCallback(async () => {
    var collection = new Collection({
      path: 'pmschecklist',
      object: common.PmsChecklist,
    });

    await collection.fetch();

    setChecklists(
      collection.collection.filter(cl => currentGroup.brandingIds.includes(cl.branding.id))
    );
  }, [currentGroup.brandingIds]);

  const getOtherBrandChecklists = useCallback(async () => {
    var collection = new Collection({
      path: 'pmschecklist',
      object: common.PmsChecklist,
    });

    await collection.fetch();

    setOtherBrandChecklists(
      collection.collection.filter(cl => !currentGroup.brandingIds.includes(cl.branding.id))
    );
  }, [currentGroup.brandingIds]);

  useEffect(() => {
    getOtherBrandChecklists();
  }, [getOtherBrandChecklists]);

  useEffect(() => {
    getChecklists();
  }, [getChecklists]);


  useEffect(() => {
    if (checklist) {
      setJson(checklist.json);
      setChecklistName(checklist.name);
      setChecklistInactive(checklist.inactive);
    }
  }, [checklist]);

  useEffect(() => {
    if (json) {
      const parsed = JSON.parse(json);
      setJsonDecoded(parsed);
    }
  }, [json]);

  const toggleValidationDropdown = (gi, qi) => {
    const key = gi + '-' + qi;
    let vd = [...validationDropdowns];

    if (vd.includes(key)) {
      vd.splice(vd.indexOf(key), 1);
    } else {
      vd.push(key);
    }

    setValidationDropdowns(vd);
  }

  const toggleNewQuestionModal = gi => {
    if (newQuestionModal !== undefined) {
      setNewQuestionModal(undefined);
    } else {
      setNewQuestionModal(gi);
    }
  }

  const moveGroup = (gi, direction) => {
    let cl = {...jsonDecoded};

    const oldOrder = cl.groups[gi].order;
    const newOrder = cl.groups[gi + direction].order;

    cl.groups[gi].order = newOrder;
    cl.groups[gi + direction].order = oldOrder;

    saveNewJson(cl);
  }

  const moveQuestion = (gi, qi, direction) => {
    let cl = {...jsonDecoded};

    const oldOrder = cl.groups[gi].questions[qi].order;
    const newOrder = cl.groups[gi].questions[qi + direction].order;

    cl.groups[gi].questions[qi].order = newOrder;
    cl.groups[gi].questions[qi + direction].order = oldOrder;

    saveNewJson(cl);
  }

  const moveQuestionOption = (gi, qi, oi, direction) => {
    let cl = {...jsonDecoded};

    const oldOrder = cl.groups[gi].questions[qi].options[oi].order;
    const newOrder = cl.groups[gi].questions[qi].options[oi + direction].order;

    cl.groups[gi].questions[qi].options[oi].order = newOrder;
    cl.groups[gi].questions[qi].options[oi + direction].order = oldOrder;

    saveNewJson(cl);
  }

  const updateGroupName = (gi, name) => {
    let cl = {...jsonDecoded};
    cl.groups[gi].name = name;
    saveNewJson(cl);
  }

  const updateChecklistName = (name) => {
    setChecklistName(name);
    setUnsavedChanges(true);
  }

  const updateQuestionName = (gi, qi, name) => {
    let cl = {...jsonDecoded};
    cl.groups[gi].questions[qi].question = name;
    saveNewJson(cl);
  }

  const updateQuestionOptionName = (gi, qi, oi, name) => {
    let cl = {...jsonDecoded};
    cl.groups[gi].questions[qi].options[oi].option = name;
    saveNewJson(cl);
  }

  const removeGroup = gi => {
    if (window.confirm('Are you sure you want to remove this group?')) {
      let cl = {...jsonDecoded};
      cl.groups.splice(gi, 1);
      saveNewJson(cl);
    }
  }

  const removeQuestion = (gi, qi) => {
    if (window.confirm('Are you sure you want to remove this question?')) {
      let cl = {...jsonDecoded};
      cl.groups[gi].questions.splice(qi, 1);
      saveNewJson(cl);
    }
  }

  const removeQuestionOption = (gi, qi, oi) => {
    if (window.confirm('Are you sure you want to remove this option?')) {
      let cl = {...jsonDecoded};
      cl.groups[gi].questions[qi].options.splice(oi, 1);
      saveNewJson(cl);
    }
  }

  const deleteChecklist = () => {
    if (window.confirm('Are you sure you want to delete this checklist?')) {
      if (checklist.id) {
        checklist.delete({ id: checklist.id })
          .then(() => {
            getChecklists();
            getOtherBrandChecklists();
            setChecklist();
            successToast('Checklist deleted!');
          })
          .catch(error => {
            errorToast(`Failed to delete the checklist: ${error.message || error}`);
        });
      }
    }
  }

  const addChecklist = () => {
    let cl = new common.PmsChecklist();
    cl.branding = new common.Branding(currentGroup.brandingIds[0]);
    cl.name = 'new checklist';
    cl.json = JSON.stringify({groups: []});
    cl.inactive = checklistInactive;
    setChecklist(cl);
    setUnsavedChanges(true);
  }

  const copyChecklist = () => {
    let cl = new common.PmsChecklist();

    cl.branding = currentGroup.brandingIds.includes(checklist.branding.id) ? checklist.branding : new common.Branding(currentGroup.brandingIds[0]);

    cl.name = checklistName === checklist.name ? 'copy of ' + checklist.name : checklistName;

    //if find an the checklist with this name then ask for a new one
    if(checklists.find(xcl => xcl.name === cl.name)) {
      infoToast('The name ' + cl.name + ' already exists. Please enter a unique name in the checklist textbox and press copy again.', false);
      setChecklistName(cl.name);
    }

    cl.json = checklist.json;

    cl.create().then(() => {
      getChecklists();
      getOtherBrandChecklists();
      //select the checklist that was copied so it can be edited
      setChecklist(cl);
      successToast('checklist copied!');
    });
  }

  const addGroup = () => {
    let cl = {...jsonDecoded};

    let maxOrder = 0;
    for (const group of cl.groups) {
      if (group.order > maxOrder) {
        maxOrder = group.order;
      }
    }

    cl.groups.push({
      name: "new group",
      order: maxOrder + 1,
      questions: []
    });

    saveNewJson(cl);

    window.scrollTo(0,document.body.scrollHeight);
  }

  const addQuestion = (gi, type) => {
    let cl = {...jsonDecoded};

    let maxOrder = 0;
    for (const question of cl.groups[gi].questions) {
      if (question.order > maxOrder) {
        maxOrder = question.order;
      }
    }

    let obj = {
      question: "new question",
      type: type,
      order: maxOrder + 1,
    };

    if (type === 'text') {
      obj.validation = 'text';
    }
    if (type === 'checklist' || type === 'multi') {
      obj.options = [];
    }

    cl.groups[gi].questions.push(obj);

    saveNewJson(cl);
  }

  const addQuestionOption = (gi, qi) => {
    let cl = {...jsonDecoded};

    let maxOrder = 0;
    let maxId = 0;
    for (const option of cl.groups[gi].questions[qi].options) {
      if (option.order > maxOrder) {
        maxOrder = option.order;
      }
      if (option.id > maxId) {
        maxId = option.id;
      }
    }

    cl.groups[gi].questions[qi].options.push({
      id: maxId + 1,
      option: 'new option',
      order: maxOrder + 1,
    });

    saveNewJson(cl);
  }

  const saveNewJson = obj => {
    const newJson = JSON.stringify(obj);
    setJson(newJson);
    setUnsavedChanges(true);
  }

  const saveChanges = () => {
    if(checklist.id) {
      checklist.update({
        name: checklistName,
        json,
        inactive: checklistInactive,
        updateddatetime: moment(),
      }).then(() => {
        setUnsavedChanges(false);
        successToast('changes saved!');
      });
    } else {
      checklist.name = checklistName;
      checklist.create().then(() => {
        getChecklists();
        successToast('new checklist created!');
      });
    }
  }

  const handleInactiveChange = (e) => {
    setChecklistInactive(e.target.checked);
    setUnsavedChanges(true);
  }

  if (!checklists) {
    return null;
  }

  return (
    <React.Fragment>
      <h3>checklists</h3>

      <InputGroup className="mb-2">
        <Input
          type="select"
          value={checklist?.id || 0}  // Use optional chaining and set default to 0
          onChange={e => {
            const selectedId = parseInt(e.target.value, 10);
            const foundChecklist = checklists.find(cl => cl.id === selectedId)

            // Handle both cases where checklist might be undefined
            if (!foundChecklist) {
              setChecklist(undefined);  // Set checklist to undefined if not found
              return; // Exit the function early to avoid unnecessary processing
            }

            if (foundChecklist.branding && foundChecklist.branding.id) {
              setChecklist(foundChecklist);
              foundChecklist.readonly = false;
              if (!currentGroup.brandingIds.includes(foundChecklist.branding.id)) {
                foundChecklist.readonly = true;
              }
            } else {
              setChecklist(undefined);
            }
          }}
        >
          <option key={0} value={0}>select a checklist</option>
          <optgroup key={currentGroup.id} label={currentGroup.name}>
          {checklists.map(cl =>
            <option key={cl.id} value={cl.id}>{cl.name}</option>
          )}
          </optgroup>
          {groups.map(function (group) {
            if(currentGroup.id !== group.id) {
              var brandchecklists = (otherbrandchecklists || []).filter(
                function (otherbrandchecklist) {
                  return group.brandingIds.includes(otherbrandchecklist.branding.id);
                }
              );
              var brandchecklistsoptions = brandchecklists.map(
                function (bcl) {
                  return <option key={bcl.id} value={bcl.id}>{bcl.name}</option>;
                }
              );
              return <optgroup key={group.id} label={group.name}>
                {brandchecklistsoptions}
              </optgroup>;
            }
          })}
        </Input>
        <InputGroupAddon addonType="append">
          <Button onClick={addChecklist}>
            <Icon icon="plus" />
          </Button>
          <Button onClick={copyChecklist} disabled={!checklist}>
            <Icon icon="copy" />
          </Button>
          <Button onClick={deleteChecklist} disabled={checklist ? checklist.readonly : false}>
              <Icon icon="trash" />
          </Button>
        </InputGroupAddon>
      </InputGroup>

      {jsonDecoded && checklist &&
      <React.Fragment>
        <Card className="mt-2 mb-2">
          <CardBody>
            <InputGroup className="mb-2">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>checklist</InputGroupText>
              </InputGroupAddon>
              <Input type="text" value={checklistName} onChange={e => updateChecklistName(e.target.value)} />
              <InputGroupAddon addonType="append">
                <Button onClick={addGroup} disabled={checklist.readonly} >
                  <Icon icon="plus" />
                </Button>
                <Button  onClick={deleteChecklist} disabled={checklist.readonly}>
                  <Icon icon="trash"  />
                </Button>
              </InputGroupAddon>
            </InputGroup>
            <Row>
              <Col>
                <strong>created:</strong> {checklist.createddatetime ? moment(checklist.createddatetime).format('DD/MM/YY HH:mm') : 'N/A'}<br />
                <strong>updated:</strong> {checklist.updateddatetime ? moment(checklist.updateddatetime).format('DD/MM/YY HH:mm') : 'N/A'}
              </Col>
              <Col xs="auto">
                {unsavedChanges &&
                <Button size="lg" color="primary" onClick={saveChanges} disabled={checklist.readonly} >
                  save changes
                </Button>
                }
              </Col>
            </Row>
            <InputGroup className="mb-2">
              <Check
                  name="checklistinactive"
                  label="checklist inactive"
                  value={checklistInactive}
                  onChange={handleInactiveChange}
                  labelWidth={0}
                />
            </InputGroup>
          </CardBody>
        </Card>
        <Row>
          <Col>
            {jsonDecoded.groups.sort((a, b) => (a.order > b.order) ? 1 : -1).map((group, gi) =>
            <Card key={'group' + gi} className="mb-2">
              <CardHeader>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>group</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" value={group.name} onChange={e => updateGroupName(gi, e.target.value)} disabled={checklist.readonly} />
                  <InputGroupAddon addonType="append">
                    <Button
                      disabled={checklist.readonly ? true : gi === 0}
                      onClick={() => moveGroup(gi, -1)}
                    >
                      <Icon icon="arrow-up" />
                    </Button>
                    <Button
                      disabled={checklist.readonly ? true : gi === jsonDecoded.groups.length - 1}
                      onClick={() => (moveGroup(gi, 1))}
                    >
                      <Icon icon="arrow-down" />
                    </Button>
                    <Button onClick={() => toggleNewQuestionModal(gi)} disabled={checklist.readonly} >
                      <Icon icon="plus" />
                    </Button>
                    <Button onClick={() => removeGroup(gi)} disabled={checklist.readonly} >
                      <Icon icon="trash" />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </CardHeader>
              <CardBody>
              {group.questions.sort((a, b) => (a.order > b.order) ? 1 : -1).map((question, qi) =>
              <Card key={'group' + gi + 'question' + qi} className="mb-1">
                <CardHeader>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>question</InputGroupText>
                      <InputGroupText>{question.type}</InputGroupText>
                    </InputGroupAddon>
                    {question.type === 'text' &&
                    <InputGroupButtonDropdown
                      addonType="prepend"
                      isOpen={validationDropdowns.includes(gi + '-' + qi)}
                      toggle={() => toggleValidationDropdown(gi, qi)}
                      disabled={checklist.readonly}
                    >
                      <DropdownToggle caret>
                        {question.validation}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>number</DropdownItem>
                        <DropdownItem header>text</DropdownItem>
                      </DropdownMenu>
                    </InputGroupButtonDropdown>
                    }
                    <Input type="text" value={question.question} onChange={e => updateQuestionName(gi, qi, e.target.value)} disabled={checklist.readonly} />
                    <InputGroupAddon addonType="append">
                      <Button
                        disabled={checklist.readonly ? true : qi === 0}
                        onClick={() => moveQuestion(gi, qi, -1)}
                      >
                        <Icon icon="arrow-up" />
                      </Button>{' '}
                      <Button
                        disabled={checklist.readonly ? true : qi === group.questions.length - 1}
                        onClick={() => (moveQuestion(gi, qi, 1))}
                      >
                        <Icon icon="arrow-down" />
                      </Button>
                      {(question.type === 'multi' || question.type === 'checklist') &&
                      <Button onClick={() => addQuestionOption(gi, qi)}  disabled={checklist.readonly}><Icon icon="plus" /></Button>
                      }
                      <Button onClick={() => removeQuestion(gi, qi)}  disabled={checklist.readonly}>
                        <Icon icon="trash" />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </CardHeader>
                {(question.type === 'multi' || question.type === 'checklist') &&
                <CardBody>
                  {question.options.sort((a, b) => (a.order > b.order) ? 1 : -1).map((option, oi) =>
                  <InputGroup className="mb-1" key={'group' + gi + 'question' + qi + 'option' + oi}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>option</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" value={option.option} onChange={e => updateQuestionOptionName(gi, qi, oi, e.target.value)} disabled={checklist.readonly} />
                    <InputGroupAddon addonType="append">
                      <Button
                        disabled={checklist.readonly ? true : oi === 0}
                        onClick={() => (moveQuestionOption(gi, qi, oi, -1))}
                      >
                        <Icon icon="arrow-up" />
                      </Button>{' '}
                      <Button
                        disabled={checklist.readonly ? true : oi === question.options.length - 1}
                        onClick={() => (moveQuestionOption(gi, qi, oi, 1))}
                      >
                        <Icon icon="arrow-down" />
                      </Button>
                      <Button onClick={() => removeQuestionOption(gi, qi, oi)} disabled={checklist.readonly}>
                        <Icon icon="trash" />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  )}
                </CardBody>
                }
              </Card>
              )}
              </CardBody>
            </Card>
            )}
          </Col>
        </Row>
        {newQuestionModal !== undefined &&
        <Modal isOpen toggle={toggleNewQuestionModal} size="lg">
          <ModalHeader toggle={toggleNewQuestionModal}>
            new question
          </ModalHeader>
          <ModalBody>
            <Button onClick={() => {
              addQuestion(newQuestionModal, 'text');
              toggleNewQuestionModal();
            }}>add new text question</Button>
            <Button onClick={() => {
              addQuestion(newQuestionModal, 'check');
              toggleNewQuestionModal();
            }}>add new checkbox question</Button>
            <Button onClick={() => {
              addQuestion(newQuestionModal, 'checklist');
              toggleNewQuestionModal();
            }}>add new checklist question</Button>
            <Button onClick={() => {
              addQuestion(newQuestionModal, 'multi');
              toggleNewQuestionModal();
            }}>add new dropdown question</Button>
          </ModalBody>
          <ModalFooter>
            <Button color="primary">save changes</Button>{' '}
            <Button color="secondary" onClick={toggleNewQuestionModal}>cancel</Button>
          </ModalFooter>
        </Modal>
        }
      </React.Fragment>
      }
    </React.Fragment>
  );
}

function Invoices() {

  const {
    state: {
      currentGroup
    }
  } = useContext(Store);

  const [subject, setSubject] = useState(currentGroup.invoiceEmailSubject || '');
  const [message, setMessage] = useState(currentGroup.invoiceEmailMessage || '');

  const save = () => {
    setGroupInvoiceEmail(currentGroup.id, subject, message);
    successToast('Changes saved!');
  }

  return (
    <React.Fragment>
      <h3>invoices</h3>
      <FormGroup>
        <Label>Email subject</Label>
        <Input
          type="text"
          value={subject}
          onChange={e => setSubject(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Email message</Label>
        <Input
          type="textarea"
          value={message}
          onChange={e => setMessage(e.target.value)}
          rows={6}
        />
      </FormGroup>
      <p>
        Valid replacement codes for message are:<br />
        <strong>##owner_salutationorfirstname##</strong> - e.g. Amy<br />
        <strong>##owner_titlesurname##</strong> - e.g. Mrs Dobson<br />
        <strong>##owner_fullname##</strong> - e.g. Mrs Amy Dobson<br />
        <strong>##workdonedate##</strong> - e.g. 19/03/20
      </p>
      <Button color="primary" onClick={save}>save changes</Button>
    </React.Fragment>
  );

}

function JobItemTemplates() {

  const {
    state: {
      brandings,
      costItemCodes,
      vatRates,
      chargingPeriods,
      amountLimitTypes,
    }
  } = useContext(Store);

  const [templates, setTemplates] = useState();
  const [selected, setSelected] = useState();
  const [fields, setFields] = useState({});
  const [ownerCharges, setOwnerCharges] = useState([]);
  const [modal, setModal] = useState(false);

  const loadTemplates = React.useCallback(() => {
    const getTemplates = async branding => {
      var collection = new Collection({
        path: 'workorderexpensetemplate',
        object: common.WorkOrderExpenseTemplate,
        parent: branding
      });

      await collection.fetch();
      return collection;
    }

    var promises = brandings.map(branding => getTemplates(branding));

    Promise.all(promises).then(values => {
      var arr = [];
      values.forEach(value => {
        arr = arr.concat(value.collection);
      });

      setTemplates(arr);
    });
  }, [brandings]);

  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);

  useEffect(() => {
    setFields({
      agencycostgross: 0,
      agencycostnet: (selected && selected.agencycostnet) || 0,
      agencycostvat: 0,
      amountgross: (selected && (selected.amountnet + selected.amountvat)) || 0,
      amountgrossestimate: 0,
      amountlimittype: selected && selected.amountlimittype && selected.amountlimittype.id,
      amountnetestimate: (selected && selected.amountnetestimate) || 0,
      amountnetlimit: (selected && selected.amountnetlimit) || 0,
      amountvatestimate: 0,
      branding: (selected && selected.parent.id) || '',
      chargingperiod: selected && selected.chargingperiod && selected.chargingperiod.id,
      chargingperiodsestimate: (selected && selected.chargingperiodsestimate) || 1,
      chargingperiodpriceperunit: (selected && selected.chargingperiodpriceperunit) || 1,
      costitemcode: (selected && selected.costitemcode.id) || '',
      description: (selected && selected.description) || '',
      itemisfromstock: (selected && selected.agencycostnet) || false,
      search: '',
      estimatevaluetype: selected && selected.chargingperiodsestimate ? 'perperiod' : 'setamount',
    });
    if (selected) {
      setOwnerCharges(selected.ownercharges.map(oc => {
        return {
          id: oc.id,
          ownerchargecode: oc.ownerchargecode,
          ownerchargeamounttype: oc.ownerchargeamounttype,
          amount: oc.amount,
          description: oc.description,
        }
      }));
    } else {
      setOwnerCharges([]);
    }
  }, [selected]);

  if (!brandings || !templates) {
    return null;
  }

  const handleChange = event => {
    const target = event.target;
    const name = target.name;
    let value = undefined;

    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'number':
        value = parseFloat(target.value);
        break;
      default:
        value = target.value;
    }

    let f = {...fields};
    f[name] = value;

    if (name === 'estimatevaluetype' && value === 'setamount') {
      f.chargingperiod = undefined;
    }
    if (name === 'estimatevaluetype' && value === 'perperiod') {
      const chargingPeriod = chargingPeriods.find(
        cp => cp.chargingperiod = 'Hour'
      );

      f.chargingperiod = chargingPeriod.id;
    }

    if (['estimatevaluetype', 'chargingperiodsestimate', 'chargingperiodpriceperunit'].includes(name)) {
      f.amountnetestimate = parseFloat(f.chargingperiodsestimate) * parseFloat(f.chargingperiodpriceperunit);
    }

    f = {...f, ...calcVat(f)};
    f = {...f, ...getHelpfulDescriptions(f)};

    setFields(f);
  }

  const handleSave = async () => {

    const obj = {
      description: fields.description,
      costitemcode: new common.CostItemCode(fields.costitemcode),
      vatband: new common.VatBand(fields.vatband),
      currency: new common.Currency(1),
    };

    if (fields.itemisfromstock) {
      obj.agencycostnet = fields.agencycostnet;
    } else {
      obj.amountnetestimate = fields.amountnetestimate;

      if (fields.estimatevaluetype === "perperiod") {
        obj.chargingperiod = new common.ChargingPeriod(fields.chargingperiod);
        obj.chargingperiodsestimate = fields.chargingperiodsestimate;
        obj.chargingperiodpriceperunit = fields.chargingperiodpriceperunit;
      }

      if (fields.amountlimittype !== "") {
        obj.amountlimittype = new common.AmountLimitType(fields.amountlimittype);
        obj.amountnetlimit = fields.amountnetlimit;
      }
    }

    let template = selected;

    if (!template || !template.id) {
      template = new common.WorkOrderExpenseTemplate();
      template.parent = new common.Branding(fields.branding);
      await template.create(obj);
    } else {
      await template.update(obj);
    }

    for (let oc of ownerCharges) {
      const newCharge = isNaN(oc.id);

      if (oc.deleted && !newCharge) {
        let ownerCharge = new common.WorkOrderOwnerChargeTemplate(oc.id);
        await ownerCharge.delete();
      } else {
        const obj = {
          ownerchargecode: oc.ownerchargecode,
          ownerchargeamounttype: oc.ownerchargeamounttype,
          amount: oc.amount,
          description: oc.description,
        };

        if (newCharge) {
          let ownerCharge = new common.WorkOrderOwnerChargeTemplate();
          ownerCharge.parent = template;
          await ownerCharge.create(obj);
        } else {
          let ownerCharge = new common.WorkOrderOwnerChargeTemplate(oc.id);
          await ownerCharge.update(obj);
        }
      }
    }

    successToast('changes saved!');
    setSelected(template);

    loadTemplates();
  }

  const handleDelete = async template => {
    if (window.confirm('Are you sure you want to remove this template?')) {
      for (let oc of template.ownercharges.collection) {
        await oc.delete();
      }

      await template.delete();

      loadTemplates();
    }
  }

  const calcVat = fields => {
    var amountvatestimate = 0;
    var amountgrossestimate = parseFloat(fields.amountnetestimate);

    var agencycostvat = 0;
    var agencycostgross = parseFloat(fields.agencycostnet);

    var vatband = undefined;

    const costItemCode = fields.costitemcode && costItemCodes.find(
      costItemCode => costItemCode.id === parseInt(fields.costitemcode, 10)
    );

    if (costItemCode && costItemCode.vatband) {
      const vr = getVatRateFromBand(costItemCode.vatband);

      amountvatestimate = (amountgrossestimate / 100 * vr.percentage);
      amountgrossestimate += amountvatestimate;

      agencycostvat = (agencycostgross / 100 * vr.percentage);
      agencycostgross += agencycostvat;

      vatband = costItemCode.vatband && costItemCode.vatband.id;
    }

    return {
      amountvatestimate,
      amountgrossestimate,
      agencycostvat,
      agencycostgross,
      vatband,
    }
  }

  const getVatRateFromBand = (vatBand) => {
    var vr;

    vatRates.forEach(vRate => {
      if (vRate.vatband &&
          vRate.vatband.id === vatBand.id &&
          moment().isBetween(vRate.fromdate, vRate.todate, 'day', '[]')
      ) {
        vr = vRate;
      }
    });

    return vr;
  }

  const getHelpfulDescriptions = fields => {

    var chargingPeriodDescription = '';
    var toleranceDescription = '';

    var amountNetEstimate = parseFloat(fields.amountnetestimate);

    const chargingPeriod = fields.chargingperiod && chargingPeriods.find(
      cp => cp.id === parseInt(fields.chargingperiod)
    );

    if (chargingPeriod) {
      var chargingPeriodsEstimate = parseFloat(fields.chargingperiodsestimate);

      var per = parseFloat(fields.chargingperiodpriceperunit);
      var chargingPeriodName = chargingPeriod.chargingperiod.toLowerCase();

      chargingPeriodDescription = [
        chargingPeriodsEstimate,
        chargingPeriodName + 's',
        ' @ ',
        '£' + per.toFixed(2),
        ' per ',
        chargingPeriodName,
        ' = ',
        '£' + amountNetEstimate
      ].join(' '); // e.g. '2 hours @ 12.00 per hour = 24.00'
    }

    const amountLimitType = fields.amountlimittype && amountLimitTypes.find(
      alt => alt.id === parseInt(fields.amountlimittype)
    );

    if (amountLimitType) {
      var estimate = amountNetEstimate;
      var limit = 0;
      var amountNetLimit = parseFloat(fields.amountnetlimit);

      switch (amountLimitType.amountlimittype) {
        case 'Fixed':
          limit = amountNetLimit;
          break;
        case 'Percentage':
          var multiplier = 1 + (amountNetLimit / 100);
          limit = (estimate * multiplier).toFixed(2);
          break;
        case 'Amount':
          limit = estimate + amountNetLimit;
          break;
        default:
      }

      toleranceDescription = limit ? 'Submitted charge may not exceed £' + parseFloat(limit).toFixed(2) : '';
    }

    return {
      chargingPeriodDescription,
      toleranceDescription,
    }

  }

  const handleOwnerCharge = oc => {
    let ocs = ownerCharges ? [...ownerCharges] : [];

    let i = oc.id ? ocs.findIndex(o => o.id === oc.id) : -1;

    if (i >= 0) {
      ocs[i] = {...oc, modified: true};
    } else {
      ocs.push({
        ...oc,
        id: Math.random().toString(36).substr(2,9),
        modified: true
      });
    }

    setOwnerCharges(ocs);
  }

  const toggle = () => {
    setModal(!modal);
  }

  return (
    <React.Fragment>
      <h3>job item templates</h3>
      <Row>
        <Col>
          <Text
            name="search"
            value={fields.search}
            onChange={handleChange}
          />
          <Table size="sm" striped>
            <thead>
              <tr>
                <th>desc</th>
                <th>code</th>
                <th>estimate</th>
                <th>stock cost</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
            {templates.map(template => {

              if (fields.search && !template.description.toLowerCase().includes(fields.search.toLowerCase())) {
                return null;
              }

              return (
              <tr key={template.id}>
                <td>{template.description}</td>
                <td>{template.costitemcode.costitemcode}</td>
                <td>{template.amountnetestimate ? template.amountnetestimate.toFixed(2) : 'N/A'}</td>
                <td>{template.agencycostnet ? template.agencycostnet.toFixed(2) : 'N/A'}</td>
                <td>
                  <Icon icon="pencil-alt" onClick={() => setSelected(template)} />{' '}
                  <Icon icon="trash" onClick={() => handleDelete(template)} />
                </td>
              </tr>
              );
            })}
            </tbody>
          </Table>
          <Button onClick={() => setSelected()}>add new template</Button>
        </Col>
        <Col>
          <h5>{selected ? selected.description : 'add new template'}</h5>
          <SelectList
            name="branding"
            value={fields.branding}
            values={brandings}
            onChange={handleChange}
            emptyValue="choose a branding"
          />
          <Text
            name="description"
            value={fields.description}
            onChange={handleChange}
          />
          <SelectList
            name="costitemcode"
            label="cost item code"
            value={fields.costitemcode}
            values={costItemCodes}
            onChange={handleChange}
            emptyValue="choose a cost item code"
          />
          {!selected &&
          <FormGroup row>
            <Label sm={4}></Label>
            <Col sm={8}>
              <CustomInput
                type="switch"
                id="itemisfromstock"
                name="itemisfromstock"
                label="item is from stock"
                checked={fields.itemisfromstock}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          }
          {!fields.itemisfromstock &&
          <React.Fragment>
            <SelectList
              name="estimatevaluetype"
              label="estimate value type"
              values={[
                {id: "setamount", toString: () => {return "Set amount"}},
                {id: "perperiod", toString: () => {return "Time period"}}
              ]}
              value={fields.estimatevaluetype}
              onChange={handleChange}
            />
            {fields.estimatevaluetype === "perperiod" &&
            <React.Fragment>
              <FormGroup row className="align-items-center">
                <Label sm={4}>charging period</Label>
                <Col sm={8}>
                  <Row>
                    <Col>
                      unit<br />
                      <Input
                        type="select"
                        name="chargingperiod"
                        value={fields.chargingperiod}
                        onChange={handleChange}
                      >
                        {chargingPeriods.map(chargingPeriod =>
                          <option key={chargingPeriod.id} value={chargingPeriod.id}>
                            {chargingPeriod.chargingperiod}
                          </option>
                        )}
                      </Input>
                    </Col>
                    <Col>
                      quantity<br />
                      <Input
                        type="number"
                        name="chargingperiodsestimate"
                        value={fields.chargingperiodsestimate}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </React.Fragment>
            }
            <FormGroup row className="align-items-center">
              <Label sm={4}>estimate amount</Label>
              <Col sm={8}>
                <Row>
                  {fields.estimatevaluetype === "perperiod" &&
                  <Col>
                    price per unit<br />
                    <Input
                      type="number"
                      name="chargingperiodpriceperunit"
                      value={fields.chargingperiodpriceperunit}
                      onChange={handleChange}
                    />
                  </Col>
                  }
                  <Col>
                    net<br />
                    <Input
                      type="number"
                      name="amountnetestimate"
                      value={fields.amountnetestimate}
                      onChange={handleChange}
                      disabled={fields.estimatevaluetype === "perperiod"}
                    />
                  </Col>
                  <Col>
                    VAT<br />
                    <Input
                      type="number"
                      name="amountvatestimate"
                      value={fields.amountvatestimate.toFixed(2)}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </Col>
                  <Col>
                    gross<br />
                    <Input
                      type="number"
                      name="amountgrossestimate"
                      value={fields.amountgrossestimate.toFixed(2)}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </Col>
                </Row>
              </Col>
            </FormGroup>
            {fields.chargingPeriodDescription &&
            <FormGroup row>
              <Label sm={4} />
              <Col sm={8}>
                {fields.chargingPeriodDescription}
              </Col>
            </FormGroup>
            }
            {/* <React.Fragment>
              <FormGroup row>
                <Label sm={4}>estimate tolerance</Label>
                <Col sm={8}>
                  <Row>
                    <Col>
                      type<br />
                      <Input
                        type="select"
                        name="amountlimittype"
                        value={fields.amountlimittype}
                        onChange={handleChange}
                      >
                        <option key="empty" value="">estimate cannot be exceeded</option>
                        {amountLimitTypes.map(amountLimitType =>
                          <option key={amountLimitType.id} value={amountLimitType.id}>
                            {amountLimitType.amountlimittype}
                          </option>
                        )}
                      </Input>
                    </Col>
                    <Col>
                      { fields.amountlimittype &&
                        fields.amountlimittype !== "" &&
                        <React.Fragment>
                          amount<br />
                          <Input
                            type="number"
                            name="amountnetlimit"
                            value={fields.amountnetlimit}
                            onChange={handleChange}
                          />
                        </React.Fragment>
                      }
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
              {fields.toleranceDescription &&
              <FormGroup row>
                <Label sm={4} />
                <Col sm={8}>
                  {fields.toleranceDescription}
                </Col>
              </FormGroup>
              }
            </React.Fragment>   */}
          </React.Fragment>
          }
          {fields.itemisfromstock &&
          <FormGroup row className="align-items-center">
            <Label sm={4}>amount</Label>
            <Col sm={8}>
              <Row>
                <Col>
                  net<br />
                  <Input
                    type="number"
                    name="agencycostnet"
                    value={fields.agencycostnet.toFixed(2)}
                    onChange={handleChange}
                  />
                </Col>
                <Col>
                  VAT<br />
                  <Input
                    type="number"
                    name="agencycostvat"
                    disabled={true}
                    value={fields.agencycostvat.toFixed(2)}
                  />
                </Col>
                <Col>
                  gross<br />
                  <Input
                    type="number"
                    name="agencycostgross"
                    disabled={true}
                    value={fields.agencycostgross.toFixed(2)}
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>
          }
          <FormGroup row className="align-items-center">
            <Label sm={4}>owner charges</Label>
            <Col sm={8}>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>code</th>
                    <th>type</th>
                    <th>amount</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {ownerCharges.filter(oc => !oc.deleted).length === 0 &&
                  <tr>
                    <td colSpan="4">
                      No owner charges found.
                    </td>
                  </tr>
                  }
                  {ownerCharges && ownerCharges.map(oc =>
                  <JobExpenseOwnerCharge
                    key={oc.id}
                    expense={fields}
                    ownerCharge={oc}
                    handleOwnerCharge={handleOwnerCharge}
                    costItemCodeId={fields.costitemcode}
                  />
                  )}
                </tbody>
              </Table>
              <Button color="primary" disabled={!fields.costitemcode} onClick={toggle}>add owner charge</Button>
              {modal &&
              <OwnerChargeModal
                expense={fields}
                toggle={toggle}
                handleOwnerCharge={handleOwnerCharge}
                costItemCodeId={fields.costitemcode}
              />
              }
            </Col>
          </FormGroup>
          <hr />
          <FormGroup row>
            <Label sm={4}></Label>
            <Col sm={8}>
              <Button
                onClick={handleSave}
                disabled={!fields.description || !fields.costitemcode || fields.amountnetestimate < 0}
              >
                {selected ? 'update' : 'add'}
              </Button>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
}

function JobExpenseOwnerCharge({expense, ownerCharge, handleOwnerCharge, costItemCodeId}) {

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  }

  if (ownerCharge.deleted) {
    return null;
  }

  const deleteOwnerCharge = () => {
    if (window.confirm('Are you sure you want to remove this owner charge?')) {
      handleOwnerCharge({...ownerCharge, deleted: true});
    }
  }

  return (
    <tr>
      <td>{ownerCharge.ownerchargecode.ownerchargecode}</td>
      <td>{ownerCharge.ownerchargeamounttype.ownerchargeamounttype}</td>
      <td>{ownerCharge.amount}</td>
      <td>
        <Icon icon="pencil-alt" onClick={toggle} />{' '}
        <Icon icon="trash" onClick={deleteOwnerCharge} />
      </td>
      {modal &&
      <OwnerChargeModal
        expense={expense}
        ownerCharge={ownerCharge}
        toggle={toggle}
        handleOwnerCharge={handleOwnerCharge}
        costItemCodeId={costItemCodeId}
      />
      }
    </tr>
  );

}

function Statuses() {

  const {
    state: {
      workOrderSubStatuses,
      user,
      userRole,
    }
  } = useContext(Store);

  const [selected, setSelected] = useState();
  const [fields, setFields] = useState();
  const [subStatusTemplates, setSubStatusTemplates] = useState();
  const [templates, setTemplates] = useState();
  const [assignees, setAssignees] = useState();

  const [template, setTemplate] = useState();
  const [assignee, setAssignee] = useState();

  useEffect(() => {
    let templates = new FilterCollection({
      path: 'template',
      object: common.Template,
    });
    templates.addFilters([{
      type: 'WorkOrderInstance',
    }]);
    templates.limit = 999;

    templates.fetch().then(() =>
      setTemplates(templates)
    );

    let assignees = new Collection({
      path: 'workordersubstatustemplateassignee',
      object: common.WorkOrderSubStatusTemplateAssignee,
    });

    assignees.fetch().then(() =>
      setAssignees(assignees)
    );
  }, []);

  useEffect(() => {
    if (templates) { setTemplate(templates.collection[0].id); }
    if (assignees) { setAssignee(assignees.collection[0].id); }
  }, [templates, assignees]);

  const getSubStatusTemplates = useCallback(() => {
    var wosst = new Collection({
      path: 'template',
      object: common.WorkOrderSubStatusTemplate,
      parent: selected
    });

    wosst.fetch().then(() => {
      setSubStatusTemplates(wosst);
    });
  }, [selected]);

  useEffect(() => {
    if (!selected) { return; }

    setFields({
      substatusreference: selected.substatusreference || '',
      substatusname: selected.substatusname || '',
      actiontext: selected.actiontext || '',
      parentstatus: selected.parentstatus || '',
      style: selected.style || '',
      assigntoactortype: selected.assigntoactortype || '',
    });

    getSubStatusTemplates();
  }, [selected, getSubStatusTemplates]);

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFields({...fields, [name]: value});
  }

  const addNewCommunication = () => {
    // check the chosen combo doesn't already exist
    const alreadyExists = subStatusTemplates.collection.find(
      sst => sst.assignee.id === parseInt(assignee, 10) && sst.template.id === parseInt(template, 10)
    );

    if (alreadyExists) {
      window.alert('Error: template/destination already exists on this status');
    } else {
      let wosst = new common.WorkOrderSubStatusTemplate();
      wosst.parent = selected;
      wosst.create({
        template: new common.Template(template),
        assignee: new common.WorkOrderSubStatusTemplateAssignee(assignee)
      }).then(() => {
        getSubStatusTemplates();
      });
    }
  }

  let filteredAssignees = [];
  selected && fields && assignees && assignees.forEach(assignee => {
    let allowed = true;

    if (['serviceManager', 'serviceCoordinator'].includes(assignee.assignee)) {
      if (fields.assigntoactortype !== assignee.assignee) {
        allowed = false;
      }
    }

    if (allowed) {
      filteredAssignees.push(
        <option key={assignee.id} value={assignee.id}>{assignee.assigneefriendlyname}</option>
      );
    }
  });

  if (userRole !== 'administrator' && user.tabsusername !== 'bev') {
    return (
      <React.Fragment>
        <h3>statuses</h3>
        <h4>Sorry!</h4>
        <h5>Only administrators may administer statuses.</h5>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <h3>statuses</h3>
      <Row>
        <Col md="auto">
          <ListGroup>
            {workOrderSubStatuses && workOrderSubStatuses.map(woss =>
            <ListGroupItem
              key={woss.id}
              tag="button"
              action
              active={selected && selected.id === woss.id}
              onClick={() => { setSelected(woss); }}
            >
              {woss.substatusname}
            </ListGroupItem>
            )}
          </ListGroup>
        </Col>
        <Col>
          {selected &&
          <Card>
            <CardBody>
              <h3>{selected.substatusname}</h3>
              <Row>
                <Col md="6">
                  {fields &&
                  <Form>
                    <Text name="reference" value={fields.substatusreference} onChange={handleChange} />
                    <Text name="name" value={fields.substatusname} onChange={handleChange} />
                    <Text name="action" value={fields.actiontext} onChange={handleChange} />
                    <Text name="parent status" value={fields.parentstatus} onChange={handleChange} />
                    <Text name="style" value={fields.style} onChange={handleChange} />
                    <Text name="assign to actor type" value={fields.assigntoactortype} onChange={handleChange} />
                  </Form>
                  }
                </Col>
                <Col>
                  <Card className="mb-3">
                    <CardHeader tag="h6">next statuses</CardHeader>
                    <CardBody>
                      {selected.nextworkordersubstatuses.count === 0 &&
                      <p>None found.</p>
                      }
                      <ListGroup>
                        {selected.nextworkordersubstatuses.map(nwoss =>
                        <ListGroupItem key={nwoss.id}>
                          <Row>
                            <Col>
                              <strong>{nwoss.nextworkordersubstatus.substatusname}</strong><br />
                              <strong>button text:</strong> {nwoss.description}<br />
                              <strong>button style:</strong> {nwoss.style}<br />
                            </Col>
                            <Col md="auto" style={{borderLeft: "1px solid lightgrey"}}>
                              <Icon icon="pencil-alt" /><br />
                              <Icon icon="trash" />
                            </Col>
                          </Row>
                        </ListGroupItem>
                        )}
                      </ListGroup>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader tag="h6">communications</CardHeader>
                      {subStatusTemplates &&
                      <CardBody>
                        <Table size="sm">
                          <thead>
                            <tr>
                              <th>destination</th>
                              <th>template</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {subStatusTemplates.collection.length === 0 &&
                            <tr key="empty">
                              <td colSpan={2}>None found.</td>
                            </tr>
                            }
                            {subStatusTemplates.map(wosst =>
                            <tr key={wosst.id}>
                              <td>{wosst.assignee.assigneefriendlyname}</td>
                              <td>{wosst.template.templatename}</td>
                              <td>
                                <Icon icon="trash" />
                              </td>
                            </tr>
                            )}
                          </tbody>
                        </Table>
                        <hr className="mb-4" />
                        <h6 className="font-weight-bold">add new communication:</h6>
                        {templates &&
                        <FormGroup row>
                          <Label sm={3}>template</Label>
                          <Col sm={9}>
                            <Input type="select" onChange={e => setTemplate(e.target.value)}>
                              {templates.map(template =>
                                <option key={template.id} value={template.id}>{template.templatename}</option>
                              )}
                            </Input>
                          </Col>
                        </FormGroup>
                        }
                        {assignees &&
                        <FormGroup row>
                          <Label sm={3}>destination</Label>
                          <Col sm={9}>
                            <Input type="select" onChange={e => setAssignee(e.target.value)}>
                              {filteredAssignees}
                            </Input>
                          </Col>
                        </FormGroup>
                        }
                        <Button onClick={addNewCommunication}>add</Button>
                      </CardBody>
                      }
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
          }
        </Col>
      </Row>
    </React.Fragment>

  )

}

function WorkTypes() {

  const [workTypes, setWorkTypes] = useState();
  const [workType, setWorkType] = useState({});
  const [workTypeTemplates, setWorkTypeTemplates] = useState([]);
  const [addToParent, setAddToParent] = useState();

  const getWorkTypes = () => {
    var wt = new Collection({
      path: 'worktype',
      object: common.WorkType,
    });

    wt.fetch().then(() => {
      wt.sort((a, b) => (a.worktype > b.worktype) ? 1 : -1);
      setWorkTypes(wt.collection);
    });
  }

  useEffect(() => {
    getWorkTypes();
  }, []);

  useEffect(() => {
    if (workType.id) {
      // get the job templates associated with this work type
      let jobs = new FilterCollection({
        path: 'workorder',
        object: common.WorkOrder,
      });
      jobs.limit = 999;
      jobs.addFilters([{
        type: 'Template',
        worktypeid: workType.id
      }]);

      jobs.fetch().then(() => {
        setWorkTypeTemplates(jobs.collection);
      })
    }
  }, [workType]);

  if (!workTypes) {
    return null;
  }

  const renderLists = (obj, lists = []) => {

    let rows = [];

    let parent = obj.id ? obj.parentworktype : {};

    const addRow = (wt) => {
      rows.push(
        <ListGroupItem
          tag="button"
          action
          active={obj.id === wt.id}
          key={wt.id}
          onClick={() => { setAddToParent(undefined); setWorkType(wt); }}
        >
          {wt.worktype}
        </ListGroupItem>
      );
    }

    const addList = (key, rows, parent) => {
      let buttonText = 'add new parent work type';
      if (parent.id) {
        buttonText = 'add new child to ' + parent.worktype;
      }

      lists.unshift(
        <Col key={key}>
          <ListGroup className="mb-2">
            {rows}
          </ListGroup>
          <Button onClick={() => { setAddToParent(parent) }}>{buttonText}</Button>
        </Col>
      );
    }

    if (obj.id && obj.id === workType.id) {
      // if this is the selected WorkType, also bosh a list for any children it has
      workTypes.forEach(wt => {
        if (wt.parentworktype.id === obj.id) {
          addRow(wt);
        }
      });

      if (rows.length > 0) {
        addList('childrenOfSelected', rows, workType);
      } else {
        // show a button to add a child to the chosen work type
        lists.unshift(
          <Col key="childrenOfSelectedButton">
            <Button onClick={() => { setAddToParent(workType) }}>
              add new child to {workType.worktype}
            </Button>
          </Col>
        );
      }

      rows = [];
    }

    workTypes.forEach(wt => {
      if ((!parent.id && !wt.parentworktype.id) || (wt.parentworktype.id === parent.id)) {
        addRow(wt);
      }
    });

    addList('lg' + obj.id, rows, parent);

    if (parent.id) {
      renderLists(parent, lists);
    }

    return lists;

  }

  const WorkTypeForm = (props) => {

    let wt = props.workType ? props.workType : {};
    let pwt = props.parentWorkType;

    let worktype = undefined;
    let description = undefined;
    let inactive = undefined;
    let blockavailability = undefined;
    let icon = undefined;
    let addpropertywarning = undefined;

    const updateOrCreate = () => {

      const obj = {
        worktype: worktype.value,
        description: description.value,
        inactive: inactive.checked,
        blockavailability: blockavailability.checked,
        icon: icon.value,
        addpropertywarning: addpropertywarning.checked,
      }

      if (wt.id) {
        // update
        wt.update(obj).then(() => {
          getWorkTypes();
        });
      } else {
        // create
        if (pwt.id) {
          obj.parentworktype = pwt;
        }
        let newWorkType = new common.WorkType();
        newWorkType.create(obj).then(() => {
          getWorkTypes();
        });
      }

    }

    const removeWorkType = () => {
      if (wt.id) {
        if (window.confirm('Are you sure you want to remove this work type?')) {
          wt.delete().then(
            () => {
              setWorkType({});
              getWorkTypes();
            },
            () => window.alert('Error removing work type. Ensure child work types are removed first.')
          );
        }
      }
    }

    return (
      <React.Fragment>
        {wt.id && <h5>{wt.worktype}</h5>}
        {pwt && pwt.id && <h5>add child to {pwt.worktype}</h5>}
        {pwt && !pwt.id && <h5>add parent work type</h5>}
        <FormGroup row>
          <Label for="worktype" sm={2}>name</Label>
          <Col sm={10}>
            <Input
              type="text"
              id="worktype"
              innerRef={(el) => worktype = el}
              defaultValue={wt.worktype}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="description" sm={2}>description</Label>
          <Col sm={10}>
            <Input
              type="textarea"
              id="description"
              innerRef={(el) => description = el}
              defaultValue={wt.description}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="worktype" sm={2}>icon</Label>
          <Col sm={9}>
            <Input
              type="text"
              id="icon"
              innerRef={(el) => icon = el}
              defaultValue={wt.icon}
            />
          </Col>
          <Col sm={1}>
            <Button block onClick={() => {
              window.alert('Visit https://fontawesome.com/icons and put the name of the icon in the icon box');
            }}>help</Button>
          </Col>
        </FormGroup>
        <FormGroup row className="mb-0">
          <Label for="inactive" sm={2}>&nbsp;</Label>
          <Col sm={10}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  id="inactive"
                  innerRef={(el) => inactive = el}
                  defaultChecked={wt.inactive}
                />{' '}
                inactive
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row className="mb-0">
          <Label for="blockavailability" sm={2}>&nbsp;</Label>
          <Col sm={10}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  id="blockavailability"
                  innerRef={(el) => blockavailability = el}
                  defaultChecked={wt.blockavailability}
                />{' '}
                block availability
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="blockavailability" sm={2}>&nbsp;</Label>
          <Col sm={10}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  id="addpropertywarning"
                  innerRef={(el) => addpropertywarning = el}
                  defaultChecked={wt.addpropertywarning}
                />{' '}
                add property warning
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        {wt.id && <Button onClick={updateOrCreate}>save changes</Button> }
        {wt.id &&
        <React.Fragment>
          {' '}<Button onClick={removeWorkType} color="danger">remove work type</Button>
        </React.Fragment>
        }
        {pwt && pwt.id && <Button onClick={updateOrCreate}>add to {pwt.worktype}</Button>}
        {pwt && !pwt.id && <Button onClick={updateOrCreate}>add</Button>}
      </React.Fragment>
    );
  }

  const removeTemplate = (jobId) => {
    if (window.confirm('Are you sure you want to remove this template?')) {
      let wo = new common.WorkOrder(jobId);
      wo.update({worktype: new common.WorkType(0)}).then(() => {
        getWorkTypes();
      });
    }
  }

  const lists = renderLists(workType);

  const workTypeTemplateRows = workTypeTemplates.map(wtt => {
    return (
      <tr key={wtt.id}>
        <td>{wtt.id}</td>
        <td>{wtt.shortdescription}</td>
        <td>
          <Icon icon="trash" onClick={() => {removeTemplate(wtt.id)}} />
        </td>
      </tr>
    );
  });

  return (
    <React.Fragment>
      <h3>work types</h3>
      <Row>
        {lists}
      </Row>
      {workType.id && !addToParent &&
      <Row className="mt-4">
        <Col sm={8}>
          <Card>
            <CardBody>
              <WorkTypeForm workType={workType} />
            </CardBody>
          </Card>
        </Col>
        <Col sm={4}>
          <Card>
            <CardBody>
              <CardTitle tag="h5">templates</CardTitle>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>description</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {workTypeTemplateRows.length === 0 ?
                    <tr><td colSpan="2">no templates found</td></tr>
                  :
                    workTypeTemplateRows
                  }
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      }
      {addToParent &&
      <Row className="mt-4">
        <Col>
          <Card>
            <CardBody>
              <WorkTypeForm parentWorkType={addToParent} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      }
    </React.Fragment>
  );
}

function ServiceTags() {

  const [serviceTags, setServiceTags] = useState();
  const [addServiceTagModal, setAddServiceTagModal] = useState(false);

  const toggleAddServiceTagModal = () => {
    setAddServiceTagModal(!addServiceTagModal);
  }

  const getServiceTags = () => {
    var st = new Collection({
      path: 'servicetag',
      object: common.ServiceTag,
    });

    st.fetch().then(() => {
      st.sort((a, b) => (a.name > b.name) ? 1 : -1);
      setServiceTags(st.collection);
    });
  }

  useEffect(() => {
    getServiceTags();
  }, []);

  if (!serviceTags) {
    return null;
  }

  return (
    <React.Fragment>
      <h3>service tags</h3>
      <Button onClick={toggleAddServiceTagModal}>
        add service tag
      </Button>
      <Row className="mt-4">
        <Col>
          <ListGroup>
            {serviceTags.sort(
              (a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1
            ).map(st =>
            <ServiceTagsRow key={st.id} st={st} refresh={getServiceTags} />
            )}
          </ListGroup>
        </Col>
      </Row>
      {addServiceTagModal &&
      <ServiceTagsModal
        toggle={toggleAddServiceTagModal}
        handleSubmit={getServiceTags}
      />
      }
    </React.Fragment>
  );
}

function ServiceTagsRow({ st, refresh }) {
  const [editServiceTagModal, setEditServiceTagModal] = useState(false);

  const toggleEditServiceTagModal = () => {
    setEditServiceTagModal(!editServiceTagModal);
  }

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to remove this service tag?')) {
      st.delete().then(() => {
        refresh();
      });
    }
  }

  return (
    <ListGroupItem
      tag="button"
      key={st.id}
      onClick={() => {}}
    >
      {st.name}
      <div className="float-right">
        <Icon
          icon="pencil-alt"
          onClick={toggleEditServiceTagModal}
        />{' '}
        <Icon
          icon="trash"
          onClick={handleDelete}
        />
      </div>
      {editServiceTagModal &&
      <ServiceTagsModal
        serviceTag={st}
        toggle={toggleEditServiceTagModal}
        handleSubmit={refresh}
      />
      }
    </ListGroupItem>
  );
}

function ServiceTagsModal(props) {
  const [validate, setValidate] = useState({});

  const [fields, setFields] = useState({
    name: (props.serviceTag && props.serviceTag.name) ? props.serviceTag.name : '',
  });

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFields({...fields, [name]: value});
  }

  const validateForm = () => {
    let v = {...validate};

    v.name = fields.name ? true : false;

    setValidate(v);

    return !Object.values(v).includes(false);
  }

  const save = async () => {
    if (!validateForm()) {
      return;
    }

    if (props.serviceTag) {
      await props.serviceTag.update({...fields});
    } else {
      let st = new common.ServiceTag();
      await st.create({...fields});
    }

    props.handleSubmit();
    props.toggle();
  }

  return (
    <Modal isOpen={true} toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>
        New Service Tag
      </ModalHeader>
      <ModalBody>
        <Form>
          <Text
            name="name"
            label="name"
            onChange={handleChange}
            value={fields.name}
            invalid={validate.name === false}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={save}>create service tag</Button>{' '}
        <Button color="secondary" onClick={props.toggle}>cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

function Users() {

  const [actor, setActor] = useState();
  const [users, setUsers] = useState();

  const {
    state: {
      currentGroup,
      fieldWorkers,
      serviceCoordinators,
      serviceManagers,
      administrators,
      user,
      userRole,
    },
    dispatch,
  } = useContext(Store);

  useEffect(() => {
    setUsers({
      fieldWorkers: fieldWorkers.map(fw => {
        return {
          id: fw.id,
          name: [fw.title, fw.firstname, fw.surname].join(' '),
          user: fw,
        }
      }),
      serviceCoordinators: serviceCoordinators.map(sc => {
        return {
          id: sc.id,
          name: [sc.title, sc.firstname, sc.surname].join(' '),
          user: sc,
        }
      }),
      serviceManagers: serviceManagers.map(sm => {
        return {
          id: sm.id,
          name: [sm.title, sm.firstname, sm.surname].join(' '),
          user: sm,
        }
      }),
      administrators: administrators.map(sm => {
        return {
          id: sm.id,
          name: [sm.title, sm.firstname, sm.surname].join(' '),
          user: sm,
        }
      }),
    });
  }, [currentGroup, fieldWorkers, serviceCoordinators, serviceManagers, administrators]);

  const reloadActor = () => {
    let s = undefined;
    if (actor.type === 'Supplier') {
      s = new common.Supplier(actor.id);
    } else {
      s = new common.TabsUser(actor.id);
    }

    s.get().then(() => {
      setActor(s);
    });
  }

  const removeUser = (type, id) => {
    if (window.confirm('Are you sure you want to remove this user?')) {
      removeGroupUser(currentGroup.id, type, id).then(() => {
        setActor(undefined);
        refreshGroup(currentGroup.id, dispatch);
      });
    }
  }

  const reorderUsers = (type, newUsers) => {
    setGroupUsers(
      currentGroup.id,
      type,
      newUsers.map(u => u.id)
    );
    setUsers({
      ...users,
      [type]: newUsers,
    });
  }

  const UserListGroup = ({type, people}) => {

    if (!people || people.length === 0) {
      return <h5>None found.</h5>
    }

    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        let newPeople = [...people];
        const item = newPeople.splice(fromIndex, 1)[0];
        newPeople.splice(toIndex, 0, item);
        reorderUsers(type, newPeople);
      },
      nodeSelector: 'button',
      // handleSelector: 'a'
    };

    const orderedList = (
      <React.Fragment>
        {people.map((user) => {
          return (
            <ListGroupItem
              tag="button"
              action
              active={actor && actor.id === user.id}
              key={user.id}
              onClick={() => {setActor(user.user)}}
            >
              {user.name}{' '}<small>{user.id}</small>
              <div className="float-right">
                <Icon
                  icon="trash"
                  onClick={(e) => {
                    e.stopPropagation();
                    setActor(undefined);
                    removeUser(type, user.id);
                  }}
                />
              </div>
            </ListGroupItem>
          );
        })}
      </React.Fragment>
    );

    if (type === 'fieldWorkers') {
      return (
        <ReactDragListView {...dragProps}>
          {orderedList}
        </ReactDragListView>
      );
    } else {
      return orderedList;
    }
  }

  const HolidaysBlock = () => {

    let fromDate = undefined;
    let toDate = undefined;

    const addHoliday = () => {
      let sh = new common.SupplierHoliday();
      sh.parent = actor;
      sh.fromdate = fromDate.value;
      sh.todate = toDate.value;

      sh.create().then(() => {
        reloadActor();
      });
    }

    const removeHoliday = holiday => {
      if (window.confirm('Are you sure you want to remove this holiday?')) {
        holiday.delete().then(() => {
          reloadActor();
        });
      }
    }

    const rows = actor.holidays.map(holiday => {
      return (
        <tr key={holiday.id}>
          <td>{moment(holiday.fromdate).format('DD/MM/YY')}</td>
          <td>{moment(holiday.todate).format('DD/MM/YY')}</td>
          <td>
              <Icon icon="trash" onClick={() => {removeHoliday(holiday)}} />
            </td>
        </tr>

      );
    });

    return (
      <React.Fragment>
        <Table size="sm">
          <thead>
            <tr>
              <th>from</th>
              <th>to</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {rows.length === 0
            ?
            <tr><td colSpan="4">No holidays found.</td></tr>
            :
            rows
            }
          </tbody>
        </Table>
        <hr />
        <h6 className="font-weight-bold">add holiday:</h6>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label>from</Label>
              <Input type="date" innerRef={(el) => {fromDate = el}} defaultValue={moment().format('YYYY-MM-DD')} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>to</Label>
              <Input type="date" innerRef={(el) => {toDate = el}} defaultValue={moment().format('YYYY-MM-DD')} />
            </FormGroup>
          </Col>
        </Row>
        <Button onClick={addHoliday}>
          add
        </Button>
      </React.Fragment>
    );
  }

  const WorkingDaysBlock = () => {
    let daysToAdd = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    let daysWeHave = {};
    daysToAdd.forEach(day => {
      daysWeHave[day] = undefined;
    });

    let rows = [];

    let selectedDay = undefined;
    let fromTime = undefined;
    let toTime = undefined;

    actor.workingdays.forEach(wd => {
      if (daysToAdd.includes(wd.dayofweek)) {
        daysToAdd.splice(daysToAdd.indexOf(wd.dayofweek), 1);
      }
      daysWeHave[wd.dayofweek] = wd;
    });

    Object.keys(daysWeHave).forEach(day => {
      if (daysWeHave[day]) {
        const wd = daysWeHave[day];
        rows.push(
          <tr key={wd.dayofweek}>
            <td>{wd.dayofweek}</td>
            <td>{wd.fromtime}</td>
            <td>{wd.totime}</td>
            <td>
              <Icon icon="trash" onClick={() => {removeWorkingDay(wd)}} />
            </td>
          </tr>
        );
      }
    });

    const addWorkingDay = () => {

      //setActor(undefined);
      //return true;

      let swd = new common.SupplierWorkingDay();
      swd.parent = actor;
      swd.fromtime = fromTime.value;
      swd.totime = toTime.value;
      swd.dayofweek = selectedDay.value;

      swd.create().then(() => {
        reloadActor();
      });
    }

    const removeWorkingDay = wd => {
      if (window.confirm('Are you sure you want to remove this working day?')) {
        wd.delete().then(() => {
          reloadActor();
        });
      }
    }

    return (
      <React.Fragment>
        <Table size="sm">
          <thead>
            <tr>
              <th>day</th>
              <th>from</th>
              <th>to</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {rows.length === 0
            ?
            <tr><td colSpan="4">No working days found.</td></tr>
            :
            rows
            }
          </tbody>
        </Table>
        <hr />
        <h6 className="font-weight-bold">add working day:</h6>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label>day</Label>
              <Input type="select" innerRef={(el) => {selectedDay = el}}>
                {daysToAdd.map(day => {
                  return <option key={day}>{day}</option>
                })};
              </Input>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>from</Label>
              <Input type="time" innerRef={(el) => {fromTime = el}} defaultValue="09:00" />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>to</Label>
              <Input type="time" innerRef={(el) => {toTime = el}} defaultValue="17:00" />
            </FormGroup>
          </Col>
        </Row>
        <Button onClick={addWorkingDay}>
          add
        </Button>
      </React.Fragment>
    );
  }

  if (!users) {
    return null;
  }

  return (
    <React.Fragment>
      <h3>users</h3>
      <Row className="mt-4">
        <Col xs="6" lg="3">
          <h4>
            field workers{' '}
            <AddUserModal
              id="fieldWorkers"
              type="field worker"
              onSave={() => refreshGroup(currentGroup.id, dispatch)}
            />
          </h4>
          <UserListGroup type='fieldWorkers' people={users.fieldWorkers} />
        </Col>
        <Col xs="6" lg="3">
          <h4>
            service coordinators{' '}
            <AddUserModal
              id="serviceCoordinators"
              type="service coordinator"
              onSave={() => refreshGroup(currentGroup.id, dispatch)}
            />
          </h4>
          <UserListGroup type='serviceCoordinators' people={users.serviceCoordinators} />
        </Col>
        <Col xs="6" lg="3">
          <h4>
            service managers{' '}
            <AddUserModal
              id="serviceManagers"
              type="service manager"
              onSave={() => refreshGroup(currentGroup.id, dispatch)}
            />
          </h4>
          <UserListGroup type='serviceManagers' people={users.serviceManagers} />
        </Col>
        <Col xs="6" lg="3">
          <h4>
            administrators{' '}
            {(userRole === 'administrator' || user.tabsusername === 'bev') &&
            <AddUserModal
              id="administrators"
              type="administrator"
              onSave={() => refreshGroup(currentGroup.id, dispatch)}
            />
            }
          </h4>
          <UserListGroup type='administrators' people={users.administrators} />
        </Col>
      </Row>
      {actor &&
      <Row className="mt-4">
        <Col>
          <Card>
            <CardBody>
              <CardTitle tag="h5">{actor.id} - {actor.title} {actor.firstname} {actor.surname}</CardTitle>
              <Row>
                {actor.type === 'Supplier' &&
                <React.Fragment>
                <Col lg="6" className="mb-4">
                  <Card>
                  <CardHeader tag="h5">work types</CardHeader>
                    <CardBody>
                      <UsersWorkTypesBlock actor={actor} />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" className="mb-4">
                  <Card>
                  <CardHeader tag="h5">holidays</CardHeader>
                    <CardBody>
                      <HolidaysBlock />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" className="mb-4">
                  <Card>
                  <CardHeader tag="h5">working days</CardHeader>
                    <CardBody>
                      <WorkingDaysBlock />
                    </CardBody>
                  </Card>
                </Col>
                </React.Fragment>
                }
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      }
    </React.Fragment>
  );
}

const UsersWorkTypesBlock = ({actor}) => {

  const [skillLevel, setSkillLevel] = useState();
  const [workType, setWorkType] = useState();
  const [actorWorkTypes, setActorWorkTypes] = useState();

  const getWorkTypes = useCallback(() => {
    var awt = new Collection({
      path: 'worktype',
      object: common.SupplierWorkType,
      parent: actor
    });

    awt.fetch().then(() => {
      awt.sort((a, b) => (a.worktype > b.worktype) ? 1 : -1);
      setActorWorkTypes(awt.collection);
    });
  }, [actor]);

  useEffect(() => {
    getWorkTypes();
  }, [getWorkTypes]);

  const addWorkType = () => {
    let swt = new common.SupplierWorkType();
    swt.parent = actor;
    swt.skilllevel = skillLevel;
    swt.worktype = workType;

    swt.create().then(() => {
      getWorkTypes();
    });
  }

  const removeWorkType = swt => {
    if (window.confirm('Are you sure you want to remove this work type?')) {
      swt.delete().then(() => {
        getWorkTypes();
      });
    }
  }

  if (!actorWorkTypes) {
    return null;
  }

  const rows = actorWorkTypes.map(swt => {
    return (
      <tr key={swt.id}>
        <td>{swt.skilllevel}</td>
        <td>{swt.worktype.worktype}</td>
        <td>
          <Icon icon="trash" onClick={() => {removeWorkType(swt)}} />
        </td>
      </tr>
    );
  });

  return (
    <React.Fragment>
      <Table size="sm">
        <thead>
          <tr>
            <th>skill level</th>
            <th>type</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {rows.length === 0
          ?
          <tr><td colSpan="4">No work types found.</td></tr>
          :
          rows
          }
        </tbody>
      </Table>
      <hr />
      <h6 className="font-weight-bold">add work type:</h6>
      <Row form>
        <Col md={7}>
          <FormGroup>
            <Label>skill level</Label>
            <Input type="select" onChange={el => setSkillLevel(el.target.value)}>
              <option key="1" value="1">1 (passing knowledge)</option>
              <option key="2" value="2">2 (working knowledge)</option>
              <option key="3" value="3">3 (intermediate skills)</option>
              <option key="4" value="4">4 (skilled)</option>
              <option key="5" value="5">5 (advanced)</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label>work type</Label><br />
            {workType &&
            <React.Fragment>
              {workType.worktype}, <strong>or</strong>{' '}
            </React.Fragment>
            }
            <SelectWorkTypeModal buttonText="choose work type" onChoose={wt => setWorkType(wt)} />
          </FormGroup>
        </Col>
      </Row>
      <Button onClick={addWorkType}>
        add
      </Button>
    </React.Fragment>
  );
}

function AddUserModal(props) {

  const [modal, setModal] = useState(false);
  const [error, setError] = useState();
  const [actor, setActor] = useState();

  const {
    state: {
      currentGroup
    }
  } = useContext(Store);
  let actorRef = undefined;

  const toggle = () => {
    setModal(!modal);
  }

  const findUser = async () => {
    try {
      let a = undefined;

      if (props.id === "fieldWorkers") {
        a = new common.Supplier(actorRef.value);
      }  else {
        a = new common.TabsUser(actorRef.value);
      }

      await a.get();

      setActor(a);
      setError(undefined);
    }
    catch(error) {
      setActor(undefined);
      setError(error.message);
    }
  }

  const addUser = () => {
    addGroupUser(currentGroup.id, props.id, actor.id).then(() => {
      setActor(undefined);
      props.onSave();
      toggle();
    });
  };

  let labelText = 'enter id of ' + props.type;

  return (
    <React.Fragment>
      <Button size="sm" onClick={toggle}>add</Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>add {props.type}</ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => {e.preventDefault()}} inline>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Label for="actor" className="mr-sm-2">{labelText}</Label>
              <Input type="text" name="actor" innerRef={(el) => {actorRef = el}} />
            </FormGroup>
            <Button onClick={findUser}>find</Button>
          </Form>
          {error &&
          <Alert className="mt-2" color="danger">{error}</Alert>
          }
          {actor &&
          <Alert className="mt-2" color="primary">
            {actor.title + ' ' + actor.firstname + ' ' + actor.surname}{' '}
            <Button onClick={addUser}>add</Button>{' '}
          </Alert>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

function Properties() {

  const {
    state: {
      groups,
      currentGroup
    }
  } = useContext(Store);

  const [properties, setProperties] = useState();
  const [children, setChildren] = useState();
  const [unsavedChages, setUnsavedChanges] = useState(false);

  useEffect(() => {
    var properties = new FilterCollection({
      path: 'property',
      object: common.Property,
    });

    properties.addFilters({
      statusid: '1',
      brandingid: currentGroup.brandingIds.join('|'),
    });

    properties.limit = 9999;
    properties.fields = 'id:name:tabspropref:address:location';
    properties.orderBy = 'name';

    properties.fetch().then(() => {
      setProperties(properties.collection);
    });

    let children = [];
    groups.forEach(group => {
      if (group.name.includes(currentGroup.name + ' - ')) {
        if (!group.propertyIds) {
          group.propertyIds = [];
        }
        children.push(group);
      }
    });

    setChildren(children);
  }, []);

  if (!properties || !children) {
    return null;
  }

  if (currentGroup.name.includes(' - ')) {
    return (
      <React.Fragment>
        <h3>properties</h3>
        <h5>Properties should be set on the parent group.</h5>
      </React.Fragment>
    );
  }

  if (children.length === 0) {
    return (
      <React.Fragment>
        <h3>properties</h3>
        <h5>Properties for this group are maintained in tabs.</h5>
      </React.Fragment>
    );
  }

  const toggleSelected = (childId, propertyId, checked) => {
    let ch = [...children];

    ch.forEach((child, i) => {
      if (child.id === childId) {
        let c = {...child};

        if (checked) {
          c.propertyIds.push(propertyId);
        } else {
          c.propertyIds.splice(c.propertyIds.indexOf(propertyId), 1);
        }

        ch[i] = c;
      } else {
        let c = {...child};

        if (c.propertyIds.includes(propertyId)) {
          c.propertyIds.splice(c.propertyIds.indexOf(propertyId), 1);
        }
      }
    });

    setChildren(ch);
    setUnsavedChanges(true);
  }

  const save = async () => {
    for (const child of children) {
      await setGroupProperties(child.id, child.propertyIds);
    }
    setUnsavedChanges(false);
    window.alert('Saved!');
  }

  return (
    <React.Fragment>
      <h3>properties</h3>
      <Button onClick={save} className="mb-2" disabled={!unsavedChages}>save changes</Button>
      <Table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            {children.map(child =>
            <th key={child.id} className="text-center">{child.name}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {properties.map(property => {

            let hasGroup = false;
            children.forEach(child => {
              if (child.propertyIds.includes(property.id)) {
                hasGroup = true;
              }
            });

            return (
              <tr key={'p' + property.id}>
                <td className={!hasGroup ? 'text-danger' : ''}>
                  {`${property.tabspropref} - ${property.name} (${property.location})`}
                </td>
                {children.map(child => {
                  const checked = child.propertyIds.includes(property.id);

                  return (
                    <td key={child.id + '-' + property.id} className="text-center">
                      <CustomInput
                        type="switch"
                        id={child.id + '-' + property.id}
                        checked={checked}
                        onChange={e => toggleSelected(child.id, property.id, e.target.checked)}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </React.Fragment>
  );
}

function Scheduler() {

  const {
    state: {
      currentGroup,
      user,
      userRole
    }
  } = useContext(Store);

  const defaultColors = {
    new: { background: '#990000', text: '#fff' },
    started: { background: '#b45f06', text: '#fff' },
    completed: { background: '#bf9000', text: '#fff' },
    financiallyCompleted: { background: '#38761d', text: '#fff' },
    invoiced: { background: '#134f5c', text: '#fff' },
    ownerCharged: { background: '#351c75', text: '#fff' },
    supplierPaid: { background: '#741b47', text: '#fff' },
  };

  const [colorPickers, setColorPickers] = useState({
    new: { background: false, text: false },
    started: { background: false, text: false },
    completed: { background: false, text: false },
    financiallyCompleted: { background: false, text: false },
    invoiced: { background: false, text: false },
    ownerCharged: { background: false, text: false },
    supplierPaid: { background: false, text: false },
  });

  const [colors, setColors] = useState(
    currentGroup.schedulerColors ? {...currentGroup.schedulerColors} : {...defaultColors}
  );

  const toggleColorPicker = (status, type) => {
    let pickers = {...colorPickers};
    pickers[status][type] = !pickers[status][type];
    setColorPickers(pickers);
  }

  const closeColorPicker = (status, type) => {
    let pickers = {...colorPickers};
    pickers[status][type] = false;
    setColorPickers(pickers);
  }

  const setColor = (key, type, value) => {
    let cs = {...colors};
    cs[key][type] = value;
    setColors(cs);
  }

  const save = () => {
    setGroupSchedulerColors(currentGroup.id, colors);
    successToast('Changes saved!');
  }

  const useDefaultColors = () => {
    setColors({...defaultColors});
  }

  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }
  const preview = {
    height: '38px',
    width: '150px',
    display: 'inline-block',
    verticalAlign: 'top',
    border: '1px solid black',
  }

  if (userRole !== 'administrator' && user.tabsusername !== 'bev') {
    return (
      <React.Fragment>
        <h3>scheduler</h3>
        <h4>Sorry!</h4>
        <h5>Only administrators may administer the scheduler.</h5>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <h3>scheduler</h3>
      <Button onClick={save} color="primary" className="mb-2">save changes</Button>{' '}
      <Button onClick={useDefaultColors} className="mb-2">reset to defaults</Button>{' '}

      {Object.keys(colorPickers).map(cp => {

        const status = cp.replace(/([A-Z])/g, ' $1').trim().toLowerCase();

        return (
          <div className="mb-2" key="first">
            {status}{' '}
            <div className="d-inline-block">
              <Button onClick={ () => toggleColorPicker(cp, 'background') } className="mr-2">background</Button>
              <div style={{...preview, background: colors[cp].background}} className="mr-2"></div>
              {colorPickers[cp].background &&
              <div style={ popover }>
                <div style={ cover } onClick={ () => closeColorPicker(cp, 'background') }/>
                <ChromePicker color={colors[cp].background} onChangeComplete={ color => setColor(cp, 'background', color.hex) } />
              </div>
              }
            </div>
            <div className="d-inline-block">
              <Button onClick={ () => toggleColorPicker(cp, 'text') } className="mr-2">text</Button>
              <div style={{...preview, background: colors[cp].text}}></div>
              {colorPickers[cp].text &&
              <div style={ popover }>
                <div style={ cover } onClick={ () => closeColorPicker(cp, 'text') }/>
                <ChromePicker color={colors[cp].text} onChangeComplete={ color => setColor(cp, 'text', color.hex) } />
              </div>
              }
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );

}
var moment = require('moment');

/*
 * Displays a date
 */
var DateDisplay = function(props) {
  if (props.children) {
    var time = moment.utc(props.children);
    if (props.convertToLocalTime) {
      time = time.local();
    }
    time = time.format(props.format);
    if (props.prefix) {
      return props.prefix + time;
    }
    return time;
  }
  return null;
};

DateDisplay.defaultProps = {
  // format: 'L' The format to use for displaying the date
  format: 'D MMM YYYY', // Setting format explicitly for now, may need to set the locale if we do more with dates.
  prefix: false,
  convertToLocalTime: true
};

module.exports = DateDisplay;

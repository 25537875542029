import React from 'react';
import Loader from 'react-loader-spinner';

export default function PrettyLoader(props) {
  return (
    <Loader
      type="Oval"
      color="#00BFFF"
      height={props.size || 100}
      width={props.size || 100}
      style={{display: 'inline'}}
      className={props.className}
    />
  );
}
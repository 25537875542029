import React, { useCallback, useState, useEffect, useContext } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Table,
  TabContent,
  TabPane,
  Tooltip } from 'reactstrap';
import moment from 'moment';
import { addTab, closeTab, updateJob, updateJobSubStatus } from "./Actions";
import Documents from './components/Documents';
import { Store } from "./Store";
import CancelJobTemplateModal from './components/CancelJobTemplateModal';
import DeleteTemplateJobsModal from './components/DeleteTemplateJobsModal';
import ContactHistory from './components/ContactHistory';
import DiscreteCard from './components/DiscreteCard';
import FilteredJobs from './components/FilteredJobs';
import GoogleMap from './components/GoogleMap';
import Icon from './components/Icon';
import IconButton from './components/IconButton';
import InvoiceModal from './components/InvoiceModal';
import JobExpenseModal from './components/JobExpenseModal';
import JobStatusHandler from "./components/JobStatusHandler";
import JobAssociationBlock from "./components/JobAssociationBlock";
import JobChecklistBlock from "./components/JobChecklistBlock";
import JobFromTemplateModal from './components/JobFromTemplateModal';
import PrettyLoader from './components/PrettyLoader';
import Notes from './components/Notes';
import SelectPersonModal from "./components/SelectPersonModal";
import SelectServiceTagModal from './components/SelectServiceTagModal';
import SelectWorkTypeModal from './components/SelectWorkTypeModal';
import TabsButton from './components/TabsButton';
import { successToast } from './components/Toasties';
import TemplateFromJobModal from "./components/TemplateFromJobModal";
import RevisitModal from "./components/RevisitModal";
import Check from './fields/Check';
import Date from './fields/Date';
import Number from './fields/Number';
import SelectList from './fields/SelectList';
import SelectProperty from './fields/SelectProperty';
import Text from './fields/Text';
import { secondsToHms } from './mixins/MiscMixin';
import { isTest } from './mixins/EnvironmentMixin';

var classnames = require('classnames'); // TODO: why the fuck am I using this?

export default function TabJob(props) {

  const {
    state: {
      currentGroup,
      fieldWorkers,
      change
    },
    dispatch
  } = useContext(Store);

  const [workOrder, setWorkOrder] = useState();
  const [validate, setValidate] = useState({});
  const [saving, setSaving] = useState(false);
  const [fields, setFields] = useState();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [progress, setProgress] = useState();
  const [activeTab, setActiveTab] = useState();
  const [edit, setEdit] = useState(!props.workOrderId ? true : false);
  const [propertyToolip, setPropertyTooltip] = useState(false);
  const [statusHistory, setStatusHistory] = useState();
  const [durationInfo, setDurationInfo] = useState();
  const [cancelTemplateModal, setCancelTemplateModal] = useState(false);
  const [deleteTemplateJobsModal, setDeleteTemplateJobsModal] = useState(false);
  const [invoices, setInvoices] = useState();
  const [lastInstanceFromTemplate, setLastInstanceFromTemplate] = useState();
  const [recurDescription, setRecurDescription] = useState();
  const [addServiceTagsModal, setAddServiceTagsModal] = useState(false);
  const [addServiceTagsModalForm, setAddServiceTagsModalForm] = useState(false);

  const setInitialFields = useCallback(() => {
    let obj = {
      worktype: (workOrder && workOrder.worktype) || undefined,
      shortdescription: (workOrder && workOrder.shortdescription) || '',
      fulldescription: (workOrder && workOrder.fulldescription) || '',
      property: (workOrder && workOrder.property) || undefined,
      rating: (workOrder && workOrder.rating) || "low",
      requiredbydate: props.options.requiredbydate || (workOrder && workOrder.requiredbydate) || '',
      invoiceto: (workOrder && workOrder.invoiceto) || "None",
      blockavailability: (workOrder && workOrder.blockavailability) || false,
      addpropertywarning: (workOrder && workOrder.addpropertywarning) || false,
      duration: 15,
      durationother: 0,
      durationotherunit: "hours",
      expenses: workOrder ? workOrder.expenses.collection : [],
      templatejob: (workOrder && workOrder.type === 'Template') ? true : false,
      noworkrequired: (workOrder && workOrder.noworkrequired === true) ? true: false,
      supplierid: props.options.supplierid || (workOrder && workOrder.workordersupplier && workOrder.workordersupplier.supplier && workOrder.workordersupplier.supplier.id) || undefined,
      preferredstartdatetime: props.options.preferredstartdatetime || undefined,
      task: false,
      converttasktojob: false,
      servicetags: [],
      expensegroups: workOrder ? workOrder.expensegroups.collection : [],
      jobsubstatus: (workOrder && workOrder.substatus.workordersubstatus.substatusreference) || undefined,
      jobstatus: (workOrder && workOrder.status) || undefined,
      servicechargeworkorder: (workOrder && workOrder.servicechargeworkorder) || undefined,
    };

    if (props.options.templatejob) {
      obj.templatejob = true;
    }

    if (props.options.task) {
      obj.task = true;
    }

    if ((workOrder && workOrder.durationminutes) || props.options.durationminutes) {
      const duration = (workOrder && workOrder.durationminutes) ?
        workOrder.durationminutes :
        props.options.durationminutes;

      if ([15, 30, 45, 60, 90, 120, 150, 180].includes(duration)) {
        obj.duration = duration;
      } else {
        obj.duration = "other";

        if (duration % 1440 === 0) {
          obj.durationother = duration / 1440;
          obj.durationotherunit = "days";
        } else if (duration % 60 === 0) {
          obj.durationother = duration / 60;
          obj.durationotherunit = "hours";
        } else {
          obj.durationother = duration;
          obj.durationotherunit = "minutes";
        }
      }
    }

    if (workOrder && workOrder.substatus && workOrder.substatus.id) {
      if (workOrder.substatus.workordersubstatus.substatusreference === 'task') {
        obj.task = true;
      }
    }

    if (workOrder && workOrder.type === 'Template') {
      obj.dayofweek = workOrder.dayofweek || 'Monday';
      obj.weekofmonth = workOrder.weekofmonth || '1';
      obj.dayofmonth = workOrder.dayofmonth || '1';
      obj.frequency = workOrder.frequency || '1';
      obj.period = workOrder.period || 'Day';
      obj.month = workOrder.month || '1';
      obj.recur = workOrder.autogenerate && workOrder.period !== 'As Required';
      obj.dayofmonthorweekofmonthanddayofweek = workOrder ? (workOrder.dayofmonth ? 'dayofmonth' : 'weekofmonthanddayofweek') : 'dayofmonth';
      obj.startdate = workOrder.startdate || moment().format('YYYY-MM-DD');
      obj.enddate = workOrder.enddate || '2099-01-01';
    } else {
      obj.dayofweek = 'Monday';
      obj.weekofmonth = '1';
      obj.dayofmonth = '1';
      obj.frequency = '1';
      obj.period = 'Day';
      obj.month = '1';
      obj.recur = false;
      obj.dayofmonthorweekofmonthanddayofweek = 'dayofmonth';
      obj.startdate = moment().format('YYYY-MM-DD');
      obj.enddate = '2099-01-01';
    }

    setFields(obj);
  }, [workOrder]);

  const getJob = async () => {
    let job = new common.WorkOrder(props.workOrderId);

    try {
      await job.get();
      await job.property.get();
      if (job.workordertemplate && job.workordertemplate.id) {
        await job.workordertemplate.get();
      }

      setWorkOrder(job);
    }
    catch(error) {
      //showError("Error loading job", error);
    }
  }

  useEffect(() => {
    if (props.workOrderId) {
      getJob();
    } else {
      setInitialFields();
    }
  }, []);

  useEffect(() => {
    if (change && change.jobId === props.workOrderId) {
      getJob();
    }
  }, [change]);

  useEffect(() => {
    if (workOrder) {
      setInitialFields();
      setProgress(buildProgress(workOrder));
      setStatusHistory(buildStatusHistory(workOrder));
      setDurationInfo(buildDurationInfo(workOrder));
      setInvoices(buildInvoices(workOrder));
      getLastInstanceFromTemplate(workOrder);
    }
  }, [workOrder, setInitialFields]);

  useEffect(() => {
    if (!fields) { return; }

    setRecurDescription(getRecurDescription());
  }, [fields, lastInstanceFromTemplate]);

  const handleChange = event => {
    if (Array.isArray(event)) {
      const property = event.length > 0 ? event[0] : undefined;
      setFields({...fields, property});
      setUnsavedChanges(true);
      return;
    }

    if (event instanceof common.WorkType) {
      setFields({...fields, worktype: event});
      setUnsavedChanges(true);
      return;
    }

    if (event instanceof common.Supplier) {
      setFields({...fields, supplierid: event.id});
      setUnsavedChanges(true);
      return;
    }

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let f = {...fields, [name]: value};

    if (name === 'period') {
      let startDate = '';
      switch(value) {
        case 'Day': startDate = moment().format('YYYY-MM-DD'); break;
        case 'Week': startDate = moment().startOf('isoweek').format('YYYY-MM-DD'); break;
        case 'Month': startDate = moment().startOf('month').format('YYYY-MM-DD'); break;
        case 'Year': startDate = moment().startOf('year').format('YYYY-MM-DD'); break;
      }
      f = {...f, startdate: startDate};
    }

    if (name === 'recur') {
      if (value) {
        if (!f.period || f.period === 'As Required') {
          f.period = 'Day';
        }
      } else {
        f.period = 'As Required';
      }
    }

    if (name === 'task') {
      f.templatejob = false;
    }
    if (name === 'templatejob') {
      f.task = false;
    }

    setFields(f);
    setUnsavedChanges(true);
  }

  const save = async () => {

    if (!validateForm()) {
      return;
    }

    setSaving(true);

    const create = !workOrder;
    let wo = undefined;

    let obj = {
      shortdescription: fields.shortdescription,
      fulldescription: fields.fulldescription,
      worktype: fields.worktype,
      rating: fields.rating,
      invoiceto: fields.invoiceto,
      accesscontacttype: 'N/A',
      blockavailability: fields.blockavailability,
      addpropertywarning: fields.addpropertywarning,
      workordersource: {id: 1},
      noworkrequired: fields.noworkrequired,
      servicechargeworkorder: fields.servicechargeworkorder
    };

    if (fields.requiredbydate
        && fields.templatejob === false
        && (fields.task === false || fields.converttasktojob)
    ) {
      obj.requiredbydate = fields.requiredbydate;
    }

    let durationminutes = fields.duration;
    if (durationminutes === 'other') {
      let durationother = fields.durationother;

      switch (fields.durationotherunit) {
        case 'minutes':
            durationminutes = durationother;
          break;
        case 'hours':
            durationminutes = durationother * 60;
          break;
        case 'days':
            durationminutes = durationother * 60 * 24;
          break;
        default:
      }
    }

    obj.durationminutes = durationminutes;

    if (fields.templatejob || (!fields.templatejob && fields.noworkrequired)) {
      obj.supplier = new common.Supplier(fields.supplierid || currentGroup.holdingSupplier);
    }

    if (create) {
      if (fields.supplierid) {
        obj.supplier = new common.Supplier(fields.supplierid);
      } else {
        obj.supplier = new common.Supplier(currentGroup.holdingSupplier);
      }

      if (fields.preferredstartdatetime) {
        obj.preferredstartdatetime = fields.preferredstartdatetime;
      }
    }

    if (fields.noworkrequired && (create || fields.converttasktojob)) {
        // advance the job straight to practically completed
        obj.preferredstartdatetime = fields.requiredbydate ? fields.requiredbydate : moment().format('YYYY-MM-DD HH:mm:ss');
        obj.starteddate = moment().format('YYYY-MM-DD');
        obj.completeddate = fields.requiredbydate ? fields.requiredbydate : moment().format('YYYY-MM-DD');
    }

    if (fields.templatejob === false) {
      obj.type = 'Instance';
      obj.property = new common.Property(fields.property.id);
    } else {
      obj.type = 'Template';
      obj.property = new common.Property(fields.property ? fields.property.id : currentGroup.holdingProperty);
      obj.period = 'As Required';
      obj.frequency = 0;
      obj.maximuminstances = 9999999;
      obj.autogenerate = false;
      obj.autogeneratebufferdays = 90; // TODO: ????

      if (fields.recur) {
        obj.autogenerate = true;
        obj.period = fields.period;
        obj.frequency = fields.frequency;
        obj.startdate = fields.startdate;
        obj.enddate = fields.enddate;

        // reset fields by default otherwise things get mixed up
        obj.dayofweek = 'null';
        obj.month = 0;
        obj.dayofmonth = 0;
        obj.weekofmonth = 0;

        switch (fields.period) {
          case 'Week':
            obj.dayofweek = fields.dayofweek;
            break;
          case 'Year':
          case 'Month':
            if (fields.period === 'Year') {
              obj.month = fields.month;
            }

            switch (fields.dayofmonthorweekofmonthanddayofweek) {
              case 'dayofmonth':
                obj.dayofmonth = fields.dayofmonth;
                break;

              case 'weekofmonthanddayofweek':
                obj.weekofmonth = fields.weekofmonth;
                obj.dayofweek = fields.dayofweek;
                break;
              default:
            }
            break;
          default:
        }
      }
    }

    if (create) {
      wo = new common.WorkOrder();
      await wo.create(obj);
    } else {
      wo = new common.WorkOrder(workOrder.id);
      await wo.update(obj);
    }

    let deletedExpenseIds = [];
    let createdExpenseIds = {};


    for (const expenseIndex in fields.expenses) {
      const expense = fields.expenses[expenseIndex];

      let e = undefined;

      if (expense.id) {
        e = new common.WorkOrderExpense(expense.id);
        e.parent = wo;

        if (expense.deleted === true) {

          const ownercharges = Array.isArray(expense.ownercharges) ?
            expense.ownercharges :
            expense.ownercharges.collection;

          for (const ownercharge of ownercharges) {
            if (ownercharge.id) {
              let oc = new common.WorkOrderOwnerCharge(ownercharge.id);
              oc.parent = e;
              await oc.delete();
            }
          }

          await e.delete();
          deletedExpenseIds.push(expense.id);

        } else if (expense.updated === true) {
          await e.update(expense);
        }

      } else if (!expense.deleted) {
        e = new common.WorkOrderExpense();
        e.parent = wo;

        await e.create(expense);
        createdExpenseIds[expenseIndex] = e.id;
      }

      const ownercharges = Array.isArray(expense.ownercharges) ?
        expense.ownercharges :
        expense.ownercharges.collection;

      for (const ownercharge of ownercharges) {

        let oc = undefined;

        let ownerChargeDeleted = ownercharge.deleted;
        if (expense.invoiceto === 'Agency') {
          // automatically delete (or don't create/update) owner charges
          // if the agency is paying for the job
          ownerChargeDeleted = true;
        }

        if (ownercharge.id) {
          oc = new common.WorkOrderOwnerCharge(ownercharge.id);
          oc.parent = e;

          if (ownerChargeDeleted === true) {
            await oc.delete();
            continue;
          } else if (ownercharge.updated === true) {
            await oc.update(ownercharge);
          }

        } else if (!ownerChargeDeleted) {
          oc = new common.WorkOrderOwnerCharge();
          oc.parent = e;
          await oc.create(ownercharge);
        }

      }

    }

    for (const group of fields.expensegroups) {
      let g = undefined;

      if (group.id) {
        g = new common.WorkOrderExpenseGroup(group.id);
        g.parent = wo;

        if (group.deleted === true) {

          const expenses = Array.isArray(group.expenses)
            ? group.expenses
            : group.expenses.collection;

          for (const expense of expenses) {
            if (expense.id && !deletedExpenseIds.some(id => id === expense.id)) {
              let e = new common.WorkOrderExpense(expense.id);
              e.parent = wo;

              await e.get().then(() => {
                e.update({
                  workorderexpensegroup: { id: 0 }
                });
              });
            }
          }

          await g.delete();
          continue;

        } else if (group.updated === true) {
          await g.update(group);
        }

      } else if (!group.deleted) {
        g = new common.WorkOrderExpenseGroup();
        g.parent = wo;

        await g.create(group);

        const expenses = Array.isArray(group.expenses)
          ? group.expenses
          : group.expenses.collection;

        for (const expense of expenses) {
          let expenseDeleted = expense.deleted;

          if (expense.id) {
            let e = new common.WorkOrderExpense(expense.id);
            e.parent = wo;

            await e.get().then(() => {
              e.update({
                workorderexpensegroup: { id: expenseDeleted ? 0 : g.id }
              });
            });
          } else if (!expenseDeleted) {
            let e = new common.WorkOrderExpense(
              createdExpenseIds[String(expense.index)]
            );
            e.parent = wo;

            await e.get().then(() => {
              e.update({
                workorderexpensegroup: { id: g.id }
              });
            });
          }
        }
      }
    }

    if (create) {
      for (const st of fields.servicetags) {
        let wost = new common.WorkOrderServiceTag();
        wost.parent = wo;
        await wost.create({ servicetag: st });
      }

      if (fields.templatejob === false) {
        if (fields.noworkrequired) {
          await updateJobSubStatus(wo.id, 'completed', dispatch);
        } else {
          await updateJobSubStatus(wo.id, fields.task ? 'task' : 'new', dispatch);

          if (fields.supplierid && fields.preferredstartdatetime) {
            await updateJobSubStatus(wo.id, 'scheduled', dispatch);
          } else if(fields.requiredbydate && fields.schedulejob) {
            await updateJobSubStatus(wo.id, 'awaitingscheduling', dispatch);
          }
        }
      }

      successToast('job created!');
      addTab('editJob', {workOrderId: wo.id}, dispatch);
      closeTab(props.tabRef, dispatch);
      return;
    } else {
      if (fields.templatejob === false) {
        if (fields.converttasktojob) {
          if (fields.noworkrequired) {
            await updateJobSubStatus(wo.id, 'completed', dispatch);
          } else {
            await updateJobSubStatus(wo.id, 'new', dispatch);
            if(fields.requiredbydate && fields.schedulejob) {
              await updateJobSubStatus(wo.id, 'awaitingscheduling', dispatch);
            }
          }
        } else {
          //schedule job will only show if the current sub status is new
          if(fields.requiredbydate && fields.schedulejob) {
            await updateJobSubStatus(wo.id, 'awaitingscheduling', dispatch);
          }
        }
      }

      await updateJob(wo.id, {}, dispatch); // why am I doing this?

      if(wo.type === 'Template' && wo.servicechargeworkorder) {
        successToast('job updated! Please remember to click update bookings');
      } else {
        successToast('job updated!');
      }
    }

    setSaving(false);
    setUnsavedChanges(false);
    setEdit(false);
  }

  const validateForm = () => {
    let v = {...validate};

    v.shortdescription = fields.shortdescription.length > 0 ? true : false;
    v.worktype = fields.worktype && fields.worktype.id ? true : false;
    v.property = true;
    v.requiredbydate = true;
    v.servicetags = true;

    if (fields.templatejob === false) {
      v.property = fields.property && fields.property.id ? true : false;

      if (fields.task === false || (fields.task && fields.converttasktojob)) {
        v.requiredbydate = fields.requiredbydate ? true : false;
      }
    }

    if (!workOrder && currentGroup.serviceTagsMandatory && fields.servicetags.length === 0) {
      v.servicetags = false;
    }

    setValidate(v);

    return !Object.values(v).includes(false);
  }

  const handleExpenses = expenses => {
    setFields({...fields, expenses});
    setUnsavedChanges(true);
    setEdit(true);
  }

  const handleGroups = expensegroups => {
    setFields({...fields, expensegroups});
    setUnsavedChanges(true);
    setEdit(true);
  }

  const getRecurDescription = () => {
    if (!fields.period || fields.period === 'As Required') {
      return null;
    }

    if (fields.frequency < 1) {
      return (
        <Alert color="danger">
          Please enter a valid frequency
        </Alert>
      )
    }

    let ordinal = ordinalSuffixOf(parseInt(fields.frequency, 10));

    if (ordinal === '2nd') {
      ordinal = 'other';
    }

    if (ordinal !== '1st') {
      ordinal += ' ';
    } else {
      ordinal = '';
    }

    let desc = 'Job will occur every ';

    switch (fields.period) {
      case 'Day':
        desc += `${ordinal}day.`;
        break;
      case 'Week':
        desc += `${ordinal}${fields.dayofweek}.`;
        break;
      case 'Month':
      case 'Year':
        if (fields.period === 'Month') {
          desc = `Every ${ordinal}month, job will occur on the `;
        } else {
          desc = `Every ${ordinal}year, job will occur in ${moment().month(parseInt(fields.month, 10) - 1).format('MMMM')}, `;
        }

        if (fields.dayofmonthorweekofmonthanddayofweek === 'dayofmonth') {
          const dayOfMonthOrdinal = ordinalSuffixOf(parseInt(fields.dayofmonth, 10));
          desc += `on the ${dayOfMonthOrdinal} day of the month.`;
        } else {
          const weekOfMonthOrdinal = ordinalSuffixOf(parseInt(fields.weekofmonth, 10));
          desc += `in the ${weekOfMonthOrdinal} week of the month on ${fields.dayofweek}.`;
        }

        break;
      default:
    }

    if (lastInstanceFromTemplate) {
      desc += ` The last instance occured on ${moment(lastInstanceFromTemplate).format('DD/MM/YYYY')}.`;
    }

    const firstStart = getNextInstancePreferredStartDateTime(lastInstanceFromTemplate);
    const secondStart = firstStart ? getNextInstancePreferredStartDateTime(moment(firstStart)) : null;
    const thirdStart = secondStart ? getNextInstancePreferredStartDateTime(moment(secondStart)) : null;

    return (
      <Alert color="primary">
        <span>{desc}</span><br />
        <span>Next three start dates:</span><br />
        <strong>1)</strong> {firstStart ? firstStart.format('DD/MM/YYYY') : 'N/A'}, <strong>2)</strong> {secondStart ? secondStart.format('DD/MM/YYYY') : 'N/A'}, <strong>3)</strong> {thirdStart ? thirdStart.format('DD/MM/YYYY') : 'N/A'}
        {fields.enddate && fields.enddate !== '2099-01-01' &&
        <><br /><span>No jobs will be scheduled after the end date of {moment(fields.enddate).format('DD/MM/YYYY')}.</span></>
        }
      </Alert>
    )
  }

  // STOLEN - https://stackoverflow.com/a/13627586
  const ordinalSuffixOf = i => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
  }

  const dayOfWeekOfMonth = (firstOfMonth, day, week) => {
      let ret = moment(firstOfMonth);
      const lastDayOfMonth = moment(ret).endOf('month');

      let weeks = 0;
      for(let i = 1; i < 31; i++) {
          if(ret.format('dddd') === day.toString()) {
              // return day matches required day
              weeks++;
              if(weeks === parseInt(week, 10)) {
                  // and it's the '$week'th week
                  return ret;
              }
          }
          // next day
          ret.add(1, 'days');

          if (ret.isSameOrAfter(lastDayOfMonth, 'date')) {
            return lastDayOfMonth;
          }
      }
  }

  const getDayOrDayOfWeekOfMonth = (dayOfMonthOrWeekOfMonthAndDayOfWeek, dayOfMonth, dayOfWeek, weekOfMonth, firstOfMonth) => {
    if (dayOfMonthOrWeekOfMonthAndDayOfWeek === 'dayofmonth') {
      // and set the required day of the month
      if(parseInt(dayOfMonth, 10) > moment(firstOfMonth).daysInMonth()) {
          // required day of month is > no. of days in month - return last day of month
          return moment(firstOfMonth).endOf('month');
      } else {
          // add required day of month to first day of month
          let date = moment(firstOfMonth);
          return date.add(dayOfMonth - 1, 'days');
      }
    } else {
        // use weekofmonth and dayofweek
        return dayOfWeekOfMonth(firstOfMonth, dayOfWeek, weekOfMonth);
    }
  }

  const getNextInstancePreferredStartDateTime = last => {

    let preferredStartDateTime = moment();
    let today = moment();
    let lastInstanceStartDateTime = undefined;
    if (last) { lastInstanceStartDateTime = moment(last); }

    if (fields.startdate) {
      if (moment(fields.startdate).isAfter(preferredStartDateTime, 'date')) {
        preferredStartDateTime = moment(fields.startdate);

        if (moment(fields.startdate).isAfter(moment(lastInstanceStartDateTime), 'date')) {
          lastInstanceStartDateTime = undefined;
        }
      }
    }

    if (lastInstanceStartDateTime) {
      preferredStartDateTime = moment(lastInstanceStartDateTime);
    }

    let hasProcessed = false;

    // find the next date after the last existing one
    while ((lastInstanceStartDateTime && !(preferredStartDateTime.isAfter(lastInstanceStartDateTime, 'date')))
          || preferredStartDateTime.isBefore(today, 'date')
          || hasProcessed === false
    ) {
        hasProcessed = true;

        // find the next date after the last existing one
        switch (fields.period) {
          case 'Day':
            if (lastInstanceStartDateTime) {
              preferredStartDateTime.add(fields.frequency, 'days');
            }
            break;
          case 'Week':
            if (lastInstanceStartDateTime) {
              preferredStartDateTime.add(fields.frequency, 'weeks');
            }

            // find next matching day of week
            for (let i = 1; i < 7; i++) {
                if(fields.dayofweek === preferredStartDateTime.format('dddd')) {
                  break;
                }
                preferredStartDateTime.add(1, 'days');
            }
            break;
          case 'Month': {
            // get 1st of required month
            let firstOfMonth = moment(preferredStartDateTime).startOf('month');

            if (lastInstanceStartDateTime) {
              firstOfMonth.add(fields.frequency, 'months');
            }

            preferredStartDateTime = getDayOrDayOfWeekOfMonth(
              fields.dayofmonthorweekofmonthanddayofweek,
              fields.dayofmonth,
              fields.dayofweek,
              fields.weekofmonth,
              firstOfMonth
            );

            if(!lastInstanceStartDateTime && preferredStartDateTime.isBefore(today, 'date')) {
              // 'next' is in the past - go forward a month
              firstOfMonth.add(fields.frequency, 'months');

              preferredStartDateTime = getDayOrDayOfWeekOfMonth(
                fields.dayofmonthorweekofmonthanddayofweek,
                fields.dayofmonth,
                fields.dayofweek,
                fields.weekofmonth,
                firstOfMonth
              );
            }}
            break;
          case 'Year': {
            let firstOfMonth = undefined;

            if (lastInstanceStartDateTime) {
              firstOfMonth = moment(preferredStartDateTime)
                .startOf('year')
                .add(fields.frequency, 'years')
                .add(fields.month - 1, 'months');
            } else {
              firstOfMonth = moment(preferredStartDateTime)
                .startOf('year')
                .add(fields.month - 1, 'months');
            }

            preferredStartDateTime = getDayOrDayOfWeekOfMonth(
              fields.dayofmonthorweekofmonthanddayofweek,
              fields.dayofmonth,
              fields.dayofweek,
              fields.weekofmonth,
              firstOfMonth
            );

            if (!lastInstanceStartDateTime && preferredStartDateTime.isBefore(today, 'date')) {
              // no previous, and 'next' is in the past - go forward a year
              firstOfMonth.add(1, 'years');

              preferredStartDateTime = getDayOrDayOfWeekOfMonth(
                fields.dayofmonthorweekofmonthanddayofweek,
                fields.dayofmonth,
                fields.dayofweek,
                fields.weekofmonth,
                firstOfMonth
              );
            }
            break;
          }
        }
    }

    if (fields.enddate) {
      if (moment(fields.enddate).isBefore(preferredStartDateTime, 'date')) {
        return null;
      }
    }

    return preferredStartDateTime;
  }

  const buildStatusHistory = job => {
    let rows = [];

    job.statushistory.forEach(status => {

      const statusName = status.status.toLowerCase();

      rows.push(
        <tr key={'s' + status.id}>
          <td className="font-weight-bold">
            {statusName === 'completed' ? 'practically completed' : statusName}
          </td>
          <td>{moment(status.from).format('DD/MM/YY HH:mm')}</td>
          <td>{status.setbydetails !== undefined ? status.setbydetails.firstname + ' ' + status.setbydetails.surname : ''}</td>
        </tr>
      );

      let subStatuses = [];
      status.substatus.forEach(subStatus => subStatuses.push(subStatus));
      subStatuses.sort((a, b) => (a.fromdatetime > b.fromdatetime) ? 1 : -1);

      subStatuses.forEach(subStatus => {
        if (statusName.replace(' ', '_') !== subStatus.workordersubstatus.substatusreference) {
          rows.push(
            <tr key={'ss' + subStatus.id}>
              <td>{subStatus.workordersubstatus.substatusname}</td>
              <td>{moment(subStatus.fromdatetime).format('DD/MM/YY HH:mm')}</td>
              <td>{subStatus.setbydetails != undefined ?  subStatus.setbydetails.firstname + ' ' + subStatus.setbydetails.surname : ''}</td>
            </tr>
          );
        }
      });

    });

    return rows;
  }

  const buildProgress = job => {
    const statuses = ['new', 'started', 'completed', 'financially completed', 'invoiced', 'owner charged', 'supplier paid'];

    let currentStatus = job.status.toLowerCase();

    if (currentStatus === 'template') {
      return null;
    }

    if (currentStatus === 'cancelled') {
      return <h3 className="text-center mt-3">cancelled</h3>;
    }

    let hasPaySupplierItems = false;
    let hasOwnerCharges = false;

    job.expenses.forEach(expense => {
      if (!expense.donotpaysupplier) {
        hasPaySupplierItems = true;
      }
      if (expense.ownercharges.collection.length > 0) {
        hasOwnerCharges = true;
      }
    });

    if (!hasPaySupplierItems) {
      // remove the invoiced and supplier paid statuses because the job should never end up on them
      statuses.splice(statuses.indexOf('invoiced'), 1);
      statuses.splice(statuses.indexOf('supplier paid'), 1);
    }
    if (!hasOwnerCharges) {
      // remove the owner charged status because the job should never end up on it
      statuses.splice(statuses.indexOf('owner charged'), 1);
    }

    const liStyle = {
      width: (98 / statuses.length).toString() + '%'
    }

    const lis = statuses.map((element, i) => {

      let className = '';
      if (i <= statuses.indexOf(job.status.toLowerCase())) {
        className += 'active ';
      }

      let subStatus = currentStatus === element ? job.substatus : undefined;

      if (subStatus &&
          subStatus.id &&
          currentStatus.replace(' ', '_') === subStatus.workordersubstatus.substatusreference.toLowerCase()) {
        // don't show sub-status when it's called the same as the parent status
        subStatus = undefined;
      }

      let subStatusName = (subStatus && subStatus.id) ? subStatus.workordersubstatus.substatusname : undefined;

      // status specific hacks
      if (element === 'invoiced') {
        if (currentStatus === 'invoice approved') {
          currentStatus = 'invoiced';
          subStatusName = 'approved';
        }
        if (currentStatus === 'invoice rejected') {
          currentStatus = 'invoiced';
          subStatusName = 'rejected';
        }
      }

      return (
        <li key={i} className={className} style={liStyle}>
          <div className={currentStatus === element ? "substatus m-1" : "m-1"}>
            <div>
              <div className={element === currentStatus ? 'font-weight-bold bg-white' : 'bg-white'}>
                {element === 'completed' ? 'practically completed' : element}
              </div>
              {subStatusName &&
              <React.Fragment>
                <br style={{lineHeight: "20px"}} />
                <div style={{backgroundColor: "#fd6413", color: "white", borderRadius: "15px", padding: "4px"}}>
                  <strong>{subStatusName}</strong>
                </div>
              </React.Fragment>
              }
            </div>
          </div>
        </li>
      );
    });

    return (
      <div className="container">
        <ul className="progressbar p-0">
          {lis}
        </ul>
      </div>
    );
  }

  const buildDurationInfo = job => {

    let started = undefined;
    let completed = undefined;
    let pausedDuration = 0;

    job.statushistory.collection.sort((a, b) => moment(a.from) > moment(b.from) ? 1 : -1).forEach(status => {
      const subStatusSorted = status.substatus.collection.sort(
        (a, b) => moment(a.fromdatetime) > moment(b.fromdatetime) ? 1 : -1
      );

      subStatusSorted.forEach((substatus, i) => {
        switch (substatus.workordersubstatus.substatusreference) {
          case 'started':
            if (!started) {
              started = moment(substatus.fromdatetime);
            }
            break;
          case 'started_paused': {
              const paused = moment(substatus.fromdatetime);

              if (subStatusSorted[i + 1]) {
                const resumed = moment(subStatusSorted[i + 1].fromdatetime);
                const seconds = resumed.diff(paused, 'seconds', true);
                pausedDuration += seconds;
              }
            }
            break;
          case 'completed':
            completed = moment(substatus.fromdatetime);
            break;
        }
      });
    });

    if (started && completed) {
      const jobDurationSeconds = completed.diff(started, 'seconds', true) - pausedDuration;
      const jobDurationStr = secondsToHms(Math.round(jobDurationSeconds / 60) * 60);
      const pausedDurationStr = pausedDuration > 0 ? secondsToHms(Math.round(pausedDuration / 60) * 60) : '';

      return (
        <Alert color="success" className="mb-0">
          <Icon icon="stopwatch" />{' '}
          Job completed in {jobDurationStr || 'less than a minute'}.
          {pausedDurationStr.length > 0 &&
          <span> The user paused the job for {pausedDurationStr}.</span>
          }
        </Alert>
      );
    }

    return null;
  }

  const buildInvoices = job => {
    let invoiceRows = [];

    if (job.expenses) {
      job.expenses.forEach(expense => {
        expense.ownercharges.forEach(wooc => {
          if (wooc.ownercharge && wooc.ownercharge.pmsinvoiceid) {
            invoiceRows.push(
              <JobExpenseInvoiceRow key={wooc.id} workOrderOwnerCharge={wooc} />
            );
          }
        });
      });
    }

    return (
      <React.Fragment>
        <Card className="mb-3">
          <CardBody>
            <CardTitle tag="h5">owner invoices</CardTitle>
            <Table size="sm">
              <thead>
                <tr>
                  <th>charge id</th>
                  <th>description</th>
                  <th>invoice id</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {invoiceRows.length > 0 ?
                invoiceRows
                :
                <tr><td colSpan={4}>No invoices found.</td></tr>
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <Card className="mb-3">
          <CardBody>
            <CardTitle tag="h5">field worker invoices</CardTitle>
            <Table size="sm" striped>
              <thead>
                <tr>
                  <th>id</th>
                  <th>date</th>
                  <th>number</th>
                  <th>description</th>
                  <th>net total</th>
                  <th>{''}</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(job.invoices) && job.invoices.length === 0 &&
                <tr>
                  <td colSpan={5}>No invoices found.</td>
                </tr>
                }
                {typeof job.invoices === 'object' && Object.values(job.invoices).map(invoice => {
                  return (
                    <tr key={invoice.id}>
                      <td>{invoice.id}</td>
                      <td>{moment(invoice.invoicedate).format('DD/MM/YYYY')}</td>
                      <td>{invoice.invoicenumber}</td>
                      <td>{invoice.description || 'N/A'}</td>
                      <td>{invoice.totalamountnet ? invoice.totalamountnet.toFixed(2) : 'N/A'}</td>
                      <td className="align-middle text-center">
                        <Icon
                          icon="download"
                          onClick={() => {
                            let inv = new common.SupplierInvoice(invoice.id);

                            inv.get().then(() => {
                              let doc = inv.document;

                              doc.file.get().then((res) => {
                                var link = document.createElement('a');
                                link.setAttribute('href', res.url);
                                link.setAttribute('download', doc.filename);
                                link.setAttribute('target', '_blank');
                                link.style.display = 'none';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              });
                            });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }

  const getLastInstanceFromTemplate = job => {
    if (job.type === 'Instance') { return; }

    let instances = new FilterCollection({
      path: 'workorder',
      object: common.WorkOrder,
    });

    instances.limit = 1;
    instances.orderBy = 'instancepreferredstartdatetime_desc';

    instances.addFilters([{
      type: 'Instance',
      templateid: job.id,
    }]);

    instances.fields = 'preferredstartdatetime';

    let lastPreferredStartDateTime = undefined;

    instances.fetch().then(() => {
      if (instances.collection.length > 0) {
        lastPreferredStartDateTime = moment(instances.collection[0].preferredstartdatetime);
      }

      setLastInstanceFromTemplate(lastPreferredStartDateTime);
    });
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const updateBookings = async () => {
    // to update the bookings we have to find the PropertySupplierService, which from the WorkOrder
    // is a bit of a chore..!
    if (window.confirm('Are you sure you want to update future bookings?')) {
      await workOrder.property.suppliers.fetch();
      for (const propertySupplier of workOrder.property.suppliers.collection) {
        for (const psdr of propertySupplier.dates.collection) {
          if (moment().isBetween(psdr.fromdate, psdr.todate)) {
            await propertySupplier.services.fetch();
            for (const propertySupplierService of propertySupplier.services.collection) {
              await propertySupplierService.charges.fetch();
              for (const serviceCharge of propertySupplierService.charges.collection) {
                if (serviceCharge.type === 'WorkOrder' && serviceCharge.workordertemplate.id === workOrder.id) {
                  await propertySupplierService.update({ forceupdateservices: true });
                  successToast('Success - bookings will update shortly!');
                }
              }
            }
          }
        }
      }
    }
  }

  const toggleAddServiceTagsModal = () => {
    setAddServiceTagsModal(!addServiceTagsModal);
  }

  const toggleAddServiceTagsModalForm = () => {
    setAddServiceTagsModalForm(!addServiceTagsModalForm);
  }

  const addServiceTags = tags => {
    let promises = [];

    for (const tag of tags) {
      let wost = new common.WorkOrderServiceTag();
      wost.parent = workOrder;
      promises.push(wost.create({ servicetag: tag }));
    }

    Promise.all(promises).then(() => getJob());
  }

  const deleteServiceTag = tag => {
    tag.delete().then(() => getJob());
  }

  const cancelChanges = () => {
    if (unsavedChanges) {
      if (window.confirm('There are unsaved changes. Discard?')) {
        setInitialFields();
        setUnsavedChanges(false);
        setEdit(false);
      }
    } else {
      setEdit(false);
    }
  }

  if (!fields) {
    return null;
  }

  let actorName = 'N/A';
  if (workOrder && workOrder.workordersupplier.id) {
    const supplier = workOrder.workordersupplier.supplier;

    if (supplier.title !== 'PMS') {
      actorName = [
        supplier.title,
        supplier.firstname,
        supplier.surname
      ].join(' ');
    }
  }

  let daysOfMonth = [];
  for (let i = 1; i <= 31; i++) {
    daysOfMonth.push(<option key={i} value={i}>{i}</option>);
  }
  daysOfMonth.push(<option key={99} value={99}>last day of month</option>);

  let weeksOfMonth = [];
  for (let i = 1; i <= 4; i++) {
    weeksOfMonth.push(<option key={i} value={i}>{i}</option>);
  }

  let daysOfWeek = [];
  ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].forEach(day =>
    daysOfWeek.push(<option key={day} value={day}>{day}</option>)
  );

  let monthCounter = 0;
  const months = ["January", "February", "March", "April", "May", "June",
                  "July", "August", "September", "October", "November", "December"].map(month => {

    monthCounter += 1;
    return (<option key={month} value={monthCounter}>{month}</option>);
  });

  let disableAll = false;
  if (workOrder) {
    if (['financially completed', 'invoiced', 'invoice approved', 'owner charged', 'supplier paid'].includes(workOrder.status.toLowerCase())) {
      disableAll = true;
    }
  }

  let fieldWorker = undefined;
  if (fields.supplierid) {
    fieldWorker = fieldWorkers.find(fw => fw.id === fields.supplierid);
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Row className="mb-2">
            <Col>
              <h3>
                {
                  [
                    !workOrder ? 'new' : null,
                    fields.templatejob ? 'template' : null,
                    fields.task ? 'task' : 'job',
                    workOrder ? `${workOrder.id} - ${workOrder.shortdescription}` : null
                  ].filter(Boolean).join(' ')
                }
                { workOrder &&
                  workOrder.workordertemplate &&
                  workOrder.workordertemplate.id &&
                  workOrder.workordertemplate.autogenerate &&
                <Icon
                  icon="sync"
                  className="text-primary cursorPointer ml-2"
                  onClick={() => { addTab('editJob', {workOrderId: workOrder.workordertemplate.id}, dispatch); }}
                />
                }
                { workOrder &&
                  workOrder.bookingserviceworkorder &&
                <Icon
                  icon="umbrella-beach"
                  className="text-primary cursorPointer ml-2"
                  onClick={() => { addTab('editJob', {workOrderId: workOrder.workordertemplate.id}, dispatch); }}
                />
                }
              </h3>
              <div>
                { !edit &&
                  workOrder &&
                  workOrder.servicetags.collection.length > 0 &&
                  workOrder.servicetags.sort(
                    (a, b) => (a.servicetag.name.toLowerCase() > b.servicetag.name.toLowerCase()) ? 1 : -1
                  ).map(wost =>
                  <React.Fragment key={wost.id}>
                    <Badge color="secondary">
                      <span style={{ verticalAlign: "top" }}>{wost.servicetag.name}</span>{' '}
                      <Icon
                        icon="times"
                        className="clickable-icon cursorPointer"
                        onClick={() => deleteServiceTag(wost)}
                      />
                    </Badge>{' '}
                  </React.Fragment>
                  )
                }
                {!edit &&
                <Badge color="primary" href="#" onClick={toggleAddServiceTagsModal}>
                  add tag(s)
                </Badge>
                }
                {addServiceTagsModal &&
                <SelectServiceTagModal
                  toggle={toggleAddServiceTagsModal}
                  onSubmit={tags => {
                    addServiceTags(tags);
                  }}
                  excludeIds={workOrder.servicetags.map(wost => wost.servicetag.id)}
                />
                }
              </div>
            </Col>
            <Col md="auto">
              {saving &&
              <React.Fragment>
                <PrettyLoader size={38} />{' '}
              </React.Fragment>
              }
              {!saving && unsavedChanges &&
              <span className="text-danger font-weight-bold h5">unsaved changes{' '}</span>
              }
              {workOrder &&
              <React.Fragment>
                <Button color="primary" onClick={() => setEdit(true)} disabled={edit}>edit</Button>{' '}
                {edit &&
                <React.Fragment>
                  <Button
                    color="secondary"
                    onClick={cancelChanges}
                  >
                    cancel
                  </Button>{' '}
                </React.Fragment>
                }
              </React.Fragment>
              }
              <Button color="primary" onClick={save} disabled={saving || !unsavedChanges}>save</Button>{' '}
              {!workOrder &&
              <ButtonGroup>
                <Button
                  active={fields.templatejob === false && fields.task === false}
                  disabled={workOrder ? true : false}
                  onClick={() => handleChange({ target: { checked: false, name: 'templatejob', type: 'checkbox' } })}
                >
                  regular job
                </Button>
                <Button
                  active={fields.templatejob === true && fields.task === false}
                  disabled={workOrder ? true : false}
                  onClick={() => handleChange({ target: { checked: true, name: 'templatejob', type: 'checkbox' } })}
                >
                  template job
                </Button>
                <Button
                  active={fields.task === true && fields.templatejob === false}
                  disabled={workOrder ? true : false}
                  onClick={() => handleChange({ target: { checked: true, name: 'task', type: 'checkbox' } })}
                >
                  task
                </Button>
              </ButtonGroup>
              }
              {workOrder &&
              <TabsButton className="ml-1" route={"managedservices/workorders/" + workOrder.id} size={38} />
              }
            </Col>
          </Row>
          <Row>
            <Col>
              {workOrder &&
              <Card className="mb-3">
                <CardBody>
                  <Row className="mb-1 text-center">
                    <DiscreteCard title='type'>
                      {workOrder.source.id ? workOrder.source.sourcename : 'supplier portal/non-PMS'}
                    </DiscreteCard>
                    <DiscreteCard title='created'>
                      {moment(workOrder.createddatetime).format('DD/MM/YY HH:mm')}
                    </DiscreteCard>
                    {workOrder.type === 'Instance' &&
                    <React.Fragment>
                      <DiscreteCard title='required by'>
                        {workOrder.requiredbydate ? moment(workOrder.requiredbydate).format('DD/MM/YY') : 'N/A'}
                      </DiscreteCard>
                      <DiscreteCard title='scheduled for'>
                        {workOrder.preferredstartdatetime ? moment(workOrder.preferredstartdatetime).format('DD/MM/YY HH:mm') : 'N/A'}
                      </DiscreteCard>
                      <DiscreteCard title='completed'>
                        {workOrder.completeddate ? moment(workOrder.completeddate).format('DD/MM/YY') : 'N/A'}
                      </DiscreteCard>
                    </React.Fragment>
                    }
                  </Row>
                  {!edit &&
                  <Row className="mb-1 text-center">
                    <DiscreteCard title='duration'>
                      {workOrder.durationminutes ? (workOrder.durationminutes + ' mins') : 'N/A'}
                    </DiscreteCard>
                    {(workOrder.type === 'Instance' || (workOrder.type === 'Template' && actorName !== 'N/A')) &&
                    <DiscreteCard title='field worker'>
                      {actorName}
                      {workOrder && workOrder.workordersupplier.id && fieldWorkers.find(fw => fw.id === workOrder.workordersupplier.id) && actorName !== 'N/A' &&
                      <React.Fragment>
                        {' '}
                        <IconButton
                          icon="show"
                          onClick={() => {
                            addTab('showFieldWorker', {supplierId: workOrder.workordersupplier.supplier.id}, dispatch);
                          }}
                        />
                      </React.Fragment>
                      }
                    </DiscreteCard>
                    }
                    <DiscreteCard title='work type'>
                      {workOrder.worktype.id ? workOrder.worktype.worktype : 'N/A'}
                    </DiscreteCard>
                    <DiscreteCard title='property'>
                      {workOrder &&
                      <React.Fragment>
                        <span id={"propertyToolip" + workOrder.id} className="text-primary">
                          {workOrder.property.name}{' '} ({workOrder.property.tabspropref}){' '}
                          <Icon icon="info-circle" />
                        </span>
                        <Tooltip
                          autohide={false}
                          placement="bottom"
                          target={"propertyToolip" + workOrder.id}
                          isOpen={propertyToolip}
                          toggle={() => setPropertyTooltip(!propertyToolip)}
                          fade={false}
                          // style={{ backgroundColor: "#4A7EFA" }}
                        >
                          <strong>{workOrder.property.name}</strong><br />
                          <IconButton
                            icon="show"
                            onClick={() => {
                              addTab('showProperty', {propertyId: workOrder.property.id}, dispatch);
                              setPropertyTooltip(false);
                            }}
                          /><br />
                          {workOrder.property.address &&
                            [
                              workOrder.property.address.line1,
                              workOrder.property.address.town,
                              workOrder.property.address.county,
                              workOrder.property.address.postcode
                            ].filter(Boolean).join(', ')
                          }
                          <GoogleMap
                            latitude={workOrder.property.address.latitude}
                            longitude={workOrder.property.address.longitude}
                            height="150"
                          />
                        </Tooltip>
                      </React.Fragment>
                      }
                    </DiscreteCard>
                  </Row>
                  }
                  {!edit && workOrder && workOrder.fulldescription.length > 0 &&
                  <Row>
                    <DiscreteCard>
                      <Icon icon="pencil-alt" />{' '}
                      {workOrder.fulldescription}
                    </DiscreteCard>
                  </Row>
                  }
                  {!edit &&
                  <Row>
                    <Col>
                      {progress}
                    </Col>
                  </Row>
                  }
                  {durationInfo}
                </CardBody>
              </Card>
              }
              {workOrder && workOrder.type === 'Template' &&
              <Card className="mb-3">
                <CardBody>
                  <CardTitle tag="h5">template actions</CardTitle>
                  <Button onClick={() => setCancelTemplateModal(true)}>
                    cancel {workOrder.status.toLowerCase() === 'cancelled' ? 'jobs from' : ''} template
                  </Button>{' '}
                  {workOrder.servicechargeworkorder &&
                  <Button onClick={updateBookings}>
                    update bookings
                  </Button>
                  }
                  {' '}
                  {fields.recur &&
                  <Button onClick={() => setDeleteTemplateJobsModal(true)}>
                    update future jobs
                  </Button>}{' '}
                </CardBody>
              </Card>
              }
              {disableAll &&
              <Alert color="warning">
                This job is financially completed, so changes may not be made.
              </Alert>
              }
              {workOrder && !edit &&
              <JobStatusHandler
                workOrder={workOrder}
                disabled={unsavedChanges}
              />
              }
              {edit &&
              <Card className={disableAll ? 'disable mb-3' : 'mb-3'}>
                <CardBody>
                  {fields.supplierid && fields.preferredstartdatetime && fieldWorker &&
                  <Alert color="warning">
                    Job will be scheduled for {fieldWorker.title} {fieldWorker.firstname} {fieldWorker.surname} at {moment(fields.preferredstartdatetime).format('DD/MM/YY HH:mm.')}
                  </Alert>
                  }

                  {fields.task && workOrder &&
                  <Check
                    name="converttasktojob"
                    label="convert task to job"
                    value={fields.converttasktojob}
                    onChange={handleChange}
                  />
                  }

                  <FormGroup row className="align-items-center">
                    <Label sm={4}>work type</Label>
                    <Col sm={8}>
                      <Row>
                        <Col>
                          {fields.worktype && fields.worktype.id &&
                          <React.Fragment>
                            {fields.worktype.worktype}, <strong>or</strong>{' '}
                          </React.Fragment>
                          }
                          <SelectWorkTypeModal
                            buttonText="choose work type"
                            onChoose={handleChange}
                          />
                        </Col>
                      </Row>
                      {fields.worktype && !workOrder &&
                      <Row className="mt-2">
                        <Col>
                          <JobFromTemplateModal workType={fields.worktype} />
                        </Col>
                      </Row>
                      }
                      {validate.worktype === false &&
                      <div className="invalid-feedback d-block">
                        You must choose a work type.
                      </div>
                      }
                    </Col>
                  </FormGroup>

                  <Text
                    name="shortdescription"
                    label="short description"
                    value={fields.shortdescription}
                    onChange={handleChange}
                    invalid={validate.shortdescription === false}
                  />

                  <Text
                    name="fulldescription"
                    label="full description"
                    value={fields.fulldescription}
                    onChange={handleChange}
                    large
                  />

                  <FormGroup row>
                    <Label sm={4}>property</Label>
                    <Col sm={8}>
                      <SelectProperty
                        selectedProperty={fields.property}
                        onChange={handleChange}
                        isInvalid={validate.property === false}
                      />
                      {validate.property === false &&
                      <div className="invalid-feedback d-block">
                        You must choose a property.
                      </div>
                      }
                    </Col>
                  </FormGroup>

                  {fields.templatejob &&
                  <FormGroup row>
                    <Label sm={4}>field worker (optional)</Label>
                    <Col sm={8}>
                      {(fieldWorker && fieldWorker.id !== currentGroup.holdingSupplier) ?
                      ([fieldWorker.title, fieldWorker.firstname, fieldWorker.surname].join(' ') + ', or ') :
                      'decide later, or '
                      }
                      <SelectPersonModal
                        button={onClick => {
                          return (
                            <Button size="sm" color="primary" onClick={onClick}>select field worker</Button>
                          );
                        }}
                        onSubmit={supplierId => {
                          const supplier = fieldWorkers.find(fw => fw.id === supplierId);
                          handleChange(supplier);
                        }}
                        oneOnly={true}
                      />
                      {fieldWorker && fieldWorker.id !== currentGroup.holdingSupplier &&
                      <React.Fragment>
                        {' '}
                        <Button
                          size="sm"
                          color="secondary"
                          onClick={() => {
                            handleChange({
                              target: {
                                name: 'supplierid',
                                value: undefined
                              }
                            });
                          }}
                        >
                          remove field worker
                        </Button>
                      </React.Fragment>
                      }
                    </Col>
                  </FormGroup>
                  }

                  <FormGroup row>
                    <Label sm={4}>duration (minutes)</Label>
                    <Col sm={4}>
                      <Input
                        type="select"
                        name="duration"
                        value={fields.duration}
                        onChange={handleChange}
                      >
                        <option value="15" key="15">15 minutes</option>
                        <option value="30" key="30">30 minutes</option>
                        <option value="45" key="45">45 minutes</option>
                        <option value="60" key="60">60 minutes (1 hour)</option>
                        <option value="90" key="90">90 minutes (1.5 hours)</option>
                        <option value="120" key="120">120 minutes (2 hours)</option>
                        <option value="150" key="150">150 minutes (2.5 hours)</option>
                        <option value="180" key="180">180 minutes (3 hours)</option>
                        <option value="other" key="other">Other (specify yourself)</option>
                      </Input>
                    </Col>
                    {fields.duration === "other" &&
                    <React.Fragment>
                      <Col sm={2}>
                        <Input
                          type="text"
                          name="durationother"
                          value={fields.durationother}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col sm={2}>
                        <Input
                          type="select"
                          name="durationotherunit"
                          value={fields.durationotherunit}
                          onChange={handleChange}
                        >
                          <option value="minutes" key="minutes">minutes</option>
                          <option value="hours" key="hours">hours</option>
                          <option value="days" key="days">days</option>
                        </Input>
                      </Col>
                    </React.Fragment>
                    }
                  </FormGroup>

                  {fields.templatejob === false && (fields.task === false || fields.task && fields.converttasktojob) &&
                  <FormGroup row>
                    <Label sm={4}>required by date</Label>
                    <Col sm={8}>
                      <Input
                        type="date"
                        name="requiredbydate"
                        placeholder="required by date"
                        value={fields.requiredbydate}
                        onChange={handleChange}
                        invalid={validate.requiredbydate === false}
                      />
                      {validate.requiredbydate === false &&
                      <div className="invalid-feedback d-block">
                        You must choose a required by date.
                      </div>
                      }
                    </Col>

                    {//if the substatus and status are new or undefined then ask if the job should go to awaiting schduling
                    (fields.jobsubstatus === undefined || fields.jobsubstatus === 'new' || fields.jobsubstatus === 'task') &&
                      (fields.jobstatus === undefined || fields.jobstatus === 'New') &&
                    <React.Fragment>
                    <Col sm={12}>
                    <Check
                      name="schedulejob"
                      label="auto set status to 'awaiting scheduling' when saved?"
                      value={fields.schedulejob}
                      onChange={handleChange}
                    />
                    </Col>
                    </React.Fragment>
                    }
                  </FormGroup>
                  }

                  {!workOrder &&
                  <FormGroup row className="align-items-center">
                    <Label sm={4}>service tags</Label>
                    <Col sm={8}>
                      {fields.servicetags.sort(
                        (a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1
                      ).map(st =>
                      <React.Fragment key={st.id}>
                        <Badge color="secondary">
                          <span style={{ verticalAlign: "top" }}>{st.name}</span>{' '}
                          <Icon
                            icon="times"
                            className="clickable-icon cursorPointer"
                            onClick={() => {
                              let sts = [...fields.servicetags];
                              sts.splice(sts.findIndex(s => s.id === st.id), 1);
                              setFields({
                                ...fields,
                                servicetags: sts
                              });
                            }}
                          />
                        </Badge>{' '}
                      </React.Fragment>
                      )}
                      <Badge color="primary" href="#" onClick={toggleAddServiceTagsModalForm}>
                        add tag(s)
                      </Badge>
                      {addServiceTagsModalForm &&
                      <SelectServiceTagModal
                        toggle={toggleAddServiceTagsModalForm}
                        onSubmit={sts => {
                          setFields({
                            ...fields,
                            servicetags: [...fields.servicetags, ...sts]
                          });
                          setUnsavedChanges(true);
                        }}
                        excludeIds={fields.servicetags.map(st => st.id)}
                      />
                      }
                      {validate.servicetags === false &&
                      <div className="invalid-feedback d-block">
                        You must add at least one service tag.
                      </div>
                      }
                    </Col>
                  </FormGroup>
                  }


                  {(fields.task === false || fields.task && fields.converttasktojob) &&
                  <React.Fragment>
                    <Check
                      name="blockavailability"
                      label="block availability"
                      value={fields.blockavailability}
                      onChange={handleChange}
                    />
                    <Check
                      name="addpropertywarning"
                      label="add warning to property"
                      value={fields.addpropertywarning}
                      onChange={handleChange}
                    />
                    <Check
                      name="noworkrequired"
                      label="no work required"
                      value={fields.noworkrequired}
                      onChange={handleChange}
                    />
                    {fields.noworkrequired && fields.templatejob === false &&
                    <FormGroup row>
                      <Label sm={4}>field worker (optional)</Label>
                      <Col sm={8}>
                        {(fieldWorker && fieldWorker.id !== currentGroup.holdingSupplier) ?
                        ([fieldWorker.title, fieldWorker.firstname, fieldWorker.surname].join(' ') + ', or ') :
                        'no field worker, or '
                        }
                        <SelectPersonModal
                          button={onClick => {
                            return (
                              <Button size="sm" color="primary" onClick={onClick}>select field worker</Button>
                            );
                          }}
                          onSubmit={supplierId => {
                            const supplier = fieldWorkers.find(fw => fw.id === supplierId);
                            handleChange(supplier);
                          }}
                          oneOnly={true}
                        />
                        {fieldWorker && fieldWorker.id !== currentGroup.holdingSupplier &&
                        <React.Fragment>
                          {' '}
                          <Button
                            size="sm"
                            color="secondary"
                            onClick={() => {
                              handleChange({
                                target: {
                                  name: 'supplierid',
                                  value: undefined
                                }
                              });
                            }}
                          >
                            remove field worker
                          </Button>
                        </React.Fragment>
                        }
                      </Col>
                    </FormGroup>
                    }
                  </React.Fragment>
                  }
                </CardBody>
              </Card>
              }
              {workOrder &&
              <Card>
                <CardBody>
                  <Nav className="mb-2" tabs>
                    {workOrder.type === 'Template' &&
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'jobs' || (!activeTab && workOrder.type === 'Template') })}
                        onClick={() => { toggleTab('jobs'); }}
                      >
                        jobs
                      </NavLink>
                    </NavItem>
                    }
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'notes' || (!activeTab && workOrder.type === 'Instance') })}
                        onClick={() => { toggleTab('notes'); }}
                      >
                        notes
                      </NavLink>
                    </NavItem>
                    {workOrder.booking.id &&
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'booking notes' })}
                        onClick={() => { toggleTab('booking notes'); }}
                      >
                        booking notes
                      </NavLink>
                    </NavItem>
                    }
                    {workOrder.type === 'Instance' &&
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'contact history' })}
                        onClick={() => { toggleTab('contact history'); }}
                      >
                        contact history
                      </NavLink>
                    </NavItem>
                    }
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'documents' })}
                        onClick={() => { toggleTab('documents'); }}
                      >
                        documents
                      </NavLink>
                    </NavItem>
                    {workOrder.type === 'Instance' &&
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'status history' })}
                        onClick={() => { toggleTab('status history'); }}
                      >
                        status history
                      </NavLink>
                    </NavItem>
                    }
                    {workOrder.type === 'Instance' &&
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'invoices' })}
                        onClick={() => { toggleTab('invoices'); }}
                      >
                        invoices
                      </NavLink>
                    </NavItem>
                    }
                  </Nav>
                  <TabContent activeTab={activeTab || (workOrder.type === 'Template' ? 'jobs' : 'notes')}>
                    {workOrder.type === 'Template' &&
                    <TabPane tabId="jobs">
                      <FilteredJobs templateId={workOrder.id} />
                    </TabPane>
                    }
                    <TabPane tabId="notes">
                      <Notes parent={workOrder} mode="job" />
                    </TabPane>
                    {workOrder.booking.id &&
                    <TabPane tabId="booking notes">
                      <Notes parent={workOrder.booking} mode="booking" />
                    </TabPane>
                    }
                    <TabPane tabId="contact history">
                      <ContactHistory entityid={workOrder.id} job={workOrder} />
                    </TabPane>
                    <TabPane tabId="documents">
                      <Documents workOrder={workOrder} />
                    </TabPane>
                    <TabPane tabId="status history">
                      <Table size="sm" striped>
                        <thead>
                          <tr>
                            <th>status</th>
                            <th>assigned</th>
                            <th>actioned by</th>
                          </tr>
                        </thead>
                        <tbody>
                          {statusHistory}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId="invoices">
                      {invoices}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
              }
            </Col>
            <Col>
              {fields.templatejob === true && !edit &&
              <Card className="mb-3">
                <CardBody>
                  <CardTitle tag="h5">recurrence settings</CardTitle>
                  <strong>recurring job:</strong> <Icon icon={fields.recur ? 'check' : 'times'} /><br />
                  {fields.recur &&
                  <React.Fragment>
                    <strong>period:</strong> Every {fields.period.toLowerCase()}<br />
                    <strong>frequency:</strong> {fields.frequency}<br />
                    {fields.period === 'Day' &&
                    <React.Fragment>
                      <strong>start date:</strong> {fields.startdate ? moment(fields.startdate).format('DD/MM/YYYY') : 'N/A'}<br />
                    </React.Fragment>
                    }
                    {fields.period === 'Week' &&
                    <React.Fragment>
                      <strong>start week:</strong> W/C {fields.startdate ? moment(fields.startdate).format('DD/MM/YYYY') : 'N/A'}<br />
                    </React.Fragment>
                    }
                    {fields.period === 'Month' &&
                    <React.Fragment>
                      <strong>start month:</strong> {fields.startdate ? moment(fields.startdate).format('MMMM YYYY') : 'N/A'}<br />
                    </React.Fragment>
                    }
                    {fields.period === 'Year' &&
                    <React.Fragment>
                      <strong>start year:</strong> {fields.startdate ? moment(fields.startdate).format('YYYY') : 'N/A'}<br />
                    </React.Fragment>
                    }
                    {fields.period === 'Week' &&
                    <React.Fragment>
                      <strong>day of week: </strong> {fields.dayofweek}<br />
                    </React.Fragment>
                    }
                    {fields.period === 'Year' &&
                    <React.Fragment>
                      <strong>month:</strong> {fields.month}<br />
                    </React.Fragment>
                    }
                    {['Month', 'Year'].includes(fields.period) &&
                    <React.Fragment>
                      <strong>schedule type:</strong> {fields.dayofmonthorweekofmonthanddayofweek === 'dayofmonth' ? 'day of the month' : 'schedule by a week of the month and a day of the week'}<br />
                      {fields.dayofmonthorweekofmonthanddayofweek === 'dayofmonth' &&
                      <React.Fragment>
                        <strong>day of month:</strong> {fields.dayofmonth}<br />
                      </React.Fragment>
                      }
                      {fields.dayofmonthorweekofmonthanddayofweek === 'weekofmonthanddayofweek' &&
                      <React.Fragment>
                        <strong>week of month:</strong> {fields.weekofmonth}<br />
                        <strong>day of week:</strong> {fields.dayofweek}<br />
                      </React.Fragment>
                      }
                    </React.Fragment>
                    }
                    {fields.enddate && fields.enddate !== '2099-01-01' &&
                    <React.Fragment>
                      <strong>end date:</strong> {fields.enddate ? moment(fields.enddate).format('DD/MM/YYYY') : 'N/A'}<br />
                    </React.Fragment>
                    }
                    {recurDescription}
                  </React.Fragment>
                  }
                </CardBody>
              </Card>
              }
              {fields.templatejob === true && edit &&
              <Card className="mb-3">
                <CardBody>
                  <CardTitle tag="h5">recurrence settings</CardTitle>
                  {!fields.property &&
                  <Alert color="primary">
                    Choose a property on the left to (optionally) set up a recurring job.
                  </Alert>
                  }
                  {fields.property &&
                    <Check
                      name="recur"
                      label="recur job"
                      onChange={handleChange}
                      value={fields.recur}
                      labelWidth={0}
                    />
                  }
                  {fields.recur &&
                  <React.Fragment>
                    <SelectList
                      name="period"
                      label="period"
                      onChange={handleChange}
                      values={[
                        {id: 'Day', name: 'every day'},
                        {id: 'Week', name: 'every week'},
                        {id: 'Month', name: 'every month'},
                        {id: 'Year', name: 'every year'},
                      ]}
                      value={fields.period}
                      optionName="name"
                    />
                    <Number
                      name="frequency"
                      label="frequency"
                      onChange={handleChange}
                      value={fields.frequency}
                    />
                    {fields.period === 'Day' &&
                    <Date
                      name="startdate"
                      label="start date"
                      value={fields.startdate}
                      onChange={handleChange}
                    />
                    }
                    {fields.period === 'Week' &&
                    <SelectList
                      name="startdate"
                      label="start week"
                      onChange={handleChange}
                      values={[...Array(52).keys()].map(interval => {
                        let startWeek = moment().startOf('isoweek').add(interval, 'weeks');

                        return {
                          id: startWeek.format('YYYY-MM-DD'),
                          name: 'W/C ' + startWeek.format('DD/MM/YYYY')
                        };
                      })}
                      value={fields.startdate}
                      optionName="name"
                    />
                    }
                    {fields.period === 'Month' &&
                    <SelectList
                      name="startdate"
                      label="start month"
                      onChange={handleChange}
                      values={[...Array(24).keys()].map(interval => {
                        let startMonth = moment().startOf('month').add(interval, 'months');

                        return {
                          id: startMonth.format('YYYY-MM-DD'),
                          name: startMonth.format('MMMM YYYY')
                        };
                      })}
                      value={fields.startdate}
                      optionName="name"
                    />
                    }
                    {fields.period === 'Year' &&
                    <SelectList
                      name="startdate"
                      label="start year"
                      onChange={handleChange}
                      values={[...Array(12).keys()].map(interval => {
                        let startYear = moment().startOf('year').add(interval, 'years');

                        return {
                          id: startYear.format('YYYY-MM-DD'),
                          name: startYear.format('YYYY')
                        };
                      })}
                      value={fields.startdate}
                      optionName="name"
                    />
                    }
                    {fields.period === 'Week' &&
                    <FormGroup row>
                      <Label sm={4}>&nbsp;</Label>
                      <Col sm={8}>
                        <FormGroup row>
                          <Label sm={4}>day of the week</Label>
                          <Col sm={8}>
                            <Input
                              type="select"
                              name="dayofweek"
                              value={fields.dayofweek}
                              onChange={handleChange}
                            >
                              {daysOfWeek}
                            </Input>
                          </Col>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                    }
                    {fields.period === 'Year' &&
                    <FormGroup row>
                      <Label sm={4}>month</Label>
                      <Col sm={8}>
                        <Input
                          type="select"
                          name="month"
                          value={fields.month}
                          onChange={handleChange}
                        >
                          {months}
                        </Input>
                      </Col>
                    </FormGroup>
                    }
                    {['Month', 'Year'].includes(fields.period) &&
                    <React.Fragment>
                      <FormGroup row>
                        <Label sm={4}>schedule type</Label>
                        <Col sm={8}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="dayofmonthorweekofmonthanddayofweek"
                                value="dayofmonth"
                                onChange={handleChange}
                                checked={fields.dayofmonthorweekofmonthanddayofweek === 'dayofmonth'}
                              />{' '}
                              schedule by a day of the month
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="dayofmonthorweekofmonthanddayofweek"
                                value="weekofmonthanddayofweek"
                                onChange={handleChange}
                                checked={fields.dayofmonthorweekofmonthanddayofweek === 'weekofmonthanddayofweek'}
                              />{' '}
                              schedule by a week of the month and a day of the week
                            </Label>
                          </FormGroup>
                        </Col>
                      </FormGroup>
                      {fields.dayofmonthorweekofmonthanddayofweek === 'dayofmonth' &&
                      <FormGroup row>
                        <Label sm={4}>&nbsp;</Label>
                        <Col sm={8}>
                          <FormGroup row>
                            <Label sm={4}>day of the month</Label>
                            <Col sm={8}>
                              <Input
                                type="select"
                                name="dayofmonth"
                                value={fields.dayofmonth}
                                onChange={handleChange}>
                                {daysOfMonth}
                              </Input>
                            </Col>
                          </FormGroup>
                        </Col>
                      </FormGroup>
                      }
                      {fields.dayofmonthorweekofmonthanddayofweek === 'weekofmonthanddayofweek' &&
                      <FormGroup row>
                        <Label sm={4}>&nbsp;</Label>
                        <Col sm={8}>
                          <FormGroup row>
                            <Col sm={12}>
                              <Row>
                                <Col>
                                  week of the month<br />
                                  <Input
                                    type="select"
                                    name="weekofmonth"
                                    value={fields.weekofmonth}
                                    onChange={handleChange}
                                  >
                                    {weeksOfMonth}
                                  </Input>
                                </Col>
                                <Col>
                                  day of the week<br />
                                  <Input
                                    type="select"
                                    name="dayofweek"
                                    value={fields.dayofweek}
                                    onChange={handleChange}
                                  >
                                    {daysOfWeek}
                                  </Input>
                                </Col>
                              </Row>
                            </Col>
                          </FormGroup>
                        </Col>
                      </FormGroup>
                      }
                    </React.Fragment>
                    }
                    <Date
                      name="enddate"
                      label="end date"
                      value={fields.enddate}
                      onChange={handleChange}
                    />
                    <FormGroup row>
                      <Label sm={4}>&nbsp;</Label>
                      <Col sm={8}>
                        {recurDescription}
                      </Col>
                    </FormGroup>
                  </React.Fragment>
                  }
                </CardBody>
              </Card>
              }
              <Card className="mb-3">
                <CardBody>
                  <CardTitle tag="h5">job items</CardTitle>
                  <JobExpenses
                    workOrder={workOrder}
                    workOrderFields={fields}
                    expenses={[...fields.expenses]}
                    expenseGroups={[...fields.expensegroups]}
                    handleExpenses={handleExpenses}
                    handleGroups={handleGroups}
                    disableAll={disableAll}
                    cancelChanges={cancelChanges}
                  />
                </CardBody>
              </Card>
              {workOrder && workOrder.type === 'Instance' &&
              <Card className="mb-3">
                <CardBody>
                  <CardTitle tag="h5">associations</CardTitle>
                  <JobAssociationBlock job={workOrder} />
                </CardBody>
              </Card>
              }
              {workOrder &&
              <Card className="mb-3">
                <CardBody>
                  <CardTitle tag="h5">checklists</CardTitle>
                  <JobChecklistBlock job={workOrder} />
                </CardBody>
              </Card>
              }
              {workOrder && workOrder.type === 'Instance' &&
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Other actions</CardTitle>
                  <TemplateFromJobModal job={workOrder} />
                  {' '}
                  {isTest() &&
                  <RevisitModal job={workOrder} />
                  }
                </CardBody>
              </Card>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      {cancelTemplateModal &&
      <CancelJobTemplateModal
        template={workOrder}
        toggle={() => setCancelTemplateModal(!cancelTemplateModal)}
        templateCancelled={workOrder.status.toLowerCase() === 'cancelled'}
      />
      }
      {deleteTemplateJobsModal &&
      <DeleteTemplateJobsModal
        template={workOrder}
        toggle={() => setDeleteTemplateJobsModal(!deleteTemplateJobsModal)}
      />
      }
    </React.Fragment>
  );

}

function JobExpenses({
  workOrder,
  workOrderFields,
  expenses,
  expenseGroups,
  handleExpenses,
  handleGroups,
  disableAll,
  cancelChanges
}) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  }

  const handleExpense = (e, i) => {
    let newExpenses = [...expenses];

    if (e.new === true && !e.deleted) {
      newExpenses.push(e);
    } else {
      newExpenses[i] = e;
    }

    handleExpenses(newExpenses);
  }

  const handleGroup = (g, i) => {
    let newExpenseGroups = [...expenseGroups];

    if (g.new === true && !g.deleted) {
      newExpenseGroups.push(g);
    } else {
      newExpenseGroups[i] = g;
    }

    handleGroups(newExpenseGroups);
  }

  const getExpenseComponent = i => {
    const expense = expenses[i];
    expense.index = i;

    if (!expense.deleted) {
      const inGroup = expenseGroups.filter(
        eg => !eg.deleted
      ).map(
        eg => eg.expenses.map(e => e.id)
      ).flatten(
      ).includes(expense.id);

      return (
        <JobExpense
          key={i}
          workOrder={workOrder}
          workOrderFields={workOrderFields}
          expense={expense}
          handleExpense={e => handleExpense(e, i)}
          disableAll={disableAll}
          inGroup={inGroup}
        />
      );
    }
  }

  let expensesRows = [];

  if (expenseGroups.filter(eg => !eg.deleted).length > 0) {
    let expensesInGroups = [];

    expenseGroups.forEach((expenseGroup, i) => {
      if (expenseGroup.deleted) { return; }

      let expensesInGroup = [];

      expenseGroup.expenses.forEach(expense => {
        const index = expense.id
          ? expenses.findIndex(e => e.id === expense.id)
          : expense.index;

        expensesInGroups.push(index);
        expensesInGroup.push(expenses[index]);
      });

      expensesRows.push(
        <tr key={'g' + i} className="table-info">
          <td colSpan="5">
            <strong>group: {expenseGroup.name || (`group ${i}`)}</strong>
          </td>
          <td>
            <ChargesTooltip expenses={expensesInGroup} expenseGroup={expenseGroup} />
          </td>
          <td>
            {!disableAll &&
            <>
              <Icon
                icon="trash"
                onClick={() => {
                  if (window.confirm('Are you sure you want to remove this group?')) {
                    handleGroup({...expenseGroup, deleted: true}, i);
                  }
                }}
              />
              {' '}
            </>
            }
          </td>
        </tr>
      );

      expenseGroup.expenses.forEach(expense => {
        const index = expense.id
          ? expenses.findIndex(e => e.id === expense.id)
          : expense.index;

        expensesRows.push(getExpenseComponent(index));
      });
    });

    expensesRows.push(
      <tr key={'gBlank'}>
        <td colSpan="7">&nbsp;</td>
      </tr>
    );

    // stuff any expenses that weren't in groups on the end. TODO:
    // replace expensesInGroups with something nicer
    const remainingExpenses = [...expenses.keys()]
      .filter(
        i => !expensesInGroups.includes(i)
      ).map(
        i => getExpenseComponent(i)
      );

    expensesRows = [
      ...expensesRows,
      ...remainingExpenses
    ];
  } else {
    expensesRows = expenses.map(
      (e, i) => getExpenseComponent(i)
    );
  }

  return (
    <React.Fragment>
      <Table size="sm" striped>
        <thead>
          <tr>
            <th>description</th>
            <th>code</th>
            <th>estimate</th>
            <th>actual amount</th>
            <th>stock cost</th>
            <th>charges</th>
            <th>actions</th>
          </tr>
        </thead>
        <tbody>
          {expensesRows.length === 0 ?
          <tr>
            <td colSpan="7">There are currently no job items.</td>
          </tr>
          :
          <React.Fragment>
            {expensesRows}
          </React.Fragment>
          }
        </tbody>
      </Table>
      <Button color='secondary' size="sm" onClick={toggle} disabled={disableAll}>
        add new job item
      </Button>{' '}
      {expenses.map(e => e.selected).includes(true) && isTest() &&
      <Button
        color='secondary'
        size="sm"
        onClick={() => {

          let expensesWithIndex = expenses.map((e, i) => {
            return {...e, index: i};
          });

          let selectedExpenses = expensesWithIndex.filter(e => e.selected);

           //check there is more than one expense
           if(selectedExpenses.length == 1) {
            alert('Error: a group must contain more than one expense');
            return;
          }

          //check that the owner charge code of all selected expenses have the same VATBAND
          var vatband = selectedExpenses[0].costitemcode.ownerchargecodes[0].vatband.vatband; //first one
          var match = true;

          selectedExpenses.forEach((se) => {
            se.costitemcode.ownerchargecodes.forEach((occ) => {
              if(occ.vatband.vatband !== vatband) {
                match = false;
                return;
              }
              vatband = occ.vatband.vatband;
            });
            if(!match) {
              return;
            }
          });


          if(!match) {
            alert('Error: all selected expenses must have the same ownerchargecodes vatband');
            cancelChanges();
            return;
          }

          var name = prompt("Please enter a name for the new group", "new group");
          if (name) {

            let expenseObjs = [];

            selectedExpenses.forEach((e) => {
              e.selected = false; //deselect so it cant be saved to another group in the same action
              const obj = {};
              if (e.id) {
                obj.id = e.id;
              } else {
                obj.index = e.index;
              }

              expenseObjs.push(obj);
            });

            handleGroup({
              new: true,
              name,
              expenses: expenseObjs
            });

            selectedExpenses = null;
          }
        }

      }
        disabled={disableAll}
      >
        add new group
      </Button>
      }
      {modal &&
      <JobExpenseModal
        workOrder={workOrder}
        workOrderFields={workOrderFields}
        toggle={toggle}
        handleExpense={handleExpense}
      />
      }
    </React.Fragment>
  );

}

function JobExpense({workOrder, workOrderFields, expense, handleExpense, disableAll, inGroup}) {

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  }

  const deleteExpense = () => {
    if (window.confirm('Are you sure you want to remove this job item?')) {
      handleExpense({...expense, deleted: true, new: false});
    }
  }

  const toggleSelected = value => {
    handleExpense({...expense, selected: value, new: false});
  }

  let expenseIdentifier = expense.id ? expense.id : expense.index;
  return (
    <tr key={expenseIdentifier}>
      <td>{expense.description}</td>
      <td>{expense.costitemcode.id ? expense.costitemcode.description : <span className="text-danger font-weight-bold">N/A</span>}</td>
      <td>{parseFloat(expense.amountnetestimate) >= 0 ? expense.amountnetestimate.toFixed(2) : 'N/A'}</td>
      <td>{parseFloat(expense.amountnet) >= 0 ? expense.amountnet.toFixed(2) : 'N/A'}</td>
      <td>{parseFloat(expense.agencycostnet) >= 0 ? expense.agencycostnet.toFixed(2) : 'N/A'}</td>
      <td>
        <ChargesTooltip expense={expense} />
      </td>
      <td className="align-middle">
        <Icon icon="eye" onClick={toggle} />{' '}
        {!disableAll &&
        <><Icon icon="trash" onClick={deleteExpense} />{' '}</>
        }

        {!inGroup && expense.invoiceto === 'Owner' && !expense.costitemcode.recharge &&
        // don't group recharges
        <CustomInput
          className="d-inline"
          type="switch"
          id={"sw" + expenseIdentifier}
          label=""
          checked={expense.selected ? true : false}
          onChange={e => toggleSelected(e.target.checked)}
        />
        }
      </td>
      {modal &&
      <JobExpenseModal
        isOpen
        workOrder={workOrder}
        workOrderFields={workOrderFields}
        toggle={toggle}
        expense={expense}
        handleExpense={handleExpense}
        disableAll={disableAll}
        inGroup={inGroup}
      />
      }
    </tr>
  );
}

function JobExpenseInvoiceRow({workOrderOwnerCharge}) {

  const [invoiceModal, setInvoiceModal] = useState(false);

  const toggleInvoiceModal = () => {
    setInvoiceModal(!invoiceModal);
  }

  return (
    <tr key={workOrderOwnerCharge.id}>
      <td>{workOrderOwnerCharge.ownercharge.id}</td>
      <td>{workOrderOwnerCharge.description}</td>
      <td>{workOrderOwnerCharge.ownercharge.pmsinvoiceid}</td>
      <td>
        <Icon icon="eye" onClick={toggleInvoiceModal} />{' '}
      </td>
      {invoiceModal &&
      <InvoiceModal invoiceId={workOrderOwnerCharge.ownercharge.pmsinvoiceid} toggle={toggleInvoiceModal} />
      }
    </tr>
  );

}

function ChargesTooltip(props) {
  const [chargesTool, setChargesTool] = useState(false);

  const expenses = props.expenses || [ props.expense ];

  let allCharges = {};
  let ownerChargesTotal = 0;

  expenses.forEach(expense => {
    if (expense.deleted) { return; }

    const ownerCharges = expense.ownercharges.collection || expense.ownercharges;

    var amountNetEstimate = parseFloat(expense.agencycostnet || expense.amountnet || expense.amountnetestimate);

    ownerCharges.forEach((oc) => {
      if (!oc.deleted) {
        const code = oc.ownerchargecode.ownerchargecode;
        const amount = parseFloat(oc.amount);
        var actualAmount = 0;

        if (oc.ownerchargeamounttype) {
          switch (oc.ownerchargeamounttype.ownerchargeamounttype) {
            case 'Fixed':
              actualAmount = amount;
              break;
            case 'Percentage':
              var multiplier = amount / 100;
              actualAmount = (amountNetEstimate * multiplier);
              break;
            case 'Amount':
              actualAmount = amountNetEstimate + amount;
              break;
            default:
          }
        } else {
          actualAmount = amountNetEstimate;
        }

        ownerChargesTotal += actualAmount;

        if (!(code in allCharges)) {
          allCharges[code] = {
            code,
            total: 0,
            description: oc.description || oc.ownerchargecode.description
          }
        }

        allCharges[code].total += actualAmount;
      }
    });
  });

  let tooltipRows = [];

  Object.values(allCharges).forEach((charge, i) => {
    tooltipRows.push(
      <span className="d-block" key={i}>
        {charge.code + ' - ' + charge.description + ' @ ' + charge.total.toFixed(2)}
      </span>
    );
  });

  if (props.expenses) {
    return (
      <>
        <span className="text-primary" id={"group" + props.expenseGroup.id}>
          {ownerChargesTotal.toFixed(2)}{' '}
          <Icon icon="info-circle" />
        </span>
        <Tooltip
          placement="bottom"
          isOpen={chargesTool}
          target={"group" + props.expenseGroup.id}
          toggle={() => { setChargesTool(!chargesTool) }}
        >
          {tooltipRows}
        </Tooltip>
      </>
    )
  } else {
    const { expense } = props;

    return (
      <span id={"charges" + expense.id}>
        {expense.invoiceto === 'Agency' &&
        <span>agency</span>
        }
        {expense.invoiceto === 'None (zero cost)' &&
        <span>none</span>
        }
        {expense.invoiceto === 'Owner' &&
        <span className="text-primary">
          {ownerChargesTotal.toFixed(2)}{' '}
          <Icon icon="info-circle" />
        </span>
        }
        {expense.invoiceto === 'Owner' &&
        <Tooltip
          placement="bottom"
          isOpen={chargesTool}
          target={"charges" + expense.id}
          toggle={() => { setChargesTool(!chargesTool) }}
        >
          {tooltipRows}
        </Tooltip>
        }
      </span>
    );
  }
}
import React, { useContext, useRef, useState } from 'react';
import { 
  Alert,
  Button,
  Form,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Row, 
  Col, 
  Table } from 'reactstrap';
import Icon from './Icon';
import { addTab, toggleTab, updateJob, updateJobSubStatus, setHighlightJob } from '../Actions';
import { Store } from "../Store";
import moment from 'moment';
import { common, FilterCollection } from 'plato-js-client';
import Text from '../fields/Text';
import PrettyLoader from '../components/PrettyLoader';
import nl2br from 'react-nl2br';

export default function JobStatusHandler(props) {

  const { state, dispatch } = React.useContext(Store);

  const workOrder = props.workOrder;

  if (!workOrder.substatus || !workOrder.substatus.id) {
    // this function only deals with jobs with a substatus!
    return null;
  }

  let currentSubStatus = workOrder.substatus.workordersubstatus;
  
  // when a job is 'new' (sub-status), the state of the expenses will dictate what
  // sub-status (if any) the job can move to
  let jobNeedsFieldWorkerEstimates = false;
  let jobHasEstimates = false;
  let jobHasKnownCosts = false;
  let hasCostItemCodes = true;
  let hasJobItems = false;
  let hasSupplierPayExpenses = false;
  let paySupplier = false;

  if (workOrder 
      && workOrder.workordersupplier 
      && workOrder.workordersupplier.id
      && workOrder.workordersupplier.supplier 
      && workOrder.workordersupplier.supplier.id
      && workOrder.workordersupplier.supplier.donotpay === false
  ) {
    paySupplier = true;
  }
  
  if (workOrder.expenses && workOrder.expenses.collection.length > 0) {
    hasJobItems = true;

    workOrder.expenses.forEach(expense => {
      if (workOrder.status === 'New' && currentSubStatus.substatusreference === 'new') {
        if (expense.amountnet === null && expense.amountnetestimate === null && expense.agencycostnet === null) {
          // job item has no amount or estimate, so it needs field work estimate
          jobNeedsFieldWorkerEstimates = true;
        }
        if (expense.amountnetestimate !== null) {
          jobHasEstimates = true;
        }
        if (expense.amountnet !== null || expense.agencycostnet !== null) {
          jobHasKnownCosts = true;
        }
      }
      if (!expense.costitemcode.id) {
        hasCostItemCodes = false;
      }
      if (expense.donotpaysupplier === false) {
        hasSupplierPayExpenses = true;
      }
    });
  }

  const getPotentialStatus = ref => {
    return state.workOrderSubStatuses.find(woss => {
      return woss.substatusreference === ref;
    });
  }

  let potentialStatus;

  if (workOrder.noworkrequired === true && workOrder.status === 'New') {
    potentialStatus = getPotentialStatus('new_no_work_required');
  } else if (jobNeedsFieldWorkerEstimates) {
    potentialStatus = getPotentialStatus('new_needs_estimates');
  } else if (jobHasEstimates) {
    potentialStatus = getPotentialStatus('new_has_estimates');
  } else if (jobHasKnownCosts) {
    potentialStatus = getPotentialStatus('new_costs_known');
  }

  if (potentialStatus) {
    currentSubStatus = potentialStatus;
  }

  const currentSubStatusRef = currentSubStatus.substatusreference;

  if (!currentSubStatus) {
    return null;
  }

  const unsortedOptions = currentSubStatus.nextworkordersubstatuses.collection;
  unsortedOptions.sort((a, b) => (a.sortorder < b.sortorder) ? 1 : -1);

  const options = unsortedOptions.map(wossn => {
    return (
      <React.Fragment key={wossn.id}>
        <UpdateSubStatusModal 
          workOrder={workOrder}
          workOrderSubStatusNext={wossn}
          currentSubStatus={currentSubStatus}
        />{' '}
      </React.Fragment>
    );
  });

  if (currentSubStatusRef === 'awaitingscheduling') {
    options.push(
      <Button 
        color="primary m-1"
        onClick={() => {
          toggleTab('scheduler', dispatch);
          setHighlightJob(workOrder.id, dispatch);
        }}
        key="scheduler"
      >
        go to scheduler
      </Button>
    )
  }

  if (currentSubStatusRef === 'reviewed' && (state.userRole === 'serviceManager' || state.userRole === 'administrator')) {
    options.push(
      <Button 
        color="primary m-1" 
        onClick={() => {addTab('completeJobs', {}, dispatch)}}
        key="scheduler"
        disabled={!hasCostItemCodes}
      >
        go to financially complete jobs
      </Button>
    );
  }

  if (!['awaitingscheduling', 'reviewed'].includes(currentSubStatusRef)) {
    if (options.length === 0) {
      // if there are no 'next' options for this sub-status then there's no point in showing anything
      return null;
    }
  }

  const ExpensesBlock = () => {
    if (!currentSubStatus.requirescoordinatoraction) {
      // have nicked the unsed requirescoordinatoraction field to act as a 'show estimates block' field
      return null;
    }

    const rows = [];

    workOrder.expenses.forEach(expense => {
      if (expense.agencycostnet === null) {
        rows.push(
          <tr key={expense.id}>
            <td>{expense.description}</td>
            <td>{expense.costitemcode.id ? expense.costitemcode.description : <span className="text-danger font-weight-bold">N/A</span>}</td>
            <td>{parseFloat(expense.amountnetestimate) >= 0 ? expense.amountnetestimate.toFixed(2) : <span className={!expense.amountnet ? "text-danger font-weight-bold" : ""}>N/A</span>}</td>
            <td>{parseFloat(expense.amountnet) >= 0 ? expense.amountnet.toFixed(2) : <span>N/A</span>}</td>
          </tr>
        );
      }
    });

    return (
      <React.Fragment>
        <Table size="sm" striped>
          <thead>
            <tr>
              <th>description</th>
              <th>code</th>
              <th>estimate</th>
              <th>actual amount</th>
            </tr>
          </thead>
          <tbody>
            {rows.length === 0 &&
            <tr>
              <td colSpan={4}>
                No job items found.
              </td>
            </tr>
            }
            {rows}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }

  return (
    <Alert color={currentSubStatus.style || 'secondary'} className={props.disabled ? 'disable blur' : ''}>
      <h3>
        {['warning', 'danger'].includes(currentSubStatus.style) &&
        <React.Fragment>
          <Icon icon="exclamation-triangle" color={currentSubStatus.style} />
          {' '}
        </React.Fragment>
        }
        {['success'].includes(currentSubStatus.style) && 
        <React.Fragment>
          <Icon icon="thumbs-up" color={currentSubStatus.style} />
          {' '}
        </React.Fragment>
        }
        {currentSubStatus.substatusname}
      </h3>
      {currentSubStatus.actiontext &&
      <div className="mb-2">
        {nl2br(currentSubStatus.actiontext)}
      </div>
      }
      <ExpensesBlock />
      {!hasCostItemCodes &&
      <Alert className="mt-2" color="warning">
        <Icon icon="exclamation-triangle" color={currentSubStatus.style} />{' '}
        The field worker has added one or more expenses which will need a cost item code (etc) assigning before the job can proceed.
      </Alert>
      }
      {currentSubStatusRef === 'completed' && !hasJobItems &&
      <Alert className="mt-2" color="warning">
        This job must have one or more job items to be financially reviewed.
      </Alert>
      }
      {currentSubStatusRef === 'completed' && hasSupplierPayExpenses &&
      <Alert className="mt-2" color="warning">
        This job has more one ore more items that are set to be paid to the field worker.
      </Alert>
      }
      {currentSubStatusRef === 'completed' && !hasSupplierPayExpenses && paySupplier &&
      <Alert className="mt-2" color="warning">
        The field worker on this job is set to be paid for items where applicable.
      </Alert>
      }
      <div className="job-status-handler-container">
        {options}
      </div>
    </Alert>
  );
}

function UpdateSubStatusModal({workOrder, workOrderSubStatusNext, currentSubStatus}) {
  
  const {
    dispatch,
    state: {
      user,
      userRole,
      currentGroup
    }
  } = useContext(Store);

  const setInitialAssignees = () => {
    let assignees = {};
    if (workOrderSubStatusNext.nextworkordersubstatus.id) {
      workOrderSubStatusNext.nextworkordersubstatus.templates.forEach(template => {
        const assignee = template.assignee.assignee;
        assignees = {...assignees, [assignee]: true};
      });      
    }
    return assignees;
  }

  const [modal, setModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fields, setFields] = useState({
    note: '',
    otherPerson: undefined,
    assignees: setInitialAssignees()
  });

  const convertToSupplierJob = workOrderSubStatusNext.description === 'convert to supplier portal job';

  const buildConfig = () => {
    const nextSubStatus = workOrderSubStatusNext.nextworkordersubstatus.id ? 
      workOrderSubStatusNext.nextworkordersubstatus.substatusreference : 
      undefined;

    let obj = {
      assignToOwner: false,
      chooseOtherPerson: false,
      otherPersonLabel: '',
      disableButton: false,
      hideButton: false,
    };

    // ascertain whether the new sub-status needs assigning to anyone 
    if (workOrderSubStatusNext.nextworkordersubstatus.assigntoactortype) {
      if (workOrderSubStatusNext.nextworkordersubstatus.assigntoactortype === 'owner') {
        obj.assignToOwner = true;
      } else {
        const type = workOrderSubStatusNext.nextworkordersubstatus.assigntoactortype;

        const convert = {
          fieldWorker: 'field worker',
          serviceCoordinator: 'service coordinator',
          serviceManager: 'service manager',
        }

        obj.chooseOtherPerson = type;
        obj.otherPersonLabel = convert[type];
      }
    }

    // possibly disable the action button if criteria for the next status aren't fulfilled
    if (currentSubStatus.substatusreference === 'field_worker_to_provide_estimates') {
      // only let user proceed if all job items have estimates
      let hasAllEstimates = true;
      workOrder.expenses.forEach(expense => {
        if (expense.amountnetestimate === null && expense.agencycostnet === null && expense.amountnet === null) {
          hasAllEstimates = false;
        }
      });
      obj.disableButton = !hasAllEstimates;
    }

    let hasCostItemCodes = true;
    let hasJobItems = false;
    let hasSupplierPayExpenses = false;

    workOrder.expenses.forEach(expense => {
      hasJobItems = true;
      if (!expense.costitemcode.id) {
        hasCostItemCodes = false;
      }
      if (expense.donotpaysupplier === false) {
        hasSupplierPayExpenses = true;
      }
    });

    obj.disableButton = !hasCostItemCodes;

    if (currentSubStatus.substatusreference === 'completed' && !hasJobItems) {
      // don't allow job to be financially reviewed if there are no job items
      obj.disableButton = true;
    }

    if (currentSubStatus.substatusreference === 'completed') {
      if (hasSupplierPayExpenses && nextSubStatus === 'reviewed') {
        // hide button to review job if there are job items to be paid to field worker
        obj.hideButton = true;
      }
      if (!hasSupplierPayExpenses && nextSubStatus === 'awaiting_invoice') {
        // hide button to set to 'awaiting invoice' if there are no items to be paid to the supplier
        obj.hideButton = true;
      }
      // todo: consider jobs with no job items but a field worker with donotpay = false
    }

    if (currentSubStatus.substatusreference === 'reviewed' && !['serviceManager', 'administrator'].includes(userRole)) {
      if (workOrderSubStatusNext.nextworkordersubstatus.id && 
          workOrderSubStatusNext.nextworkordersubstatus.substatusreference === 'cancelled') {

        // don't allow non-service managers/adminstrators to cancel job when it's reviewed
        obj.disableButton = true;
      }
    }

    return obj;
  };

  const config = useRef(buildConfig());

  const toggle = () => {
    setModal(!modal);
  }

  const update = async () => {
    setSaving(true);

    const subStatus = workOrderSubStatusNext.nextworkordersubstatus.id ? 
      workOrderSubStatusNext.nextworkordersubstatus.substatusreference : 
      undefined;

    // status specific updates go here
    if (subStatus === 'awaitingscheduling') {
      if (workOrder.status === 'Completed') {
        // job is already completed, so get the job back to 'Started'
        // (note you have to do this in two separate calls otherwise the API says F U)
        await updateJob(workOrder.id, {completeddate: 'null'}, dispatch);
        await updateJob(workOrder.id, {starteddate: 'null'}, dispatch);
      }

      if (workOrder.status === 'Started') {
        // job is already started, so go back to 'New' in the workflow
        await updateJob(workOrder.id, {starteddate: 'null'}, dispatch);
      }

      await updateJob(workOrder.id, {supplier: {id: currentGroup.holdingSupplier}}, dispatch);
    }
    if (subStatus === 'started') {
      await updateJob(workOrder.id, {starteddate: moment().format('YYYY-MM-DD')}, dispatch);
    }
    if (subStatus === 'completed') {
      await updateJob(workOrder.id, {completeddate: moment().format('YYYY-MM-DD')}, dispatch);
    }
    if (subStatus === 'cancelled') {
      await updateJob(workOrder.id, {cancelleddatetime: moment().format('YYYY-MM-DD HH:mm:ss')}, dispatch);
    }
    if (convertToSupplierJob) {
      await updateJob(workOrder.id, {
        approveddate: moment().format('YYYY-MM-DD'),
        workordersourceid: {id: 2}
      }, dispatch);
    }
    if (workOrder.noworkrequired === true && subStatus === 'completed') {
      await updateJob(workOrder.id, {starteddate: moment().format('YYYY-MM-DD')}, dispatch);
      await updateJob(workOrder.id, {completeddate: moment().format('YYYY-MM-DD')}, dispatch);
    }

    let person = undefined;
    if (assignToOwner) {
      workOrder.property.owners.fetch().then(pos => {
        pos.forEach(po => {
          if (moment().isBetween(po.ownerfromdate, po.ownertodate, 'day', '[]')) {
            person = po.owner;
          }
        })
      });
    }
    if (chooseOtherPerson) {
      person = fields.otherPerson;
    }

    let workOrderNote = undefined;
    if (fields.note.length > 0) {
      var note = new common.Note();
      note.notetype = {notetype: 'Normal'};
      note.subject = 'new status: ' + workOrderSubStatusNext.nextworkordersubstatus.substatusname;
      note.createdby = {id: user.id};
      note.notetext_createdbyactorid = user.id;
      note.notetext_notetext = fields.note;
      note.notetext_followupdatetime = undefined;
      note.notetext_actionedbyactorid = undefined;
      note.notetext_actioneddatetime = undefined;
      note.bookingid = undefined;

      await note.create();

      var noteActor = new common.NoteActor();
      noteActor.parent = note;
      noteActor.actorid = user.id;
      noteActor.notifychanges = false;
      noteActor.reminderdate = undefined;

      await noteActor.create();

      workOrderNote = new common.WorkOrderNote();
      workOrderNote.workorder = workOrder;
      workOrderNote.note = note;

      await workOrderNote.create();
    }

    // communications
    let assignees = [];
    for (const assignee in fields.assignees) {
      if (fields.assignees[assignee]) {
        assignees.push(assignee);
      }
    }    

    if (subStatus) {
      await updateJobSubStatus(workOrder.id, subStatus, dispatch, person, workOrderNote, assignees);
    }
    
    setSaving(false);
    toggle();
  }

  const { assignToOwner, chooseOtherPerson, otherPersonLabel, disableButton, hideButton } = config.current;

  let disableSubmit = false;
  if (chooseOtherPerson && !fields.otherPerson) {
    disableSubmit = true;
  } else if (workOrderSubStatusNext.nextworkordersubstatus.notemandatory && !fields.note) {
    disableSubmit = true;
  }

  let style = {};
  if (hideButton) { style.display = "none"; }

  return (
    <React.Fragment>
      <Button 
        disabled={disableButton} 
        color={workOrderSubStatusNext.style || 'secondary'} 
        onClick={toggle}
        className="m-1"
        style={style}
      >
        {workOrderSubStatusNext.description}
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>{workOrderSubStatusNext.description}</ModalHeader>
        <ModalBody>
          {chooseOtherPerson &&
          <FormGroup row>
            <Label sm={4}>{otherPersonLabel}</Label>
            <Col sm={8}>
              {fields.otherPerson && fields.otherPerson.title + ' ' + fields.otherPerson.firstname + ' ' + fields.otherPerson.surname + ', or '}
              <ChooseUserModal 
                type={chooseOtherPerson} 
                onChoose={person => setFields({...fields, otherPerson: person})} 
              />
            </Col>
          </FormGroup>
          }
          <Text
            label="note"
            labelSmall={workOrderSubStatusNext.nextworkordersubstatus.id && workOrderSubStatusNext.nextworkordersubstatus.notemandatory ? "(mandatory)" : ""}
            value={fields.note}
            onChange={e => setFields({...fields, note: e.target.value})}
            large
          />
          {workOrderSubStatusNext.nextworkordersubstatus.id && workOrderSubStatusNext.nextworkordersubstatus.templates.collection.length > 0 &&
          <FormGroup row>
            <Label sm={4}>send communications</Label>
            <Col sm={8}>
              {workOrderSubStatusNext.nextworkordersubstatus.templates.map(template => 
              <FormGroup key={template.id} check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={fields && fields.assignees[template.assignee.assignee]}
                    onChange={e => setFields(
                      {...fields, assignees: {...fields.assignees, [template.assignee.assignee]: e.target.checked}}
                    )}
                  />{' '}
                  {template.assignee.assigneefriendlyname}
                </Label>
              </FormGroup>
              )}
            </Col>
          </FormGroup>          
          }
          {convertToSupplierJob &&
          <Alert color="warning">
            Note that once a PMS job is converted to a cleaner job, the rest of the job's workflow will occur outside of the PMS system.
          </Alert>
          }
        </ModalBody>
        <ModalFooter>
          {saving &&
          <PrettyLoader size={38} />
          }
          <Button
            color="primary" 
            onClick={update}
            disabled={saving || disableSubmit}
          >
            {workOrderSubStatusNext.description}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

function ChooseUserModal(props) {

  const [modal, setModal] = useState(false);
  const [chosen, setChosen] = useState(undefined);

  const {
    state: {
      fieldWorkers,
      serviceCoordinators,
      serviceManagers,
    }
  } = useContext(Store);

  const toggle = () => {
    setModal(!modal);
  }

  let users = undefined;
  let typeFriendly = '';

  switch (props.type) {
    case 'fieldWorker':
      users = fieldWorkers;
      typeFriendly = 'field worker';
      break;
    case 'serviceCoordinator':
      users = serviceCoordinators;
      typeFriendly = 'service coordinator';
      break;
    case 'serviceManager':
      users = serviceManagers;
      typeFriendly = 'service manager';
      break;
    default:
  }

  const UserBlock = () => {
    return (
      <ListGroup>
      {users.map(user => {

        const name = ' - ' + user.title + ' ' + user.firstname + ' ' + user.surname;

        return (
          <ListGroupItem
            tag="button"
            action
            active={chosen && chosen.id === user.id}
            key={user.id}
            onClick={() => {setChosen(user)}}
          >
            {user.id}{name}
          </ListGroupItem>
        );
      })}
      </ListGroup>      
    );

  }

  const chooseUser = () => {
    props.onChoose(chosen);
    toggle();
  }

  return (
    <React.Fragment>
      <Button onClick={toggle} color={'secondary'}>
        choose {typeFriendly}
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>choose {typeFriendly}</ModalHeader>
        <ModalBody>
          <UserBlock />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={chooseUser}>go</Button>
          <Button color="secondary" onClick={toggle}>cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
import React, { useContext, useEffect, useState } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import {
  Alert,
  Button,
  CustomInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table } from 'reactstrap';
import Icon from './Icon';
import { addTab, updateJob } from '../Actions';
import { Store } from "../Store";
import moment from 'moment';
import { successToast } from './Toasties';

export default function CancelJobTemplateModal(props) {

  const {
    dispatch
  } = useContext(Store);

  const [jobs, setJobs] = useState();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggleCancelBtn, setToggleCancelBtn] = useState(true);
  const [cancelledRows, setCancelledRows] = useState([]);

  useEffect(() => {

    const getJobs = async () => {
      setLoading(true);
      setToggleCancelBtn(false);

      let jobs = new FilterCollection({
        path: 'workorder',
        object: common.WorkOrder,
      });

      jobs.limit = 999;
      jobs.page = 1;
      jobs.fields = 'id:preferredstartdatetime:status';
      jobs.addFilters({
        type: 'Instance',
        templateid: props.template.id,
        status: 'New|Started|Completed'
      });

      await jobs.fetch();

      setJobs(jobs.collection);
      setLoading(false);
      setToggleCancelBtn(true);
    }

    getJobs();

  }, []);

  const toggleSelected = (jobId, checked) => {
    let s = [...selected];

    if (checked) {
      s.push(jobId);
    } else {
      s.splice(s.indexOf(jobId), 1);
    }

    setSelected(s);
  };

  const selectAll = () => {
    setSelected(jobs.map(j => j.id));
  };

  const deselectAll = () => {
    setSelected([]);
  };

  const cancelJobsAndTemplate = async () => {
    if (window.confirm('Do you want to proceed?')) {
      //disable the cancel button
      setToggleCancelBtn(false);

      //cancel the workorders one by one
      for (const id of selected) {
        //'disable' the row to say that one is done
        cancelledRows.push(id);
        setCancelledRows(cancelledRows);
        await updateJob(
          id, { cancelleddatetime: moment().format('YYYY-MM-DD HH:mm:ss') }, dispatch
        );
      }

      if (!props.templateCancelled) {
        await updateJob(
          props.template.id, {cancelleddatetime: moment().format('YYYY-MM-DD HH:mm:ss')}, dispatch
        );
      }

      successToast('Done!');
      props.toggle();
    }
  }

  return (
    <Modal isOpen toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>cancel job template</ModalHeader>
      <ModalBody>
        {props.templateCancelled ?
        <Alert color="info">
          This template has already been cancelled, but you may still cancel outstanding jobs deriving from it.
        </Alert>
        :
        <p>
          Any outstanding jobs deriving from this template can now be cancelled below.
        </p>
        }
        <div className="mb-2 clearfix">
          <Button onClick={deselectAll} className="float-right ml-2" disabled={selected.length === 0}>deselect all</Button>
          <Button onClick={selectAll} className="float-right" disabled={!jobs || jobs.length === 0}>select all</Button>
        </div>
        <Table size="sm" className="">
          <thead>
            <tr>
              <th>id</th>
              <th>start</th>
              <th>status</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {!(jobs && jobs.length > 0) &&
            <tr>
              {loading ?
              <td colSpan="4">Loading...</td>
              :
              <td colSpan="4">There are no outstanding jobs to cancel.</td>
              }
            </tr>
            }
            {jobs && jobs.length > 0 && jobs.map(job => {
              return (
                //change the colour of the row once processed
                <tr key={job.id} style={cancelledRows.indexOf(job.id) !== -1
                    ? { background: 'grey' }
                    : {  }
                }>
                  <td>{job.id}</td>
                  <td>{moment(job.preferredstartdatetime).format('DD/MM/YY HH:mm')}</td>
                  <td>{job.status}</td>
                  <td className="align-middle text-center">
                    <Icon
                      icon="pencil-alt"
                      onClick={() => {
                        addTab('editJob', {workOrderId: job.id}, dispatch);
                        props.toggle();
                      }}
                    />{' '}
                    <CustomInput
                      className="d-inline"
                      type="switch"
                      id={"sw" + job.id}
                      checked={selected.includes(job.id)}
                      onChange={e => toggleSelected(job.id, e.target.checked)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        {selected.length > 0 ?
        <Button
          color="secondary"
          onClick={cancelJobsAndTemplate}
          disabled={!toggleCancelBtn}
        >
          cancel {!props.templateCancelled ? 'template and' : ''} {selected.length} job(s)
        </Button>
        :
        <Button
          color="secondary"
          onClick={cancelJobsAndTemplate}
          disabled={!toggleCancelBtn || props.templateCancelled}
        >
          cancel template
        </Button>
        }
      </ModalFooter>
    </Modal>
  );
}

import React, { useState, useEffect } from 'react';
import { common, Collection, FilterCollection } from 'plato-js-client';
import { 
  Button,
  Card, 
  CardBody,
  CardTitle,
  Col, 
  Nav, 
  NavItem, 
  NavLink, 
  Row,
  Table,
  TabContent,
  TabPane } from 'reactstrap';
import TabsButton from './components/TabsButton';
import FilteredJobs from './components/FilteredJobs';
import GoogleMap from './components/GoogleMap';
import Notes from './components/Notes';
import JobTemplatesModal from './components/JobTemplatesModal';
import SanitizedHTML from 'react-sanitized-html';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Icon from './components/Icon';
import AvailabilityMonth from './components/calendar/AvailabilityMonth';

const moment = extendMoment(Moment);


export default function TabProperty(props) {

  const [activeTab, setActiveTab] = useState("jobs");
  const [property, setProperty] = useState();
  const [owner, setOwner] = useState();
  const [instructions, setInstructions] = useState();
  const [answers, setAnswers] = useState();
  const [jobTemplatesModal, setJobTemplatesModal] = useState(false);

  useEffect(() => {
    const loadProperty = () => {
      let property = new common.Property(props.propertyId);
      property.get().then(() => {
        setProperty(property);
  
        property.owners.fetch().then(() => {
          property.owners.forEach(propertyOwner => {
            if (moment().isBetween(propertyOwner.ownerfromdate, propertyOwner.ownertodate, 'day', '[]')) {
              propertyOwner.owner.get().then((owner) => {
                setOwner(owner);
              });
            }
          });
        });
  
        let propertyBookingBrand = property.primarypropertybranding.bookingbrand;
  
        var instructions = new Collection({
          path: 'instruction',
          object: common.PropertyBookingBrandInstruction,
          parent: propertyBookingBrand,
        });
  
        instructions.fetch().then(() => {
          setInstructions(instructions);
        });
  
        var answers = new Collection({
          path: 'answer',
          object: common.PropertyAnswer,
          parent: property
        });
  
        answers.fetch().then(() => {
          setAnswers(answers);
        });
      });
    }

    loadProperty();
  }, [props.propertyId]);
  
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }  

  if (!property || !owner || !instructions || !answers) {
    return <span>loading...</span>
  }

  const address = [
    property.address.line1,
    property.address.town,
    property.address.county,
    property.address.postcode,
  ].filter(Boolean).join(', ');

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col><h3>{property.name}</h3></Col>
      </Row>        
      <Row>
        <Col md="8">
          <Card className="mb-3">
            <CardBody>
              <Row className="mb-1 text-center">
                <Col><strong>id</strong><br />{property.id}</Col>
                <Col><strong>ref</strong><br />{property.tabspropref}</Col>
                <Col>
                  <strong>owner</strong><br />
                  {owner &&
                  [owner.firstname, owner.surname].join(' ')
                  }
                </Col>
                <Col><strong>actions</strong><br /><TabsButton route={"property/" + property.id} /></Col>
              </Row>
              <Row>
                <Col><strong>address:</strong>  {address}</Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Nav className="mb-2" tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === 'jobs' ? 'active' : ''}
                    onClick={() => { toggleTab('jobs'); }}
                  >
                    jobs
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'bookings' ? 'active' : ''}
                    onClick={() => { toggleTab('bookings'); }}
                  >
                    bookings
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'notes' ? 'active' : ''}
                    onClick={() => { toggleTab('notes'); }}
                  >
                    notes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'instructions' ? 'active' : ''}
                    onClick={() => { toggleTab('instructions'); }}
                  >
                    instructions
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === 'answers' ? 'active' : ''}
                    onClick={() => { toggleTab('answers'); }}
                  >
                    contract
                  </NavLink>
                </NavItem>                        
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="jobs">
                  <FilteredJobs propertyId={property.id} />    
                </TabPane>
                <TabPane tabId="bookings">
                  <Bookings 
                    property={property}
                  />
                </TabPane>
                <TabPane tabId="notes">
                  <Notes parent={property} mode="property" />
                </TabPane>
                <TabPane tabId="instructions">
                  <PropertyInstructions instructions={instructions.collection} />
                </TabPane>
                <TabPane tabId="answers">
                  <PropertyAnswers answers={answers.collection} />
                </TabPane>     
              </TabContent>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="mb-3">
            {parseFloat(property.address.latitude) !== 0 && parseFloat(property.address.longitude) !== 0 &&
            <GoogleMap 
              latitude={parseFloat(property.address.latitude)} 
              longitude={parseFloat(property.address.longitude)} 
            />
            }
          </Card>
          <Card>
            <CardBody>
              <CardTitle tag="h5">Other actions</CardTitle>
              <Button onClick={() => setJobTemplatesModal(true)} block>
                job templates
              </Button>
              {jobTemplatesModal &&
              <JobTemplatesModal 
                toggle={() => setJobTemplatesModal(!jobTemplatesModal)} 
                propertyid={property.id}
              />
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );

}

function PropertyInstructions(props) {
  let instructions = props.instructions;

  let rows = instructions.map(instruction => {
    const content = instruction.html ? <SanitizedHTML html={ instruction.instructiontext } /> : instruction.instructiontext;

    return (
      <Card key={instruction.id}>
        <CardBody>
          <CardTitle tag="h5">{instruction.instructiontype.instructiontype}</CardTitle>
          {content}
        </CardBody>
      </Card>
    )
  });

  return rows;
}

function PropertyAnswers({answers}) {
  
  let rows = [];

  const downloadDocument = (doc) => {
    doc.file.get().then((res) => {
      var link = document.createElement('a');
      link.setAttribute('href', res.url);
      link.setAttribute('download', doc.filename);
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }  

  answers.forEach(answer => {
    if (!answer.question.propertyquestioncategory.questioncategory.toLowerCase().startsWith('pms')) {
      return;
    }

    rows.push(
      <tr key={answer.id}>
        <td className="align-middle">{answer.question.question}</td>
        <td className="align-middle">
          <span>
            {answer.question.booleananswerrequired &&
            <React.Fragment>
              <Icon icon={answer.booleananswer ? 'check' : 'times'} size="lg" />{' '}
            </React.Fragment>
            }
            {answer.textanswer}
          </span>
        </td>
        <td className="align-middle">
          {answer.documents.map(pad => 
            <React.Fragment key={pad.id}>
              {pad.propertydocument.document.filename}{' '}
              <Icon icon="eye" onClick={() => {downloadDocument(pad.propertydocument.document)}} />
              <br />
            </React.Fragment>  
          )}
        </td>
      </tr>
    );
  });

  return (
    <Table size="sm" striped>
      <thead>
        <tr>
          <th>question</th>
          <th>answer</th>
          <th>documents</th>
        </tr>
      </thead>
      <tbody>
        {rows.length === 0 &&
        <tr>
          <td colSpan={3}>
            No contract info found.
          </td>
        </tr>
        }
        {rows}
      </tbody>
    </Table>
  );

}

function Bookings({property}) {

  const [year, setYear] = useState(moment().startOf('year'));
  const [bookings, setBookings] = useState();
  const [warnings, setWarnings] = useState();
  const [calendar, setCalendar] = useState();
  
  useEffect(() => {

    var bookings = new FilterCollection({
      path: 'booking',
      object: common.Booking
    });

    bookings.addFilters([{
      propertyid: property.id,
      affectsavailability: 1,
      cancelledbooking: 0,
      transferredbooking: 0
    }]);

    bookings.limit = 9999;
    bookings.fields = 'id:bookref:guid:fromdate:todate:guesttype:status:actorname';

    bookings.fetch().then(() => {
      setBookings(bookings.collection);
    });

    let warnings = new FilterCollection({
      path: 'note',
      object: common.Note,
      parent: property
    });

    warnings.addFilters([{
      archivedby: null,
      notetype: 'Warning'
    }]);

    warnings.limit = 10;
    warnings.page = 1;

    warnings.fetch().then(
      () => setWarnings(warnings)
    );

  }, []);

  const mergeCalendars = (arr1, arr2, isBooking) => {
    for (let arr2Obj of arr2) {
      var arr1Index = arr1.findIndex(function(arr1Obj) {
        return arr1Obj.date === arr2Obj.date;
      });

      if (arr1Index > -1) {
        if (isBooking && arr1[arr1Index].hasOwnProperty('bookings')) {
          arr1[arr1Index].bookings = arr1[arr1Index].bookings.concat(arr2Obj.bookings).sort(
            (a, b) => a.bookingId > b.bookingId ? 1 : -1
          );
        } else {
          arr1[arr1Index] = {...arr1[arr1Index], ...arr2Obj};
        }
      }
    }
  };

  useEffect(() => {

    if (!bookings || !warnings) {
      return;
    }

    var propertyBookingCalendar = [];
    bookings.forEach(function(booking) {
      var bookingStatus = booking.getStatus();

      if (
        !(
          bookingStatus.showAs === 'potential' &&
          booking.potentialbooking?.expired
        ) &&
        !(
          bookingStatus.showAs === 'cancelled' &&
          booking.status !== 'Confirmed Fully Paid - Cancelled'
        )
      ) {
        var range = moment.range(
          moment(booking.fromdate),
          moment(booking.todate),
        );

        for (let _moment of range.by('days')) {
          var keyDate = _moment.format('YYYY-MM-DD');
          var bookingObj = { date: keyDate, bookings: [] };
          var item = booking.getStatus();
          item.bookingID = booking.id;
          item.bookingRef = booking.bookref;
          item.startBool = _moment.format('YYYY-MM-DD') == booking.fromdate;
          item.endBool = _moment.format('YYYY-MM-DD') == booking.todate;
          item.fromDate = booking.fromdate;
          item.toDate = booking.todate;
          item.bookingCustomer = booking.actorname

          bookingObj.bookings.push(item);
          bookingObj.bookings.sort((a, b) =>
            a.bookingId > b.bookingId ? 1 : -1,
          );

          propertyBookingCalendar.push(bookingObj);
        }
      }
    });

    var range = moment.range(year.clone().add(-3, 'M'), moment(year).endOf('year').add(3, 'M'));

    var propertyAvailabilityCalendar = [];

    for (let _moment of range.by('days')) {
      propertyAvailabilityCalendar.push ({ date: _moment.format('YYYY-MM-DD') });
    }

    mergeCalendars(propertyAvailabilityCalendar, propertyBookingCalendar, true);

    var months = [];

    for (let m of range.by('months')) {
      months.push(
        <AvailabilityMonth
          key={m.format('YYYY-MM')}
          property={property}
          propertyWarnings={warnings}
          branding={property.primarypropertybranding}
          month={moment(m)}
          availability={propertyAvailabilityCalendar}
          // propertyBrandingSOCalendar={ this.state.propertyBrandingSOCalendar }
          // transferFrom={ transferFromBooking }
          // copyFrom={ copyFromBooking }
        />
      );
    }

    setCalendar(months);
  }, [bookings, warnings]);

  if (!calendar) {
    return null;
  }

  return (
    <React.Fragment>{calendar}</React.Fragment>
  );
}
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import {
  Button,  
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  Row, 
  TabContent,
  TabPane 
} from 'reactstrap';
import { Store } from './Store';
import moment from 'moment';
import GoogleMap from './components/GoogleMap';
import InitialsCircle from './components/InitialsCircle';

var classnames = require('classnames');

export default function TabFieldWorkerTracker() {

  const { 
    state: {
      fieldWorkers,
    }
  } = useContext(Store);  

  const [activeTab, setActiveTab] = useState('last location');
  const [actorLocations, setActorLocations] = useState();

  const getData = useCallback(async () => {

    let locations = new FilterCollection({
      path: 'actorlocation',
      object: common.ActorLocation,
    });
    locations.addFilters([{
      lastlocationonly: true
    }]);
    locations.limit = 999;
  
    await locations.fetch();

    let actors = {};

    locations.forEach(location => {
      const find = fieldWorkers.find(fw => fw.id === parseInt(location.actorid, 10));

      if (find) {
        actors[location.actorid] = {...location, actor: find};
      }
    });

    setActorLocations(Object.values(actors));

  }, []);

  useEffect(() => {
    getData();
  }, []);

  if (!actorLocations) {
    return null;
  }

  if (actorLocations.length === 0) {
    return (
      <React.Fragment>
        <h3>field worker tracker</h3>
        <Card>
          <CardBody>
            <h4>No tracking data found for any field workers.</h4>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <h3>field worker tracker</h3>
      <Card>
        <CardBody>
          <Nav className="mb-2" tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'last location' })}
                onClick={() => setActiveTab('last location')}
              >
                last location
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'by day' })}
                onClick={() => setActiveTab('by day')}
              >
                by day
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="last location">
              <TabFieldWorkerTrackerLastLocation actorLocations={actorLocations} />
            </TabPane>
            <TabPane tabId="by day">
              <TabFieldWorkerTrackerByDay actorLocations={actorLocations} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

function TabFieldWorkerTrackerLastLocation({actorLocations}) {

  let markers = [];
  Object.values(actorLocations).map(al => {
    if (al.actor) {
      markers.push({
        title: al.actor.firstname + ' ' + al.actor.surname,
        latitude: al.latitude,
        longitude: al.longitude,
        textInCircle: al.actor.firstname.substring(0, 1) + al.actor.surname.substring(0, 1),
        control: (
          <Row className="align-items-center">
            <Col>
              {[al.actor.title, al.actor.firstname, al.actor.surname].join(' ')}<br />
              <small>{al.actor.id}</small><br />
              <strong>last tracked:</strong> {moment(al.loggeddatetime).format('DD/MM/YY HH:mm')}
            </Col>
            <Col md="auto">
              <InitialsCircle 
                size={40}
                id={'popover' + al.actor.id} 
                firstname={al.actor.firstname} 
                surname={al.actor.surname} 
              />
            </Col>
          </Row>
        )
      });
    }
  });

  return (
    <GoogleMap markers={markers} />           
  );

}

function TabFieldWorkerTrackerByDay({actorLocations}) {

  const [fieldWorker, setFieldWorker] = useState();
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [markers, setMarkers] = useState();

  useEffect(() => {
    if (!(fieldWorker && fromDate && toDate)) {
      return;
    }

    setMarkers();

    let locations = new FilterCollection({
      path: 'actorlocation',
      object: common.ActorLocation,
    });
    locations.addFilters([{
      actorid: fieldWorker.id,
      loggeddatetime: [
        fromDate + 'T00.00.00',
        toDate + 'T23.59.59'
      ].join('/')
    }]);
    locations.limit = 999;
  
    locations.fetch().then(() => {
      setMarkers(locations.map(location => {
        return {
          title: moment(location.loggeddatetime).format('DD/MM/YY HH:mm'),
          latitude: location.latitude,
          longitude: location.longitude,
        };
      }));
    });
  }, [fieldWorker, fromDate, toDate]);

  return (
    <Row>
      <Col sm="auto">
        <ListGroup>
          {actorLocations.map((al, i) => 
          <ListGroupItem
            key={i}
            action
            active={fieldWorker && fieldWorker.id === al.actor.id}
            className="cursorPointer"
            onClick={() => setFieldWorker(al.actor)}
          >
            <Row className="align-items-center">
              <Col>
                {[al.actor.title, al.actor.firstname, al.actor.surname].join(' ')}<br />
                <small>{al.actor.id}</small><br />
                <strong>last tracked:</strong> {moment(al.loggeddatetime).format('DD/MM/YY HH:mm')}
              </Col>
              <Col md="auto">
                <InitialsCircle 
                  size={40}
                  firstname={al.actor.firstname} 
                  surname={al.actor.surname} 
                />
              </Col>
            </Row>
          </ListGroupItem>
          )}
        </ListGroup>
      </Col>
      <Col>
        <Form inline className="mb-2">
          <FormGroup className="mr-sm-2 mb-sm-0">
            <Label className="mr-sm-2">from</Label>
            <Input type="date" value={fromDate} onChange={e => setFromDate(e.target.value)} />
          </FormGroup>
          <FormGroup className="mr-sm-2 mb-sm-0">
            <Label className="mr-sm-2">to</Label>
            <Input type="date" value={toDate} onChange={e => setToDate(e.target.value)} />
          </FormGroup>
        </Form>
        {markers && markers.length > 0 &&
        <GoogleMap markers={markers} />
        }
      </Col>
    </Row>
  );

}
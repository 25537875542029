import React, { useEffect, useState } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import { 
  Button,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row } from 'reactstrap';

export default function SelectPersonModal(props) {

  const [serviceTags, setServiceTags] = useState();
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState();

  useEffect(() => {
    const getServiceTags = () => {
      let st = new FilterCollection({
        path: 'servicetag',
        object: common.ServiceTag,
      });
      st.limit = 999;
      st.page = 1;
      // jobs.orderBy = state.orderBy; TODO

      st.fetch().then(() => {
        setServiceTags(st);
      });
    }
  
    getServiceTags();
  }, []);

  const toggleSelected = st => {
    const newSelected = [...selected];

    if (newSelected.map(ns => ns.id).includes(st.id)) {
      newSelected.splice(
        newSelected.findIndex(ns => ns.id === st.id), 
        1
      ); 
    } else {
      newSelected.push(st);
    }

    setSelected(newSelected);
  }

  if (!serviceTags) {
    return null;
  }

  return (
    <React.Fragment>
      <Modal isOpen={true} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>
          choose one or more service tags
        </ModalHeader>
        <ModalBody>
          Search:<br />
          <Input 
            type="text" 
            value={search} 
            onChange={e => setSearch(e.target.value)} 
            className="mb-3" 
          />
          <ListGroup>
            {serviceTags.filter(st => {
              if (props.excludeIds && props.excludeIds.length > 0 && props.excludeIds.includes(st.id)) { 
                return false;
              }
              if (!search) { return true; }
              return st.name.toLowerCase().includes(search.toLowerCase());
            }).sort(
              (a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1
            ).map(st => 
            <ListGroupItem 
              key={st.id} 
              action 
              active={selected.map(s => s.id).includes(st.id)} 
              onClick={() => toggleSelected(st)}
              className="very-skinny"
            >
              <Row>
                <Col>
                  {st.name}
                </Col>
              </Row>
            </ListGroupItem>
            )}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <span className="mr-3">{selected.length} service tags selected</span>
          <Button 
            color="primary" 
            disabled={selected.length === 0} 
            onClick={() => { 
              props.onSubmit(selected); 
              props.toggle(); 
            }}
          >
            go
          </Button>
          <Button color="secondary" onClick={props.toggle}>cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

import React, { useContext, useEffect, useReducer, useState } from 'react';
import { common, Collection, FilterCollection } from 'plato-js-client';
import { 
  Button,
  ButtonDropdown,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Table } from 'reactstrap';
import { Store } from './Store';
import Icon from './components/Icon';
import PrettyLoader from './components/PrettyLoader';
import { successToast, infoToast } from './components/Toasties';
import moment from 'moment';
import TabsButton from './components/TabsButton';

export default function TabInvoiceJobs() {

  const [activeTab, setActiveTab] = useState('invoice jobs');

  return (
    <Row className="">
      <Col>
        <h3>
          Owner invoices
        </h3>
        <Card>
          <CardBody>
            <Nav className="mb-2" tabs>
              <NavItem>
                <NavLink
                  className={activeTab === 'invoice jobs' ? 'active' : ''}
                  onClick={() => { setActiveTab('invoice jobs'); }}
                >
                  invoice jobs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === 'send invoices' ? 'active' : ''}
                  onClick={() => { setActiveTab('send invoices'); }}
                >
                  review sent invoices
                </NavLink>
              </NavItem>             
            </Nav>
            <TabContent className="mb-3 mt-3" activeTab={activeTab}>
              <TabPane tabId="invoice jobs">
                <InvoiceJobsPane />    
              </TabPane>   
              <TabPane tabId="send invoices">
                <SendInvoicesPane />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Row>    
  );

}

function InvoiceJobsPane() {

  const {
    state: {
      properties,
      user,
      currentGroup,
    }
  } = useContext(Store);

  const [ownersNoAddress, setOwnersNoAddress] = useState();
  const [ownersNoEmail, setOwnersNoEmail] = useState();  
  const [ownersNoContactPrefs, setOwnersNoContactPrefs] = useState([]);
  const [toggleOwnerNoAddress, setToggleOwnerNoAddress] = useState(false);
  const [toggleOwnerNoEmail, setToggleOwnerNoEmail] = useState(false);
  const [toggleOwnerNoEmailPrefs, setToggleOwnerNoEmailPrefs] = useState(false);
  const [toggleDisabledAddressSwitches, setToggleDisabledAddressSwitches] = useState(false);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_CHARGES':
        return {...state, charges: action.payload};
      case 'SET_SELECTED':
        let selected = [...state.selected];

        if (action.value === true) {
          selected.push(action.id);
        } else {
          selected = selected.filter(id => id !== action.id);
        }
        
        return {...state, selected};
      case 'SET_SELECTED_PROPERTY':
          let selectedProperties = [...state.selectedProperties];
  
          if (action.value === true) {
            selectedProperties.push(action.id);
          } else {
            selectedProperties = selectedProperties.filter(id => id !== action.id);
          }
          
          return {...state, selectedProperties};        
      case 'SELECT_ALL': {
        let selected = [];

        for (const owner of Object.values(state.charges)) {
          for (const property of Object.values(owner.properties)) {
            for (const charge of property.charges) {
              if(owner.owner.address !== '' || (owner.owner.address == '' && toggleDisabledAddressSwitches)) {
                selected.push(charge.id);
              }
            }
          }
        }
        
        return {...state, selected, action: action.type};
      }
      case 'SELECT_ALL_NON_HOPA': {
        let selected = [];

        for (const owner of Object.values(state.charges)) {
          for (const property of Object.values(owner.properties)) {
            for (const charge of property.charges) {
              if (charge.ownerchargecode.ownerchargecode !== 'HOPA' && owner.owner.address !== '' || (owner.owner.address == '' && toggleDisabledAddressSwitches)) {
                selected.push(charge.id);
              }
            }
          }
        }
        
        return {...state, selected, action: action.type};
      }      
      case 'DESELECT_ALL':
        return {...state, selected: []}; 
      case 'SELECT_ALL_PROPERTIES': {
        let selectedProperties = properties.map(property => property.id);

        return {...state, selectedProperties};
      }
      case 'DESELECT_ALL_PROPERTIES':
        return {...state, selectedProperties: []};
      case 'SELECT_ALL_NONLET_PROPERTIES': {
        let selectedProperties = [];
        for (const property of properties) {
          if (property.nonlet === true) {
            selectedProperties.push(property.id);
          }
        }

        return {...state, selectedProperties};
      }
      case 'REFRESH':
        return {...state, refresh: state.refresh + 1};
      case 'SET_WORKDONEDATE':
        return {...state, workdonedate: action.payload};
      case 'SET_ADDENDUM':
        return {...state, addendum: action.payload};
      case 'SET_SUBJECT':
        return {...state, subject: action.payload};
      case 'SET_MESSAGE':
        return {...state, message: action.payload};
      case 'SET_LOADING':
        return {...state, loading: action.payload};
      case 'SET_ONLY_SHOW_NON_LET':
        return {...state, onlyShowNonLet: action.payload}
      case 'SET_SENDING':
          return {...state, sending: action.payload}
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    charges: undefined,
    selected: [],
    selectedProperties: [],
    refresh: 0,
    workdonedate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
    addendum: '',
    subject: currentGroup.invoiceEmailSubject || '',
    message: currentGroup.invoiceEmailMessage || '',
    onlyShowNonLet: false,
    action: undefined,
    sending: false
  });

  const getCharges = async () => {

    dispatch({type: 'SET_LOADING', payload: true});
    dispatch({type: 'SET_SENDING', payload: false});

    let charges = new FilterCollection({
      path: 'ownercharge',
      object: common.OwnerCharge,
    });
    charges.limit = 999;
    charges.page = 1;
    charges.addFilters([{
      propertyid: state.selectedProperties.join('|'),
      workdonedate: '<' + moment(state.workdonedate).subtract(1, 'days').format('YYYY-MM-DD'),
      cancelleddatetime: 'null',
      hasnopmsinvoiceorownerstatement: 1,
      pmsinvoicejobinprogress: 0,
    }]);
    charges.orderBy = 'workdonedate';
    
    await charges.fetch();

    let ownerIds = charges.collection.map(charge => {
      return charge.owner.substr(charge.owner.lastIndexOf('/') + 1);
    });

    let owners = new FilterCollection({
      path: 'owner',
      object: common.Owner,
    });
    owners.limit = 100;
    owners.page = 1;
    owners.fields  = 'id:title:firstname:surname:address:emailaddress:contactdetails'
    owners.addFilters([{
      id: [...new Set(ownerIds)].join('|')
    }]);

    if (ownerIds.length > 0) {
      await owners.fetch();
    }

    setOwnersNoAddress(owners.collection.filter(o => o.address === ''));
    setOwnersNoEmail(owners.collection.filter(o => o.emailaddress === ''));

    let hasStatementPrefs = false;
    let ownersNoStatementContactPrefs = [];
    owners.forEach((o, i) => {
      hasStatementPrefs = false;
      //get contact details with valid email
      let contactdetails = o.contactdetails.collection.filter(ocd => ocd.contactmethodtype == 'Email' && !ocd.invalid);
        //loop over the contact details
        contactdetails.forEach((cd, j) => {
          cd.contactpreferences.collection.forEach((cp, k) => {
            if(cp.rolereason.reason.name == 'Statements' && !hasStatementPrefs) {
              hasStatementPrefs = true;
            } 
          });
        });
        if(!hasStatementPrefs) {
          ownersNoStatementContactPrefs.push(o);
        }
    });
    setOwnersNoContactPrefs(ownersNoStatementContactPrefs);

    const data = {};

    for (const charge of charges.collection) {
      if (!charge.property.id) {
        continue;
      }

      if (charge.ownerchargecode.ownerchargecode === 'XXXX') {
        continue;
      }

      const property = properties.find(p => charge.property.id === p.id);
      const owner = owners.collection.find(
        o => charge.owner.substr(charge.owner.lastIndexOf('/') + 1) === o.id.toString()
      );
      
      if(owner) {
        const ownerId = owner.id;

        if (!data[ownerId]) {
          data[ownerId] = {
            owner,
            properties: {},
            chargeCount: 0,
          }
        }

        if (!data[ownerId].properties[property.id]) {
          data[ownerId].properties[property.id] = {
            property,
            charges: [],
            chargeCount: 0,
          };
        }

        data[ownerId].chargeCount += 1;
        data[ownerId].properties[property.id].charges.push(charge);
        data[ownerId].properties[property.id].chargeCount += 1;
      }
    }

    dispatch({type: 'SET_CHARGES', payload: data});
    dispatch({type: 'DESELECT_ALL'});

    dispatch({type: 'SET_LOADING', payload: false});

  }

  const Check = () => (
    <div className="container">
      <div className="row">
        This will send the invoices to all owners (including non-HOPA)? Did you intend to do this?
        </div>
        <div className="row text-center">
          <div className="col text-center">
            <button className="btn btn-primary" onClick={createJob}>Yes</button> 
            <button className="btn btn-secondary" onClick={() => dispatch({type: 'DESELECT_ALL'}) }>No</button>
            </div>
        </div>
    </div>
  );

  const checkHOPA = async () => {
    //check they intended to press select all and not non HOPA
    if(state.action == 'SELECT_ALL') {
      infoToast(<Check />, false);
    } else {
      createJob();
    }
  }

  const createJob = async () => {
    dispatch({type: 'SET_SENDING', payload: true});

    var jts = new Collection({
      path: 'jobtype',
      object: common.JobType,
    });
    
    await jts.fetch(); 

    const jobType = jts.collection.find(jt => jt.jobtype === 'PMS invoices');

    let job = new common.Job();
    await job.create({
      jobtype: jobType,
      createddatetime: moment().format('YYYY-MM-DD HH:mm:ss'),
      createdbyactor: user,
      reference: 'PMSINV' + moment().format('YYYYMMDDHHmmss') + '-' + currentGroup.id,
      ids: state.selected.join(','),
      workdonedate: moment(state.workdonedate).format('YYYY-MM-DD'),
      addendum: state.addendum,
      subject: state.subject,
      message: state.message,
    });

    await getCharges();
    dispatch({type: 'SET_SENDING', payload: false});

    successToast(
      <React.Fragment>
        Invoice sending scheduled!<br />
        Check the 'review sent invoices' tab for progress.
      </React.Fragment>
    );
  }

  const previewInvoice = (ownerId, chargeIds) => {
    let pmsInvoice = new common.PmsInvoice();

    pmsInvoice.preview(
      ownerId,
      chargeIds.join(','),
      state.addendum,
      state.workdonedate
    ).then((res) => {
      var link = document.createElement('a');
      link.setAttribute('href', res.entity.url);
      link.setAttribute('download', ownerId + 'preview.pdf');
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  const { charges } = state; 

  const chargeRows = [];
  let lastOwner = undefined;
  let lastProperty = undefined;

  if (charges) {
    Object.values(state.charges).forEach(owner => {
      Object.values(owner.properties).forEach(property => {
        property.charges.forEach(charge => {

          const style = {};
          let className = '';
          if (lastOwner && lastOwner !== owner.owner.id) {
            style.borderTop = "2px solid #dee2e6";
          }  

          if(ownersNoContactPrefs.find(o => o.id === owner.owner.id)) {
            className = "bg-info";
          }

          if(owner.owner.emailaddress == '') {
            className = "bg-warning";
          } 

          if(owner.owner.address == '') {
            className = "bg-danger";
          }


          chargeRows.push(
            <tr className={className} style={style} key={charge.id}>
              {lastOwner !== owner.owner.id &&
              <td id={'ownerref'+owner.owner.id} key={owner.id} className="align-middle" rowSpan={owner.chargeCount}>
                {owner.owner.firstname + ' ' + owner.owner.surname} 
                {owner.owner.address == '' &&
                <TabsButton route={"owner/" + owner.owner.id} />
                }
              </td>
              }
              {lastProperty !== property.property.id &&
              <td className="align-middle" rowSpan={property.chargeCount}>{property.property.name}</td>
              }
              <td>{charge.id}</td>
              <td>{charge.description}</td>
              <td>{charge.amountnet.toFixed(2)}</td>
              <td>{charge.amountvat.toFixed(2)}</td>
              <td>{(charge.amountnet + charge.amountvat).toFixed(2)}</td>
              <td>{moment(charge.workdonedate).format('DD/MM/YYYY')}</td>
              <td className="align-middle text-center">
                <CustomInput 
                  className="d-inline"
                  type="switch" 
                  id={"sw" + charge.id}
                  label="" 
                  checked={state.selected.includes(charge.id)}
                  onChange={e => {
                    dispatch({type: 'SET_SELECTED', id: charge.id, value: e.target.checked});
                  }}
                  disabled={owner.owner.address == '' && !toggleDisabledAddressSwitches}
                />
              </td>
              {lastOwner !== owner.owner.id && 
              <td className="align-middle text-center" rowSpan={owner.chargeCount}>
                <Icon 
                  icon="eye" 
                  className="mt-1 mr-2 text-primary cursorPointer" 
                  onClick={() => 
                    previewInvoice(
                      owner.owner.id,
                      property.charges.map(charge => charge.id).filter(
                        chargeId => state.selected.includes(chargeId)
                      )
                    )
                  }
                  disabled={
                    property.charges.map(charge => charge.id).filter(
                      chargeId => state.selected.includes(chargeId)
                    ).length === 0 ? true : false
                  }
                />
              </td>
              }
            </tr>
          );

          lastOwner = owner.owner.id;
          lastProperty = property.property.id;
        });
      });
    });
  }

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col md={3}>
          <h5>
            Properties{' '}
            <Button size="sm" onClick={() => dispatch({type: 'SELECT_ALL_PROPERTIES'})}>select all</Button>{' '}
            <Button size="sm" onClick={() => dispatch({type: 'SELECT_ALL_NONLET_PROPERTIES'})}>select all non-let</Button>{' '}
            <Button size="sm" onClick={() => dispatch({type: 'DESELECT_ALL_PROPERTIES'})}>deselect all</Button>
          </h5>

          <FormGroup check>
            <Label check>
              <Input 
                type="checkbox" 
                checked={state.onlyShowNonLet} 
                onChange={
                  (e) => dispatch({
                    type: 'SET_ONLY_SHOW_NON_LET', 
                    payload: e.target.checked
                  })
                }
              />{' '}only show non-let properties
            </Label>
          </FormGroup>

          <Card className="pl-3 mb-2" style={{height: "10em", overflow: "scroll"}}>
            <CardBody>
              {properties
                .filter(property => state.onlyShowNonLet ? property.nonlet === true : true)
                .map(property => 

                  <React.Fragment key={property.id}>
                    <Label>
                      <Input 
                        type="checkbox" 
                        checked={state.selectedProperties.includes(property.id)} 
                        onChange={
                          (e) => dispatch({
                            type: 'SET_SELECTED_PROPERTY', 
                            id: property.id, 
                            value: e.target.checked
                          })
                        }
                      />{' '}
                      {property.tabspropref} - {property.name}
                    </Label><br />
                  </React.Fragment>
              )}
            </CardBody>
          </Card>
          <h5>Options</h5>
          <Card>
            <CardBody>
              <FormGroup>
                <Label>Work done before</Label>
                <Input 
                  type="date" 
                  value={state.workdonedate} 
                  onChange={e => dispatch({type: 'SET_WORKDONEDATE', payload: e.target.value})}
                />
              </FormGroup>
              <FormGroup>
                <Label>Add note to invoices</Label>
                <Input 
                  type="textarea"
                  value={state.addendum}
                  onChange={e => dispatch({type: 'SET_ADDENDUM', payload: e.target.value})}  
                />
              </FormGroup>       
              <FormGroup>
                <Label>Email subject</Label>
                <Input 
                  type="text"
                  value={state.subject}
                  onChange={e => dispatch({type: 'SET_SUBJECT', payload: e.target.value})}  
                />
              </FormGroup>  
              <FormGroup>
                <Label>Email message</Label>
                <Input 
                  type="textarea"
                  value={state.message}
                  onChange={e => dispatch({type: 'SET_MESSAGE', payload: e.target.value})}  
                />
              </FormGroup>                                     
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Row className="mb-2">
            <Col>
              <Button 
                onClick={getCharges}
                disabled={state.selectedProperties.length === 0}
              >
                get charges
              </Button>{' '}
              {state.loading &&
              <PrettyLoader size={38} />
              }
            </Col>
            <Col sm="auto">
              <Button 
                disabled={!charges || charges.length === 0}
                onClick={() => dispatch({type: 'SELECT_ALL'}) }
              >
                select all
              </Button>{' '}
              <Button 
                disabled={!charges || charges.length === 0}
                onClick={() => dispatch({type: 'SELECT_ALL_NON_HOPA'}) }
              >
                select all non-HOPA
              </Button>{' '}              
              <Button 
                disabled={!charges || charges.length > 0}
                onClick={() => dispatch({type: 'DESELECT_ALL'}) }
              >
                deselect all
              </Button>{' '}    
              <Button 
                onClick={checkHOPA}
                disabled={state.selected.length === 0 || state.sending}
                color="primary"
              >
                send invoices
              </Button>
            </Col>
          </Row>
          {ownersNoAddress !== undefined && ownersNoAddress.length > 0 &&
          <Row>
            <Col>
              <p>
                {ownersNoAddress.length} owners have no address, please add an address using TABS before proceeding and press the switch when fixed {' '}
                <CustomInput 
                  className="d-inline"
                  type="switch" 
                  id={"swfixedOwnerAddresses"}
                  label="" 
                  checked={toggleDisabledAddressSwitches}
                  onChange={() => { setToggleDisabledAddressSwitches(!toggleDisabledAddressSwitches) }}
                /> {' '}
                <Button size="sm" color="danger" onClick={() => { setToggleOwnerNoAddress(!toggleOwnerNoAddress) }}>
                  view
                </Button>
              </p>
              {toggleOwnerNoAddress &&
                <ul>
                {ownersNoAddress.map((owner, i) => 
                  <li key={owner.id}><a href={'#ownerref'+owner.id}>{owner.id} {owner.firstname} {owner.surname}</a></li>
                )}
                </ul>
              }
            </Col>
          </Row> 
          }
          {ownersNoEmail !== undefined && ownersNoEmail.length > 0 &&
          <Row>
            <Col>
              <p>{ownersNoEmail.length} owners have no email address. If you proceed you will need to print and post the invoice once generated {' '}

              <Button size="sm" color="warning" onClick={() => { setToggleOwnerNoEmail(!toggleOwnerNoEmail) }}>
                    View
                  </Button>
              </p>
              {toggleOwnerNoEmail &&
                <ul>
                {ownersNoEmail.map((owner, i) => 
                  <li key={owner.id}><a href={'#ownerref'+owner.id}>{owner.id} {owner.firstname} {owner.surname}</a></li>
                )}
                </ul>
              }
            </Col>
          </Row> 
          }
          {ownersNoContactPrefs.length > 0 &&
          <Row>
            <Col>
              <p>{ownersNoContactPrefs.length} owners have incorrect contact preferences set on their email address in TABS. If you proceed you will need to print and post the invoice once generated {' '}

              <Button size="sm" color="info" onClick={() => { setToggleOwnerNoEmailPrefs(!toggleOwnerNoEmailPrefs) }}>
                    View
                  </Button>
              </p>
              {toggleOwnerNoEmailPrefs &&
                <ul>
                {ownersNoContactPrefs.map((owner, i) => 
                  <li key={owner.id}><a href={'#ownerref'+owner.id}>{owner.id} {owner.firstname} {owner.surname}</a></li>
                )}
                </ul>
              }
            </Col>
          </Row> 
          }
          
          <Card>
            <CardBody>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>owner</th>
                    <th>property</th>
                    <th>charge id</th>
                    <th>description</th>
                    <th>net</th>
                    <th>VAT</th>
                    <th>gross</th>
                    <th>date</th>
                    <th>{' '}</th>
                    <th>{' '}</th>
                  </tr>
                </thead>
                <tbody>
                  {chargeRows.length === 0 &&
                  <tr>
                    <td colSpan={9}>No charges found.</td>
                  </tr>
                  }
                  {chargeRows}
                </tbody>     
              </Table>   
            </CardBody>
          </Card>
       
        </Col>
      </Row>
    </React.Fragment>
  );

}

function SendInvoicesPane() {

  const {
    state: {
      currentGroup,
    }
  } = useContext(Store);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'PREV_PAGE':
        return {...state, page: state.page - 1};
      case 'NEXT_PAGE':
        return {...state, page: state.page + 1};
      case 'SET_LIMIT':
        return {...state, limit: action.payload};
      case 'SET_RUNS':
        return {...state, runs: action.payload, selected: []};
      case 'TOGGLE_LIMIT_DROPDOWN':
        return {...state, limitDropdown: !state.limitDropdown};
      case 'REFRESH':
        return {...state, refresh: state.refresh + 1};
      case 'VIEW_RUN':
        return {...state, run: action.payload, modal: true};
      case 'TOGGLE_MODAL':
        return {...state, modal: !state.modal};
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    page: 1,
    limit: 10,
    loading: false,
    runs: {},
    limitDropdown: false,
    refresh: 0,
    run: undefined,
    modal: false,
  });

  useEffect(() => {

    const refresh = async () => {
      let runs = new FilterCollection({
        path: 'job',
        object: common.Job,
      });
      runs.limit = state.limit;
      runs.page = state.page;
      runs.orderBy = 'createddatetime_desc';

      runs.addFilters([{
        jobtypeid: 9,
        reference: '~-' + currentGroup.id,
      }]);
      await runs.fetch();
      return runs;
    }

    refresh().then(runs => {
      dispatch({type: 'SET_RUNS', payload: runs});
    });

  }, [state.page, state.limit, state.refresh]);

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col>
          <div style={{display: 'inline-flex', verticalAlign: 'middle'}}>
            <div className="mr-2 p-1 float-right">
              results per page:
            </div>           
            <ButtonDropdown 
              size="sm" 
              isOpen={state.limitDropdown} 
              toggle={() => { dispatch({type: 'TOGGLE_LIMIT_DROPDOWN'}) }}
              disabled={state.busy}
            >
              <DropdownToggle caret>
                {state.limit} 
              </DropdownToggle>
              <DropdownMenu>
                {[10, 20, 30, 50].map(quantity => 
                <DropdownItem key={quantity} onClick={() => {
                  dispatch({type: 'SET_LIMIT', payload: quantity})
                }}>
                  {quantity}
                </DropdownItem>  
                )}
              </DropdownMenu>
            </ButtonDropdown>
          </div>         
        </Col>
        <Col sm="auto">
          <Button size="sm" onClick={() => { dispatch({type: 'REFRESH'}) }}>
            refresh
          </Button>{' '}
          <ButtonGroup size="sm">
            <Button
              onClick={() => { dispatch({type: 'PREV_PAGE'}) }}
              disabled={!state.runs.previous || state.busy}
            >
              previous page
            </Button>
            <Button 
              onClick={() => { dispatch({type: 'NEXT_PAGE'}) }}
              disabled={!state.runs.next || state.busy}
            >
              next page
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Table size="sm" striped>
        <thead>
          <tr>
            <th>created</th>
            <th>created by</th>
            <th>reference</th>
            <th>progress</th>
            <th>{''}</th>
          </tr>
        </thead>
        <tbody>
          {state.runs.collection && state.runs.collection.length === 0 &&
          <tr>
            <td colSpan={5}>No runs found.</td>
          </tr>
          }
          {state.runs.collection && state.runs.collection.map(run =>
          <tr key={run.id}>
            <td>{moment(run.createddatetime).format('DD/MM/YYYY HH:mm:ss')}</td>
            <td>user</td>
            <td>{run.reference}</td>
            <td className="align-middle text-center">
              <Progress value={run.completionpercentage} />
            </td>
            <td className="align-middle text-center">
              <Icon 
                icon="eye" 
                className="text-primary cursorPointer" 
                onClick={() => dispatch({type: 'VIEW_RUN', payload: run})}
              />
            </td>
          </tr>
          )}
        </tbody>
      </Table>
      {state.modal &&
      <SendInvoicesModal 
        run={state.run} 
        isOpen={state.modal} 
        toggle={() => dispatch({type: 'TOGGLE_MODAL'})} />
      }
    </React.Fragment>
  );

}

function SendInvoicesModal(props) {

  const [items, setItems] = useState();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);

  const getParams = url => {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  };

  useEffect(() => {
    const getItems = async () => {

      let invoicesRemaining = undefined;
      let limit = 999;
      let page = 1;
      let invoices;
      let invoicesCollection = [];
      //sometimes more than 999 results 
      //so loop and create a collection to hold them all
      do {
        invoices = new FilterCollection({
          path: 'pmsinvoice',
          object: common.PmsInvoice,
        });
        invoices.limit = limit;
        invoices.page = page;

        invoices.addFilters([{
          jobid: props.run.id,
        }]);

        await invoices.fetch();

        invoicesCollection = invoicesCollection.concat(invoices.collection);

        page++;
        
      } while (invoices.next !== null);

      //always want the latest invoice: less likely to have an error
      invoicesCollection.reverse();

      const items = new Collection({
        path: 'item',
        object: common.JobItem,
        parent: props.run
      });
    
      await items.fetch();

      items.forEach(item => {
        const params = getParams(item.requesturl);
        const ownerId = parseInt(params['ownerid'], 10);
        const invoice = invoicesCollection.find(inv => inv.owner.id === ownerId);

        item.ownerid = ownerId;

        if (invoice) {
          item.invoice = invoice;
        }
      });

      setItems(items.collection);
      setLoading(false);
    }

    getItems();

  }, []);

  const downloadDocument = (doc) => {
    doc.file.get().then((res) => {
      var link = document.createElement('a');
      link.setAttribute('href', res.url);
      link.setAttribute('download', doc.filename);
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  const toggleSelected = (id, value) => {
    let s = [...selected];

    if (value) {
      s.push(id);
    } else {
      s.splice(s.indexOf(id), 1);
    }

    setSelected(s);
  } 

  const printSelected = () => {

    let documentIds = [];

    selected.forEach(s => {
      const item = items.find(i => i.id === s);
      documentIds.push(item.invoice.document.id);
    });

    let doc = new common.Document();

    doc.stitch(
      documentIds
    ).then((res) => {
      var link = document.createElement('a');
      link.setAttribute('href', res.entity.url);
      link.setAttribute('download', 'print.pdf');
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  const selectAllUnsent = () => {
    let s = [];
    items.forEach(item => {
      if (item.invoice && !item.invoice.sent) {
        s.push(item.id);
      }
    })
    setSelected(s);
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>
        {props.run.reference}{' '}
        {loading &&
          <PrettyLoader size={38} />
        }
      </ModalHeader>
      <ModalBody>
        <Button className="float-right mb-2" onClick={() => selectAllUnsent()}>select all unsent</Button>
        {selected.length > 0 &&
        <Button className="float-right mb-2" onClick={() => printSelected()}>print selected</Button>
        }
        <Table size="sm" striped>
          <thead>
            <tr>
              <th>owner id</th>
              <th className="text-center">invoice created</th>
              <th className="text-center">invoice emailed</th>
              <th>{''}</th>
            </tr>
          </thead>
          <tbody>
            {items && items.length === 0 && 
            <th colSpan={4}>No invoices found.</th>
            }
            {items && items.map(item => {

              let error = undefined;
              if (item.lastjobitemresponse.content) {
                error = item.lastjobitemresponse.content;
                //now try to parse it in case its json and get the description
                try {
                  const parsed = JSON.parse(error);
                  if (parsed && typeof parsed === "object" && parsed.errorDescription) {
                    error = parsed.errorDescription;
                  } 
                } catch {
                  
                }
              }

              return (
                <React.Fragment key={'fr' + item.id}>
                  <tr key={item.id}>
                    <td>{item.ownerid}</td>
                    <td className="align-middle text-center">
                      <Icon icon={(item.invoice && item.invoice.document && item.invoice.document.id) ? 'check' : 'times'} />
                    </td>
                    <td className="align-middle text-center">
                      <Icon icon={item.invoice && item.invoice.sent ? 'check' : 'times'} />
                    </td>
                    <td className="align-middle text-center">
                      {item.invoice &&
                      <React.Fragment>
                        <Icon 
                          icon="eye"
                          className="text-primary cursorPointer" 
                          disabled={!(item.invoice && item.invoice.document && item.invoice.document.id)}
                          onClick={() => {
                            downloadDocument(item.invoice.document);
                          }}
                        />
                        {' '}
                        <CustomInput 
                          className="d-inline"
                          disabled={!(item.invoice && item.invoice.document && item.invoice.document.id)}
                          type="switch"
                          id={"sw" + item.id}
                          label="" 
                          checked={selected.includes(item.id)}
                          onChange={e => toggleSelected(item.id, e.target.checked)}
                        />
                      </React.Fragment>
                      }
                    </td>
                  </tr>
                  {error &&
                  <tr className="bg-danger text-white" key={item.id + 'error'}>
                    <td colSpan={4}>{item.ownerid} error: {error}</td>
                  </tr>
                  }
                </React.Fragment>
              );

            })}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={props.toggle}>close</Button>
      </ModalFooter>
    </Modal>
  );
}
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { 
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Table,
  TabContent,
  TabPane,
  Row } from 'reactstrap';
import Icon from './components/Icon';
import moment from 'moment';
import { addTab } from "./Actions";
import Documents from './components/Documents';
import TabsButton from './components/TabsButton';
import { Store } from "./Store";
import GoogleMap from './components/GoogleMap';
import Notes from './components/Notes';
import IconButton from './components/IconButton';
import JobStatusHandler from './components/JobStatusHandler';
import TemplateFromJobModal from './components/TemplateFromJobModal';
import JobAssociationBlock from './components/JobAssociationBlock';
import JobChecklistBlock from './components/JobChecklistBlock';
import ContactHistory from './components/ContactHistory';

var classnames = require('classnames');

export default function TabShowJob(props) {

  const {
    state: {
      jobs
    }, 
    dispatch
  } = useContext(Store);

  const jobTracker = jobs[props.workOrderId];

  const [activeTab, setActiveTab] = useState('notes');
  const [job, setJob] = useState();
  const [statusHistory, setStatusHistory] = useState();
  const [progress, setProgress] = useState();
  const [workTypeString, setWorkTypeString] = useState();

  const refreshJob = useCallback(() => {
    //loadJob(props.workOrderId, dispatch);
  }, [props.workOrderId, dispatch]);

  useEffect(() => {
    refreshJob();
  }, [refreshJob]);

  useEffect(() => {
    if (jobTracker) {
      setJob(jobTracker);
      setStatusHistory(buildStatusHistory(jobTracker));
      setProgress(buildProgress(jobTracker));
      setWorkTypeString(buildWorkTypeString(jobTracker));
    }
  }, [jobTracker]);

  const buildStatusHistory = (job) => {
    let rows = [];
    
    job.statushistory.forEach(status => {

      const statusName = status.status.toLowerCase();

      rows.push(
        <tr key={'s' + status.id}>
          <td className="font-weight-bold">
            {statusName === 'completed' ? 'practically completed' : statusName}
          </td>
          <td>{moment(status.from).format('DD/MM/YY HH:mm')}</td>
        </tr>
      );

      let subStatuses = [];
      status.substatus.forEach(subStatus => subStatuses.push(subStatus));
      subStatuses.sort((a, b) => (a.fromdatetime > b.fromdatetime) ? 1 : -1);

      subStatuses.forEach(subStatus => {
        if (statusName.replace(' ', '_') !== subStatus.workordersubstatus.substatusreference) {
          rows.push(
            <tr key={'ss' + subStatus.id}>
              <td>{subStatus.workordersubstatus.substatusname}</td>
              <td>{moment(subStatus.fromdatetime).format('DD/MM/YY HH:mm')}</td>
            </tr>
          );
        }
      });

    });

    return rows;
  }

  const buildProgress = (job) => {
    const statuses = ['new', 'started', 'completed', 'financially completed', 'invoiced', 'owner charged', 'supplier paid'];

    let currentStatus = job.status.toLowerCase();

    if (currentStatus === 'template') {
      return <h2 className="text-center text-primary">template job</h2>;
    }

    if (currentStatus === 'cancelled') {
      return <h3 className="text-center mt-3">cancelled</h3>;
    }

    let started = undefined;
    let completed = undefined;
    let completedDuration = undefined;

    job.statushistory.forEach(status => {
      const statusName = status.status.toLowerCase();
      if (statusName === 'started') { started = moment(status.from); }
      if (statusName === 'completed') { completed = moment(status.from); }
    });

    if (started && completed) {
      const duration = moment.duration(completed.diff(started));
      completedDuration = Math.ceil(duration.asMinutes());
    }

    let hasPaySupplierItems = false;
    let hasOwnerCharges = false;

    job.expenses.forEach(expense => {
      if (!expense.donotpaysupplier) {
        hasPaySupplierItems = true;
      }
      if (expense.ownercharges.collection.length > 0) {
        hasOwnerCharges = true;
      }
    });

    if (!hasPaySupplierItems) {
      // remove the invoiced and supplier paid statuses because the job should never end up on them
      statuses.splice(statuses.indexOf('invoiced'), 1);
      statuses.splice(statuses.indexOf('supplier paid'), 1);
    }
    if (!hasOwnerCharges) {
      // remove the owner charged status because the job should never end up on it
      statuses.splice(statuses.indexOf('owner charged'), 1);
    }

    const liStyle = {
      width: (98 / statuses.length).toString() + '%'
    }
  
    const lis = statuses.map((element, i) => {
  
      let className = '';
      if (i <= statuses.indexOf(job.status.toLowerCase())) {
        className += 'active ';
      }
  
      let subStatus = currentStatus === element ? job.substatus : undefined;
  
      if (subStatus && 
          subStatus.id && 
          currentStatus.replace(' ', '_') === subStatus.workordersubstatus.substatusreference.toLowerCase()) {
        // don't show sub-status when it's called the same as the parent status
        subStatus = undefined;
      }

      let subStatusName = (subStatus && subStatus.id) ? subStatus.workordersubstatus.substatusname : undefined;

      // status specific hacks
      if (element === 'invoiced') {
        if (currentStatus === 'invoice approved') {
          currentStatus = 'invoiced';
          subStatusName = 'approved';
        }
        if (currentStatus === 'invoice rejected') {
          currentStatus = 'invoiced';
          subStatusName = 'rejected';
        }
      }
  
      return (
        <li key={i} className={className} style={liStyle}>
          <div className={currentStatus === element ? "substatus m-1" : "m-1"}>
            <div>
              <div className={element === currentStatus ? 'font-weight-bold bg-white' : 'bg-white'}>
                {element === 'completed' ? 'practically completed' : element}
                {element === 'completed' && completedDuration !== undefined && completedDuration > 0 &&
                <React.Fragment>
                  <br />
                  {completedDuration} minute(s)
                </React.Fragment>
                }
              </div>
              {subStatusName &&
              <React.Fragment>
                <br style={{lineHeight: "20px"}} />
                <div style={{backgroundColor: "#fd6413", color: "white", borderRadius: "15px", padding: "4px"}}>
                  <strong>{subStatusName}</strong>  
                </div>
              </React.Fragment>
              }
            </div>
          </div>
        </li>
      );
    });

    return (
      <div className="container">
        <ul className="progressbar">
          {lis}
        </ul>
      </div>
    );
  }

  const buildWorkTypeString = (job) => {

    const workTypeArr = (workType, arr = []) => {
      if (!workType.id) {
        return arr;
      }
  
      arr.push(workType.worktype);
      if (workType.parentworktype.id) {
        workTypeArr(workType.parentworktype, arr);
      }
  
      return arr;
    }

    const arr = workTypeArr(job.worktype).reverse();

    if (arr.length === 0) {
      return;
    }

    return arr.reverse().map((workType, i) => {
      return (
        <React.Fragment key={i}>
        <span>{workType}</span>
        { i < arr.length - 1 &&
        <React.Fragment>{' '}<Icon icon="arrow-right" />{' '}</React.Fragment>
        }
        </React.Fragment>
      )
    });

  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }  
  
  if (!job) {
    return <h3>Loading...</h3>;
  }  

  let propertyAddress = '';
  if (job.property.address) {
    propertyAddress = [
      job.property.address.line1,
      job.property.address.town,
      job.property.address.county,
      job.property.address.postcode  
    ].filter(Boolean).join(', ');
  }

  let actorName = 'N/A';
  if (job && job.workordersupplier.id) {
    const supplier = job.workordersupplier.supplier;

    if (supplier.title !== 'PMS') {
      actorName = [
        supplier.title,
        supplier.firstname,
        supplier.surname
      ].join(' ');
    }
  }  

  return (
    <React.Fragment>
      <Row className="mb-2 align-items-center">
        <Col>
          <h3>
            job {job.id} - {job.shortdescription}
            {job.workordertemplate && job.workordertemplate.id && job.workordertemplate.autogenerate &&
            <Icon 
              icon="sync" 
              className="text-primary cursorPointer ml-2"
              onClick={() => { addTab('showJob', {workOrderId: job.workordertemplate.id}, dispatch); }}
            />
            }
            <IconButton 
              icon="refresh" 
              className="float-right" 
              size="lg" 
              color="secondary"
              onClick={refreshJob}
            />
          </h3>
        </Col>
      </Row>        
      <Row>
        <Col md="8">
          <Card className="mb-3">
            <CardBody>
              <Row className="mb-1 text-center">
                <Col><strong>type</strong><br />{job.source.id ? job.source.sourcename : 'supplier portal/non-PMS'}</Col>
                <Col><strong>created</strong><br />{moment(job.createddatetime).format('DD/MM/YY HH:mm')}</Col>
                <Col>
                  <strong>scheduled for</strong><br />
                  {job.preferredstartdatetime ? moment(job.preferredstartdatetime).format('DD/MM/YY HH:mm') : 'N/A'}
                </Col>
                <Col>
                  <strong>field worker</strong><br />{actorName}
                  {job && job.workordersupplier.id && actorName !== 'N/A' &&
                  <React.Fragment>
                    {' '}
                    <IconButton 
                      icon="show" 
                      onClick={() => {
                        addTab('showFieldWorker', {supplierId: job.workordersupplier.supplier.id}, dispatch); 
                      }} 
                    />
                  </React.Fragment>
                  } 

                </Col>
                <Col><strong>completed</strong><br />{job.completeddate ? moment(job.completeddate).format('DD/MM/YY') : 'N/A'}</Col>
                <Col><strong>actions</strong><br />
                  <IconButton icon="edit" onClick={() => { addTab('editJob', {workOrderId: job.id}, dispatch); }} />{' '}
                  <TabsButton route={"managedservices/workorders/" + job.id} />  
                </Col>
              </Row>
              <Row className="mb-1">
                <Col>
                  <strong>work type:</strong>{' '}
                  { workTypeString || 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col><strong>full description</strong><br />{job.fulldescription ? job.fulldescription : 'N/A'}</Col>
              </Row>                
              <Row>
                <Col>
                  {progress}
                </Col>
              </Row>                  
            </CardBody>
          </Card>
          { job.substatus !== '' &&
          <JobStatusHandler workOrder={job} accepted={refreshJob} user={props.user} />
          }
          <Card>
            <CardBody>
              <Nav className="mb-2" tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'notes' })}
                    onClick={() => { toggleTab('notes'); }}
                  >
                    notes
                  </NavLink>
                </NavItem>
                {job.booking.id &&
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'booking notes' })}
                    onClick={() => { toggleTab('booking notes'); }}
                  >
                    booking notes
                  </NavLink>
                </NavItem>          
                }
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'contact history' })}
                    onClick={() => { toggleTab('contact history'); }}
                  >
                    contact history
                  </NavLink>
                </NavItem>                
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'documents' })}
                    onClick={() => { toggleTab('documents'); }}
                  >
                    documents
                  </NavLink>
                </NavItem>                
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="notes">
                  <Notes parent={job} mode="job" />    
                </TabPane>
                {job.booking.id &&
                <TabPane tabId="booking notes">
                  <Notes parent={job.booking} mode="booking" />
                </TabPane>
                }
                <TabPane tabId="contact history">
                  <ContactHistory entityid={job.id} job={job} />
                </TabPane>                
                <TabPane tabId="documents">
                  <Documents workOrder={job} />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="mb-3">
            <CardBody>
              <CardTitle tag="h5">
                property{' '}
                <IconButton 
                  icon="show" 
                  onClick={() => {
                    addTab('showProperty', {propertyId: job.property.id}, dispatch); 
                  }} 
                />
              </CardTitle>
              <React.Fragment>
                <strong>{job.property.name}</strong><br />
                {propertyAddress !== '' &&
                  propertyAddress
                }
              </React.Fragment>
              <GoogleMap latitude={job.property.address.latitude} longitude={job.property.address.longitude} height="300" />
            </CardBody>
          </Card>
          <Card className="mb-3">
            <CardBody>
              <CardTitle tag="h5">status history</CardTitle>
              <Table size="sm" striped>
                <thead>
                  <tr>
                    <th>status</th>
                    <th>assigned</th>
                  </tr>
                </thead>
                <tbody>
                  {statusHistory}
                </tbody>
              </Table>                
            </CardBody>
          </Card>
          {job.type === 'Instance' &&
          <Card className="mb-3">
            <CardBody>
              <CardTitle tag="h5">associations</CardTitle>
              <JobAssociationBlock job={job} />
            </CardBody>
          </Card>
          }
          <Card className="mb-3">
            <CardBody>
              <CardTitle tag="h5">checklists</CardTitle>
              <JobChecklistBlock job={job} />
            </CardBody>
          </Card>  
          {job.type === 'Instance' &&                  
          <Card>
            <CardBody>
              <CardTitle tag="h5">Other actions</CardTitle>
              <TemplateFromJobModal job={job} />
            </CardBody>
          </Card>
          }
        </Col>
      </Row>
    </React.Fragment>
  );
}


import React, { useState, useContext } from 'react';
import { 
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row } from 'reactstrap';
import { Store } from "../Store";
import InitialsCircle from './InitialsCircle';

export default function SelectPersonModal(props) {

  const [selected, setSelected] = useState([]);
  const [modal, setModal] = useState(false);

  const {
    state: {
      fieldWorkers,
      serviceCoordinators,
      serviceManagers,
    }
  } = useContext(Store);

  const toggle = () => {
    setModal(!modal);
  }

  const toggleSelected = id => {
    const newSelected = props.oneOnly ? [] : [...selected];
    if (newSelected.includes(id)) {
      newSelected.splice(newSelected.indexOf(id), 1); 
    } else {
      newSelected.push(id);
    }
    setSelected(newSelected);
  }

  let modeLong = '';
  let persons = undefined;

  switch (props.mode || 'fieldWorker') {
    case 'fieldWorker': modeLong = 'field worker'; persons = fieldWorkers; break;
    case 'serviceCoordinator': modeLong = 'service coordinator'; persons = serviceCoordinators; break;
    case 'serviceManager': modeLong = 'service manager'; persons = serviceManagers; break;
  }

  let personItems = [];
  persons.forEach(actor => {
    if (props.excludeIds && props.excludeIds.includes(actor.id)) {
      return;
    }

    if (props.payOnly && actor.donotpay) {
      return;
    }

    const actorName = [
      actor.title,
      actor.firstname,
      actor.surname
    ].join(' ');

    personItems.push(
      <ListGroupItem 
        key={actor.id} 
        action 
        active={selected.includes(actor.id)} 
        onClick={() => toggleSelected(actor.id)}
        className="very-skinny"
      >
        <Row>
          <Col>
            {actorName}{' '}
            <small>{actor.id}</small>
          </Col>
          <Col md="auto">
            <InitialsCircle 
              size={30}
              id={'popover' + actor.id} 
              firstname={actor.firstname} 
              surname={actor.surname} 
            />       
          </Col>
        </Row>
      </ListGroupItem>              
    );    
  });

  return (
    <React.Fragment>
      {props.button ? props.button(toggle) : <Button size="sm" onClick={toggle}>choose {modeLong}(s)</Button>}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>choose {props.oneOnly ? 'a' : 'one or more'} {modeLong}{props.oneOnly ? '' : 's'}</ModalHeader>
        <ModalBody>
          <ListGroup>
            {personItems}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          {!props.oneOnly &&
          <span className="mr-3">{selected.length} {modeLong}s selected</span>
          }
          <Button 
            color="primary" 
            disabled={selected.length === 0} 
            onClick={() => { props.onSubmit(props.oneOnly ? selected[0] : selected); toggle(); }}
          >
            go
          </Button>
          <Button color="secondary" onClick={toggle}>cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

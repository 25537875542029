import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPencilAlt, faSync, faEye, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

export default function IconButton(props) {
  
  let icon = undefined;

  switch(props.icon) {
    case 'edit':
      icon = faPencilAlt;
      break;
    case 'show':
      icon = faEye;
      break;
    case 'refresh':
      icon = faSync;
      break;
    case 'config':
      icon = faCog;
      break;
    default:
      icon = faQuestion;
  }

  return (
    <Button 
      size={props.size || "sm"}
      color={props.color || "primary"}
      onClick={props.onClick}
      style={props.style}
      className={props.className}
    >
      <FontAwesomeIcon icon={icon}/>
    </Button>
  );

}
import React, { useCallback, useEffect, useContext, useReducer, useState } from 'react';
import { 
  Button, 
  ButtonGroup, 
  Row, 
  Col, 
  Card, 
  CardBody, 
  ListGroup, 
  ListGroupItem, 
  Nav, 
  NavItem, 
  NavLink, 
  TabContent, 
  TabPane 
} from 'reactstrap';
import moment from 'moment';
import Icon from './components/Icon';
import { common } from 'plato-js-client';
import InitialsCircle from './components/InitialsCircle';
import TabsButton from './components/TabsButton';
import FilteredJobs from './components/FilteredJobs';
import ContactHistory from './components/ContactHistory';
import Notes from './components/Notes';
import { Store } from "./Store";
import DatePickerButton from './components/DatePickerButton';
import GoogleMap from './components/GoogleMap';

var classnames = require('classnames');

export default function TabFieldWorker(props) {

  const {
    state: {
      fieldWorkers,
      properties
    }
  } = useContext(Store);

  const [activeTab, setActiveTab] = useState('jobs');
  const [activeJobsTab, setActiveJobsTab] = useState('day');
  const [fieldWorker, setFieldWorker] = useState();

  useEffect(() => {
    setFieldWorker(
      fieldWorkers.find(fw => fw.id === props.supplierId)
    );
  }, [fieldWorkers, props.supplierId]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }  

  const toggleJobsTab = (tab) => {
    if (activeJobsTab !== tab) {
      setActiveJobsTab(tab);
    }
  }

  if (!fieldWorker) {
    return <span>Loading...</span>
  }

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col><h3>{fieldWorker.title + ' ' + fieldWorker.firstname + ' ' + fieldWorker.surname}</h3></Col>
      </Row>
      <Row>
        <Col>
          <Card className="mb-3">
            <CardBody>
              <Row className="mb-1 text-center align-items-center">
                <Col>
                  <InitialsCircle 
                    size={80}
                    id={fieldWorker.id}
                    firstname={fieldWorker.firstname} 
                    surname={fieldWorker.surname} 
                  />
                </Col>
                <Col><strong>id</strong><br />{fieldWorker.id}</Col>
                <Col><strong>tabs code</strong><br />{fieldWorker.tabscode}</Col>
                <Col><strong>company name</strong><br />{fieldWorker.companyname || 'N/A'}</Col>
                <Col><strong>TOCC employed</strong><br /><Icon icon={fieldWorker.agencyemployed ? 'check' : 'times'} /></Col>
                <Col>
                  <strong>actions</strong>
                  <br />       
                  <TabsButton route={"supplier/" + fieldWorker.id} />
                </Col>
              </Row>
            </CardBody>
          </Card>             
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="mb-3">
            <CardBody>
              <Nav className="mb-2" tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'jobs' })}
                    onClick={() => { toggleTab('jobs'); }}
                  >
                    jobs
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'contact' })}
                    onClick={() => { toggleTab('contact'); }}
                  >
                    contact history
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'notes' })}
                    onClick={() => { toggleTab('notes'); }}
                  >
                    notes
                  </NavLink>
                </NavItem>                  
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="jobs">
                  <Nav className="mb-2 mt-3" tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeJobsTab === 'day' })}
                        onClick={() => { toggleJobsTab('day'); }}
                      >
                        by day
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeJobsTab === 'all' })}
                        onClick={() => { toggleJobsTab('all'); }}
                      >
                        all
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeJobsTab}>
                    <TabPane tabId="day">
                      <DayPlan google={props.google} properties={properties} fieldWorker={fieldWorker} supplierId={props.supplierId} />
                    </TabPane>
                    <TabPane tabId="all">           
                      <FilteredJobs supplierId={props.supplierId} />
                    </TabPane>
                  </TabContent>
                </TabPane>
                <TabPane tabId="contact">
                  <ContactHistory type="Supplier" actor={fieldWorker} />
                </TabPane>
                <TabPane tabId="notes">
                  <Notes parent={fieldWorker} mode="fieldworker" />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );

}

export function DayPlan(props) {
  
  const { 
    state: {
      change,
    }
  } = useContext(Store);

  const reducer = (state, action) => {

    switch (action.type) {
      case 'CHANGE_DAY':
        return {...state, day: action.day};
      case 'PREV_DAY':
        return {...state, day: moment(state.day).add(-1, 'days').format('YYYY-MM-DD')};
      case 'NEXT_DAY':
        return {...state, day: moment(state.day).add(1,  'days').format('YYYY-MM-DD')};
      case 'PLAN':
        return {...state, plan: action.plan, refresh: false};
      case 'REFRESH':
        return {...state, refresh: true};
      case 'CHECK_CHANGE':
        if (!state.plan) {
          return state;
        }

        let refreshJobs = false;

        if (Object.keys(state.plan.jobs).includes(action.change.jobId)) {
          refreshJobs = true;
        } else {
          if (props.supplierId === action.change.supplierId) {
            // TODO: check job is the day we're looking at
            refreshJobs = true;
          }
        }
    
        if (refreshJobs) {
          return {...state, refresh: true};
        }

        return state;
      default:
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    day: moment().format('YYYY-MM-DD'),
    plan: undefined,
    refresh: false,
  });

  const getDayPlan = useCallback(async () => {

    let sdp = new common.SupplierDayPlan(state.day);
    sdp.parent = props.fieldWorker;
    await sdp.get();

    let plan = JSON.parse(sdp.dayplan);

    let rows = [];
    let totalJobDuration = 0;
    let totalDistance = 0;
    let totalTravelTime = 0;
    let properties = {};

    if (plan.plan.length === 0) {
      rows.push(
        <h3 key="nojobs">No jobs found for selected day.</h3>
      );
    }

    plan.plan.forEach((item, i) => {
      switch (item.type) {
        case 'travel':
          totalTravelTime += item.durationValue || 0;
          totalDistance += item.distanceValue || 0;

          rows.push(
            <DayPlanTravelRow
              origin={item.origin}
              destination={item.destination}
              distance={item.distance}
              duration={item.duration}
              key={'travel' + i}
            />
          );
          break;
        case 'job':
            const job = plan.jobs[item.id];
            const property = job.property;

            if (!properties[property.id]) {
              properties[property.id] = property;
            }

            totalJobDuration += job.durationminutes;

            rows.push(
              <DayPlanJobRow
                id={job.id}
                key={'job' + i}
                start={job.preferredstartdatetime ? moment(job.preferredstartdatetime).format('HH:mm') : 'N/A'}
                duration={job.durationminutes ? job.durationminutes + ' minutes' : 'N/A'}
                description={job.shortdescription}
                property={job.property.name}
                association={item.association}
              />
            );
          break;
        default:
          break;
      }
    });

    plan.rows = rows;
    plan.totalJobDuration = totalJobDuration;
    plan.totalDistance = (totalDistance / 1609.344).toFixed(1);
    plan.totalTravelTime = Math.round(totalTravelTime / 60);
    plan.properties = Object.values(properties);

    dispatch({type: 'PLAN', plan});
  }, [props.fieldWorker, state.day]);

  useEffect(() => {
    getDayPlan();
  }, [state.day, getDayPlan]);

  useEffect(() => {
    if (state.refresh) {
      getDayPlan();
    }
  }, [state.refresh, getDayPlan]);

  useEffect(() => {
    if (change) {
      dispatch({type: 'CHECK_CHANGE', change});
    }
  }, [change]);

  const { plan } = state;

  if (!plan) {
    return <h4>loading...</h4>
  }

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>
          <ButtonGroup size="sm" className="float-right">
            <Button onClick={() => dispatch({type: 'PREV_DAY'})}>
              previous day
            </Button>
            <Button onClick={() => dispatch({type: 'NEXT_DAY'})}>
              next day
            </Button>
          </ButtonGroup>
          <div className="mr-2 float-right">
            <DatePickerButton
              selected={state.day}
              onChange={day => dispatch({type: 'CHANGE_DAY', day})}
              size="sm"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          {plan.rows && plan.rows.length > 0 && 
          <Row className="mb-3 text-center">
            <Col>
              <Card>
                <CardBody>
                <strong>total job duration</strong><br />
                {plan.totalJobDuration} minutes
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <strong>total distance</strong><br />
                  {plan.totalDistance} miles
                </CardBody>
              </Card>
            </Col>            
            <Col>
              <Card>
                <CardBody>
                  <strong>total travel time</strong><br />
                  {plan.totalTravelTime} minutes
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
          <Row>
            <Col>
            <ListGroup>{plan.rows}</ListGroup>
            </Col>
          </Row>
        </Col>
        <Col md="4">
          {plan.properties && plan.properties.length > 0 &&
          <Card className="mb-3">
            <GoogleMap markers={plan.properties.map(property => {
              return {
                title: property.name,
                latitude: property.address.latitude,
                longitude: property.address.longitude,
              }
            })} 
            />
          </Card>
          }        
        </Col>
      </Row>
    </React.Fragment>
  );

}

const DayPlanJobRow = (props) => {
  return (
    <ListGroupItem>
      <Row>
        <Col>
          <Row>
            <Col md="auto">
              <Icon icon="hammer" size="3x" />
            </Col>
            {props.association &&
            <Col md="auto">
              <Icon icon="hands-helping" size="3x" />
            </Col>
            }
            <Col>
              <Row>
                <Col md="auto">
                  <strong>id:</strong> {props.id}
                </Col>
                <Col md="auto">
                  <strong>start:</strong> {props.start}
                </Col>
                <Col md="auto">
                  <strong>duration:</strong> {props.duration}
                </Col>                  
                <Col>
                  <strong>property:</strong> {props.property}
                </Col>                  
              </Row>
              <Row>
                <Col>
                  <strong>description:</strong> {props.description}
                </Col>
              </Row>
            </Col>
          </Row>             
        </Col>
      </Row>
    </ListGroupItem>
  );
}

const DayPlanTravelRow = (props) => {
  return (
    <ListGroupItem color="info" className={!props.destination.startsWith("base") ? "box arrow-bottom blue" : ""}>
      <Row>
        <Col md="auto">
          <Icon icon="car-side" size="3x" />
        </Col>
        <Col>
          <Row>
            <Col>
              <strong>origin</strong><br />{props.origin}
            </Col>
            <Col>
              <strong>destination</strong><br />{props.destination}
            </Col>
            <Col>
              <strong>distance</strong><br />{props.distance}
            </Col>
            <Col>
              <strong>duration</strong><br />{props.duration}
            </Col>
          </Row>             
        </Col>
      </Row>
    </ListGroupItem>
  );
}
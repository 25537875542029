import { Component } from 'react';

class LogOut extends Component {
  componentDidMount = () => {
    this.props.logOut();
  }

  componentWillUnmount = () => {
    this.props.whoAmI();
  }

  render() {
    return 'You have been logged out'
  }
}

export default LogOut;

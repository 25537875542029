var PropTypes = require('prop-types');
var React = require('react');
var sanitizeHtml = require('sanitize-html');

function SanitizedHTML(props) {
 
  function getHTML() {
    //Use sanitize-html (https://www.npmjs.com/package/sanitize-html) to make sure the HTML can be rendered safely
    return {
      __html: sanitizeHtml(
        props.HTML,
        {
          allowedTags: [
            'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'li', 'b', 'i', 'span', 'code',
            'strong', 'em', 'sub', 'sup', 'br', 'p', 'img', 'table', 'thead', 'tbody', 'tr', 'td', 'tfoot', 'pre', 'hr'
          ],
          allowedAttributes: {
            a: ['href', 'target'],
            img: [ 'src', 'width', 'height' ],
            '*': ['style']
          }
        }
      )
    };
  }

  return <div dangerouslySetInnerHTML={ getHTML() } />;
}

SanitizedHTML.propTypes = {
  HTML: PropTypes.string.isRequired
};

module.exports = SanitizedHTML;


import React, { useContext, useEffect, useState } from 'react';
import { Collection, common } from 'plato-js-client';
import { 
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row } from 'reactstrap';
import { Store } from '../Store';
import Check from '../fields/Check';
import Icon from '../components/Icon';
import SelectList from '../fields/SelectList';
import Text from '../fields/Text';
import { successToast } from '../components/Toasties';
import moment from 'moment';
import { updateJob } from '../Actions';

export default function JobChecklistBlock({job}) {

  const {
    state: {
      checklists,
      currentGroup,
    }
  } = useContext(Store);

  const [addJobChecklistModal, setAddJobChecklistModal] = useState(false);
  const [jobChecklists, setJobChecklists] = useState();
  const [checklist, setChecklist] = useState();

  const getJobChecklists = async () => {
    var collection = new Collection({
      path: 'pmschecklist',
      object: common.WorkOrderPmsChecklist,
      parent: job,
    });

    await collection.fetch();
    setJobChecklists(collection.collection);
  }

  useEffect(() => {
    if (job) {
      getJobChecklists();
    }
  }, []);

  const toggleAddJobChecklistModal = () => {
    setAddJobChecklistModal(!addJobChecklistModal);
  }

  const addChecklist = () => {
    let wocl = new common.WorkOrderPmsChecklist();
    wocl.parent = job;
    wocl.pmschecklist = checklist;
    wocl.json = "{}";
    wocl.updateddatetime = moment();

    wocl.create().then(() => {
      getJobChecklists();
      toggleAddJobChecklistModal();
      successToast('new checklist added!');
    });
  }

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col>
          {jobChecklists && jobChecklists.length > 0 &&
          <ListGroup className="mt-2 mb-2">
            {jobChecklists.map(cl => 
            <JobChecklistBlockRow key={cl.id} job={job} checklist={cl} getJobChecklists={getJobChecklists} />
            )}
          </ListGroup>
          }
          <Button onClick={toggleAddJobChecklistModal} disabled={!job}>add checklist</Button>{' '}
        </Col>
      </Row>
      {addJobChecklistModal &&
      <Modal isOpen toggle={toggleAddJobChecklistModal} size="lg">
        <ModalHeader toggle={toggleAddJobChecklistModal}>
          add checklist
        </ModalHeader>
        <ModalBody>
          <Input 
            type="select" 
            onChange={e => {
              const cl = checklists.find(cl => cl.id === parseInt(e.target.value, 10));
              if (cl) {
                setChecklist(cl);
              } else {
                setChecklist(undefined);
              }
            }}
          >
            <option key={0} value={0}>select a checklist</option>
            {checklists
              .filter((cl) => cl.inactive === false)
              .map((cl) => (
                <option key={cl.id} value={cl.id}>
                  {cl.name}
                </option>
              ))
            }
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={!checklist} onClick={addChecklist}>add</Button>{' '}
          <Button color="secondary" onClick={toggleAddJobChecklistModal}>cancel</Button>
        </ModalFooter>
      </Modal>      
      }
    </React.Fragment>
  );
}

function JobChecklistBlockRow({checklist, job, getJobChecklists}) {

  const { dispatch } = useContext(Store);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const removeChecklist = id => {
    let wopc = new common.WorkOrderPmsChecklist(id);
    wopc.parent = job;

    wopc.delete().then(() => {
      getJobChecklists();
    });
  }

  return (
    <ListGroupItem className="skinny">
      <Row>
        <Col>
          {checklist.pmschecklist.name}  
        </Col>
        <Col md="auto">
          <Icon icon="eye" onClick={toggleModal} />
          {' '}
          <Icon icon="trash" onClick={() => {
            if (window.confirm('Are you sure you want to remove this checklist?')) {
              removeChecklist(checklist.id);
            }
          }} />
        </Col>
      </Row>
      {modal &&
      <JobChecklistModal job={job} checklist={checklist} toggle={toggleModal} />
      }
    </ListGroupItem>
  );
}

function JobChecklistModal({job, checklist, toggle}) {

  const [parsed, setParsed] = useState();
  const [answers, setAnswers] = useState();

  useEffect(() => {
    var parsed = JSON.parse(checklist.json);

    if (!parsed.groups) {
      // checklist hasn't been filled in yet
      parsed = JSON.parse(checklist.pmschecklist.json);
    }

    let answers = {};

    for (const gi in parsed.groups.sort((a, b) => (a.order > b.order) ? 1 : -1)) {
      const g = parsed.groups[gi];
      for (const qi in g.questions.sort((a, b) => (a.order > b.order) ? 1 : -1)) {
        const q = g.questions[qi];
        switch (q.type) {
          case 'check':
              answers[gi + '-' + qi] = q.checked || false;
              break;
          case 'checklist':
              answers[gi + '-' + qi] = q.answers || [];
              break;
          default:
              answers[gi + '-' + qi] = q.answer || '';
        }
      }
    }

    setParsed(parsed);
    setAnswers(answers);
  }, []);

  if (!parsed || !answers) {
    return null;
  }

  const handleChange = (groupIndex, questionIndex, value) => {
    let a = {...answers};
    a[groupIndex + '-' + questionIndex] = value;
    setAnswers(a);
  }

  const handleSave = () => {
    let p = {...parsed};
    p.groups.sort((a, b) => (a.order > b.order) ? 1 : -1).forEach((group, gi) => {
      group.questions.sort((a, b) => (a.order > b.order) ? 1 : -1).forEach((question, qi) => {
        if (Object.keys(answers).includes(gi + '-' + qi)) {
          const val = answers[gi + '-' + qi];

          switch (question.type) {
            case 'check':
              question.checked = val;
              break;
            case 'checklist':
              question.answers = val;
              break;
            default:
              question.answer = val;
          }
        }
      });
    });

    const newJson = JSON.stringify(p);
    
    checklist.update({
      json: newJson
    }).then(() => {
      successToast('changes saved!');
      toggle();
    });
  }

  const previewChecklist = (answersonly) => {
    let cl = new common.WorkOrderPmsChecklist(checklist.id);
    cl.answersonly = answersonly;
    cl.parent = job;

    cl.render().then((res) => {
      var link = document.createElement('a');
      link.setAttribute('href', res.entity.url);
      link.setAttribute('download', 'checklist' + checklist.id + '.pdf');
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  return (
    <Modal isOpen toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        checklist
      </ModalHeader>
      <ModalBody>
      <Button onClick={() => { previewChecklist(true);}} className="mb-2">download PDF</Button>
      <Button onClick={() => { previewChecklist(false);}} style={{marginLeft: '5px'}} className="mb-2">download full PDF</Button>
      {parsed.groups.sort((a, b) => (a.order > b.order) ? 1 : -1).map((group, gi) =>
        <div key={'group' + gi}>
          <div>
            <h4>{group.name}</h4>
          </div>
          {group.questions.sort((a, b) => (a.order > b.order) ? 1 : -1).map((question, qi) => {
            switch(question.type) {
              case 'multi':
                return (
                  <JobChecklistMulti 
                    key={'group' + gi + 'question' + qi} 
                    question={question} 
                    value={answers[gi + '-' + qi]}
                    handleChange={value => handleChange(gi, qi, value)}
                  />
                );
              case 'text':
                return (
                  <JobChecklistText 
                    key={'group' + gi + 'question' + qi} 
                    question={question}
                    value={answers[gi + '-' + qi]}         
                    handleChange={value => handleChange(gi, qi, value)}
                  />
                );
              case 'checklist':
                return (
                  <JobChecklistChecklist 
                    key={'group' + gi + 'question' + qi} 
                    question={question} 
                    value={answers[gi + '-' + qi]}
                    handleChange={value => handleChange(gi, qi, value)}
                  />
                );
              case 'check':
                return (
                  <JobChecklistCheck 
                    key={'group' + gi + 'question' + qi} 
                    question={question} 
                    value={answers[gi + '-' + qi]}
                    handleChange={value => handleChange(gi, qi, value)}
                  />
                );
            }
          })}
          <hr />
        </div>
      )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>save changes</Button>{' '}
        <Button color="secondary" onClick={toggle}>cancel</Button>
      </ModalFooter>
    </Modal>
  );

}

function JobChecklistMulti({question, value, handleChange}) {
  return (
    <SelectList
      name={question.question}
      value={value}
      values={question.options.sort((a, b) => (a.order > b.order) ? 1 : -1)}
      optionName="option"
      emptyValue="select an option"
      onChange={e => handleChange(e.target.value)}
    />
  );
}

function JobChecklistText({question, value, handleChange}) {
  return (
    <Text
      name={question.question}
      value={value}
      onChange={e => handleChange(e.target.value)}
    />
  );
}

function JobChecklistChecklist({question, value, handleChange}) {
  return (
    <FormGroup row>
      <Label sm={4}>{question.question}</Label>
      <Col sm={8}>
        {question.options.sort((a, b) => (a.order > b.order) ? 1 : -1).map((option, i) => 
        <FormGroup check key={i}>
          <Label check>
            <Input 
              type="checkbox" 
              name={question.question + option.id} 
              onChange={e => {
                let v = [...value];
                if (e.target.checked) {
                  v.push(option.id);
                } else {
                  v.splice(v.indexOf(option.id), 1);
                }

                handleChange(v);
              }}
              checked={value.includes(option.id)}
            />{' '}
            {option.option}
          </Label>
        </FormGroup>
        )}             
      </Col>
    </FormGroup>
  );
}

function JobChecklistCheck({question, value, handleChange}) {
  return (
    <Check
      name={question.question}
      value={value}
      onChange={e => handleChange(e.target.checked)}
    />
  );
}
import { common, Collection } from 'plato-js-client';

export const getGroups = async () => {
  const setting = await getSetting();
  const groups = JSON.parse(setting.defaultvalue).groups.sort((a, b) => a.name > b.name ? 1 : -1);
  return groups;
}

export const addGroupUser = async (groupId, userType, ref) => {
  const setting = await getSetting();
  const value = JSON.parse(setting.defaultvalue);
  
  let groupIndex = value.groups.findIndex(group => {return group.id === groupId});

  if (!value.groups[groupIndex][userType]) {
    value.groups[groupIndex][userType] = [];
  }

  let arr = [...value.groups[groupIndex][userType]];
  
  if (!arr.includes(ref)) {
    arr.push(ref);
  }

  value.groups[groupIndex][userType] = arr;

  await setting.update({defaultvalue: JSON.stringify(value)});
}

export const removeGroupUser = async (groupId, userType, userId) => {
  const setting = await getSetting();
  const value = JSON.parse(setting.defaultvalue);

  let groupIndex = value.groups.findIndex(group => {return group.id === groupId});

  let arr = [...value.groups[groupIndex][userType]];
    
  if (arr.includes(userId)) {
    arr.splice(arr.indexOf(userId), 1);
  }

  value.groups[groupIndex][userType] = arr;

  await setting.update({defaultvalue: JSON.stringify(value)});
}

export const reorderGroupUser = async (groupId, userType, userId, direction) => {
  const setting = await getSetting();
  const value = JSON.parse(setting.defaultvalue);

  let groupIndex = value.groups.findIndex(group => {return group.id === groupId});

  let arr = [...value.groups[groupIndex][userType]];

  const index = arr.indexOf(userId);

  if (index === -1 || 
      (index === 0 && direction === -1) ||
      (index === arr.length - 1 && direction === 1)) {

    return;
  }

  arr[index] = arr[index + direction];
  arr[index + direction] = userId;

  value.groups[groupIndex][userType] = arr;

  await setting.update({defaultvalue: JSON.stringify(value)});
}

export const setGroupUsers = async (groupId, userType, userIds) => {
  const setting = await getSetting();
  const value = JSON.parse(setting.defaultvalue);

  let groupIndex = value.groups.findIndex(group => {return group.id === groupId});

  value.groups[groupIndex][userType] = userIds;

  await setting.update({defaultvalue: JSON.stringify(value)});
}

export const setGroupProperties = async (groupId, propertyIds) => {
  const setting = await getSetting();
  const value = JSON.parse(setting.defaultvalue);
  
  let groupIndex = value.groups.findIndex(group => {return group.id === groupId});

  value.groups[groupIndex]['propertyIds'] = propertyIds;

  await setting.update({defaultvalue: JSON.stringify(value)});
}

export const setGroupSchedulerColors = async (groupId, colors) => {
  const setting = await getSetting();
  const value = JSON.parse(setting.defaultvalue);
  
  let groupIndex = value.groups.findIndex(group => {return group.id === groupId});

  value.groups[groupIndex]['colors'] = colors;

  await setting.update({defaultvalue: JSON.stringify(value)});
}

export const setGroupInvoiceEmail = async (groupId, subject, message) => {
  const setting = await getSetting();
  const value = JSON.parse(setting.defaultvalue);
  
  let groupIndex = value.groups.findIndex(group => {return group.id === groupId});

  value.groups[groupIndex]['invoiceEmailSubject'] = subject;
  value.groups[groupIndex]['invoiceEmailMessage'] = message;

  await setting.update({defaultvalue: JSON.stringify(value)});
}

const getSetting = async () => {
  const settings = new Collection({
    path: 'setting',
    object: common.Setting,
  });

  await settings.fetch();
  
  const setting = settings.collection.find(s => { return s.name === 'PMS config' });

  return setting;
}
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { 
  Button, 
  ButtonGroup, 
  Col, 
  DropdownItem, 
  DropdownMenu, 
  Row } from 'reactstrap';
import DatePickerButton from './components/DatePickerButton';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const blankCanvas = document.createElement('canvas');

const moment = extendMoment(Moment);

const fieldWorker = [
  {  id: '1', name: 'Chris Dobson' },
  {  id: '2', name: 'Amy Dobson' },
  {  id: '3', name: 'Sebastian Dobson' },
  {  id: '4', name: 'Ann Dobson' },
  {  id: '5', name: 'Martin Dobson' },
  {  id: '6', name: 'Suzannah Dobson' },
  {  id: '7', name: 'Charlotte Oxford' },
  {  id: '8', name: 'Ann Smith' },
  {  id: '9', name: 'Paul Smith' }
];

const job = [
  {
    id: 1,
    duration: 120,
    title: 'test 1',
    resource: '1',
    start: moment('2020-07-03 23:45:00')
  },
  {
    id: 2,
    duration: 120,
    title: 'test 2',
    resource: '1',
    start: moment('2020-07-02 23:45:00'),
    property: 5,
    travelTo: 15,
    travelFrom: 20
  },
  {
    id: 3,
    duration: 90,
    title: 'test 3',
    resource: '1',
    start: moment('2020-06-18 10:30:00')
  },
  {
    id: 4,
    duration: 90,
    title: 'test 4',
    resource: '4',
    start: moment('2020-06-18 10:30:00')
  },
  {
    id: 5,
    duration: 90,
    title: 'test 5',
    resource: '4',
    start: moment('2020-06-18 10:35:00')
  },
  {
    id: 6,
    duration: 90,
    title: 'test 6',
    resource: '4',
    start: moment('2020-06-18 12:30:00')
  },
  {
    id: 7,
    duration: 90,
    title: 'test 7',
    resource: '1',
    start: moment('2020-06-18 09:30:00')
  },
];

const unscheduled = [
  {
    id: 101,
    duration: 60,
    title: 'test 101',
  },
  {
    id: 102,
    duration: 90,
    title: 'test 102',
  },
  {
    id: 103,
    duration: 90,
    title: 'test 103',
  },
];

export default function TabDebug() {

  const [fieldWorkers, setFieldWorkers] = useState();
  const [jobs, setJobs] = useState();
  const [unscheduledJobs, setUnscheduledJobs] = useState();
  const [scale, setScale] = useState("quarter");
  const [day, setDay] = useState(moment());
  const [selectedJob, setSelectedJob] = useState();

  useEffect(() => {      
    setFieldWorkers(
      fieldWorker.map(fw => {
        return {...fw};
      })
    );

    setJobs(
      job.map(j => {
        return {...j};
      })
    );

    setUnscheduledJobs(
      unscheduled.map(u => {
        return {...u};
      })
    );
  }, []);

  if (!fieldWorkers || !job || !unscheduledJobs) {
    return null;
  }

  const handleOutsideClick = () => {
    const contextMenu = document.getElementById('context-menu');
    contextMenu.style.display = "none";
  }

  const gridChangeHandler = (e) => {
    switch (e.action) {
      case 'create': { // the job has been scheduled
        let newUnscheduledJobs = [...unscheduledJobs];
        let jobIndex = newUnscheduledJobs.findIndex(uj => uj.id === e.id);

        let newJobs = [...jobs];
        newJobs.push({
          ...newUnscheduledJobs[jobIndex],
          start: e.start,
          resource: e.resource,
        });

        newUnscheduledJobs.splice(jobIndex, 1);

        setUnscheduledJobs(newUnscheduledJobs);
        setJobs(newJobs);

        break;
      }
      case 'update':
        let newJobs = [...jobs];
        let job = newJobs.find(nj => nj.id === e.id);
        
        if (e.hasOwnProperty('start')) { job.start = e.start; }
        if (e.hasOwnProperty('resource')) { job.resource = e.resource; }
        if (e.hasOwnProperty('duration')) { job.duration = e.duration; }

        setJobs(newJobs);
        break;
      case 'remove': { // the job has been unscheduled
        let newUnscheduledJobs = [...unscheduledJobs];
        newUnscheduledJobs.push({
          id: e.id,
          duration: e.duration,
          title: e.title,
        });
        setUnscheduledJobs(newUnscheduledJobs);

        let newJobs = [...jobs];
        newJobs.splice(
          newJobs.findIndex(nj => nj.id === e.id),
          1
        );
        setJobs(newJobs);

        break;
      }
      case 'select':
        let selected = jobs.find(job => job.id === e.id);
        setSelectedJob(selected);

        // add property of selected job to resources
        let newFieldWorkers = [...fieldWorkers];

        if (newFieldWorkers[0].id.startsWith('property')) {
          newFieldWorkers.splice(0, 1);
        }

        if (selected.hasOwnProperty('property')) {
          newFieldWorkers.unshift({
            id: 'property' + selected.property, name: 'property' + selected.property
          });
        }

        setFieldWorkers(newFieldWorkers);

        // todo: get bookings 

        break;
      default:
        alert('unrecognised action');
        break;
    }
  }

  const navigate = where => {
    const newDay = moment(day);

    let unit = 'days';
    if (scale === 'hour') { unit = 'weeks'; }
    if (scale === 'day') { unit = 'months'; }

    switch (where) {
      case 'prev': newDay.subtract(1, unit); break;
      case 'now': newDay = moment(); break;
      case 'next': newDay.add(1, unit); break;
      default: newDay = moment(where); break;
    }

    setDay(newDay);
  }
  
  return (
    <div onClick={handleOutsideClick}>
      <h3>Debug</h3>
      <p>debug</p>

      {selectedJob && selectedJob.id}

      <div className="d-flex flex-wrap mb-2">
        {unscheduledJobs.map(
          job => <UnscheduledJob key={'unscheduled' + job.id} id={'unscheduled' + job.id} job={job} />
        )}
      </div>

      <div className="bg-dark p-2">
        <Row className="align-items-center m-0">
          <Col>
          </Col>
          <Col xs="auto">
            <ButtonGroup className="mr-2">
              <Button color="light" size="sm" onClick={() => { setScale("quarter") }} active={scale === "quarter"}>quarter hour</Button>
              <Button color="light" size="sm" onClick={() => { setScale("half") }} active={scale === "half"}>half hour</Button>
              <Button color="light" size="sm" onClick={() => { setScale("hour") }} active={scale === "hour"}>hour</Button>
              <Button color="light" size="sm" onClick={() => { setScale("day") }} active={scale === "day"}>day</Button>
            </ButtonGroup>
            <ButtonGroup className="mr-2">
              <Button color="light" size="sm" onClick={() => { navigate('prev') }}>
                previous{' '}
                {scale === "quarter" && <span>day</span>}
                {scale === "half" && <span>day</span>}
                {scale === "hour" && <span>week</span>}
                {scale === "day" && <span>month</span>}
              </Button>
              <Button color="light" size="sm" onClick={() => { navigate('now') }}>today</Button>
              <Button color="light" size="sm" onClick={() => { navigate('next') }}>
                next{' '}
                {scale === "quarter" && <span>day</span>}
                {scale === "half" && <span>day</span>}
                {scale === "hour" && <span>week</span>}
                {scale === "day" && <span>month</span>}
              </Button>
            </ButtonGroup>
            <DatePickerButton 
              color="light" 
              selected={day} 
              onChange={date => navigate(date)} 
              size="sm"
            />
          </Col>
        </Row>
      </div>

      <Grid 
        scale={scale}
        day={day}
        resources={fieldWorkers} 
        events={jobs}
        unscheduledEvents={unscheduledJobs}
        emitChanges={gridChangeHandler} 
        gridDragEnterEvent={() => {
          console.log('drag enter');

          var ghost = document.getElementById('ghost');
          if (ghost) {
            ghost.style.display = 'none';
          }
        }}  
        gridDragLeaveEvent={() => {
          console.log('drag leave');

          var ghost = document.getElementById('ghost');
          if (ghost) {
            ghost.style.display = 'block';
          }
        }}
      /> 
    </div>
  );
}

function Grid(props) {

  const reducer = (state, action) => {
    switch (action.type) {
      case 'CREATE_EVENT': {
        let start = moment(props.day).startOf('day');
        let minutes = (action.left / 40) * 15;
        start.add(minutes, 'minutes');
  
        const resource = state.resourceMeta.find(
          resource => action.top >= resource.yStart && action.top <= resource.yEnd
        );

        let changes = {
          id: action.eventId,
          start,
          resource: resource.id,
          action: 'create',
        };

        // todo: handle removing ghost image some other way..? 
        const ghost = document.getElementById('ghost');
        if (ghost) {
          ghost.remove();
        }

        return {...state, changes};
      }
      case 'UPDATE_EVENT': {
        let event = props.events.find(
          event => event.id === parseInt(action.eventId, 10)
        );

        let changes = { 
          id: event.id,
          start: event.start,
          resource: event.resource,
          action: 'update'
        };

        if (action.hasOwnProperty('left')) {
          let start = moment(props.day).startOf('day');
          let minutes = (action.left / 40) * 15;
          start.add(minutes, 'minutes');
          changes.start = start;
        }

        if (action.hasOwnProperty('top')) {
          const resource = state.resourceMeta.find(
            resource => action.top >= resource.yStart && action.top <= resource.yEnd
          );
          changes.resource = resource.id
        }

        if (action.hasOwnProperty('width')) {
          const minutes = (action.width / 40) * 15;
          changes.duration = minutes; 
        }

        return {...state, changes};
      }
      case 'REMOVE_EVENT': {
        let event = props.events.find(
          event => event.id === parseInt(action.payload, 10)
        );

        let changes = {
          id: action.payload,
          duration: event.duration,
          title: event.title,
          action: 'remove',
        }

        return {...state, changes};
      }
      case 'SET_RESOURCE_META':
        return {...state, resourceMeta: action.payload};
      case 'SET_EVENT_META':
        return {...state, eventMeta: action.payload};           
      case 'SET_RENDERED':
        return {...state, rendered: action.payload}; 
      case 'SET_SELECTED_EVENT': {
        let changes = {
          id: action.payload.id,
          action: 'select',
        }

        return {
          ...state, 
          selectedEvent: action.payload, 
          changes
        };
      }
      case 'SET_CONTEXT_MENU_EVENT':
        return {...state, contextMenuEvent: action.payload};
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    resourceMeta: undefined,
    eventMeta: undefined,
    rendered: undefined,
    selectedEvent: undefined,
    contextMenuEvent: undefined,
    changes: undefined,
  });

  useEffect(() => {
    getMeta();
  }, [props.resources, props.events, props.day, props.scale]);

  useEffect(() => {
    if (state.changes) {
      props.emitChanges(state.changes);
    }
  }, [state.changes]);

  const blockHover = useRef(false);
  const hoveredCell = useRef(undefined);

  const styleTime = (x, highlighted) => {
    const timeDiv = document.getElementById('times' + x);

    if (highlighted) {
      timeDiv.classList.add('csdscheduler-time-highlight');
    } else {
      timeDiv.classList.remove('csdscheduler-time-highlight');
    }
  }

  const styleResource = (y, highlighted) => {
    const resourceDiv = document.getElementById('resource' + y);

    if (highlighted) {
      resourceDiv.classList.add('csdscheduler-resource-highlight');
    } else {
      resourceDiv.classList.remove('csdscheduler-resource-highlight');
    }
  }

  const styleSlot = (x, y, highlighted) => {
    const slotDiv = document.getElementById('slot' + y + '-' + x);

    if (highlighted) {
      slotDiv.classList.add('csdscheduler-slot-highlight');
    } else {
      slotDiv.classList.remove('csdscheduler-slot-highlight');
    }
  }

  const getMeta = () => {
    let yCounter = 0;

    let dayOrWeekOrMonth = undefined;
    let minutesPerCell = undefined;
    
    switch (props.scale) {
      case 'quarter':
        dayOrWeekOrMonth = 'day';
        minutesPerCell = 15;
        break;
      case 'half':
        dayOrWeekOrMonth = 'day';
        minutesPerCell = 30;
        break;
      case 'hour':
        dayOrWeekOrMonth = 'week';
        minutesPerCell = 60;
        break;
      case 'day':
        dayOrWeekOrMonth = 'month';
        minutesPerCell = 1440;
        break;
    }

    const startOfPeriod = moment(props.day).startOf(dayOrWeekOrMonth);
    const endOfPeriod = moment(props.day).endOf(dayOrWeekOrMonth);
    const minutesInPeriod = endOfPeriod.diff(startOfPeriod, 'minutes') + 1;
    const horizontalSlots = minutesInPeriod / minutesPerCell;

    let resourceMeta = props.resources.map(r => {
      return {...r};
    });

    let eventMeta = [];
    props.events.forEach(e => {
      // check whether event falls within current day/week/month
      let eventStart = moment(e.start);
      let eventEnd = moment(eventStart).add(e.duration, 'minutes');

      if (eventStart.isBefore(endOfPeriod) && eventEnd.isAfter(startOfPeriod)) {
        eventMeta.push({...e});
      }
    });

    resourceMeta.forEach(resource => {
      const eventsFiltered = eventMeta
        .filter(event => event.resource === resource.id)
        .sort((a, b) => (a.start > b.start) ? 1 : -1);

      let mostClashes = 0;
  
      eventsFiltered.forEach(event => {
        let clashes = 0;

        let rangeA = moment.range(
          event.start, 
          moment(event.start).add(event.duration, 'minutes')
        );
  
        eventsFiltered.forEach(otherEvent => {
          if (event.id === otherEvent.id || otherEvent.hasOwnProperty('yOffset')) { return; }

          const rangeB = moment.range(
            otherEvent.start,
            moment(otherEvent.start).add(otherEvent.duration, 'minutes')
          );

          if (rangeA.overlaps(rangeB)) { 
            rangeA = moment.range(
              otherEvent.start, 
              moment(otherEvent.start).add(otherEvent.duration, 'minutes')
            );

            clashes += 1;
            otherEvent.yOffset = clashes;
            otherEvent.yLocation = yCounter + (clashes * 40);
          }
        });

        if (!event.yOffset) { 
          event.yOffset = 0;
          event.yLocation = yCounter;
        }

        let startMinutesIntoPeriod = event.start.diff(startOfPeriod, 'minutes');
        let endMinutesIntoPeriod = startMinutesIntoPeriod + event.duration;

        let startOffset = 0;
        let endOffset = 0;

        if (startMinutesIntoPeriod < 0) { startOffset = startMinutesIntoPeriod; }
        if (endMinutesIntoPeriod > minutesInPeriod) { endOffset = endMinutesIntoPeriod - minutesInPeriod; }

        event.xLocation = Math.round(((startMinutesIntoPeriod - startOffset) / minutesPerCell) * 40);
        event.startOffset = Math.round((startMinutesIntoPeriod / minutesPerCell) * 40) - event.xLocation; // used by drag shadow
        event.width = (event.duration + startOffset - endOffset) / minutesPerCell * 40;
        event.originalWidth = event.duration / minutesPerCell * 40; // used by drag shadow

        if (event.travelTo) { Math.round(event.travelToWidth = event.travelTo / minutesPerCell * 40); }
        if (event.travelFrom) { Math.round(event.travelFromWidth = event.travelFrom / minutesPerCell * 40); }

        if (clashes > mostClashes) { mostClashes = clashes; }
      });

      resource.ySize = mostClashes += 1;
      resource.height = resource.ySize * 40;
      resource.yStart = yCounter;
      yCounter += resource.height;
      resource.yEnd = yCounter - 1;
    });

    dispatch({ type: 'SET_RESOURCE_META', payload: resourceMeta });
    dispatch({ type: 'SET_EVENT_META', payload: eventMeta });

    var timeDivs = [];
    var timeGroupDivs = [];
    var resourceDivs = [];
    var leftHeader = [];

    for (var slot = 0; slot < horizontalSlots; slot++) {

      if (props.scale === 'quarter') {
        const hour = slot / 4;
        const timeInHour = (hour % 1) * 60;
        
        if (slot % 4 === 0) {
          const hourFormatted = (hour <= 12 ? hour : (hour % 12)) + (hour <= 11 ? 'AM' : 'PM');

          timeGroupDivs.push(
            <div key={'timesgroup' + slot} className="csdscheduler-time-group-cell" style={{ flex: "0 0 160px" }}>
              {hourFormatted}
            </div>
          );
        }
  
        timeDivs.push(
          <div key={'times' + slot} id={'times' + slot} className="csdscheduler-time-cell">
              {timeInHour.toString().padStart(2, '0')}
          </div>
        );
      }

      if (props.scale === 'half') {
        const hour = slot / 2;
        const timeInHour = (hour % 1) * 60;
  
        if (slot % 2 === 0) {
          const hourFormatted = (hour <= 12 ? hour : (hour % 12)) + (hour <= 11 ? 'AM' : 'PM');

          timeGroupDivs.push(
            <div key={'timesgroup' + slot} className="csdscheduler-time-group-cell" style={{ flex: "0 0 80px" }}>
              {hourFormatted}
            </div>
          );
        }
  
        timeDivs.push(
          <div key={'times' + slot} id={'times' + slot} className="csdscheduler-time-cell">
              {timeInHour.toString().padStart(2, '0')}
          </div>
        );
      }

      if (props.scale === 'hour') {
        const hourFormatted = (slot <= 12 ? slot : (slot % 12)) + (slot <= 11 ? 'AM' : 'PM');
  
        if (slot % 24 === 0) {
          const dayNumber = Math.floor(slot / 24);
          const dayFormatted = moment(startOfPeriod).add(dayNumber, 'days').format('DD/MM/YYYY');

          timeGroupDivs.push(
            <div key={'timesgroup' + slot} className="csdscheduler-time-group-cell" style={{ flex: "0 0 960px" }}>
              {dayFormatted}
            </div>
          );
        }
  
        timeDivs.push(
          <div key={'times' + slot} id={'times' + slot} className="csdscheduler-time-cell">
              {hourFormatted}
          </div>
        );
      }

      if (props.scale === 'day') {
        if (slot === 0) {
          const flexWidth = (horizontalSlots * 40) + 'px';

          timeGroupDivs.push(
            <div key={'timesgroup' + slot} className="csdscheduler-time-group-cell" style={{ flex: `0 0 ${flexWidth}` }}>
              {startOfPeriod.format('MMMM YYYY')}
            </div>
          );
        }
  
        timeDivs.push(
          <div key={'times' + slot} id={'times' + slot} className="csdscheduler-time-cell">
              {slot}
          </div>
        );
      }

    }
  
    resourceMeta.forEach(resource => {
      leftHeader.push(
        <div 
          key={'resource' + resource.id} 
          id={'resource' + resource.id} 
          className="csdscheduler-resource-cell"
          style={{ height: resource.height + 'px' }}
        >
          {resource.name}
        </div>
      );

      var slotDivs = [];
  
      for (var slot = 0; slot < horizontalSlots; slot++) {
        slotDivs.push(
          <div 
            key={'slot' + resource.id + '-' + slot} 
            id={'slot' + resource.id + '-' + slot}
            className="csdscheduler-grid-cell"
            style={{ height: resource.height + 'px' }}
          ></div>
        );
      };
  
      resourceDivs.push(
        <div key={'resourcerow' + resource.id} className="csdscheduler-grid-row">
          {slotDivs}
        </div>
      );
    });

    dispatch({
      type: 'SET_RENDERED', 
      payload: {
       timeDivs,
       timeGroupDivs,
       resourceDivs,
       leftHeader,
      }
    });
  };

  const getSlotFromX = x => {
    const slot = Math.floor(x / 40);
    return slot < state.rendered.timeDivs.length ? slot : -1;
  }

  const getResourceFromY = y => {
    const resource = state.resourceMeta.find(
      resource => y >= resource.yStart && y <= resource.yEnd
    );
    return resource;
  }

  const scrollHandler = e => {
    const resourcesDiv = document.getElementById("resources");
    const timesDiv = document.getElementById("times");
    const timesGroupDiv = document.getElementById("times-group")
    const gridDiv = document.getElementById("grid");
    const bottomLeftCornerDiv = document.getElementById("bottom-left-corner");
    const topRightCornerDiv = document.getElementById("top-right-corner");
    const topRightCornerDiv2 = document.getElementById("top-right-corner-2");

    const scrollBarSize = gridDiv.offsetWidth - gridDiv.clientWidth;

    resourcesDiv.scrollTop = e.target.scrollTop;
    timesDiv.scrollLeft = e.target.scrollLeft;
    timesGroupDiv.scrollLeft = e.target.scrollLeft;
    bottomLeftCornerDiv.style.flex = `0 0 ${scrollBarSize}px`;
    topRightCornerDiv.style.flex = `0 0 ${scrollBarSize}px`;
    topRightCornerDiv2.style.flex = `0 0 ${scrollBarSize}px`;
  }

  const dragEnterHandler = e => {
    e.preventDefault();

    props.gridDragEnterEvent();
  }

  const dragOverHandler = e => {
    e.preventDefault();
    
    const rect = e.currentTarget.getBoundingClientRect();
    const shadow = document.getElementById('drag-shadow');
    const leftOffset = parseInt(shadow.getAttribute('leftOffset'), 10);

    let y = snapToGrid(e.clientY - rect.y + e.currentTarget.scrollTop);
    if (y < 0) { y = 0; }

    const resource = getResourceFromY(y);

    if (resource) {
      let x = snapToGrid(e.clientX - rect.x + e.currentTarget.scrollLeft - leftOffset);

      shadow.style.left = x + 'px';
      shadow.style.top = resource.yStart + 'px';
      shadow.style.height = resource.height + 'px';
      shadow.style.display = "block";
    }


  }

  const dragLeaveHandler = e => {
    e.preventDefault();

    props.gridDragLeaveEvent();

    // event has been dragged outside of the grid
    const shadow = document.getElementById('drag-shadow');
    shadow.style.display = 'none';
  }

  const dropHandler = e => {
    const shadow = document.getElementById('drag-shadow');
    shadow.style.display = 'none';

    const data = e.dataTransfer.getData("text").split(',');

    if (data.length !== 2) {
      return;
    }

    const eventId = data[0];
    const leftOffset = parseInt(data[1]);

    const event = document.getElementById(eventId);
    const rect = e.currentTarget.getBoundingClientRect();

    const left = snapToGrid(e.clientX - rect.x + e.currentTarget.scrollLeft - leftOffset);
    const top = snapToGrid(e.clientY - rect.y + e.currentTarget.scrollTop);

    // event.style.left = left + 'px';
    // event.style.top = top + 'px';

    if (event.id.startsWith('unscheduled')) {
      dispatch({
        type: 'CREATE_EVENT',
        eventId: parseInt(eventId.replace('unscheduled', ''), 10),
        left,
        top,
      });
    } else {
      dispatch({ 
        type: 'UPDATE_EVENT', 
        eventId: parseInt(eventId.replace('event', ''), 10),
        left,
        top
      });
    }

    // TODO: REMOVE THIS HORRIBLENESS
    [
      'csdscheduler-time-highlight', 
      'csdscheduler-resource-highlight', 
      'csdscheduler-slot-highlight'
    ].forEach(cls => {
      const elements = document.getElementsByClassName(cls);
      Array.from(elements).forEach(element => {
        element.classList.remove(cls);
      });
    });

    blockHover.current = true;

    setTimeout(() => {
      blockHover.current = false;
    }, 400);

  }

  const snapToGrid = val => {
    return Math.floor(val / 40) * 40;
  }

  const mouseMoveHandler = e => {
    const rect = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - rect.x + e.currentTarget.scrollLeft;
    const mouseY = e.clientY - rect.y + e.currentTarget.scrollTop;

    const slotX = getSlotFromX(mouseX);

    const resource = getResourceFromY(mouseY);
    const slotY = resource ? parseInt(resource.id, 10) : -1;
    const slotXY = (slotX >= 0 && slotY >= 0) ? (slotX + '-' + slotY) : undefined;

    if (hoveredCell.current && hoveredCell.current !== slotXY) {
      const current = hoveredCell.current.split('-');
      
      styleTime(current[0], false);
      styleResource(current[1], false);
      styleSlot(current[0], current[1], false);

      hoveredCell.current = undefined;
    }

    if (!hoveredCell.current && slotXY) {
      styleTime(slotX, true);
      styleResource(slotY, true);
      styleSlot(slotX, slotY, true);

      hoveredCell.current = slotXY;
    }
  }

  const mouseLeaveHandler = e => {
    if (hoveredCell.current) {
      const current = hoveredCell.current.split('-');
      
      styleTime(current[0], false);
      styleResource(current[1], false);
      styleSlot(current[0], current[1], false);

      hoveredCell.current = undefined;
    }
  }

  if (!state.rendered) {
    return null;
  }

  return (
    <div className="cdscheduler">
      {/* resource column */}
      <div className="cdscheduler-resource-column">
        <div id="top-left-corner" className="cdscheduler-resource-column-top"></div>
        <div id="resources" className="cdscheduler-resource-column-middle">
          {state.rendered.leftHeader}
        </div>
        <div id="bottom-left-corner" className="cdscheduler-resource-column-bottom"></div>
      </div>
      {/* grid column */}
      <div className="cdscheduler-grid-column">
        <div id="times-group" className="cdscheduler-grid-column-times">
          {state.rendered.timeGroupDivs}
          <div id="top-right-corner-2" className="cdscheduler-grid-column-times-corner"></div>
        </div>
        <div id="times" className="cdscheduler-grid-column-times">
          {state.rendered.timeDivs}
          <div id="top-right-corner" className="cdscheduler-grid-column-times-corner"></div>
        </div>
        <div 
          key="grid"
          id="grid"
          onScroll={scrollHandler}
          onDragEnter={dragEnterHandler}
          onDragOver={dragOverHandler}
          onDragLeave={dragLeaveHandler}
          onDrop={dropHandler}
          onMouseMove={mouseMoveHandler}
          onMouseLeave={mouseLeaveHandler}
        >
          {state.eventMeta.map(event => {
            return (
              <GridEvent 
                key={'event' + event.id}
                id={'event' + event.id}
                event={event}
                dispatch={dispatch}
                setSelectedEvent={() => {
                  dispatch({ type: 'SET_SELECTED_EVENT', payload: event })
                }}
                setContextMenuEvent={() => {
                  dispatch({ type: 'SET_CONTEXT_MENU_EVENT', payload: event })
                }}
              />
            );
          })}
          <div id="drag-shadow" className="cdscheduler-drag-shadow"></div>
          {state.rendered.resourceDivs}
        </div>
      </div>
      <DropdownMenu id={'context-menu'} style={{ position: "fixed" }}>
        <DropdownItem header>{state.contextMenuEvent && state.contextMenuEvent.id}</DropdownItem>
        <DropdownItem>view job</DropdownItem>
        <DropdownItem 
          onClick={() => dispatch({ 
            type: 'REMOVE_EVENT', payload: state.contextMenuEvent && state.contextMenuEvent.id 
          })}
          toggle={false}
        >
          unschedule job
        </DropdownItem>
      </DropdownMenu>        
    </div>
  );
}

function GridEvent(props) {

  const snapToGridUp = val => {
    return Math.ceil(val / 40) * 40;
  }

  const snapToGridDown = val => {
    return Math.floor(val / 40) * 40;
  }

  const [resize, setResize] = useState();

  const handleResize = e => {
    if (!resize) { return; }

    if (resize.side === 'left') {
      const newLeft = snapToGridDown(resize.left + (e.clientX - resize.clientX));
      const newWidth = resize.width + (resize.left - newLeft);

      resize.event.style.left = newLeft + 'px';
      resize.event.style.width = newWidth + 'px';      
    } else {
      const oldRight = resize.left + resize.width;
      const newRight = snapToGridUp(oldRight + (e.clientX - resize.clientX));
      const newWidth = (newRight - resize.left);

      resize.event.style.width = newWidth + 'px';
    }
  }

  const handleEndResize = e => {
    document.removeEventListener('mousemove', handleResize);
    document.removeEventListener('mouseup', handleEndResize);
  }

  useEffect(() => {
    if (resize) {
      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleEndResize);
    }
  }, [resize]);

  const resizerMouseDownHandler = e => {
    e.preventDefault();

    const side = e.target.getAttribute('side');

    setResize({ 
      clientX: e.clientX, 
      width: parseInt(e.target.parentElement.style.width),
      left: parseInt(e.target.parentElement.style.left),
      event: e.target.parentElement,
      side,
    });

    // temporarily make the resize div wider, to avoid ugly cursor pointer issues
    e.target.style.width = "80px";

    if (side === 'left') {
      e.target.style.left = "-40px";
    } else {
      e.target.style.right = "-40px";
    }
  }

  const resizerMouseUpHandler = e => {
    e.preventDefault();
    e.stopPropagation(); // stops job getting selected unintentionally, but stops the mouseup 
                         // EventListener firing, necessitating calling handleEndResize

    setResize();
    handleEndResize();

    props.dispatch({ 
      type: 'UPDATE_EVENT', 
      eventId: props.event.id,
      left: parseInt(e.target.parentElement.style.left),
      width: parseInt(e.target.parentElement.style.width)  
    });

    

    const side = e.target.getAttribute('side');
    e.target.style.width = "20px";

    if (side === 'left') {
      e.target.style.left = "-10px";
    } else {
      e.target.style.right = "-10px";
    }
  }

  const dragStartHandler = e => {
    const rect = e.currentTarget.getBoundingClientRect();
    const leftOffset = snapToGridDown(e.clientX - rect.left - props.event.startOffset);

    e.dataTransfer.setData("text", [e.target.id, leftOffset].join(','));
    e.dataTransfer.setDragImage(blankCanvas, 0, 0);

    const shadow = document.getElementById('drag-shadow');
    shadow.setAttribute('leftOffset', leftOffset);
    shadow.style.width = props.event.originalWidth + 'px';
  }

  const dragEndHandler = e => {
    const shadow = document.getElementById('drag-shadow');
    shadow.style.display = 'none';
  }

  const contextMenuHandler = e => {
      e.preventDefault();
      props.setContextMenuEvent();
      const contextMenu = document.getElementById('context-menu');
      contextMenu.style.display = "block";
      contextMenu.style.left = e.clientX + 'px';
      contextMenu.style.top = e.clientY + 'px';
  }

  const mouseUpHandler = e => {
    if (e.button === 0) {
      e.preventDefault();
      props.setSelectedEvent();
    }
  }

  return (
    <div
      id={props.id}
      className="csdscheduler-event"
      style={{ 
        width: props.event.width + "px", 
        left: props.event.xLocation + "px", 
        top: props.event.yLocation + "px",
      }} 
      draggable 
      onDrag={e => {
        // console.log('dragging event');
      }}
      onDragOver={e => { e.preventDefault(); }}
      onDragStart={dragStartHandler}
      onDragEnd={dragEndHandler}
      onContextMenu={contextMenuHandler}
      onMouseUp={mouseUpHandler}
      onDragLeave={e => {
        // todo: temp
        console.log('leave event')
      }}
    >
      <div 
        side="left"
        className="csdscheduler-event-resizer"
        style={{ left: "-10px" }}
        onMouseDown={resizerMouseDownHandler}
        onMouseUp={resizerMouseUpHandler}
      ></div>
      <div 
        side="right"
        className="csdscheduler-event-resizer"
        style={{ right: "-10px" }}
        onMouseDown={resizerMouseDownHandler}
        onMouseUp={resizerMouseUpHandler}
      ></div>
      {props.event.travelToWidth &&
      <div 
        className="csdscheduler-event-travel-to" 
        style={{ 
          width: props.event.travelToWidth + 'px',
          left: -(props.event.travelToWidth + 1) + 'px'
        }}
      ></div>
      }
      {props.event.travelFromWidth &&
      <div 
        className="csdscheduler-event-travel-from" 
        style={{ 
          width: props.event.travelFromWidth + 'px',
          right: -(props.event.travelFromWidth + 1) + 'px',
        }}
      ></div>
      }
      {props.event.id}<br />
      {props.event.title}
    </div>
  );
}

function UnscheduledJob({id, job}) {

  const dragHandler = e => {
    const ghost = document.getElementById('ghost');
    const x = e.clientX - e.currentTarget.scrollLeft;
    const y = e.clientY - e.currentTarget.scrollTop;

    if (x > 0 && y > 0) {
      ghost.style.left = x + 'px';
      ghost.style.top = y + 'px';
    };
  }

  const dragStartHandler = e => {
    e.dataTransfer.setData("text", [e.target.id, 0].join(','));
    e.dataTransfer.setDragImage(blankCanvas, 0, 0);

    // todo: drag-shadow belongs to grid so should only be handled in there
    const shadow = document.getElementById('drag-shadow');
    shadow.setAttribute('leftOffset', 0);
    shadow.style.width = (job.duration / 15 * 40) + 'px';

    // create a 'ghost' element for the drag image which is a clone of the unscheduled job
    if (document.getElementById('ghost')) {
      document.getElementById('ghost').remove(); 
    }

    var ghost = e.target.cloneNode(true);
    ghost.id = 'ghost';
    ghost.style.position = 'absolute';
    ghost.style.pointerEvents = 'none';
    ghost.style.opacity = .5;
    ghost.style.left = (e.clientX - e.currentTarget.scrollLeft) + 'px';
    ghost.style.top = (e.clientY - e.currentTarget.scrollTop) + 'px';
    document.body.appendChild(ghost);
  }

  const dragEndHandler = e => {
    console.log('drag end')

    // todo: drag-shadow belongs to grid so should only be handled in there
    const shadow = document.getElementById('drag-shadow');
    shadow.style.display = 'none';

    const ghost = document.getElementById('ghost');
    if (ghost) {
      ghost.remove();
    }
  }

  return (
    <Button 
      id={id}
      draggable
      onDragOver={e => {
        e.preventDefault();
      }}
      onDrag={dragHandler}
      onDragStart={dragStartHandler}
      onDragEnd={dragEndHandler}
    >
      {id}
    </Button>
  );
}
import React from 'react';
import { 
  Card, 
  CardBody, 
  CardHeader } from 'reactstrap';

export default function DiscreteCard(props) {

  let cardStyle = { borderRadius: '0' };

  if (props.columns) {
    cardStyle.flex = "1 1 40%";
  }

  let headerStyle = { padding: '.25rem .75rem' };
  let bodyStyle = { padding: '.25rem .75rem' };

  let classes = "m-1";
  if (!props.columns) {
    classes += " flex-fill";
  }
  
  return (
    <Card className={classes} style={cardStyle}>
      {props.title &&
      <CardHeader style={headerStyle}>
        {props.title}
      </CardHeader>
      }
      <CardBody style={bodyStyle}>
        {props.children}
      </CardBody>
    </Card>
  );
}
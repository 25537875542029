import React, { useCallback, useRef } from 'react';

export default function InitialsCircle(props) {

  const stringToColour = useCallback(str => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
  
    return colour;
  }, []);

  const circleStyle = useRef({
    width: props.size ? props.size.toString() + 'px' : '100px',
    height: props.size ? props.size.toString() + 'px' : '100px',
    backgroundColor: stringToColour(props.firstname + props.surname),
  });

  const initialsStyle = useRef({
    fontSize: props.size ? (props.size * .5).toFixed(0) + 'px' : '50px',
  });

  return (
    <div id={props.id} className="avatar-circle" style={circleStyle.current}>
      <div style={{ height: "100%", width: "100%", display: "table" }}>
        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
          <span className="initials" style={initialsStyle.current}>
            {props.firstname.substr(0, 1).toUpperCase() + props.surname.substr(0, 1).toUpperCase()}
          </span>
        </div>
      </div>
    </div>
  );
}
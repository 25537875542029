import React, { useContext, useEffect, useState } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import {
  Button,
  CustomInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table } from 'reactstrap';
import Icon from './Icon';
import { addTab } from '../Actions';
import { Store } from "../Store";
import moment from 'moment';
import { successToast, errorToast } from './Toasties';

export default function DeleteTemplateJobsModal(props) {

  const {
    dispatch
  } = useContext(Store);

  const [jobs, setJobs] = useState();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggleDeleteBtn, setToggleDeleteBtn] = useState(true);
  const [deletedRows, setDeletedRows] = useState([]);
  const [initialSelected, setInitialSelected] = useState(null);

  useEffect(() => {
    const getJobs = async () => {
      try {
        setLoading(true);
        setToggleDeleteBtn(false);

        let jobs = new FilterCollection({
          path: 'workorder',
          object: common.WorkOrder,
        });

        jobs.limit = 999;
        jobs.page = 1;
        jobs.fields = 'id:preferredstartdatetime:status:substatus';
        jobs.addFilters({
          type: 'Instance',
          templateid: props.template.id,
          status: 'New|Started|Completed',
          preferredstartdatetime: '>' + moment().format('YYYY-MM-DDT00.00.00')
        });

        await jobs.fetch();

        // Apply the additional filter here
        const filteredJobs = jobs.collection.filter(job =>
          job.status === "New" ||
          job.status === "Started" ||
          (job.status === "Completed" && (
            !job.substatus.id ||
            (job.substatus.workordersubstatus.substatusname.toLowerCase() === 'job practically completed')
          ))
        );

        setJobs(filteredJobs);
      } catch (error) {
        errorToast(`Error fetching jobs: ${error.message}`);
      } finally {
        setLoading(false);
        setToggleDeleteBtn(true);
      }
    };

    getJobs();
  }, [props.template.id]);

  const toggleSelected = (jobId, checked) => {
    let newSelected = [...selected];

    const jobIndex = jobs.findIndex(j => j.id === jobId);
    const initialSelectedIndex = jobs.findIndex(j => j.id === initialSelected);

    if (checked) {
      // If this is the first selection, or is earlier than the earliest selected job, then set the earliest selected job to this one
      if (initialSelectedIndex === -1 || jobIndex < initialSelectedIndex) {
        setInitialSelected(jobId);
      }

      // Select the current jobId and all jobs with later dates
      for (let i = jobIndex; i < jobs.length; i++) {
        if (!newSelected.includes(jobs[i].id)) {
          newSelected.push(jobs[i].id);
        }
      }
    } else if (jobIndex <= initialSelectedIndex) {
      // Unselect the current jobId and all jobs with later dates
      for (let i = jobIndex; i < jobs.length; i++) {
        newSelected = newSelected.filter(id => id !== jobs[i].id);
      }

      // Clear the initialSelected
      setInitialSelected(null);
    }

    setSelected(newSelected);
  };

  const selectAll = () => {
    setSelected(jobs.map(j => j.id));
  };

  const deselectAll = () => {
    setSelected([]);
  };

  const deleteJobs = async () => {
    if (window.confirm('Do you want to proceed?')) {
      //disable the delete button
      setToggleDeleteBtn(false);

      try {
        //delete the workorders one by one
        for (const id of selected) {
          //'disable' the row to say that one is done
          deletedRows.push(id);
          setDeletedRows(deletedRows);
          let workOrderJob = new common.WorkOrder(id);

          // Delete the Job item
          await workOrderJob.delete();
        }

        successToast('Jobs updated successfully!');
      } catch (error) {
        errorToast(`Error updating the job: ${error.message}`);
      }
      props.toggle();
    }
  }

  return (
    <Modal isOpen toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>Update jobs</ModalHeader>
      <ModalBody>
        <p>
          Any outstanding jobs deriving from this template can now be updated below.
          Once updated, new jobs will be created automatically with the newest template information.
        </p>
        <div className="mb-2 clearfix">
          <Button onClick={deselectAll} className="float-right ml-2" disabled={selected.length === 0}>deselect all</Button>
          <Button onClick={selectAll} className="float-right" disabled={!jobs || jobs.length === 0}>select all</Button>
        </div>
        <Table size="sm" className="">
          <thead>
            <tr>
              <th>id</th>
              <th>start</th>
              <th>status</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {!(jobs && jobs.length > 0) &&
            <tr>
              {loading ?
              <td colSpan="4">Loading...</td>
              :
              <td colSpan="4">There are no outstanding jobs to update.</td>
              }
            </tr>
            }
            {jobs && jobs.length > 0 && jobs.map(job => {
              return (
                //change the colour of the row once processed
                <tr key={job.id} style={deletedRows.indexOf(job.id) !== -1
                    ? { background: 'grey' }
                    : {  }
                }>
                  <td>{job.id}</td>
                  <td>{moment(job.preferredstartdatetime).format('DD/MM/YY HH:mm')}</td>
                  <td>{job.status + (job.substatus.id ? ' - ' + job.substatus.workordersubstatus.substatusname : '')}</td>
                  <td className="align-middle text-center">
                    <Icon
                      icon="pencil-alt"
                      onClick={() => {
                        addTab('editJob', {workOrderId: job.id}, dispatch);
                        props.toggle();
                      }}
                    />{' '}
                    <CustomInput
                      className="d-inline"
                      type="switch"
                      id={"sw" + job.id}
                      checked={selected.includes(job.id)}
                      onChange={e => toggleSelected(job.id, e.target.checked)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        {selected.length > 0 &&
        <Button
          color="secondary"
          onClick={deleteJobs}
          disabled={!toggleDeleteBtn}
        >
          Apply to {selected.length} job(s)
        </Button>
        }
      </ModalFooter>
    </Modal>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { common, Collection, FilterCollection } from 'plato-js-client';
import { 
  Button, 
  ButtonGroup,
  FormGroup,
  Table, 
  Row, 
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from 'reactstrap';
import Icon from './Icon';
import SendContactModal from './SendContactModal';
import SanitizedHTML from './SanitizedHTML.react';
import isHtml from 'is-html';
import SelectList from '../fields/SelectList';
import Text from '../fields/Text';

import { Store } from "../Store";
import moment from 'moment';

export default function ContactHistory(props) {

  const [page, setPage] = useState(1);
  const [contacts, setContacts] = useState();
  const [logContactModal, setLogContactModal] = useState(false);
  const [sendContactModal, setSendContactModal] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const getContacts = () => {
      let contacts = new FilterCollection({
        path: 'contact',
        object: common.Contact,
      });
      contacts.limit = 10;
      contacts.page = page;
      contacts.orderBy = 'contactdatetime_desc';
  
      let filters = undefined;
      if (props.type && props.actor.id) {
        filters = {
          contactentitytype: props.type,
          recipientid: props.actor.id,
        };
      } else {
        filters = {
          templateentityid: props.entityid,
          contacttypeid: 10
        }
      }
  
      contacts.addFilters([filters]);
      
      contacts.fetch().then(() => {
          setContacts(contacts);
      });
    }

    getContacts();

  }, [page, refresh]);

  const changePage = modifier => {
    setPage(page + modifier);
  }

  const toggleLogContactModal = () => {
    setLogContactModal(!logContactModal);
  }

  if (!contacts) {
    return <span>Loading...</span>;
  }

  const contactsFrom = ((page - 1) * contacts.limit) + 1;
  const contactsTo = Math.min((((page - 1) * contacts.limit) + contacts.limit), contacts.total);  

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col>
          <ButtonGroup size="sm" className="float-right">
            <Button 
              onClick={() => changePage(-1)}
              disabled={!contacts.previous}
            >previous page</Button>
            <Button 
              onClick={() => {changePage(1)}}
              disabled={!contacts.next}
            >next page</Button>
          </ButtonGroup>
          <div className="mr-2 p-1 float-right">
            {contacts && contacts.total > 0 && 
            'contact records ' + contactsFrom + ' to ' + contactsTo + ' of ' + contacts.total
            }
          </div>                
        </Col>
      </Row>
      <Table size="sm" striped>
        <thead>
          <tr>
            <th>date</th>
            <th>method</th>
            <th>logged by</th>
            <th>from</th>
            <th>to</th>
            <th>subject</th>
            <th>status</th>
            <th>actions</th>
          </tr>
        </thead>
        <tbody>
          {contacts.total === 0 &&
          <tr>
            <td colSpan="8">No contact records found.</td>
          </tr>        
          }
          {contacts.map(contact => 
            <ContactHistoryRow key={contact.id} contact={contact} />
          )}
        </tbody>
      </Table>
      {props.type && props.actor &&
      <React.Fragment>
        <Button color="primary" onClick={toggleLogContactModal}>log contact</Button>
        <LogContactModal 
          isOpen={logContactModal} 
          toggle={toggleLogContactModal} 
          actor={props.actor}
          onSave={() => setRefresh(refresh + 1)}
        />      
      </React.Fragment>
      }
      {props.entityid &&
      <React.Fragment>
        <Button color="primary" onClick={() => setSendContactModal(!sendContactModal)}>send contact</Button>
        {sendContactModal &&
        <SendContactModal 
          toggle={() => setSendContactModal(!sendContactModal)}
          onSave={() => setRefresh(refresh + 1)}
          job={props.job} 
        />
        }
      </React.Fragment>
      }
    </React.Fragment>
  );

}

function ContactHistoryRow({contact}) {

  const [modal, setModal] = useState(false);

  const getCurrentStatus = () => {
    if (contact.status.intermediary === 'Outlook') {
      return 'From Outlook';
    }

    if (contact.subject.substr(-7) === 'confman') {
      return 'Removed from confman';
    }

    var mostRecentStatus = '';
    var mostRecentDatetime = moment('1970-01-01');

    contact.contactentities.forEach(ce => {
      if (ce['function'] === 'to') {
        ce.status_history.forEach(sh => {
          var datetime = moment(sh.statusdatetime);
          if (datetime.isSameOrAfter(mostRecentDatetime)) { // We're interested in same in additon to after as sometimes the 'Delivered' and 'Complaint' hooks can be received in the same second.
            mostRecentDatetime = datetime;
            mostRecentStatus = sh
          }
        });
      }
    });

    return mostRecentStatus;
  }

  const toggleModal = () => {
    setModal(!modal);
  }

  const contactDateTime = moment(contact.contactdatetime).format('DD/MM/YY HH:mm');

  let contactEntityFrom = contact.contactentities.find(ce => {
    return ce.function === 'from';
  })[0];

  let contactEntityTo = contact.contactentities.find(ce => {
    return ce.function === 'to';  
  })[0];

  var status = getCurrentStatus();
  if (status.hasOwnProperty('status')) {
    if (status.detail) {
      status = <span data-tooltip={ status.detail }>{ status.status }</span>;
    } else {
      status = status.status;
    }
  }

  const createdBy = contact.createdby ? contact.createdby.firstname + ' ' + contact.createdby.surname : '';

  var content = contact.content;

  if (content) {
    content = content.replace(/%0A/g, '\n');

    if (isHtml(content)) {
      content = <div style={{ width: '100%' }}><SanitizedHTML HTML={ content }/></div>
    } else {
      content = <div className='plainText'>{ content }</div>;
    }
  }

  return (
    <React.Fragment>
      <tr key={contact.id}>
        <td>{contactDateTime}</td>
        <td>{contact.contactmethodtype.method}</td>
        <td>{createdBy}</td>
        <td>{contactEntityFrom && contactEntityFrom.contactdetailvalue}</td>
        <td>{contactEntityTo && contactEntityTo.contactdetailvalue}</td>
        <td>{contact.subject}</td>
        <td>{status}</td>
        <td>
          <Icon icon="eye" onClick={toggleModal} />
        </td>
      </tr>
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>{contactDateTime}</ModalHeader>
        <ModalBody>
          {content}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>close</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );

}

function LogContactModal(props) {

  const { state } = React.useContext(Store);

  const [contactMethodTypes, setContactMethodTypes] = React.useState();
  const [contactDetails, setContactDetails] = React.useState();
  const [senderContactDetails, setSenderContactDetails] = React.useState();
  const [contactTypes, setContactTypes] = React.useState();
  const [agencies, setAgencies] = React.useState();
  const [fields, setFields] = React.useState({
    contacttype: 'Other',
    contactmethodtype: undefined,
    contactdetail: undefined,
    sendercontactdetail: undefined,
    subject: '',
    details: '',
  });

  React.useEffect(() => {

    const getContactMethodTypes = () => {
      let cmt = [];
      props.actor.contactdetails.forEach(cd => {
        if (cd.type === 'P') {
          cmt.push('Post');
        } else {
          switch(cd.contactmethodtype) {
            case 'Email':
              cmt.push('Email');
              break;
            case 'Mobile':
              cmt.push('Text');
              break;
            case 'Phone':
              cmt.push('Text');
              cmt.push('Phone');
              break;
            default:
          }
        }
      });
  
      cmt = [...new Set(cmt)];
      return cmt;
    }

    const getContactTypes = async () => {
      var ct = new Collection({
        path: 'contacttype',
        object: common.ContactType,
      });
      
      await ct.fetch(); 
      return ct;
    }
  
    const getAgencies = async () => {
      var a = new Collection({
        path: 'agency',
        object: common.Agency,
      });
    
      a.fetch(); 
      return a;
    }
  
    setContactMethodTypes(getContactMethodTypes());
    getContactTypes().then(ct => setContactTypes(ct));
    getAgencies().then(a => setAgencies(a));
  }, [props.actor.contactdetails]);

  if (!contactMethodTypes || !contactTypes || !agencies) {
    return null;
  }

  const logContact = () => {

    const contactdatetime = moment().toString();
    const status = 'Manual Entry';

    var c = new common.Contact();
    c.contacttype = fields.contacttype;
    c.contactmethodtype = fields.contactmethodtype;
    c.contactdatetime = fields.contactdatetime;
    c.content = fields.details;
    c.subject = fields.subject;
    c.deleted = false;
    c.status_status = status;
    c.status_statusdatetime = contactdatetime;
    c.sendercontactdetailid = fields.sendercontactdetail;
    c.comments = null;
    c.contactreason = null;

    c.create().then(contact => {

      let promises = [];

      //Add the recipient(s) to the contact
      let ce_to = new common.ContactEntity();
      ce_to.parent = c;
      ce_to.contactEntityType = props.actor.path;
      ce_to.entityid = props.actor.id;
      ce_to.function = 'to';
      ce_to.perform_send = false;
      ce_to.contactdetailid = fields.contactdetail;
      ce_to.status_status = status;
      ce_to.status_statusdatetime = contactdatetime;
      contact.contactentities.push(ce_to);
      promises.push(ce_to.create());
          
      // Add the sender
      var ce_from = new common.ContactEntity();
      ce_from.parent = c;
      ce_from.contactEntityType = 'TabsUser';
      ce_from.entityid = state.user.id;
      ce_from.function = 'from';
      ce_from.contactdetailid = fields.sendercontactdetail;
      ce_from.status_status = status;
      ce_from.status_statusdatetime = contactdatetime;
      contact.contactentities.push(ce_from);
      promises.push(ce_from.create());

      Promise.all(promises).then(() => {
        props.onSave();
        props.toggle();
      })

    });

  }

  const handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const newFields = {...fields, [name]: value};

    if (name === 'contactmethodtype') {
      filterContactMethodType(value);
    }

    setFields(newFields);
  }

  const filterContactMethodType = (cmt) => {

    const filterContactDetails = (contactDetails) => {

      let rows = [];

      contactDetails.forEach(cd => {

        const option = () => {
          if (cd.type === 'P' && cmt === 'Post') {
            rows.push(<option key={ cd.id } value={ cd.id }>{ [cd.address.line1, cd.address.town].join(', ') }</option>);
          } else {
            var value = cd.type === 'F' ? cd.getFormattedNumber() : cd.value;
            if (cmt === 'Email' && cd.contactmethodtype === cmt) {
              rows.push(<option key={ cd.id } value={ cd.id }>{ value }</option>);
            } else if (cmt === 'Text' && cd.contactmethodtype === 'Mobile') {
              rows.push(<option key={ cd.id } value={ cd.id }>{ value }</option>);
            } else if (cmt === 'Phone' && (cd.contactmethodtype === 'Mobile' || cd.contactmethodtype === 'Phone')) {
              rows.push(<option key={ cd.id } value={ cd.id }>{ value }</option>);
            }
          }
        }
  
        return option();
        
      });

      return rows;
    };

    let cd = filterContactDetails(props.actor.contactdetails);

    setContactDetails(cd);

    let sct = filterContactDetails(state.user.contactdetails);

    agencies.forEach(agency => {
      const agencyRows = filterContactDetails(agency.contactdetails);
      if (agencyRows.length > 0) {
        sct.push(
          <optgroup key={agency.companyname} label={agency.companyname}>
            {agencyRows}
          </optgroup>
        );
      }
    });

    setSenderContactDetails(sct);
  };

  const disabled = !fields.contactmethodtype || !fields.contactdetail || !fields.sendercontactdetail || !fields.details;

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>log contact</ModalHeader>
      <ModalBody>
        <SelectList
          name="contacttype"
          label="contact type"
          optionName="type"
          optionValue="type"
          values={contactTypes}
          value={fields.contacttype}
          onChange={handleChange}
        />
        <SelectList
          name="contactmethodtype"
          label="method"
          optionName="type"
          values={contactMethodTypes.map(cmt => {
            return {id: cmt, type: cmt}
          })}
          value={fields.contactmethodtype}
          onChange={handleChange}
          emptyValue="choose a method"
        />
        {fields.contactmethodtype &&
        <React.Fragment>
          {contactDetails && contactDetails.length > 0 &&
          <FormGroup row>
            <Label sm={4}>contact detail</Label>
            <Col sm={8}>
              <Input 
                type="select"
                name="contactdetail"
                value={fields.contactdetail}
                onChange={handleChange}
              >
                <option key="empty" value="">choose a contact detail</option>
                {contactDetails}
              </Input>            
            </Col>
          </FormGroup>
          }
          {senderContactDetails && senderContactDetails.length > 0 &&
          <FormGroup row>
            <Label sm={4}>sender contact detail</Label>
            <Col sm={8}>
              <Input 
                type="select"
                name="sendercontactdetail"
                value={fields.sendercontactdetail}
                onChange={handleChange}
              >
                <option key="empty" value="">choose a sender contact detail</option>
                {senderContactDetails}
              </Input>  
            </Col>
          </FormGroup>
          }
          <Text
            name="subject"
            value={fields.subject}
            onChange={handleChange}
          />
          <Text
            name="details"
            value={fields.details}
            onChange={handleChange}
            large
          />
        </React.Fragment>
        }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={logContact} disabled={disabled}>log contact</Button>
        <Button color="secondary" onClick={props.toggle}>close</Button>
      </ModalFooter>
    </Modal>    
  );

}

import React, { useContext, useState } from 'react';
import { common } from 'plato-js-client';
import {
  Button,
  Card,
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  Row } from 'reactstrap';
import { updateJob } from '../Actions';
import { Store } from "../Store";
import DiscreteCard from "./DiscreteCard";
import SelectJobModal from "./SelectJobModal";
import selectBookingModal from "./SelectBookingModal";
import SelectPersonModal from "./SelectPersonModal";
import Icon from "./Icon";
import TabsButton from './TabsButton';
import { addTab } from '../Actions';
import SelectBookingModal from './SelectBookingModal';
import BookingRoomDetail from './BookingRoomDetail';
import moment from 'moment';

export default function JobAssociationBlock(props) {

  const {
    dispatch,
    state: {
      fieldWorkers,
      serviceCoordinators,
      serviceManagers,
    }
  } = useContext(Store);

  const [selectBookingModal, setSelectBookingModal] = useState(false);
  const [selectJobModal, setSelectJobModal] = useState(false);

  const toggleSelectJobModal = () => {
    setSelectJobModal(!selectJobModal);
  }

  const addAssociatedJobs = jobIds => {
    let promises = jobIds.map(jobId => {
      let woa = new common.WorkOrderAssociation();
      woa.parent = props.job;
      woa.associatedworkorder = new common.WorkOrder(jobId);
      return woa.create();
    });

    Promise.all(promises).then(() => {
      updateJob(props.job.id, {}, dispatch);
    });
  }

  const removeAssociatedJob = id => {
    let woa = new common.WorkOrderAssociation(id);
    woa.parent = props.job;

    woa.delete().then(() => {
      updateJob(props.job.id, {}, dispatch);
    });
  }

  const addAssociatedPerson = personIds => {
    let promises = personIds.map(id => {
      let woa = new common.WorkOrderActor();
      woa.parent = props.job;
      woa.actor = new common.Actor(id);
      return woa.create();
    });

    Promise.all(promises).then(() => {
      updateJob(props.job.id, {}, dispatch);
    });
  }

  const removeAssociatedPerson = id => {
    let woa = new common.WorkOrderActor(id);
    woa.parent = props.job;

    woa.delete().then(() => {
        updateJob(props.job.id, {}, dispatch);
    });
  }

  return (
    <React.Fragment>
      <Row>
        <DiscreteCard columns={2} title="field workers">
          {props.job.actors.collection.length === 0 ?
          <div className="mb-1">No associated field workers.</div>
          :
          <ListGroup className="mb-1">
            {props.job.actors.map(woa => {
              const fw = fieldWorkers.find(fw => fw.id === woa.actor.id);

              if (!fw) {
                return null;
              }

              return (
                <ListGroupItem key={woa.id} action className="skinny">
                  <Row>
                    <Col>
                      {[fw.title, fw.firstname, fw.surname].join(' ')}
                    </Col>
                    <Col md="auto">
                      <Icon icon="eye" onClick={() => {
                        addTab('showFieldWorker', {supplierId: woa.actor.id}, dispatch);
                      }} />
                      {' '}
                      <Icon icon="trash" onClick={() => {
                        if (window.confirm('Are you sure you want to remove this associated field worker?')) {
                          removeAssociatedPerson(woa.id);
                        }
                      }} />
                    </Col>
                  </Row>
                </ListGroupItem>
              );
            })}
          </ListGroup>
          }
          <SelectPersonModal
            button={onClick => {
              return (
                <Button size="sm" onClick={onClick}>add field worker(s)</Button>
              );
            }}
            onSubmit={addAssociatedPerson}
            excludeIds={props.job.actors.map(woa => woa.actor.id)}
          />
        </DiscreteCard>
        <DiscreteCard columns={2} title="jobs">
          {props.job.parentassociations.collection.length > 0 &&
          <ListGroup className="mb-1">
            {props.job.parentassociations.map(woa =>
            <ListGroupItem key={woa.id} action className="skinny">
              <Row>
                <Col>
                  <Icon icon="info-circle" />{' '}
                  child of job {woa.workorder.id}
                </Col>
                <Col md="auto">
                  <Col md="auto">
                      <Icon icon="pencil-alt" onClick={() => {
                        addTab('editJob', {workOrderId: woa.workorder.id}, dispatch);
                      }} />
                  </Col>
                </Col>
              </Row>
            </ListGroupItem>
            )}
          </ListGroup>
          }
          {props.job.childassociations.collection.length === 0 ?
          <div className="mb-1">No associated jobs.</div>
          :
          <ListGroup className="mb-1">
            {props.job.childassociations.map(woa =>
            <ListGroupItem key={woa.id} action className="skinny">
              <Row>
                <Col>
                  job {woa.associatedworkorder.id}
                </Col>
                <Col md="auto">
                  <Icon icon="pencil-alt" onClick={() => {
                    addTab('editJob', {workOrderId: woa.associatedworkorder.id}, dispatch);
                  }} />
                  {' '}
                  <Icon icon="trash" onClick={() => {
                    if (window.confirm('Are you sure you want to remove this associated job?')) {
                      removeAssociatedJob(woa.id);
                    }
                  }} />
                </Col>
              </Row>
            </ListGroupItem>
            )}
          </ListGroup>
          }
          <Button
            size="sm"
            onClick={toggleSelectJobModal}
            disabled={props.job.parentassociations.collection.length > 0}
          >
            add job(s)
          </Button>
          {selectJobModal &&
          <SelectJobModal
            onSubmit={addAssociatedJobs}
            excludeIds={props.job.childassociations.map(woa => woa.associatedworkorder.id)}
            toggle={toggleSelectJobModal}
          />
          }
        </DiscreteCard>
        <DiscreteCard columns={2} title="booking - blocking availability">
          {props.job.availabilityblockingbooking.id ?
          <ListGroup>
            <ListGroupItem key={props.job.availabilityblockingbooking.id} action className="skinny">
              <Row>
                <Col>
                  booking {props.job.availabilityblockingbooking.id}
                </Col>
                <Col md="auto">
                  <TabsButton route={`booking/${props.job.availabilityblockingbooking.id}`} />
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
          :
          <span>No booking is blocking availability.</span>
          }
        </DiscreteCard>
        <DiscreteCard columns={2} title="booking - associated with job">
          {props.job.booking.id ?
          <React.Fragment>
            <ListGroup className="mb-1">
              <ListGroupItem key={props.job.booking.id} action className="skinny">
                <Row className="align-items-center">
                  <Col>
                    <strong>id:</strong>{' '}{props.job.booking.id}<br />
                    <strong>dates:</strong>{' '}{moment(props.job.booking.fromdate).format('DD/MM/YY')} - {moment(props.job.booking.todate).format('DD/MM/YY')}<br />
                    <strong>guest:</strong>{' '}{props.job.booking.customers.collection.length > 0 ? props.job.booking.customers.collection[0].name : props.job.booking.guesttype}
                    <strong>party size:</strong>{' '}{`${props.job.booking.adults} adult(s) / ${props.job.booking.children} children / ${props.job.booking.infants} infants / ${props.job.booking.pets} pet(s)`}
                    <BookingRoomDetail booking={ props.job.booking } />
                  </Col>
                  <Col md="auto">
                    <TabsButton route={`booking/${props.job.booking.id}`} />{' '}
                    <Icon
                      icon="trash"
                      onClick={() => {
                        if (window.confirm('Are you sure you want to remove this associated booking?')) {
                          updateJob(
                            props.job.id,
                            {booking: new common.Booking(0)},
                            dispatch
                          )
                        }
                      }}
                    />
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </React.Fragment>
          :
          <div className="mb-1">No associated booking.</div>
          }
          <Button size="sm" onClick={() => setSelectBookingModal(!selectBookingModal) }>{props.job.booking.id ? 'update' : 'add'} booking</Button>
          {selectBookingModal &&
          <SelectBookingModal
            toggle={() => setSelectBookingModal(!selectBookingModal)}
            propertyId={props.job.property.id}
            onChoose={booking => {
              updateJob(
                props.job.id,
                {booking},
                dispatch
              );
            }}
          />
          }
        </DiscreteCard>
        <DiscreteCard columns={2} title="service coordinators/managers">
          <ListGroup className="mb-1">
            {props.job.actors.map(woa => {
              let scm = undefined;
              let type = undefined;

              scm = serviceCoordinators.find(sc => sc.id === woa.actor.id);

              if (scm) {
                type = 'Coordinator';
              } else {
                scm = serviceManagers.find(sm => sm.id === woa.actor.id);

                if (scm) {
                  type = 'Manager';
                } else {
                  return null;
                }
              }

              return (
                <ListGroupItem key={woa.id} action className="skinny">
                  <Row>
                    <Col>
                      {[type + ' -', scm.title, scm.firstname, scm.surname].join(' ')}
                    </Col>
                    <Col md="auto">
                      <Icon icon="trash" onClick={() => {
                        if (window.confirm(`Are you sure you want to remove this associated ${type.toLowerCase()}?`)) {
                          removeAssociatedPerson(woa.id);
                        }
                      }} />
                    </Col>
                  </Row>
                </ListGroupItem>
              );
            })}
          </ListGroup>
          <SelectPersonModal
            mode="serviceCoordinator"
            button={onClick => {
              return (
                <Button size="sm" onClick={onClick}>add coordinator(s)</Button>
              );
            }}
            onSubmit={addAssociatedPerson}
            excludeIds={props.job.actors.map(woa => woa.actor.id)}
          />{' '}
          <SelectPersonModal
            mode="serviceManager"
            button={onClick => {
              return (
                <Button size="sm" onClick={onClick}>add manager(s)</Button>
              );
            }}
            onSubmit={addAssociatedPerson}
            excludeIds={props.job.actors.map(woa => woa.actor.id)}
          />
        </DiscreteCard>
      </Row>
    </React.Fragment>
  );
}


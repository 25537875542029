import React from 'react';

import { 
  Card,
  CardBody,
  Col,
  Row,
} from 'reactstrap';

export default function WhatsNew() {

  return (
    <Row className="m-3">
      <Col>
        <h3>what's new?</h3>
        <Card className="mb-2">
          <CardBody>
            <h3 className="mb-3">09/08/21</h3>
            <h5>Invoice modal</h5>
            <p>PMS-133: add property id to invoice view <br/>
              add owner ref and button</p>
            <hr />
            <h5>Dashboard</h5>
            <p>PMS-182: hide a 'new' and 'tasks' jobs counts from the dashboard</p>
            <hr />
            <h5>Jobs List tab</h5>
            <p>PMS-182: don't show awaiting scheduling jobs in 'new' filter</p>
            <hr />
            <h5>new template job tab</h5>
            <p>PMS-160:add end date to template job recurrance settings (no instances will be created for after this date)</p>
            <hr />
            <h5>Fieldworker invoices:</h5>
            <p>PMS-136: allow user to delete invoice (if it has no items) and prevent invoices being opened more than once</p>
            <hr />
            <h5>admin/checklists</h5>
            <p>PMS-202: when add new checklist button is pressed show checklist name box<br/>
            save the checklist when the save changes button is clicked<br/>
            add all other brand checklists to checklist drop down and make them copyable<br/>
            add delete button alongside checklist drop down</p>
            <hr />
            <h5>property tab</h5>
            <p>PMS-214: add two new job status pills to property tab &gt; jobs list tab<br/>
            new pills to show all jobs and completed jobs (includes financially completed, completed and owner charged)</p>
            <hr />
            <h5>Job Tab</h5>
            <p>PMS-203: remove view field worker details button if the work order supplier id is not in the field workers collection<br/>
            PMS-206: Add "actioned by" column to status history tab on job tab<br/>
            PMS-183: Add property reference next to property name on jobs tab<br/>
            PMS-184: show booking party makeup</p>
            <hr />
            <h5>New job tab</h5>
            <p>PMS-153 add new "auto set status to 'awaiting scheduling' when saved?" checkbox to new job fields.  When this is checked the job will be automatically moved to awaiting scheduling</p>
            <hr />
            <h5>Job item modal</h5>
            <p>PMS-189: add a note under the job item description to detail that this description is internal only </p>
            <hr />
            <h5>Owner invoices tab</h5>
            <p>PMS-195: amend label on owner invoice tab - work done up to - to - work done before<br/>
            PMS-171: Add message to warn user they have selected all charges and did they mean to select non-hopa?</p>
            <hr />
            <h5>Review sent invoices tab</h5>
            <p>PMS-201: The review sent invoices tab should only show invoices for the selected brand</p>
            <hr />
            <h5>Job Scheduler</h5>
            <p>PMS-180: load this week and next weeks jobs on scheduler<br/>
            PMS-180: change logic for job pill colours</p>
            <hr />
            <h5>New job modal</h5>
            <p>PMS-188: add fieldworker title, name and surname before ID on new job modal<br/>
            modal appears when you have highlighted a time on the scheduler next to a fieldworker and then create a job from there</p>

          </CardBody>
        </Card>

        <Card className="mb-2">
          <CardBody>
            <h3 className="mb-3">15/02/21</h3>
            <h5>Service tags</h5>
            <p>Service tags, which can be added in the job view, help categorise jobs and are mandatory for certain 'brands'.</p>
          </CardBody>
        </Card>

        <Card className="mb-2">
          <CardBody>
            <h3 className="mb-3">29/01/21</h3>
            <h5>New scheduler</h5>
            <p>The new scheduler looks very similar to the old one but has been written from scratch with speed and reliability in mind.</p>
            <hr />
            <h5>Update bookings associated with booking service templates</h5>
            <p>Bookings associated with booking service templates can now be updated from the job view, so you don't have to do it in tabs.</p>
          </CardBody>
        </Card>

        <Card className="mb-2">
          <CardBody>
            <h3 className="mb-3">04/06/20</h3>
            <h5>Better job template scheduling information</h5>
            <p>When viewing a template you will be able to see when the next three jobs will be scheduled for.</p>
          </CardBody>
        </Card>

        <Card className="mb-2">
          <CardBody>
            <h3 className="mb-3">15/05/20</h3>
            <h5>Better invoice visibility</h5>
            <p>The invoice associated with a job may now be viewed directly from the job, and the invoice view has been improved to show more detail.</p>
            <hr />
            <h5>Upcoming Cancelled filter</h5>
            <p>An Upcoming Cancelled filter has been added to the jobs list when viewing a property or supplier.</p>
          </CardBody>
        </Card>

        <Card className="mb-2">
          <CardBody>
            <h3 className="mb-3">01/05/20</h3>
            <h5>When cancelling a template, you can now cancel associated jobs</h5>
            <p>When cancelling a template job, you can now (optionally) cancel jobs that were created from said template.</p>
            <hr />
            <h5>Tasks</h5>
            <p>'Tasks' may now be created, which are like tentative jobs. They can be turned into jobs later on, and can be seen using the 'tasks' filter in the jobs list.</p>
            <hr />
            <h5>Add service coordinators/managers to jobs</h5>
            <p>In the 'associations' section of a job, you may now add service coordinators/managers, so the job will appear in the coordator's/manager's 'associated with/assigned to me' filter in the jobs list.</p>
          </CardBody>
        </Card>

        <Card className="mb-2">
          <CardBody>
            <h3 className="mb-3">02/04/20</h3>
            <h5>Hide cancelled job templates</h5>
            <p>In the 'view templates' section, previously cancelled templates were shown. A 'status' column has been added and an option to (by default) only show 'live' templates has been added, or 'all' (which includes cancelled templates).</p>
            <hr />
            <h5>Allow cancellation of templates</h5>
            <p>A button to cancel a template job has been added to the job view.</p>
            <hr />
            <h5>Show associated jobs in day plan</h5>
            <p>Previously when a field worker was associated with a job, it would not show in the plan. It now will, including travel times.</p>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h3 className="mb-3">18/03/20</h3>
            <h5>Combined edit/view job</h5>
            <p>Jobs are now edited and viewed in the same tab.</p>
            <hr />
            <h5>Create job via scheduler</h5>
            <p>Jobs may now be created in the scheduler. Click and drag a period and click 'schedule job' to see this in action.</p>
            <hr />
            <h5>Better work order reordering</h5>
            <p>In the admin section, field workers may now be clicked and dragged to reorder them.</p>
            <hr />
            <h5>Owner charge ordering</h5>
            <p>Owner charges are now ordered by date in 'invoice jobs'. Charges in the invoice itself will also be ordered.</p>
            <hr />
            <h5>HOPA charges in 'financially complete jobs'</h5>
            <p>A 'select all non-HOPA' option has been added to exclude HOPA charges from the usual process.</p>
            <hr />
            <h5>Speed improvements</h5>
            <p>Loading the system is now much quicker because field worker jobs are now loaded 'on demand' in the scheduler.</p>
            <hr />
            <h5>Job statuses key on scheduler</h5>
            <p>A 'show key' button has been added to the scheduler so you can see what all the different colours mean.</p>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );

}


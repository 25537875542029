import React, { useEffect, useReducer } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import { 
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row, 
  Table } from 'reactstrap';
import Icon from './Icon';
import moment from 'moment';

export default function SelectBookingModal(props) {

  const reducer = (state, action) => {
    switch (action.type) {
      case 'PREV_PAGE':
        return {...state, page: state.page - 1};
      case 'NEXT_PAGE':
        return {...state, page: state.page + 1};
      case 'SET_BOOKINGS':
        return {
          ...state, 
          bookings: action.payload,
        };
      default:
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    bookings: undefined,
    selected: [],
    page: 1,
  });

  useEffect(() => {

    const getBookings = async () => {
      let bookings = new FilterCollection({
        path: 'booking',
        object: common.Booking,
      });
  
      bookings.page = state.page;
      bookings.limit = 10;
      bookings.addFilters([
        {
          propid: props.propertyId,
          fromdate: '>' + moment().format('YYYY-MM-DD'),
          cancelledbooking: false,
          transferredbooking: false,
        },
      ]);
  
      await bookings.fetch();

      dispatch({type: 'SET_BOOKINGS', payload: bookings});
    }
  
    getBookings();

  }, [state.page]);

  return (
    <React.Fragment>
      <Modal isOpen toggle={props.toggle} size="lg">
        <ModalHeader toggle={props.toggle}>choose a booking</ModalHeader>
        <ModalBody>
          <Row className="mb-2">
            <Col>

            </Col>
            <Col md="auto">
              <ButtonGroup size="sm">
                <Button 
                  onClick={() => { dispatch({type: 'PREV_PAGE'}) }}
                  disabled={!state.bookings || !state.bookings.previous}
                >previous page</Button>
                <Button 
                  onClick={() => { dispatch({type: 'NEXT_PAGE'}) }}
                  disabled={!state.bookings || !state.bookings.next}
                >next page</Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>booked</th>
                    <th>from</th>
                    <th>to</th>
                    <th>guest</th>
                    <th>status</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {!(state.bookings && state.bookings.total > 0) &&
                  <tr><td colSpan="6">No results found.</td></tr>
                  }
                  {state.bookings && state.bookings.total > 0 && state.bookings.map(booking => {
                    return (
                      <tr key={booking.id}>
                        <td>{booking.id}</td>
                        <td>{moment(booking.bookeddatetime).format('DD/MM/YY HH:mm')}</td>
                        <td>{moment(booking.fromdate).format('DD/MM/YY')}</td>
                        <td>{moment(booking.todate).format('DD/MM/YY')}</td>
                        <td>
                          {booking.customers.collection.length > 0 ? booking.customers.collection[0].name : booking.guesttype}
                        </td>
                        <td>{booking.status}</td>
                        <td>
                          <Icon icon="check" onClick={() => {
                            props.onChoose(booking);
                            props.toggle();
                          }} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>             
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.toggle}>cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

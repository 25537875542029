import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav, 
  NavItem, 
  Navbar, 
  NavLink, 
  NavbarBrand, 
  NavbarToggler,
  UncontrolledDropdown } from 'reactstrap';
import { Store } from "./Store";
import { switchGroup } from "./Actions";
import { saveSetting } from "./components/TabsUserSetting";
import { isTest } from './mixins/EnvironmentMixin';

const NavBar = () => {

  const {
    state: { 
      currentGroup,
      groups,
      user,
    },
    dispatch
  } = useContext(Store);

  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const toggleGroup = groupId => {
    switchGroup(groupId, dispatch);
    saveSetting(user, 'PMSGroup', groupId);
  }

  return (
    <Navbar color="dark" dark expand="md" className={isTest() ? 'navbar-test' : ''}>
      <NavbarBrand href="/">
        TOCC PMS
        {isTest() &&
        <span className="text-danger">{' '}TEST</span>
        }
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <NavLink href="/" to="/">home</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/admin">admin</NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/whatsnew">what's new?</NavLink>
          </NavItem>             
        </Nav>
        <Nav className="ml-auto" navbar>
          {currentGroup && groups &&
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {currentGroup.name}
            </DropdownToggle>
            <DropdownMenu right>
              {groups.map(group => {
                return (
                  <DropdownItem key={group.id} onClick={() => toggleGroup(group.id) }>
                    {group.name}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>                   
          }
          <NavItem>
            <NavLink tag={Link} to="/logout">log out</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
  
};

export default React.memo(NavBar);
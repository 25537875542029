import React, { useContext, useEffect, useReducer, useState } from 'react';
import { common, Collection, FilterCollection } from 'plato-js-client';
import { 
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row, 
  Table } from 'reactstrap';
import SelectProperty from '../fields/SelectProperty';
import Icon from './Icon';
import SelectWorkTypeModal from './SelectWorkTypeModal';
import PrettyLoader from './PrettyLoader';
import { addTab, updateJobSubStatus } from '../Actions';
import { Store } from "../Store";
import moment from 'moment';
import Text from '../fields/Text';
import SelectList from '../fields/SelectList';
import { Editor } from '@tinymce/tinymce-react';

export default function SendContactModal(props) {

  const { state } = React.useContext(Store);

  const [templates, setTemplates] = useState();
  const [templatesAvailable, setTemplatesAvailable] = useState();
  const [content, setContent] = useState('');
  const [subject, setSubject] = useState('');
  const [actor, setActor] = useState();

  const [contactMethodTypes, setContactMethodTypes] = useState();
  const [contactDetails, setContactDetails] = useState();
  const [senderContactDetails, setSenderContactDetails] = useState();
  const [agencies, setAgencies] = useState();

  const [isMandrillTemplate, setIsMandrillTemplate] = useState(false);
  const [templateContactMethod, setTemplateContactMethod] = useState();

  const [fields, setFields] = useState({
    template: undefined,
    target: undefined,

    contacttype: 'WorkOrder',
    contactmethodtype: undefined,
    contactdetail: undefined,
    sendercontactdetail: undefined,
  });
  const [validate, setValidate] = useState({});

  const [busy, setBusy] = useState(false);

  const targets = [
    'Owner',
    'Supplier',
  ];

  if (props.job.booking.id && props.job.booking.customers.collection.length > 0) {
    targets.unshift('Customer');
  }

  useEffect(() => {

    const getTemplates = async () => {
      let templates = new FilterCollection({
        path: 'template',
        object: common.Template,
      });
      templates.addFilters([{
        type: 'WorkOrderInstance',
        brandingid: state.currentGroup.brandingIds.join('|'),
      }]);
      templates.limit = 999;
    
      await templates.fetch();
      setTemplates(templates);
    }

    const getAgency = async () => {
      let as = [];

      for (const brandingId of state.currentGroup.brandingIds) {
        const branding = new common.Branding(brandingId);
        await branding.get();
        await branding.bookingbrand.agency.get();
        as.push(branding.bookingbrand.agency);
      }

      setAgencies(as);
    }

    getTemplates();
    getAgency();

  }, []);

  const handleChange = async event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let f = {...fields};
    f[name] = value;

    if (name === 'target') { 
      filterContactMethodTypes(value);
      f.contactmethodtype = '';

      filterTemplates();
      f.template = '';

      f = {...f, ...filterContactDetails()};
    }
    if (name === 'contactmethodtype') { 
      filterTemplates(value);
      f.template = '';

      f = {...f, ...filterContactDetails(value)};
    }
    if (name === 'template') { setContentAndSubject(value); }

    if (name === 'target' || name === 'contactmethodtype' || f.template === '') {
      setIsMandrillTemplate(false);
      setSubject('');
      setContent('');
    }

    setFields(f);
  };

  const validateForm = () => {
    let newValidate = {...validate};
    let valid = true;

    newValidate.contactdetail = !!fields.contactdetail;
    newValidate.sendercontactdetail = !!fields.sendercontactdetail;

    newValidate.subject = true;
    if (!isMandrillTemplate){
      if (fields.contactmethodtype === 'Email' && !subject) {
        newValidate.subject = false;
      }
    }

    if (!isMandrillTemplate) {
      newValidate.content = !!content;
    }
    
    valid = !Object.values(newValidate).includes(false);
    
    setValidate(newValidate);

    return valid;
  }

  const setContentAndSubject = async templateId => {
    const template = templates.collection.find(t => t.id === parseInt(templateId));

    if (!template) {
      return;
    }

    const mandrillTemplate = template?.contactmethods?.collection[0]?.mandrilltemplate?.id ?? null;

    if (mandrillTemplate) {
      setIsMandrillTemplate(true);
    }

    const contactMethod = template.contactmethods.collection.find(
      cm => cm.contactmethodtype.method === 'Email'
    );

    setTemplateContactMethod(contactMethod);

    if (contactMethod) {
      const result = await contactMethod.getEmailContent(props.job.id);

      if (result.entity) {
        setContent(
          mandrillTemplate ? JSON.stringify(result.entity.fields) : result.entity.elementArray.body);
        setSubject(result.entity.subject);
      }
    }
  };

  const getSubject = () => {
    if (isMandrillTemplate) {
      return ' ';
    } else {
      return subject;
    }
  }

  useEffect( () => {
  }, [isMandrillTemplate]);

  const filterTemplates = contactMethodType => {
    if (!contactMethodType) {
      setTemplatesAvailable();
      return;
    }

    let templatesAvailable = [];
    templates.forEach(template => {
      const tt = template.templatetargetsource.templatetarget.templatetarget;
      if (tt === fields.target) {
        template.contactmethods.forEach(tcm => {
          if (tcm.contactmethodtype.method === contactMethodType) {
            templatesAvailable.push({
              id: template.id, 
              toString: () => template.templatename + ' - ' + tcm.language.name
            });
          }
        });
      }
    });
    setTemplatesAvailable(templatesAvailable);
  };
  
  const filterContactMethodTypes = async target => {

    if (!target) {
      setContactMethodTypes([]);
      return;
    }

    let actor = undefined;

    switch(target) {
      case 'Supplier':
        actor = await props.job.workordersupplier.supplier.get();
        break;
      case 'Owner':
        let property = await props.job.property.get();
        await property.owners.fetch();

        for (const propertyOwner of property.owners.collection) {
          if (moment().isBetween(propertyOwner.ownerfromdate, propertyOwner.ownertodate, 'day', '[]')) {
            await propertyOwner.owner.get();
            actor = propertyOwner.owner;
          }
        }
        
        break;
      case 'Customer':
        let booking = props.job.booking;
        if (booking.id && booking.customers && booking.customers.collection.length > 0) {
          let customer = await booking.customers.collection[0].details.get();
          actor = customer;
        }
        break;
    }

    setActor(actor);

    if (!actor) {
      setContactMethodTypes([]);
      return;
    }

    let contactDetails = actor.contactdetails;

    let cmt = [];
    contactDetails.forEach(cd => {
      if (cd.invalid) {
        return;
      }

      if (cd.type === 'P') {
        //cmt.push('Post');
      } else {
        switch(cd.contactmethodtype) {
          case 'Email':
            cmt.push('Email');
            break;
          case 'Mobile':
            cmt.push('Text');
            break;
          case 'Phone':
            cmt.push('Text');
            //cmt.push('Phone');
            break;
          default:
        }
      }
    });

    cmt = [...new Set(cmt)];
    setContactMethodTypes(cmt);
  };

  const filterContactDetails = cmt => {

    if (!cmt) {
      setContactDetails();
      setSenderContactDetails();
      return;
    }

    var contactdetail = '';
    var sendercontactdetail = '';

    const filter = (contactDetails) => {

      let rows = [];
      let selected = undefined;
      let countContactDetails = 0;

      contactDetails.forEach((cd, i) => {
        if (cd.invalid) {
          return;
        }

        const option = () => {
          if (cd.type === 'P' && cmt === 'Post') {
            rows.push(<option key={ cd.id } value={ cd.id }>{ [cd.address.line1, cd.address.town].join(', ') }</option>);
            if (countContactDetails === 0) { selected = cd.id; }
            countContactDetails++;
          } else {
            var value = cd.type === 'F' ? cd.getFormattedNumber() : cd.value;
            if (cmt === 'Email' && cd.contactmethodtype === cmt) {
              rows.push(<option key={ cd.id } value={ cd.id }>{ value }</option>);
              if (countContactDetails === 0) { selected = cd.id; }
              countContactDetails++;
            } else if (cmt === 'Text' && cd.contactmethodtype === 'Mobile') {
              rows.push(<option key={ cd.id } value={ cd.id }>{ value }</option>);
              if (countContactDetails === 0) { selected = cd.id; }
              countContactDetails++;
            } else if (cmt === 'Phone' && (cd.contactmethodtype === 'Mobile' || cd.contactmethodtype === 'Phone')) {
              rows.push(<option key={ cd.id } value={ cd.id }>{ value }</option>);
              if (countContactDetails === 0) { selected = cd.id; }
              countContactDetails++;
            }
          }
        }
  
        return option();
      });

      return { rows, selected };
    };

    let cd = filter(actor.contactdetails);
    contactdetail = cd.selected;

    setContactDetails(cd.rows);

    let scd = [];

    const senderRows = filter(state.user.contactdetails);

    if (senderRows.rows.length > 0) {
      scd.push(
        <optgroup key="Me" label="Me">
          {senderRows.rows}
        </optgroup>
      );
    }

    agencies.forEach((agency, i) => {
      const agencyRows = filter(agency.contactdetails);

      if (i === 0) { sendercontactdetail = agencyRows.selected; }

      if (agencyRows.rows.length > 0) {
        scd.push(
          <optgroup key={agency.companyname} label={agency.companyname}>
            {agencyRows.rows}
          </optgroup>
        );
      }
    });

    setSenderContactDetails(scd);

    return { contactdetail, sendercontactdetail };
  };

  const send = () => {

    if (!validateForm()) {
      return;
    }

    setBusy(true);

    const contactdatetime = moment().toString();
    const status = 'Sending Email';

    var c = new common.Contact();
    c.contacttype = fields.contacttype;
    c.contactmethodtype = fields.contactmethodtype;
    c.contactdatetime = fields.contactdatetime;
    c.content = content;
    c.subject = getSubject();
    c.deleted = false;
    c.status_status = status;
    c.status_statusdatetime = contactdatetime;
    c.sendercontactdetailid = fields.sendercontactdetail;
    c.comments = null;
    c.contactreason = null;
    c.templateentityid = props.job.id;
    c.templatecontactmethod = templateContactMethod ? templateContactMethod.id : null;
    
    c.create().then(contact => {

      let promises = [];

      //Add the recipient(s) to the contact
      let ce_to = new common.ContactEntity();
      ce_to.parent = c;
      ce_to.contactEntityType = actor.path;
      ce_to.entityid = actor.id;
      ce_to.function = 'to';
      ce_to.perform_send = false;
      ce_to.contactdetailid = fields.contactdetail;
      ce_to.status_status = status;
      ce_to.status_statusdatetime = contactdatetime;
      contact.contactentities.push(ce_to);
      promises.push(ce_to.create());

      // Add the sender
      var ce_from = new common.ContactEntity();
      ce_from.parent = c;
      ce_from.contactEntityType = 'TabsUser';
      ce_from.entityid = state.user.id;
      ce_from.function = 'from';
      ce_from.contactdetailid = fields.sendercontactdetail;
      ce_from.status_status = status;
      ce_from.status_statusdatetime = contactdatetime;
      contact.contactentities.push(ce_from);
      promises.push(ce_from.create());

      Promise.all(promises).then(
        () => {
          var send = new common.ContactSend(c.id);
          send.create().then(
            () => {
              props.onSave();
              props.toggle();
              setBusy(false);
            },
            (error) => {
              if (error.statusCode > 204) {
            
              } else {
                props.onSave();
                props.toggle();
                setBusy(false);
              }
            }
          );
        }
      );

    });

  }

  if (!targets || !templates) {
    return null;
  }

  let sendDisabled = false;
  if (!fields.target || !fields.contactmethodtype) {
    sendDisabled = true;
  }

  if (!isMandrillTemplate) {
    if (fields.contactmethodtype === 'Email' && (!subject || subject.length === 0)) {
      sendDisabled = true;
    }
    if (!content || content.length === 0) {
      sendDisabled = true;
    }
  }

  return (
    <Modal isOpen toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>send contact</ModalHeader>
      <ModalBody>     
        <SelectList
          name="target"
          value={fields.target}
          values={targets.map(target => {
            return {id: target, toString: () => {
              switch(target) {
                case 'Supplier': return 'Field worker';
                default: return target;
              }
            }};
          })}
          onChange={handleChange}
          emptyValue="choose a target"
        />
        {contactMethodTypes && contactMethodTypes.length > 0 &&
        <SelectList
          name="contactmethodtype"
          label="method"
          optionName="type"
          values={contactMethodTypes.map(cmt => {
            return {id: cmt, type: cmt}
          })}
          value={fields.contactmethodtype}
          onChange={handleChange}
          emptyValue="choose a method"
        />        
        }
        {contactDetails && contactDetails.length > 0 &&
        <FormGroup row>
          <Label sm={4}>destination</Label>
          <Col sm={8}>
            <Input 
              type="select"
              name="contactdetail"
              value={fields.contactdetail}
              onChange={handleChange}
              invalid={validate.contactdetail === false}
            >
              {contactDetails}
            </Input>
            {validate.contactdetail === false &&
            <div className="invalid-feedback d-block">
              You must choose a destination.
            </div>
            }             
          </Col>
        </FormGroup>
        }
        {senderContactDetails && senderContactDetails.length > 0 &&
        <FormGroup row>
          <Label sm={4}>sender</Label>
          <Col sm={8}>
            <Input 
              type="select"
              name="sendercontactdetail"
              value={fields.sendercontactdetail}
              onChange={handleChange}
              invalid={validate.sendercontactdetail === false}
            >
              {senderContactDetails}
            </Input>  
            {validate.sendercontactdetail === false &&
            <div className="invalid-feedback d-block">
              You must choose a sender.
            </div>
            }
          </Col>
        </FormGroup>
        }
        {fields.target && fields.contactmethodtype && templatesAvailable &&
        <SelectList
          name="template"
          value={fields.template}
          values={templatesAvailable}
          onChange={handleChange}
          emptyValue="no template"
        />        
        }
        {fields.contactmethodtype === 'Email' && !isMandrillTemplate &&
        <Text
          name="subject"
          value={subject}
          onChange={e => setSubject(e.target.value)}
          invalid={validate.subject === false}
        />        
        }
        {fields.contactmethodtype && !isMandrillTemplate &&
        <Editor
          value={content}
          init={{
            height: 300,
            menubar: false,
            content: content,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help'
          }}
          onEditorChange={(content, editor) => setContent(content)}
        />
        }
        {isMandrillTemplate &&
        <div className='alert alert-warning'>
          <p>Mandrill templates are not editable within PMS.</p>
        </div>
        }
      </ModalBody>
      <ModalFooter>
        {busy &&
        <PrettyLoader size={38} />
        }
        <Button color="secondary" onClick={props.toggle}>cancel</Button>{' '}
        <Button 
          color="primary" 
          onClick={send} 
          disabled={sendDisabled}>
            send
        </Button>
      </ModalFooter>
    </Modal>
  );

}
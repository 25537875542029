import React, { useContext, useState } from 'react';
import { common, Collection } from 'plato-js-client';
import { 
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, 
  Row } from 'reactstrap';
import { addTab } from "../Actions";
import { Store } from "../Store";
import PrettyLoader from '../components/PrettyLoader';
import Check from '../fields/Check';
import SelectList from '../fields/SelectList';
import SelectProperty from '../fields/SelectProperty';
import Number from '../fields/Number';
import { getSupplierName } from '../mixins/MiscMixin';

export default function TemplateFromJobModal({job}) {

  const [modal, setModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fields, setFields] = useState({
    'dayofweek': 'Monday',
    'weekofmonth': '1',
    'dayofmonth': '1',
    'frequency': '1',
    'period': 'Day',
    'assigntoproperty': false,
    'recur': false,
    'property': job.property.id,
    'dayofmonthorweekofmonthanddayofweek': 'dayofmonth',
    'assigntofieldworker': false,
  });

  const { 
    dispatch, 
    state: {
      currentGroup
    } 
  } = useContext(Store);

  const toggle = () => {
    setModal(!modal);
  }

  const createTemplate = async () => {

    setSaving(true);

    let obj = {
      type: 'Template',
      shortdescription: job.shortdescription,
      worktype: job.worktype,
      invoiceto: job.invoiceto,
      accesscontacttype: 'N/A',
      blockavailability: job.blockavailability,
      addpropertywarning: job.addpropertywarning,
      rating: job.rating,
      property: new common.Property(currentGroup.holdingProperty),
      durationminutes: job.durationminutes,
      supplier: new common.Supplier((fields.assigntofieldworker && job.workordersupplier.id) ? job.workordersupplier.supplier.id : currentGroup.holdingSupplier),
      period: 'As Required',
      frequency: 0,
      maximuminstances: 9999999,
      autogenerate: false,
      autogeneratebufferdays: 90, // ???,
      workordersource: {id: 1},
      noworkrequired: job.noworkrequired,
    };

    if (fields.assigntoproperty) {
      obj.property = new common.Property(fields.property);
    }

    if (fields.recur) {
      obj.autogenerate = true;
      obj.period = fields.period;
      obj.frequency = fields.frequency;
      switch (fields.period) {
        case 'Week':
          obj.dayofweek = fields.dayofweek;
          break;
        case 'Year':
        case 'Month':
          if (fields.period === 'Year') {
            obj.month = fields.month;
          }

          switch (fields.dayofmonthorweekofmonthanddayofweek) {
            case 'dayofmonth':
              obj.dayofmonth = fields.dayofmonth;
              break;
            case 'weekofmonthanddayofweek':
              obj.weekofmonth = fields.weekofmonth;
              obj.dayofweek = fields.dayofweek;
              break;
            default:
          };
          break;
        default:
      }
    }

    let newJob = new common.WorkOrder();

    await newJob.create(obj);

    for (const expense of job.expenses.collection) {

      let eObj = {
        description: expense.description,
        currency: expense.currency,
        vatband: expense.vatband,
        costitemcode: expense.costitemcode,
        amountnetestimate: expense.amountnetestimate,
        chargingperiod: expense.chargingperiod,
        chargingperiodsestimate: expense.chargingperiodsestimate,
        amountlimittype: expense.amountlimittype,
        amountnetlimit: expense.amountnetlimit,
        agencycostnet: expense.agencycostnet,
      }

      var newExpense = new common.WorkOrderExpense();
      newExpense.parent = newJob;

      await newExpense.create(eObj);

      for (const charge of expense.ownercharges.collection) {

        let cObj = {
          ownerchargecode: charge.ownerchargecode,
          ownerchargeamounttype: charge.ownerchargeamounttype,
          amount: charge.amount,
          description: charge.description,
        }

        var newCharge = new common.WorkOrderOwnerCharge();
        newCharge.parent = newExpense;

        await newCharge.create(cObj);

      }

    }

    // copy checklists
    var checklists = new Collection({
      path: 'pmschecklist',
      object: common.WorkOrderPmsChecklist,
      parent: job,
    });

    await checklists.fetch();
    
    for (const checklist of checklists.collection) {
      let clObj = {
        pmschecklist: checklist,
        json: "{}"
      }

      let cl = new common.WorkOrderPmsChecklist();
      cl.parent = newJob;

      await cl.create(clObj);
    }

    // copy service tags
    for (const wost of job.servicetags.collection) {
      let newWost = new common.WorkOrderServiceTag();
      newWost.parent = newJob;

      await newWost.create({ servicetag: wost.servicetag });
    }

    addTab('editJob', {workOrderId: newJob.id}, dispatch);
    toggle();
  }

  const handleChange = event => {
    if (Array.isArray(event)) {
      const property = event.length > 0 ? event[0].id : undefined;
      setFields({...fields, property});
      return;
    }

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let f = {...fields, [name]: value};
    f = {...f, 'description': getRecurDescription(f)};

    setFields(f);
  }

  const getRecurDescription = fields => {

    let ordinal = ordinalSuffixOf(parseInt(fields.frequency, 10));

    if (ordinal !== '1st') {
      ordinal += ' ';
    } else {
      ordinal = '';
    }

    let desc = 'Job will occur every ';

    switch (fields.period) {
      case 'Day':
        desc += `${ordinal}day.`;
        break;
      case 'Week':
        desc += `${ordinal}${fields.dayofweek}.`;
        break;
      case 'Month':
      case 'Year':
        if (fields.period === 'Month') {
          desc = `Every ${ordinal}month, job will occur every `;
        } else {
          desc = `Every ${ordinal}year, job will occur every ${fields.month}, on the `;
        }
        
        if (fields.dayofmonthorweekofmonthanddayofweek === 'dayofmonth') {
          const dayOfMonthOrdinal = ordinalSuffixOf(parseInt(fields.dayofmonth, 10));
          desc += `${dayOfMonthOrdinal} day of the month.`; 
        } else {
          const weekOfMonthOrdinal = ordinalSuffixOf(parseInt(fields.weekofmonth, 10));
          desc += `${weekOfMonthOrdinal} week of the month on ${fields.dayofweek}.`;
        }
        break;
      default:
    }

    return desc;
  }

  // STOLEN - https://stackoverflow.com/a/13627586
  const ordinalSuffixOf = i => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

  let daysOfMonth = [];
  for (let i = 1; i <= 31; i++) {
    daysOfMonth.push(<option key={i} value={i}>{i}</option>);
  }
  daysOfMonth.push(<option key={99} value={99}>last day of month</option>);

  let weeksOfMonth = [];
  for (let i = 1; i <= 4; i++) {
    weeksOfMonth.push(<option key={i} value={i}>{i}</option>);
  }

  let daysOfWeek = [];
  ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].forEach(day =>
    daysOfWeek.push(<option key={day} value={day}>{day}</option>)
  );

  const months = ["January", "February", "March", "April", "May", "June",
                  "July", "August", "September", "October", "November", "December"].map(month => 
    <option key={month} value={month}>{month}</option>
  );

  return (
    <React.Fragment>
      <Button color="primary" size="sm" onClick={toggle}>
        create template from job
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>create template from job</ModalHeader>
        <ModalBody>
          <p>Use this facility to create a template job which you can reuse later.</p>
          <p>Note that job items and their respective values are retained.</p>
          <p>You can administer templates in admin {'>'} work types.</p>
          {job.workordersupplier.id && job.workordersupplier.supplier.id !== currentGroup.holdingSupplier &&
          <Check
            name="assigntofieldworker"
            label={"assign template to field worker " + getSupplierName(job.workordersupplier.supplier) + '?'}
            onChange={handleChange}
            value={fields.assigntofieldworker}
            labelWidth={1}
          />
          }
          {fields.assigntoproperty &&
          <FormGroup row>
            <Label sm={4}>property</Label>
            <Col sm={8}>
              <SelectProperty selectedProperty={job.property} onChange={handleChange} />
            </Col>
          </FormGroup>
          }
          {fields.assigntoproperty &&
          <Check
            name="recur"
            label="recur job"
            onChange={handleChange}
            value={fields.recur}
          />          
          }
          {fields.recur &&
          <React.Fragment>
            <SelectList
              name="period"
              label="period"
              onChange={handleChange}
              values={[
                {id: 'Day', name: 'every day'},
                {id: 'Week', name: 'every week'},
                {id: 'Month', name: 'every month'},
                {id: 'Year', name: 'every year'},
              ]}
              value={fields.period}
              optionName="name"
            />
            <Number
              name="frequency"
              label="frequency"
              onChange={handleChange}
              value={fields.frequency}
            />
            {fields.period === 'Week' && 
            <FormGroup row>
              <Label sm={4}>&nbsp;</Label>
              <Col sm={8}>
                <FormGroup row>
                  <Label sm={4}>day of the week</Label>
                  <Col sm={8}>
                    <Input type="select" name="dayofweek" onChange={handleChange}>
                      {daysOfWeek}
                    </Input>             
                  </Col>
                </FormGroup>
              </Col>
            </FormGroup>             
            }
            {fields.period === 'Year' &&
            <FormGroup row>
              <Label sm={4}>month</Label>
              <Col sm={8}>
                <Input type="select" name="month" onChange={handleChange}>
                  {months}
                </Input>
              </Col>
            </FormGroup>            
            }
            {['Month', 'Year'].includes(fields.period) &&
            <React.Fragment>
              <FormGroup row>
                <Label sm={4}>schedule type</Label>
                <Col sm={8}>
                  <FormGroup check>
                    <Label check>
                      <Input 
                        type="radio" 
                        name="dayofmonthorweekofmonthanddayofweek" 
                        value="dayofmonth" 
                        onChange={handleChange} 
                        checked={fields.dayofmonthorweekofmonthanddayofweek === 'dayofmonth'}
                      />{' '}
                      schedule by a day of the month
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input 
                        type="radio" 
                        name="dayofmonthorweekofmonthanddayofweek" 
                        value="weekofmonthanddayofweek" 
                        onChange={handleChange}
                        checked={fields.dayofmonthorweekofmonthanddayofweek === 'weekofmonthanddayofweek'}
                      />{' '}
                      schedule by a week of the month and a day of the week
                    </Label>
                  </FormGroup>                
                </Col>
              </FormGroup>
              {fields.dayofmonthorweekofmonthanddayofweek === 'dayofmonth' &&
              <FormGroup row>
                <Label sm={4}>&nbsp;</Label>
                <Col sm={8}>
                  <FormGroup row>
                    <Label sm={4}>day of the month</Label>
                    <Col sm={8}>
                      <Input type="select" name="dayofmonth" onChange={handleChange}>
                        {daysOfMonth}
                      </Input>                      
                    </Col>
                  </FormGroup>
                </Col>
              </FormGroup>
              }
              {fields.dayofmonthorweekofmonthanddayofweek === 'weekofmonthanddayofweek' &&
              <FormGroup row>
                <Label sm={4}>&nbsp;</Label>
                <Col sm={8}>
                  <FormGroup row>
                    <Col sm={12}>
                      <Row>
                        <Col>
                          week of the month<br />
                          <Input type="select" name="weekofmonth" onChange={handleChange}>
                            {weeksOfMonth}
                          </Input>                        
                        </Col>  
                        <Col>
                          day of the week<br />
                          <Input type="select" name="dayofweek" onChange={handleChange}>
                            {daysOfWeek}
                          </Input>                        
                        </Col>
                      </Row>                     
                    </Col>
                  </FormGroup>
                </Col>
              </FormGroup>
              }
            </React.Fragment>
            }
            <FormGroup row>
              <Label sm={4}>&nbsp;</Label>
              <Col sm={8}>
                <Alert color="primary">
                  {fields.description}
                </Alert>
              </Col>
            </FormGroup>
          </React.Fragment>
          }
        </ModalBody>
        <ModalFooter>
          {saving &&
            <PrettyLoader size={38} />
          }
          <Button color="primary" onClick={createTemplate}>create template</Button>
          <Button color="secondary" onClick={toggle}>cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

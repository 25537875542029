import React, { useContext, useEffect, useState } from 'react';
import { common, Collection, FilterCollection } from 'plato-js-client';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader } from 'reactstrap';
import SelectProperty from '../fields/SelectProperty';
import { addTab, closeTab, updateJobSubStatus } from '../Actions';
import { Store } from "../Store";
import PrettyLoader from '../components/PrettyLoader';
import Grouping from 'plato-js-client/src/common/Grouping';

export default function JobFromTemplateModal(props) {
  const {
    state: {
      properties
    }
  } = useContext(Store);
  const storeDispatch = useContext(Store).dispatch;

  const [modal, setModal] = useState(false);
  const [templates, setTemplates] = useState();
  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState(true); //disable the save button by default
  const [templateRows, setTemplateRows] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();
  const [validate, setValidate] = useState({});
  const [fields, setFields] = useState();

  const getOptions = (templates) => {
    setDisabled(true);

    let propertyid = 0;
    let options = [];
    let optiongroups = [];
    let propertyname = '';

    //add propertyid to root of templates and them sort (to allow grouping)
    let templatesXtra = templates.map(template => ({ ...template, propertyid: template.property.id }))
    let sortedtemplates = templatesXtra.sort((a, b) => a.propertyid > b.propertyid ? 1 : -1);

    //now create an option group for each property with template options
    sortedtemplates.map((template, i) => {
      //not the first loop AND the property id has changed so add all previous options to a group
      if(i !== 0 && template.property.id !== propertyid && options.length > 0) {
        optiongroups.push(<optgroup key={propertyid} label={propertyname}>{options}</optgroup>);
        //and clear options down
        options = [];
      }

      //create an array of options for this property
      options.push(<option key={template.id} value={template.id}>{template.shortdescription}</option>);

      //save the property id so we know when it changes and the options can be assigned to the group
      propertyid = template.property.id;

      //save the property name so it can be used as the group name
      propertyname = properties.find(property => property.id === template.propertyid);
      if(propertyname === undefined) {
        propertyname = 'Generic';
      }
    });

    //add the last lot of options to a group
    if(options.length > 0) {
      optiongroups.push(<optgroup key={propertyid} label={propertyname}>{options}</optgroup>);
    }

    //if no properties groups to return then tell them no templates found
    if(optiongroups.length === 0) {
      options = [];
      options.push(<option key='no_templates'>No templates found</option>);
      return options;
    }

    setDisabled(false);
    return optiongroups;
  }

  const {
    dispatch,
    state: {
      currentGroup
    }
  } = useContext(Store);

  let template = undefined;
  let property = undefined;

  useEffect(() => {
    if (props.workType && props.workType.id) {
      // get the job templates associated with this work type
      let jobs = new FilterCollection({
        path: 'workorder',
        object: common.WorkOrder,
      });
      jobs.limit = 999;
      jobs.addFilters([{
        type: 'Template',
        worktypeid: props.workType.id,
        //only get the templates for the properties we are interested in
        propid: properties.map(property => property.id).join('|') + '|' + currentGroup.holdingProperty
      }]);
      jobs.fields = 'id:shortdescription:propertynano';

      jobs.fetch().then(() => {
        setTemplates(jobs.collection);
        setTemplateRows(getOptions(jobs.collection));
      })

    }
  }, [props.workType]);

  if (!props.workType || !templates || templates.length === 0) {
    return (
    <React.Fragment>
     <Button color="danger" size="sm">
        No templates for this work type
      </Button>
    </React.Fragment>
    );
  }

  const toggle = () => {
    setModal(!modal);
    setDisabled(true);
  }

  const createJob = async () => {

    if (!validateForm()) {
      return;
    }
    setDisabled(true);
    setSaving(true);

    let job = new common.WorkOrder(template.value);
    await job.get();

    let obj = {
      type: 'Instance',
      shortdescription: job.shortdescription,
      worktype: job.worktype,
      invoiceto: job.invoiceto,
      accesscontacttype: 'N/A',
      blockavailability: job.blockavailability,
      addpropertywarning: job.addpropertywarning,
      rating: job.rating,
      property: new common.Property(selectedProperty),
      durationminutes: job.durationminutes,
      supplier: new common.Supplier(currentGroup.holdingSupplier),
      workordersource: {id: 1},
      noworkrequired: job.noworkrequired,
      requiredbydate: fields.requiredbydate,
    };

    if(job.noworkrequired) {
      obj.preferredstartdatetime = fields.requiredbydate;
    }

    let newJob = new common.WorkOrder();

    await newJob.create(obj);

    // copy job items
    for (const expense of job.expenses.collection) {

      let eObj = {
        description: expense.description,
        currency: expense.currency,
        vatband: expense.vatband,
        costitemcode: expense.costitemcode,
        amountnetestimate: expense.amountnetestimate,
        chargingperiod: expense.chargingperiod,
        chargingperiodsestimate: expense.chargingperiodsestimate,
        amountlimittype: expense.amountlimittype,
        amountnetlimit: expense.amountnetlimit,
        agencycostnet: expense.agencycostnet,
      }

      var newExpense = new common.WorkOrderExpense();
      newExpense.parent = newJob;

      await newExpense.create(eObj);

      for (const charge of expense.ownercharges.collection) {

        let cObj = {
          ownerchargecode: charge.ownerchargecode,
          ownerchargeamounttype: charge.ownerchargeamounttype,
          amount: charge.amount,
          description: charge.description,
        }

        var newCharge = new common.WorkOrderOwnerCharge();
        newCharge.parent = newExpense;

        await newCharge.create(cObj);

      }

    }

    // copy checklists
    var checklists = new Collection({
      path: 'pmschecklist',
      object: common.WorkOrderPmsChecklist,
      parent: job,
    });

    await checklists.fetch();

    for (const checklist of checklists.collection) {
      let clObj = {
        pmschecklist: checklist,
        json: "{}"
      }

      let cl = new common.WorkOrderPmsChecklist();
      cl.parent = newJob;

      await cl.create(clObj);
    }

    // copy service tags
    for (const wost of job.servicetags.collection) {
      let newWost = new common.WorkOrderServiceTag();
      newWost.parent = newJob;

      await newWost.create({ servicetag: wost.servicetag });
    }

    // give new job the 'new' sub-status or completed if no work is required
    if(job.noworkrequired) {
      let obj = {completeddate: fields.requiredbydate};
      let wo = new common.WorkOrder(newJob.id);
      await wo.update(obj);
      await updateJobSubStatus(wo.id, 'completed', dispatch);
    } else {
      await updateJobSubStatus(newJob.id, 'new', dispatch);
    }

    addTab('editJob', {workOrderId: newJob.id}, dispatch);
    closeTab(props.tabRef, dispatch);
    toggle();
  }

  //filter the templates when a property is selected
  const filterTemplates = property => {
    let propertyid = (property && property[0]) ? property[0].id : null;

    let propertyOptions = '';

    if(propertyid) {
      setSelectedProperty(propertyid);
      //property specific
      propertyOptions = getOptions(templates.filter(template => template.property.id === propertyid || template.property.id === currentGroup.holdingProperty));
    } else {
      //no id provided just get all the templates
      propertyOptions = getOptions(templates);
      //no property id so disable the save button
      setDisabled(true);
    }

    //set them
    setTemplateRows(propertyOptions);
  }

  const handleChange = event => {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let f = {...fields, [name]: value};

    setFields(f);
  }

  const validateForm = () => {
    let v = {...validate};

    v.requiredbydate = fields ? fields.requiredbydate ? true : false : false;

    setValidate(v);

    return !Object.values(v).includes(false);
  }

  return (
    <React.Fragment>
      <Button color="primary" size="sm" onClick={toggle}>
        create job from template ({templates.length} available)
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>create job from template</ModalHeader>
        <ModalBody>
          {templates &&
          <React.Fragment>
            <FormGroup row>
              <Label sm={4}>property</Label>
              <Col sm={8}>
                <SelectProperty onChange={(value) => { property = value; filterTemplates(property); }} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={4}>template</Label>
              <Col sm={8}>
                <Input type="select" innerRef={(el) => { template = el }}>
                  {templateRows}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
            <Label sm={4}>required by date</Label>
              <Col sm={8}>
                <Input
                  type="date"
                  name="requiredbydate"
                  placeholder="required by date"
                  onChange={handleChange}
                  invalid={validate.requiredbydate === false}
                />
                {validate.requiredbydate === false &&
                <div className="invalid-feedback d-block">
                  You must choose a required by date.
                </div>
                }
              </Col>
            </FormGroup>
          </React.Fragment>
          }
        </ModalBody>
        <ModalFooter>
          {saving &&
          <PrettyLoader size={38} />
          }
          <Button color="primary" onClick={createJob} disabled={disabled}>create job</Button>
          <Button color="secondary" onClick={toggle}>cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

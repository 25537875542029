import React, { useContext } from 'react';
import { Store } from "../Store";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

var { Typeahead } = require('react-bootstrap-typeahead');

export default function SelectProperty(props) {

  const { 
    state: {
      properties
    } 
  } = useContext(Store);

  const options = properties.map(property => {
    return {
      id: property.id, 
      name: property.name + ' (' + property.tabspropref + ')'
    };
  });

  let defaultSelected;
  if (props.selectedProperty) {
    defaultSelected = [{
      id: props.selectedProperty.id,
      name: props.selectedProperty.name + ' (' + props.selectedProperty.tabspropref + ')'      
    }];
  }

  return (
    <Typeahead
      style={props.style}
      className={props.className}
      id="property"
      name="property"
      labelKey="name"
      multiple={false}
      options={options}
      placeholder="Start typing the property name or reference..."
      onChange={props.onChange}
      isInvalid={props.isInvalid}
      defaultSelected={defaultSelected}
      clearButton={true}
    />
  );

}
import React, { useContext, useState } from 'react';
import { 
  Button,
  ListGroup, 
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row } from 'reactstrap';
import { Store } from "../Store";

export default function SelectWorkTypeModal(props) {

  const {
    state: {
      workTypes
    }
  } = useContext(Store);

  const [modal, setModal] = useState(false);
  const [workType, setWorkType] = useState({});

  const toggle = () => {
    setModal(!modal);
  }

  const returnWorkType = () => {
    props.onChoose(workType);
    toggle();
  }

  const renderLists = (obj, lists = []) => {

    let rows = [];
  
    let parent = obj.id ? obj.parentworktype : {};

    const addRow = (wt) => {
      rows.push(
        <ListGroupItem
          tag="button"
          action
          active={obj.id === wt.id}
          key={wt.id}
          onClick={() => { setWorkType(wt); }}
        >
          {wt.worktype}
        </ListGroupItem>
      );
    }

    const addList = (key, rows) => {
      lists.unshift(
        <Col key={key}>
          <ListGroup className="mb-2">
            {rows}
          </ListGroup>
        </Col>
      );
    }

    if (obj.id && obj.id === workType.id) {
      // if this is the selected WorkType, also bosh a list for any children it has
      workTypes.forEach(wt => {
        if (wt.parentworktype.id === obj.id) {
          addRow(wt);
        }
      });

      if (rows.length > 0) {
        addList('childrenOfSelected', rows, workType);
      }
      
      rows = [];
    }

    workTypes.forEach(wt => {
      if ((!parent.id && !wt.parentworktype.id) || (wt.parentworktype.id === parent.id)) {
        addRow(wt);
      }
    });

    addList('lg' + obj.id, rows, parent);

    if (parent.id) {
      renderLists(parent, lists);
    }

    return lists;

  }

  if (!workTypes) {
    return null;
  }

  const lists = renderLists(workType);

  return (
    <React.Fragment>
      <Button size="sm" onClick={toggle}>{props.buttonText || "choose work type"}</Button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>choose work type</ModalHeader>
        <ModalBody>
          <Row>
            {lists}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={returnWorkType} disabled={!workType.id}>go</Button>
          <Button color="secondary" onClick={toggle}>cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

import React, { useState, useEffect } from 'react';
import { Collection } from 'plato-js-client';
import { 
  Col, 
  FormFeedback,
  FormGroup,
  Input,
  Label } from 'reactstrap';

export default function SelectList(props) {

  const [collection, setCollection] = useState();

  useEffect(() => {
    if (props.values) {
      setCollection(props.values);
    } else {
      var collection = new Collection({
        path: props.path || props.name,
        object: props.object,
      });
      collection.fetch().then(() => {
        setCollection(collection);
      });
    }
  }, [props]);

  if (!collection) {
    return null;
  }

  let listItems = [];
  if (props.emptyValue) {
    listItems.push(
      <option key="empty" value="">{props.emptyValue}</option>
    );
  }

  let firstItemValue = undefined;

  collection.forEach((item, i) => {
    const optionName = props.optionName ? item[props.optionName] : item.toString();
    const value = props.optionValue ? item[props.optionValue] : item.id;

    if (i === 0) {
      firstItemValue = value;
    }

    listItems.push(
      <option key={value} value={value}>{optionName}</option>
    );
  });

  let obj = {
    type: "select",
    name: props.name,
    invalid: props.invalid,
    onChange: props.onChange,
    disabled: props.disabled,
  };

  if (props.innerRef) {
    obj.innerRef = props.innerRef;
    obj.defaultValue = props.defaultValue;
  } else {
    obj.value = props.value || props.emptyValue || firstItemValue;
  }

  return (
    <FormGroup row>
      <Label sm={4} for={props.name}>{props.label || props.name}</Label>
      <Col sm={8}>
        <Input {...obj}>
          {listItems}
        </Input>
        <FormFeedback>You must choose a {props.label || props.name}.</FormFeedback>
      </Col>
    </FormGroup>
  );
  

}
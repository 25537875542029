import React from 'react';

export default function Icon(props) {

  let classes = ['numberCircle'];
  if (props.color) {
    classes.push(props.color);
  } else {
    classes.push('green');
  }
  
  return (
    <div className={classes.join(' ')}>
      <div className={props.onClick ? 'cursorPointer' : ''} onClick={props.onClick}>
        {props.children}
      </div>
    </div>
  );
}
module.exports = {
  stringToColour: function(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
  
    return colour;
  },

  secondsToHms: function(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour" : " hours") : null;
    var mDisplay = m > 0 ? m + (m == 1 ? " minute" : " minutes") : null;
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : null;

    return [hDisplay, mDisplay, sDisplay].filter(Boolean).join(', ');
  },

  hasVatNumber: function(workOrder) {
    if (workOrder 
        && workOrder.workordersupplier
        && workOrder.workordersupplier.id
        && workOrder.workordersupplier.supplier
        && workOrder.workordersupplier.supplier.id
        && workOrder.workordersupplier.supplier.vatnumber
        && workOrder.workordersupplier.supplier.vatnumber.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  },
  
  isTest: function() {
    var hostName = window.location.hostname;
    return (hostName.includes('localhost') ||
            hostName.includes('staging'));
  },

  getSupplierName: function(supplier) {
    return [
      supplier.title,
      supplier.firstname,
      supplier.surname
    ].join(' '); 
  }
}

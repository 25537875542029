import React from 'react';
import FilteredJobs from './components/FilteredJobs';
import { Card, CardBody, Col, Row } from 'reactstrap';

export default function TabsJobsList() {
  return (
    <Row className="m-3">
      <Col>
        <h3>Jobs list</h3>
        <Card>
          <CardBody>
            <FilteredJobs mode="attention" />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );

}
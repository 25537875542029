import React, { useState, useEffect, useContext } from 'react';
import { Collection, common } from 'plato-js-client';
import {
  Alert,
  Button,
  Row,
  Col,
  Card,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table } from 'reactstrap';
import moment from 'moment';
import { Store } from "../Store";
import Icon from './Icon';
import OwnerChargeModal from './OwnerChargeModal';
import SelectList from '../fields/SelectList';
import Text from '../fields/Text';
import { hasVatNumber } from '../mixins/MiscMixin';

export default function JobExpenseModal({
  workOrder,
  workOrderFields,
  expense,
  toggle,
  handleExpense,
  disableAll,
  inGroup
}) {

  const {
    state: {
      vatRates,
      costItemCodes,
      chargingPeriods,
      amountLimitTypes,
      jobItemTemplates,
      ownerChargeCodes,
    }
  } = useContext(Store);

  const [validate, setValidate] = useState({});
  const [useExistingTemplate, setUseExistingTemplate] = useState(false);
  const [ownerChargeModal, setOwnerChargeModal] = useState(false);
  const [fields, setFields] = useState({
    agencycostgross: 0,
    agencycostnet: (expense && expense.agencycostnet) || 0,
    agencycostvat: 0,
    amountgrossestimate: 0,
    amountlimittype: expense && expense.amountlimittype && expense.amountlimittype.id,
    amountgross: 0,
    amountnet: (expense && expense.amountnet) ? expense.amountnet : 0,
    amountvat: 0,
    amountnetestimate: (expense && expense.amountnetestimate) || 0,
    amountnetlimit: (expense && expense.amountnetlimit) || 0,
    amountvatestimate: 0,
    chargingperiod: expense && expense.chargingperiod && expense.chargingperiod.id,
    chargingperiodsestimate: (expense && expense.chargingperiodsestimate) || 1,
    chargingperiodpriceperunit: (expense && expense.chargingperiodpriceperunit) || 1,
    costitemcode: expense && expense.costitemcode && expense.costitemcode.id,
    description: (expense && expense.description) || '',
    gross: 0,
    estimatevaluetype: (expense && expense.chargingperiod && expense.chargingperiod.id) ? 'perperiod' : (expense && expense.chargingperiodpriceperunit) ? 'peritem' : 'setamount',
    itemisfromstock: (expense && expense.agencycostnet) || false,
    nocost: (expense && expense.amountnetestimate === 0) ? true : false,
    template: '',
    templatetotal: 0,
    templatequantity: 1,
    vatband: (expense && expense.vatband && expense.vatband.id) || 2,
    invoiceto: expense && ['Agency', 'Owner', 'None (zero cost)'].includes(expense.invoiceto) ? expense.invoiceto : 'Owner',
    ownercharges: (expense && expense.ownercharges) ? (expense.ownercharges.collection ? expense.ownercharges.collection : expense.ownercharges) : [],
    agencyownerchargecode: expense && expense.agencyownerchargecode && expense.agencyownerchargecode.id,
    requires1104: expense && expense.costitemcode && expense.costitemcode.id && expense.costitemcode.recharge === true
  });
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    let f = {...fields};
    f = {...f, ...getVats(f)};
    f = {...f, ...getHelpfulDescriptions(f)};
    setFields(f);
  }, []);

  const mode = expense ? 'edit' : 'add';
  const subStatus = workOrder && workOrder.substatus ? workOrder.substatus.workordersubstatus.substatusreference : '';
  const propertyId = workOrder && workOrder.property ? workOrder.property.id : workOrderFields.property.id;

  useEffect(() => {
    const loadProperty = () => {
      let property = new common.Property(propertyId);
      property.get().then(() => {
        var answers = new Collection({
          path: 'answer',
          object: common.PropertyAnswer,
          parent: property
        });

        answers.fetch().then(() => {
          setAnswers(answers);
        });
      });
    }

    loadProperty();
  }, [propertyId]);

  let showEstimateBlock = false;
  let showAmountBlock = false;
  let showCostBlock = false;
  let fieldWorkerSuppliedItem = false;

  if (fields.itemisfromstock) {
    showCostBlock = true;
  } else {
    showEstimateBlock = true;

    if (fields.amountnet) {
      showAmountBlock = true;
    }
  }

  if (mode === 'edit' && subStatus !== 'new' && !fields.costitemcode) {
    fieldWorkerSuppliedItem = true;
  }

  if (fields.nocost === true) {
    showAmountBlock = false;
    showEstimateBlock = false;
  }

  const handleChange = event => {

    const target = event.target;
    const name = target.name;
    let value = undefined;

    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'number':
        value = parseFloat(target.value);
        break;
      default:
        value = target.value;
    }

    let f = {...fields};
    f[name] = value;

    if (['template', 'templatequantity'].includes(name)) {
      let templatetotal = 0;
      const jit = jobItemTemplates.find(template => template.id === parseInt(f.template, 10));

      if (jit) {
        if (jit.agencycostnet) {
          templatetotal = jit.agencycostnet;
        } else if (jit.amountnetestimate) {
          templatetotal = jit.amountnetestimate;
        }

        templatetotal *= f.templatequantity;
      }

      f.templatetotal = templatetotal;
    }

    if (name === 'estimatevaluetype' && value === 'setamount') {
      f.chargingperiod = undefined;
    }
    if (name === 'estimatevaluetype' && value === 'perperiod') {
      const chargingPeriod = chargingPeriods.find(
        cp => cp.chargingperiod == 'Hour'
      );

      f.chargingperiod = chargingPeriod.id;
    }
    if (name === 'estimatevaluetype' && value === 'peritem') {
      f.chargingperiod = undefined;
    }

    if (name === 'costitemcode') {
      const cic = costItemCodes.find(cic => cic.id === parseInt(value, 10));
      if (cic) {
        f.requires1104 = cic.recharge === true;
        if (f.requires1104) {
          f.invoiceto = 'Owner';
        }
      }
    }

    if (['estimatevaluetype', 'chargingperiodsestimate', 'chargingperiodpriceperunit'].includes(name)) {
      f.amountnetestimate = parseFloat(f.chargingperiodsestimate) * parseFloat(f.chargingperiodpriceperunit);
    }

    f = {...f, ...getVats(f)};

    f = {...f, ...getHelpfulDescriptions(f)};

    setFields(f);
  }

  const getVats = fields => {
    var amountvatestimate = 0;
    var amountgrossestimate = parseFloat(fields.amountnetestimate);

    var agencycostvat = 0;
    var agencycostgross = parseFloat(fields.agencycostnet);

    var amountvat = 0;
    var amountgross = parseFloat(fields.amountnet);

    var vatband = undefined;

    const costItemCode = fields.costitemcode && costItemCodes.find(
      costItemCode => costItemCode.id === parseInt(fields.costitemcode, 10)
    );

    if (costItemCode && costItemCode.vatband) {
      const vr = getVatRateFromBand(costItemCode.vatband);

      amountvatestimate = (amountgrossestimate / 100 * vr.percentage);
      amountgrossestimate += amountvatestimate;

      agencycostvat = (agencycostgross / 100 * vr.percentage);
      agencycostgross += agencycostvat;

      amountvat = (amountgross / 100 * vr.percentage);
      amountgross += amountvat;

      vatband = costItemCode.vatband && costItemCode.vatband.id;
    }

    return {
      amountvatestimate,
      amountgrossestimate,
      agencycostvat,
      agencycostgross,
      amountvat,
      amountgross,
      vatband,
    }
  }

  const getVatRateFromBand = (vb) => {
    var vr;

    vatRates.forEach(vRate => {
      if (vRate.vatband &&
          vRate.vatband.id === vb.id &&
          moment().isBetween(vRate.fromdate, vRate.todate, 'day', '[]')
      ) {
        vr = vRate;
      }
    });

    return vr;
  }

  const getHelpfulDescriptions = fields => {

    var chargingPeriodDescription = '';
    var toleranceDescription = '';

    var amountNetEstimate = parseFloat(fields.amountnetestimate);

    const chargingPeriod = fields.chargingperiod && chargingPeriods.find(
      cp => cp.id === parseInt(fields.chargingperiod)
    );

    if (chargingPeriod || (!chargingPeriod && fields.chargingperiodpriceperunit)) {
      var chargingPeriodsEstimate = parseFloat(fields.chargingperiodsestimate);

      var per = parseFloat(fields.chargingperiodpriceperunit);
      var chargingPeriodName = !chargingPeriod ? 'item' : chargingPeriod.chargingperiod.toLowerCase();

      chargingPeriodDescription = [
        chargingPeriodsEstimate,
        chargingPeriodName + 's',
        ' @ ',
        '£' + per.toFixed(2),
        ' per ',
        chargingPeriodName,
        ' = ',
        '£' + amountNetEstimate.toFixed(2)
      ].join(' '); // e.g. '2 hours @ 12.00 per hour = 24.00'
    }

    const amountLimitType = fields.amountlimittype && amountLimitTypes.find(
      alt => alt.id === parseInt(fields.amountlimittype)
    );

    if (amountLimitType) {
      var estimate = amountNetEstimate;
      var limit = 0;
      var amountNetLimit = parseFloat(fields.amountnetlimit);

      switch (amountLimitType.amountlimittype) {
        case 'Fixed':
          limit = amountNetLimit;
          break;
        case 'Percentage':
          var multiplier = 1 + (amountNetLimit / 100);
          limit = (estimate * multiplier).toFixed(2);
          break;
        case 'Amount':
          limit = estimate + amountNetLimit;
          break;
        default:
      }

      toleranceDescription = limit ? 'Submitted charge may not exceed £' + parseFloat(limit).toFixed(2) : '';
    }

    return {
      chargingPeriodDescription,
      toleranceDescription,
    }

  }

  const onSave = async () => {

    if (disableAll) {
      toggle();
      return;
    }

    if (!validateForm()) {
      return false;
    }

    var obj = {
      new: !expense ? true : false,
      updated: true,
      description: fields.description,
      currency: new common.Currency(1),
      vatband: new common.VatBand(fields.vatband || 2),
      costitemcode: costItemCodes.find(cic => cic.id === parseInt(fields.costitemcode, 10)),
      // donotpaysupplier: fields.donotpaysupplier,
      invoiceto: fields.invoiceto,
    };

    if (showCostBlock) {
      obj.agencycostnet = fields.agencycostnet;
    } else if (showAmountBlock) {
      obj.amountnet = fields.amountnet;
    } else if (showEstimateBlock) {
      obj.amountnetestimate = fields.amountnetestimate;

      if (fields.estimatevaluetype === "perperiod" || fields.estimatevaluetype === "peritem") {
        obj.chargingperiod = fields.estimatevaluetype === "perperiod" ? chargingPeriods.find(cp => cp.id === parseInt(fields.chargingperiod, 10)) : 0;
        obj.chargingperiodsestimate = fields.chargingperiodsestimate;
        obj.chargingperiodpriceperunit = fields.chargingperiodpriceperunit;
      } else {
        obj.chargingperiod = 0;
      }

      if (fields.amountlimittype !== undefined) {
        obj.amountlimittype = amountLimitTypes.find(alt => alt.id === parseInt(fields.amountlimittype, 10));
        obj.amountnetlimit = fields.amountnetlimit;
      } else {
        obj.amountlimittype = new common.AmountLimitType(0);
      }
    }

    if (fields.invoiceto === 'Agency') {
      obj.agencyownerchargecode = ownerChargeCodes.find(occ => occ.id === parseInt(fields.agencyownerchargecode, 10));
    }

    if (fields.nocost === true) {
      obj.amountnetestimate = 0;
    }

    obj.ownercharges = fields.ownercharges;

    handleExpense({...expense, ...obj});
    toggle();
  }

  const validateForm = () => {
    let newValidate = {...validate};
    let valid = true;

    newValidate.description = fields.description.length > 0 ? true : false;
    newValidate.costitemcode = fields.costitemcode ? true : false;
    //if estimate value type is perperiod then we must have a charging period
    //if estimate value type is peritem then we must have a price per unit and charging period must be null
    newValidate.chargingperiod = (!(fields.estimatevaluetype === 'perperiod' && !fields.chargingperiod) ||
                                   (fields.estimatevaluetype === 'peritem' && !fields.chargingperiodpriceperunit && fields.chargingperiod)) ? true : false;

    newValidate.ownercharges = true;
    if (fields.invoiceto === 'Owner') {
      if (fields.ownercharges.filter(oc => !oc.deleted).length === 0) {
        newValidate.ownercharges = false;
      }
    }

    newValidate.agencyownerchargecode = true;
    if (fields.invoiceto === 'Agency' && !fields.agencyownerchargecode) {
      newValidate.agencyownerchargecode = false;
    }

    newValidate.amountnetestimate = true;
    newValidate.amountnet = true;
    newValidate.agencycostnet = true;

    if (showCostBlock && fields.agencycostnet === 0) {
      newValidate.agencycostnet = false;
    } else if (showAmountBlock && fields.amountnet === 0) {
      newValidate.amountnet = false;
    } else if (showEstimateBlock && fields.amountnetestimate < 0) {
      newValidate.amountnetestimate = false;
    }

    newValidate.requires1104 = true;
    if (fields.requires1104) {
      newValidate.requires1104 = false;
      fields.ownercharges.filter(oc => !oc.deleted).map(oc => {
        if (oc.ownerchargecode.recharge === true) {
          newValidate.requires1104 = true;
        }
      });
    }

    valid = !Object.values(newValidate).includes(false);

    setValidate(newValidate);

    return valid;
  }

  const useTemplate = () => {
    let f = {...fields};
    const jit = jobItemTemplates.find(template => template.id === parseInt(f.template, 10));

    f.description = jit.description;
    f.estimatevaluetype = 'setamount';
    f.nocost = false;

    if (jit.agencycostnet) {
      f.agencycostnet = jit.agencycostnet * f.templatequantity;
      f.itemisfromstock = true;
    } else {
      f.amountnetestimate = jit.amountnetestimate * f.templatequantity;
      f.itemisfromstock = false;

      if (jit.chargingperiodsestimate) {
        f.estimatevaluetype = (jit.chargingperiod.chargingperiod === null) ? 'peritem' : 'perperiod';
        f.chargingperiod = jit.chargingperiod.id;
        f.chargingperiodsestimate = jit.chargingperiodsestimate * f.templatequantity;
        f.chargingperiodpriceperunit = jit.chargingperiodpriceperunit;
      } else {
        f.chargingperiod = 0;
      }
    }

    f.costitemcode = jit.costitemcode.id;

    f = {...f, ...getVats(f)};

    let ownercharges = [];

    jit.ownercharges.forEach(ownercharge => {
      let oc = {...ownercharge};

      // todo: maybe just build the object from scratch instead of mutating a WorkOrderOwnerChargeTemplate
      delete oc.id;
      delete oc.createPath;
      delete oc.parent;
      delete oc.path;

      oc.new = true;
      oc.updated = true;
      oc.description = '';
      oc.description = workOrderFields.shortdescription + ' - ' + (oc.description ? oc.description : oc.ownerchargecode.description);
      //PMS-205 if the type is fixed multiply amount by quantity
      oc.amount = oc.ownerchargeamounttype.ownerchargeamounttype == 'Fixed' ? f.templatequantity * oc.amount : oc.amount;
      ownercharges.push(oc);
    });

    f.ownercharges = ownercharges;

    // if no owner charges on job item template, set invoice to zero cost
    if (!ownercharges.length) {
      f.invoiceto = 'None (zero cost)';
    }

    setFields(f);
  }

  const toggleOwnerChargeModal = () => {
    setOwnerChargeModal(!ownerChargeModal);
  }

  const handleOwnerCharge = (oc, i) => {
    let ownercharges = [...fields.ownercharges];

    if (oc.new === true && !oc.deleted) {
      ownercharges.push(oc);
    } else {
      ownercharges[i] = oc;
    }

    setFields({...fields, ownercharges});
  }

  let agencyOwnerChargeCodes = ownerChargeCodes;
  if (fields.costitemcode) {
    const cic = costItemCodes.find(cic => cic.id === parseInt(fields.costitemcode, 10));
    if (cic.ownerchargecodes.length > 0) {
      agencyOwnerChargeCodes = cic.ownerchargecodes;
    }
  }

  let showVat = hasVatNumber(workOrder);

  const hasOwnerCharges = fields.ownercharges.filter(oc => !oc.deleted).length;
  let rows = [];

  answers.forEach(answer => {
    if (answer.question.question.toLowerCase().startsWith('what is the invoice handle')) {
      rows.push(
        <React.Fragment key={answer.id}>
          <div>
            <span style={{ textAlign: 'left' }}>Invoice Handling fee %</span>
            <span style={{ float: 'right' }}>{answer.textanswer}</span>
          </div>
        </React.Fragment>
      )
    }
  });

  return (
    <Modal isOpen toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{mode} job item</ModalHeader>
      <ModalBody className={disableAll ? 'disable' : ''}>

        {inGroup &&
        <Alert color="primary">
          This job item is part of a group, so some options (e.g. &apos;charge to&apos;) are disabled.
        </Alert>
        }
        <Alert color={rows.length === 0 ? "primary" : 'warning'}>
          <div>
            {rows.length === 0 &&
            <>
              <span style={{ textAlign: 'left' }}>Invoice Handling fee %</span>
              <span style={{ float: 'right' }}>none</span>
            </>
            }
            {rows}
          </div>
        </Alert>
        {mode === 'add' &&
        <Button
          color="primary"
          className="mb-3"
          onClick={() => setUseExistingTemplate(!useExistingTemplate)}
          block
        >
          {useExistingTemplate && "don't "} use template
        </Button>
        }
        {useExistingTemplate &&
        <Card body outline color="primary" className="mb-3">
          <Row form>
            <Col>
              <FormGroup>
                <Label>template</Label>
                <Input
                  type="select"
                  name="template"
                  value={fields.template}
                  onChange={handleChange}
                >
                  <option key="empty" value="">choose template</option>
                  {jobItemTemplates && jobItemTemplates.map(template => {
                    const agencyCost = template.agencycostnet !== null && template.agencycostnet !== undefined // add check for agencycostnet and amountnetestimate
                      ? template.agencycostnet.toFixed(2)
                      : 0;
                    const amountEstimate = template.amountnetestimate !== null && template.amountnetestimate !== undefined
                      ? template.amountnetestimate.toFixed(2)
                      : 0;
                    const displayValue = agencyCost || amountEstimate;

                    return (
                      <option key={template.id} value={template.id}>
                        {template.description} - £{displayValue}{template.agencycostnet ? ' (from stock)' : ''}
                      </option>
                    );
                  })}

                </Input>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>quantity</Label>
                <Input
                  type="number"
                  name="templatequantity"
                  value={fields.templatequantity}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>net total</Label>
                <Input
                  type="number"
                  name="templatetotal"
                  value={fields.templatetotal.toFixed(2)}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup>
                <Label>&nbsp;</Label>
                <Button className="d-block" disabled={!fields.template} onClick={useTemplate}>use</Button>
              </FormGroup>
            </Col>
          </Row>
        </Card>
        }
        <Form onSubmit={(e) => {e.preventDefault()}}>
          <Text
            name="description"
            value={fields.description}
            onChange={handleChange}
            invalid={validate.description === false}
          />
          <Alert color="warning">
            Please note: this description is for internal use and will not show to owners.
          </Alert>
          <FormGroup row>
            <Label sm={4}>charge to</Label>
            <Col sm={8}>
              <Input
                type="select"
                name="invoiceto"
                value={fields.invoiceto}
                onChange={handleChange}
                disabled={fields.requires1104 || hasOwnerCharges || inGroup}
              >
                <option key="Owner" value="Owner">owner</option>
                <option key="Agency" value="Agency">lettings brand</option>
                <option key="None (zero cost)" value="None (zero cost)">no charge</option>
              </Input>
            </Col>
          </FormGroup>

          <SelectList
            name="costitemcode"
            label="cost item code"
            value={fields.costitemcode}
            values={costItemCodes}
            onChange={handleChange}
            emptyValue="choose cost item code"
            invalid={validate.costitemcode === false}
            disabled={hasOwnerCharges}
          />

          {fields.requires1104 &&
          <FormGroup row>
            <Label sm={4}>&nbsp;</Label>
            <Col sm={8}>
              <Alert color="warning">
                A recharge owner charge must be added when using a recharge cost item code.
              </Alert>
            </Col>
          </FormGroup>
          }

          {mode === 'add' &&
          <FormGroup row className="align-items-center">
            <Label sm={4}></Label>
            <Col sm={8}>
              <CustomInput
                type="switch"
                id="itemisfromstock"
                name="itemisfromstock"
                label="item is from stock"
                checked={fields.itemisfromstock}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          }

          {!fields.itemisfromstock && fields.invoiceto === 'Owner' && workOrderFields.noworkrequired === true &&
          <FormGroup row className="align-items-center">
            <Label sm={4}></Label>
            <Col sm={8}>
              <CustomInput
                type="switch"
                id="nocost"
                name="nocost"
                label="item has no cost"
                checked={fields.nocost}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
          }

          {fieldWorkerSuppliedItem &&
          <Alert color="primary">
            This job item was added by the field worker. Assign it a cost item code and owner charge(s) as necessary.
          </Alert>
          }

          {fields.itemisfromstock &&
          <Alert color="primary">
            Note that stock items should have already been purchased. The field worker will not be paid for these items.
          </Alert>
          }

          {showEstimateBlock &&
          <React.Fragment>
            <SelectList
              name="estimatevaluetype"
              label="estimate value type"
              values={[
                {id: "setamount", toString: () => {return "Set amount"}},
                {id: "perperiod", toString: () => {return "Time period"}},
                //{id: "peritem", toString: () => {return "Items used"}}
              ]}
              value={fields.estimatevaluetype}
              onChange={handleChange}
            />
            {(fields.estimatevaluetype === "perperiod" || fields.estimatevaluetype === "peritem") &&
            <React.Fragment>
              <FormGroup row className="align-items-center">
                <Label sm={4}>charging type</Label>
                <Col sm={8}>
                  <Row>
                  {(fields.estimatevaluetype === "perperiod") &&
                    <Col>
                      unit<br />
                      <Input
                        type="select"
                        name="chargingperiod"
                        value={fields.chargingperiod}
                        onChange={handleChange}
                        invalid={validate.chargingperiod === false}
                      >
                        {chargingPeriods.map(chargingPeriod =>
                          <option key={chargingPeriod.id} value={chargingPeriod.id}>
                            {chargingPeriod.chargingperiod}
                          </option>
                        )}
                      </Input>

                    </Col>
                     }
                    <Col>
                      quantity<br />
                      <Input
                        type="number"
                        name="chargingperiodsestimate"
                        value={fields.chargingperiodsestimate}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </React.Fragment>
            }

            <FormGroup row className="align-items-center">
              <Label sm={4}>estimate amount</Label>
              <Col sm={8}>
                <Row>
                  {(fields.estimatevaluetype === "perperiod" || fields.estimatevaluetype === "peritem") &&
                  <Col>
                    price per unit<br />
                    <Input
                      type="number"
                      name="chargingperiodpriceperunit"
                      value={fields.chargingperiodpriceperunit}
                      onChange={handleChange}
                      invalid={validate.chargingperiodpriceperunit === false}
                    />
                  </Col>
                  }
                  <Col>
                    net<br />
                    <Input
                      type="number"
                      name="amountnetestimate"
                      value={fields.amountnetestimate}
                      onChange={handleChange}
                      invalid={validate.amountnetestimate === false}
                      disabled={fields.estimatevaluetype === "perperiod" || fields.estimatevaluetype === "peritem"}
                    />
                  </Col>
                  <Col>
                  {showVat &&
                  <React.Fragment>
                    VAT<br />
                    <Input
                      type="number"
                      name="amountvatestimate"
                      value={fields.amountvatestimate.toFixed(2)}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </React.Fragment>
                  }
                  </Col>
                  <Col>
                    {showVat &&
                    <React.Fragment>
                      gross<br />
                      <Input
                        type="number"
                        name="amountgrossestimate"
                        value={fields.amountgrossestimate.toFixed(2)}
                        onChange={handleChange}
                        disabled={true}
                      />
                    </React.Fragment>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {validate.amountnetestimate === false &&
                    <div className="invalid-feedback d-block mb-2">
                      You must enter an amount equal to 0 or more.
                    </div>
                    }
                  </Col>
                </Row>
              </Col>
            </FormGroup>
            {fields.chargingPeriodDescription &&
            <FormGroup row>
              <Label sm={4} />
              <Col sm={8}>
                {fields.chargingPeriodDescription}
              </Col>
            </FormGroup>
            }
          </React.Fragment>
          }
          {showCostBlock &&
          <React.Fragment>
            <FormGroup row className="align-items-center">
              <Label sm={4}>actual amount</Label>
              <Col sm={8}>
                <Row>
                  <Col>
                    net<br />
                    <Input
                      type="number"
                      name="agencycostnet"
                      value={fields.agencycostnet}
                      onChange={handleChange}
                      invalid={validate.agencycostnet === false}
                    />
                  </Col>
                  <Col>
                    {showVat &&
                    <React.Fragment>
                      VAT<br />
                      <Input
                        type="number"
                        name="agencycostvat"
                        disabled={true}
                        value={fields.agencycostvat.toFixed(2)}
                      />
                    </React.Fragment>
                    }
                  </Col>
                  <Col>
                    {showVat &&
                    <React.Fragment>
                      gross<br />
                      <Input
                        type="number"
                        name="agencycostgross"
                        disabled={true}
                        value={fields.agencycostgross.toFixed(2)}
                      />
                    </React.Fragment>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {validate.amountnetestimate === false &&
                    <div className="invalid-feedback d-block mb-2">
                      You must enter an amount.
                    </div>
                    }
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </React.Fragment>
          }
          {showAmountBlock &&
          <React.Fragment>
            <FormGroup row className="align-items-center">
              <Label sm={4}>amount</Label>
              <Col sm={8}>
                <Row>
                  <Col>
                    net<br />
                    <Input
                      type="number"
                      name="amountnet"
                      value={fields.amountnet}
                      onChange={handleChange}
                      invalid={validate.amountnet === false}
                    />
                  </Col>
                  <Col>
                    {showVat &&
                    <React.Fragment>
                      VAT<br />
                      <Input
                        type="number"
                        name="amountvat"
                        disabled={true}
                        value={fields.amountvat.toFixed(2)}
                      />
                    </React.Fragment>
                    }
                  </Col>
                  <Col>
                    {showVat &&
                    <React.Fragment>
                      gross<br />
                      <Input
                        type="number"
                        name="amountgross"
                        disabled={true}
                        value={fields.amountgross.toFixed(2)}
                      />
                    </React.Fragment>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {validate.amountnetestimate === false &&
                    <div className="invalid-feedback d-block mb-2">
                      You must enter an amount.
                    </div>
                    }
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </React.Fragment>
          }

          {fields.invoiceto === 'Agency' &&
          <SelectList
            name="agencyownerchargecode"
            label="agency charge code"
            values={agencyOwnerChargeCodes.map(occ => {
              return {
                id: occ.id,
                toString: () => occ.description + ' (' + occ.ownerchargecode + ')'
              }
            })}
            emptyValue="Please select"
            value={fields.agencyownerchargecode}
            onChange={handleChange}
            invalid={validate.agencyownerchargecode === false}
          />
          }

          {fields.invoiceto === 'Owner' &&
          <React.Fragment>
            <hr />
            <FormGroup row className="align-items-center">
              <Label sm={2}>owner charges</Label>
              <Col sm={10}>
                <Table size="sm" className="mb-1">
                  <thead>
                    <tr>
                      <th>code</th>
                      <th>description</th>
                      <th>type</th>
                      <th>amount</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.ownercharges.filter(oc => !oc.deleted).length === 0 &&
                    <tr>
                      <td colSpan="4">
                        No owner charges found.
                      </td>
                    </tr>
                    }
                    {fields.ownercharges.map((oc, i) =>
                    <JobExpenseOwnerCharge
                      key={i}
                      workOrderFields={workOrderFields}
                      expense={fields}
                      ownerCharge={oc}
                      handleOwnerCharge={ownerCharge => handleOwnerCharge(ownerCharge, i)}
                      costItemCodeId={fields.costitemcode}
                      workOrder={workOrder}
                      inGroup={inGroup}
                    />
                    )}
                  </tbody>
                </Table>
                {validate.ownercharges === false &&
                <div className="invalid-feedback d-block mb-2">
                  <h6>You must add at least one owner charge.</h6>
                </div>
                }
                {validate.requires1104 === false &&
                <div className="invalid-feedback d-block mb-2">
                  <h6>You must add a recharge owner charge.</h6>
                </div>
                }
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Button
                      color="primary"
                      disabled={!fields.costitemcode || inGroup}
                      onClick={toggleOwnerChargeModal}
                    >
                      add owner charge
                    </Button>
                  </Col>
                </Row>
                {ownerChargeModal &&
                <OwnerChargeModal
                  expense={fields}
                  toggle={toggleOwnerChargeModal}
                  handleOwnerCharge={handleOwnerCharge}
                  costItemCodeId={fields.costitemcode}
                  descriptionAddendum={workOrderFields.shortdescription}
                  workOrder={workOrder}
                />
                }
              </Col>
            </FormGroup>
          </React.Fragment>
          }
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSave} disabled={disableAll}>OK</Button>{' '}
        <Button color="secondary" onClick={toggle}>cancel</Button>
      </ModalFooter>
    </Modal>
  );

}

function JobExpenseOwnerCharge({
  workOrder,
  workOrderFields,
  expense,
  ownerCharge,
  handleOwnerCharge,
  costItemCodeId,
  inGroup
}) {

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  }

  if (ownerCharge.deleted === true) {
    return null;
  }

  const deleteOwnerCharge = () => {
    if (window.confirm('Are you sure you want to remove this owner charge?')) {
      handleOwnerCharge({...ownerCharge, deleted: true});
    }
  }

  return (
    <tr>
      <td>{ownerCharge.ownerchargecode.ownerchargecode}</td>
      <td>{ownerCharge.description || ownerCharge.ownerchargecode.description}</td>
      <td>{ownerCharge.ownerchargeamounttype.ownerchargeamounttype}</td>
      <td>{ownerCharge.amount}</td>
      <td>
        <Icon icon="pencil-alt" onClick={toggle} />{' '}
        <Icon icon="trash" onClick={deleteOwnerCharge} disabled={inGroup} />
      </td>
      {modal &&
      <OwnerChargeModal
        expense={expense}
        ownerCharge={ownerCharge}
        toggle={toggle}
        handleOwnerCharge={handleOwnerCharge}
        costItemCodeId={costItemCodeId}
        descriptionAddendum={workOrderFields.shortdescription}
        workOrder={workOrder}
        inGroup={inGroup}
      />
      }
    </tr>
  );

}
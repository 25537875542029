import React, { useEffect, useRef, useState } from 'react';
import { 
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from 'reactstrap';

export default function GoogleMap(props) {

  const googleMapRef = useRef();
  const [googleMap, setGoogleMap] = useState();
  const [markers, setMarkers] = useState();

  useEffect(() => {
    const gm = createGoogleMap();
    setGoogleMap(gm);
  }, []);

  useEffect(() => {
    if (googleMap) {
      let markers = [];

      if (props.latitude && props.longitude) {
        markers.push({
          marker: createMarker(props)
        });
      } else {
        var bounds = new window.google.maps.LatLngBounds();

        props.markers.forEach(marker => {
          markers.push({
            marker: createMarker(marker),
            control: marker.control,
          });

          const latitude = parseFloat(marker.latitude);
          const longitude = parseFloat(marker.longitude);
          bounds.extend({lat: latitude, lng: longitude});
        });

        googleMap.fitBounds(bounds);
      }

      setMarkers(markers);
    }
  }, [googleMap]);

  const createGoogleMap = () => {
    let mapProps = {
      zoom: 16,
    };

    if (props.latitude && props.longitude) {
      mapProps.center = {
        lat: parseFloat(props.latitude),
        lng: parseFloat(props.longitude),
      }
    } else {
      let avgLat = 0;
      let avgLong = 0;
      
      props.markers.forEach(marker => {
        avgLat += parseFloat(marker.latitude);
        avgLong += parseFloat(marker.longitude);
      });
  
      avgLat = avgLat / props.markers.length;
      avgLong = avgLong / props.markers.length;
  
      mapProps.center = {lat: avgLat, lng: avgLong};
    }

    return new window.google.maps.Map(googleMapRef.current, mapProps);
  }

  const createMarker = props => {
    let obj = {
      position: { 
        lat: parseFloat(props.latitude), 
        lng: parseFloat(props.longitude),
      },
      map: googleMap, 
      title: props.title,
    };

    if (props.textInCircle) {
      obj.icon = {
        url: `data:image/svg+xml;utf8,${textInCircleSvg(props.textInCircle)}`
      }
    }

    return new window.google.maps.Marker(obj);  
  }

  const textInCircleSvg = text => `
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <g transform="translate(25,25)">
        <circle cx="0" cy="0" r="22" stroke="${encodeURIComponent("#fd6413")}" stroke-width="5" fill="white"></circle>
        <text x="0" y="0" alignment-baseline="middle" font-size="22" text-anchor="middle" font-family="Arial" dy=".05em" fill="${encodeURIComponent("#fd6413")}" font-weight="bold">
          ${text}
        </text>
      </g>
    </svg>`;  

  return (
    <Row>
      {markers && markers[0].control &&
      <Col sm="auto">
        <ListGroup>
          {markers.map((marker, i) => 
          <ListGroupItem 
            key={i}
            action
            className="cursorPointer"
            onClick={() => {
              googleMap.panTo(marker.marker.getPosition());
              marker.marker.setAnimation(window.google.maps.Animation.BOUNCE);

              setTimeout(() => {
                marker.marker.setAnimation(null);
              }, 2000);
            }}
          >
            {marker.control}
          </ListGroupItem>  
          )}
        </ListGroup>
      </Col>
      }
      <Col>
        <div
          id="google-map"
          ref={googleMapRef}
          style={{ width: '100%', height: props.height ? (props.height + 'px') : '300px' }}
        />
      </Col>
    </Row>
  );
}
import React, { useEffect, useReducer } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import { 
  Button,
  ButtonGroup,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row, 
  Table } from 'reactstrap';
import Icon from './Icon';  
import moment from 'moment';

export default function SelectJobModal(props) {

  const reducer = (state, action) => {
    switch (action.type) {
      case 'PREV_PAGE':
        return {...state, page: state.page - 1};
      case 'NEXT_PAGE':
        return {...state, page: state.page + 1};
      case 'FILTER_MODE':
        let filters = {
          type: 'Instance'
        };

        if (props.subStatus) { filters.substatus = props.subStatus; }

        switch (action.mode) {
          case 'recentlycreated':
            filters.createddate = '>' + moment().add(-7, 'days').format('YYYY-MM-DDTHH.mm.ss');
            break;
          case 'search':
            filters.id = action.search;
            break;
          case 'supplier':
            filters.supplierid = props.supplierId;
            break;
          default:
        }
        return {...state, filters, filterMode: action.mode, page: 1};
      case 'JOBS':
        return {
          ...state, 
          jobs: action.jobs.collection,
          maxPage: Math.ceil(action.jobs.total / state.limit)
        };
      case 'TOGGLE_SELECTED':
        let selected = props.oneOnly ? [] : [...state.selected];

        if (selected.includes(action.id)) {
          selected.splice(selected.indexOf(action.id), 1);
        } else {
          selected.push(action.id);
        }
        return {...state, selected};
      case 'SET_LOADING':
        return {...state, loading: action.payload};
      default:
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    modal: false,
    jobs: undefined,
    selected: [],
    limit: 10,
    page: 1,
    maxPage: 1,
    orderBy: 'instancepreferredstartdatetime_asc:id',
    filters: undefined,
    filterMode: undefined,
    loading: false,
  });

  useEffect(() => {
    const getJobs = () => {
      dispatch({ type: 'SET_LOADING', payload: true });

      let jobs = new FilterCollection({
        path: 'workorder',
        object: common.WorkOrder,
      });
      jobs.limit = state.limit;
      jobs.page = state.page;
      jobs.orderBy = state.orderBy;
      jobs.fields = 'id:propertynano:status:substatus:shortdescription:createddatetime:completeddate';

      jobs.addFilters([state.filters]);

      jobs.fetch().then(() => {
        dispatch({type: 'JOBS', jobs});
        dispatch({ type: 'SET_LOADING', payload: false });
        console.log(jobs);
      });
    }
  
    if (state.filters && state.page && state.orderBy) {
      getJobs();
    }
  }, [state.filters, state.page, state.orderBy, state.limit]);

  useEffect(() => {
    if (props.supplierId) {
      dispatch({ type: 'FILTER_MODE', mode: 'supplier' });
    }
  }, []);

  const toggle = () => {
    props.toggle();
  }

  let search = '';

  return (
    <React.Fragment>
      <Modal isOpen={true} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>choose {props.oneOnly ? 'a' : 'one or more'} job{props.oneOnly ? '' : 's'}</ModalHeader>
        <ModalBody>
          <Row className="mb-2">
            <Col>
              <Row>
                <Col>
                  <Row>
                    {state.filterMode !== 'supplier' &&
                    <React.Fragment>
                      <Col md="auto">
                        <Button 
                          size="sm" 
                          color="secondary" 
                          onClick={() => dispatch({type: 'FILTER_MODE', mode: 'recentlycreated'})} 
                          active={state.filterMode === 'recentlycreated'}
                        >
                          recently created
                        </Button>
                      </Col>
                      <Col>
                        <InputGroup size="sm">
                          <Input placeholder="search by job id" innerRef={(el) => { search = el }} />
                          <InputGroupAddon addonType="append">
                            <Button onClick={() => { dispatch({type: 'FILTER_MODE', mode: 'search', search: search.value}) }}>search</Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </React.Fragment>
                    }
                  </Row>
                </Col>
                <Col md="auto">
                  <ButtonGroup size="sm">
                    <Button 
                      onClick={() => { dispatch({type: 'PREV_PAGE'}) }}
                      disabled={state.page === 1}
                    >previous page</Button>
                    <Button 
                      onClick={() => { dispatch({type: 'NEXT_PAGE'}) }}
                      disabled={state.page >= state.maxPage}
                    >next page</Button>
                  </ButtonGroup>                
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>job</th>
                    <th>completed</th>
                    <th>property</th>
                    <th>status</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {state.loading &&
                  <tr><td colSpan="5">Loading...</td></tr>
                  }
                  {state.jobs && state.jobs.length === 0 &&
                  <tr><td colSpan="5">No results found.</td></tr>
                  }
                  {state.jobs && state.jobs.length > 0 && state.jobs.map(job => {

                    let status = job.status.toLowerCase();

                    let substatus = undefined;
                    if (job.substatus.id) {
                      if (status.replace(' ', '_') !== job.substatus.workordersubstatus.substatusreference) {
                        substatus = job.substatus.workordersubstatus.substatusname;
                      }
                    }

                    if (status === 'completed') {
                      status = 'practically completed';
                    }                    

                    return (
                      <tr key={job.id} className={props.oneOnly && state.selected.length > 0 && state.selected[0] === job.id ? 'table-info' : ''}>
                        <td>{job.id}</td>
                        <td>{job.shortdescription}</td>
                        <td>{moment(job.completeddate).format('DD/MM/YY')}</td>
                        <td>{job.property.name}</td>
                        <td>{status + (substatus ? ' - ' + substatus : '')}</td>
                        <td className="align-middle text-center">
                          {props.oneOnly ?
                          <Icon icon="check" onClick={() => dispatch({type: 'TOGGLE_SELECTED', id: job.id})} />
                          :
                          <CustomInput
                            className="d-inline"
                            disabled={props.excludeIds && props.excludeIds.includes(job.id)}
                            type="switch"
                            id={"sw" + job.id}
                            label=""
                            checked={state.selected.includes(job.id)}
                            onChange={() => { dispatch({type: 'TOGGLE_SELECTED', id: job.id }) }}
                          />
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>             
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {!props.oneOnly &&
          <span className="mr-3">{ state.selected.length } jobs selected</span>
          }
          <Button 
            color="primary" 
            disabled={state.selected.length === 0} 
            onClick={() => { 
              props.onSubmit(props.oneOnly ? state.selected[0] : state.selected); 
              toggle(); 
            }}
          >
            go
          </Button>
          <Button color="secondary" onClick={toggle}>cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

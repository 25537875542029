import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Icon(props) {

  let className = "";
  if (props.className) {
    className = props.className;
  } else if (props.onClick) {
    className = "text-primary cursorPointer";
  }

  if (props.disabled) {
    className += " disabled-opacity";
    className = className.replace('cursorPointer', '');
  }

  return (
    <FontAwesomeIcon 
      className={className}
      onClick={props.disabled ? () => {} : props.onClick}
      icon={props.icon}
      size={props.size}
      color={props.color}
      disabled={props.disabled}
      fixedWidth={props.fixedWidth}
    />
  );
}
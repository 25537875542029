import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Icon from './components/Icon';
import TabDashboard from './TabDashboard';
import TabScheduler from './TabSchedulerNew';
import TabJobsList from './TabJobsList';
import { closeTab, addTab, toggleTab, loadTabs, updateJob } from './Actions';
import { Store } from "./Store";
import WebSocks from './WebSocks';
import TabDebug from './TabDebug';

export default function Home(props) {

  const {
    state: {
      activeTab,
      tabs,
      user,
      userRole,
      properties,
      ignoreHookJobs,
    }, 
    dispatch 
  } = useContext(Store);

  const ignoreJobs = useRef([]);

  useEffect(() => {
    loadTabs(dispatch).then(() => {
      if (localStorage.querystring) {
        localStorage.querystring.substring(1).split("&").forEach(p => {
          const key = p.split("=")[0];
          const val = p.split("=")[1];
  
          if (['jobid'].includes(key)) {
            addTab('editJob', {workOrderId: val}, dispatch);
          }
        });

        localStorage.querystring = '';
      }
    });
  }, [dispatch]);

  useEffect(() => {
    ignoreJobs.current = ignoreHookJobs;
  }, [ignoreHookJobs]);

  if (!['serviceCoordinator', 'serviceManager', 'administrator'].includes(userRole)) {
    return (
      <Row className="m-3">
        <Col>
          <h3>Sorry!</h3>
          <h5>You are not authorised to access this group.</h5>
        </Col>
      </Row>  
    )
  }

  const onMessage = data => {
    // check message contents and react accordingly
    if (data.entity === 'WorkOrderInstance') {
      if (ignoreJobs.current.includes(data.id)) {
        console.log('ignoring hook job', data.id);
        dispatch({type: 'REMOVE_IGNORE_HOOK_JOB', payload: data.id});
      } else {
        console.log('processing hook job', data.id);
        if (data.newdata && data.newdata.property) {
          const propertyId = parseInt(data.newdata.property.substr(data.newdata.property.lastIndexOf("/") + 1), 10);
          const property = properties.find(property => property.id === propertyId);

          if (property) {
            updateJob(data.id, {}, dispatch, true);
          }
        }
      }
    }

  }

  let navItems = [
    (
      <NavItem key="dashboard">
        <NavLink
          className={'cursorPointer ' + (activeTab === 'dashboard' ? 'active' : '')}
          onClick={() => { toggleTab('dashboard', dispatch); }}
        >
          <Icon icon="chart-pie" />{' '}dashboard
        </NavLink>
      </NavItem>
    ),
    (
      <NavItem key="scheduler">
        <NavLink
          className={'cursorPointer ' + (activeTab === 'scheduler' ? 'active' : '')}
          onClick={() => { toggleTab('scheduler', dispatch); }}
        >
          <Icon icon="calendar-alt" />{' '}scheduler
        </NavLink>
      </NavItem>
    ),
    (
      <NavItem key="jobslist">
        <NavLink
          className={'cursorPointer ' + (activeTab === 'jobslist' ? 'active' : '')}
          onClick={() => { toggleTab('jobslist', dispatch); }}
        >
          <Icon icon="list-ul" />{' '}jobs list
        </NavLink>
      </NavItem>
    )
  ];

  if (user.tabsusername === 'bev') {
    navItems.push(
      <NavItem key="debug">
        <NavLink
          className={'cursorPointer ' + (activeTab === 'debug' ? 'active' : '')}
          onClick={() => { toggleTab('debug', dispatch); }}
        >
          <Icon icon="bug" />{' '}debug
        </NavLink>
      </NavItem>
    );
  }

  let tabPanes = [
    (
      <TabPane key="dashboard" tabId="dashboard">
        <TabDashboard />
      </TabPane>
    ),
    (
      <TabPane key="scheduler" tabId="scheduler">
        <TabScheduler />
      </TabPane>
    ),
    (
      <TabPane key="jobslist" tabId="jobslist">
        <TabJobsList />
      </TabPane>
    )
  ];

  if (user.tabsusername === 'bev') {
    tabPanes.push(
      <TabPane key="debug" tabId="debug">
        <TabDebug />
      </TabPane>
    );
  }

  tabs.forEach(tab => {
    let tabIcon = undefined;

    const tabIcons = {
      showJob: "eye",
      createJob: "hammer",
      editJob: "hammer",
      showFieldWorker: "user",
      showProperty: "home",
      fieldWorkerTracker: "globe",
    };

    if (tab.type in tabIcons) {
      tabIcon = (
        <React.Fragment>
          <Icon icon={tabIcons[tab.type]} />{' '}
        </React.Fragment>
      );
    }

    navItems.push(
      <NavItem key={tab.ref}>
        <NavLink
          className={'cursorPointer ' + (activeTab === tab.ref ? 'active' : '')}
          onClick={() => { toggleTab(tab.ref, dispatch); }}
        >
          {tabIcon}{tab.name}{' '}
          <Icon icon="times" className="clickable-icon" onClick={(e) => {
            e.stopPropagation();
            closeTab(tab.ref, dispatch);
          }} />
        </NavLink>
      </NavItem>
    );
    tabPanes.push(
      <TabPane key={tab.ref} tabId={tab.ref}>
        <Row className="m-3">
          <Col sm="12">
            {tab.content}
          </Col>
        </Row>
      </TabPane>    
    );
  });

  return (
    <React.Fragment>
      <WebSocks onMessage={ onMessage } />
      <div className="schedulernav">
        <Nav tabs>      
          {navItems}
        </Nav>
      </div>
      <TabContent activeTab={activeTab}>
        {tabPanes}
      </TabContent> 
    </React.Fragment>
  );

}
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { common, Collection, FilterCollection } from 'plato-js-client';
import { 
  Button, 
  Row, 
  Col, 
  Card, 
  CardBody, 
  CardTitle, 
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  ListGroup, 
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover, 
  PopoverHeader, 
  PopoverBody,
  Table } from 'reactstrap';
import moment from 'moment';
import Icon from './components/Icon';
import InitialsCircle from './components/InitialsCircle';
import IconButton from './components/IconButton';
import NumberCircle from './components/NumberCircle';
import { Pie } from 'react-chartjs-2';
import { Store } from './Store';
import SelectProperty from './fields/SelectProperty';
import { addTab, toggleTab, loadUser, setQuickFilter } from './Actions';
import InvoiceModal from './components/InvoiceModal';
import JobTemplatesModal from './components/JobTemplatesModal';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Responsive } from 'react-grid-layout';
import WidthProvider from './components/WidthProvider';
import { loadSetting, saveSetting } from './components/TabsUserSetting'; 
import { errorToast } from './components/Toasties';
import { isTest } from './mixins/MiscMixin';

const ResponsiveGridLayout = WidthProvider(Responsive);

const defaultWidgets = [
  "fieldWorkers",
  "upcomingJobsByFieldWorker",
  "quickActions",
  "quickFilters",
];

const defaultLayouts = {
  lg: [
    {i: "fieldWorkers", x: 0, y: 0, w: 2, h: 16, minW: 2, minH: 16},
    {i: "upcomingJobsByFieldWorker", x: 2, y: 0, w: 3, h: 8, minW: 3, minH: 8},
    {i: "quickActions", x: 5, y: 0, w: 7, h: 8, minW: 7, minH: 8},
    {i: "quickFilters", x: 2, y: 8, w: 10, h: 8, minW: 2, minH: 8},
  ],
  md: [
    {i: "fieldWorkers", x: 0, y: 0, w: 3, h: 20, minW: 3, minH: 20},
    {i: "upcomingJobsByFieldWorker", x: 0, y: 20, w: 5, h: 9, minW: 5, minH: 9},
    {i: "quickActions", x: 3, y: 0, w: 7, h: 8, minW: 7, minH: 8},
    {i: "quickFilters", x: 3, y: 8, w: 7, h: 12, minW: 7, minH: 12},
  ],
  sm: [
    {i: "fieldWorkers", x: 0, y: 0, w: 2, h: 8, minW: 2, minH: 8},
    {i: "upcomingJobsByFieldWorker", x: 0, y: 20, w: 4, h: 9, minW: 4, minH: 9},
    {i: "quickActions", x: 2, y: 0, w: 4, h: 8, minW: 4, minH: 8},
    {i: "quickFilters", x: 2, y: 8, w: 6, h: 12, minW: 6, minH: 12},
  ],
  xs: [
    {i: "fieldWorkers", x: 0, y: 0, w: 4, h: 8, minW: 4, minH: 8},
    {i: "upcomingJobsByFieldWorker", x: 0, y: 31, w: 4, h: 11, minW: 4, minH: 11},
    {i: "quickActions", x: 0, y: 8, w: 4, h: 11, minW: 4, minH: 11},
    {i: "quickFilters", x: 0, y: 19, w: 4, h: 12, minW: 4, minH: 12},
  ],
  xxs: [
    {i: "fieldWorkers", x: 0, y: 0, w: 2, h: 20, minW: 2, minH: 20},
    {i: "upcomingJobsByFieldWorker", x: 0, y: 36, w: 2, h: 9, minW: 2, minH: 9},
    {i: "quickFilters", x: 0, y: 2, w: 2, h: 11, minW: 2, minH: 11},
    {i: "quickActions", x: 0, y: 1, w: 2, h: 12, minW: 2, minH: 12}
  ]
};

export default function TabDashboard() {

  const { 
    state: {
      activeTab,
      user,
    }, 
    dispatch
  } = useContext(Store);

  const [widgets, setWidgets] = useState();
  const [widgetComponents, setWidgetComponents] = useState();
  const [layouts, setLayouts] = useState();
  const [lastSavedLayouts, setLastSavedLayouts] = useState();
  const [settingsModal, setSettingsModal] = useState(false);

  const layoutSetting = useRef(loadSetting(user, 'PMSDashboardLayout'));

  useEffect(() => {
    if (layoutSetting.current) {
      setLayouts(layoutSetting.current.layouts);
      setLastSavedLayouts(layoutSetting.current.layouts);
      setWidgets(layoutSetting.current.widgets);
    } else {
      setLayouts(defaultLayouts);
      setWidgets(defaultWidgets);
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'dashboard') {
      let event = new CustomEvent("resize");
      window.dispatchEvent(event);
    }
  }, [activeTab]);

  useEffect(() => {

    if (!widgets) {
      return;
    }

    let ri = [];

    widgets.forEach(item => {
      let component = undefined;

      if (item === 'jobsToday' || item === 'unscheduledJobs') {
        return;
      }

      switch (item) {
        case 'fieldWorkers':
          component = <TabDashboardPeopleWidget />;
          break;
        case 'upcomingJobsByFieldWorker':
          component = <TabDashboardUpcomingJobsByFieldWorkerWidget />;
          break;
        case 'quickActions':
          component = <TabDashboardQuickActionsWidget />;
          break;
        case 'quickFilters':
          component = <TabDashboardQuickFiltersWidget />;
          break;
        default:
      }

      ri.push(
        <div key={item}>
          {component}
        </div>
      );
    });    

    setWidgetComponents(ri);

  }, [widgets]);

  const saveLayout = (newLayouts) => {

    const filterLayouts = (l) => {
      const filtered = {};
      Object.keys(l).forEach(key => {
        filtered[key] = l[key].map(x => {
          let obj = {i: x.i, x: x.x, y: x.y, w: x.w, h: x.h, minW: x.minW, minH: x.minH};

          if (obj.w === 1 && obj.h === 1) {

            // a widget has ended up at the minimum size, so reset back to its default (hacky)
            for (const dl of defaultLayouts[key]) {
              if (dl.i === obj.i) {
                obj.w = dl.w;
                obj.h = dl.h;
                break;
              }
            }
          }

          return obj;
        });
      });
      return filtered;
    }

    const oldL = filterLayouts(lastSavedLayouts ? lastSavedLayouts : defaultLayouts);
    const newL = filterLayouts(newLayouts);

    if (JSON.stringify(oldL) !== JSON.stringify(newL)) {
      // will save
      setLastSavedLayouts(newL); // don't know why I can't save newLayouts
      setLayouts(newL);

      console.log('saving new layout', newL);

      const value = JSON.stringify({
        widgets: widgets,
        layouts: newL,
      });

      saveSetting(user, 'PMSDashboardLayout', value).then(() => {
        user.get().then(u => {
          loadUser(u, dispatch);
        });
      });

    }

  }

  const resetWidgets = () => {
    setLayouts(defaultLayouts);
    setWidgets(defaultWidgets);
  }

  const toggleSettingsModal = () => {
    setSettingsModal(!settingsModal);
  }

  if (!layouts || !widgetComponents) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="m-3">
        <h3 className="ml-3 mb-0">
          Dashboard
          <IconButton icon="config" color="secondary" className="float-right" onClick={toggleSettingsModal} />
        </h3>
        <ResponsiveGridLayout 
          className="layout" 
          breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
          cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
          rowHeight={30}
          onLayoutChange={(layout, newLayout) => { 
            saveLayout(newLayout);
          }}
          layouts={layouts}
          draggableCancel=".dontDragMe"
          useCSSTransforms={false}
        >
          {widgetComponents}
        </ResponsiveGridLayout>
      </div>
      {settingsModal &&
      <Modal isOpen toggle={toggleSettingsModal}>
        <ModalHeader toggle={toggleSettingsModal}>Dashboard settings</ModalHeader>
        <ModalBody>
          {defaultWidgets.map(widget => {
            return (
              <FormGroup check key={widget}>
                <Label check>
                  <Input 
                    type="checkbox" 
                    id="inactive" 
                    checked={widgets.includes(widget)}
                    onChange={e => {
                      const checked = e.target.checked;
                      const w = [...widgets];

                      if (checked) {
                        w.push(widget);
                      } else {
                        w.splice(w.indexOf(widget), 1);
                      }

                      setWidgets(w);
                    }}
                  />{' '}
                  {widget.replace( /([A-Z])/g, " $1" ).toLowerCase()}
                </Label>
              </FormGroup>
            );
          })}
          <Button className="float-right mb-2" onClick={resetWidgets}>reset widgets</Button>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleSettingsModal}>close</Button>
        </ModalFooter>
      </Modal>
      }
    </React.Fragment>  
  );
}

function TabDashboardQuickFiltersWidget() {

  const {
    dispatch,
    state: {
      quickFilterCounts
    }
  } = useContext(Store);

  return (
    <Card className="h-100 overflow-auto">
      <CardBody className="text-center">
        <CardTitle tag="h5">job counts</CardTitle>
        <div className="d-flex flex-wrap justify-content-center">
          {Object.values(quickFilterCounts).sort((a, b) => (a.order > b.order) ? 1 : -1).map(qf => {

            let color = qf.count > 0 ? 'red' : 'green';
            if (qf.title === 'jobs today' || qf.title === 'tasks' || qf.title === 'new') {
              color = 'blue';
              // TODO: DO PROPERLY
            }

            return (
              <div className="m-2" key={qf.title}>
                <div className="mb-1" style={{height: "50px", display: "flex"}}>
                  <div style={{margin: "auto", width: "150px"}}>
                    {qf.title}
                  </div>
                </div>           
                <NumberCircle 
                  color={color}
                  onClick={() => {
                    setQuickFilter(qf.title, dispatch);
                    toggleTab('jobslist', dispatch);
                  }}
                >
                  {qf.count}
                </NumberCircle>
              </div>  
            );
          })}
        </div>
      </CardBody>                  
    </Card>     
  );

};

function TabDashboardUpcomingJobsByFieldWorkerWidget() {

  const {
    state: {
      fieldWorkers,
      currentGroup,
      change
    }
  } = useContext(Store);

  const [chartData, setChartData] = useState();

  const getJobs = async () => {
    if (currentGroup.fieldWorkers.length === 0) {
      return;
    }

    let jobs = new FilterCollection({
      path: 'workorder',
      object: common.WorkOrder,
    });
    jobs.limit = 9999;
    jobs.addFilters([{
      type: 'Instance',
      supplierid: currentGroup.fieldWorkers.join('|'),
      preferredstartdatetime: '>' + moment().format('YYYY-MM-DDT00.00.00'),
      status: [
        'Approved', 
        'Completed', 
        'Financially Completed', 
        'Invoice Approved', 
        'Invoice Rejected', 
        'Invoiced', 
        'New', 
        'Owner Charged', 
        'Started', 
        'Supplier Paid'
      ].join('|'),
    }]);
  
    jobs.fields = 'id:supplierid';
    await jobs.fetch();

    let futureJobsLabels = [];
    let futureJobsColors = [];
    let futureJobsData = [];

    fieldWorkers.forEach(fieldWorker => {

      const fieldWorkerName = fieldWorker.title + ' ' + fieldWorker.firstname + ' ' + fieldWorker.surname;
      const color = stringToColour(fieldWorker.firstname + fieldWorker.surname);

      let futureJobs = 0;

      jobs.forEach(job => {
        if (job.supplierid === fieldWorker.id) {
          futureJobs += 1;
        }
      });
      
      futureJobsLabels.push(fieldWorkerName);
      futureJobsColors.push(color);
      futureJobsData.push(futureJobs); 
    });

    setChartData({
      labels: futureJobsLabels,
      datasets: [{
        data: futureJobsData,
        backgroundColor: futureJobsColors
      }]
    }); 
  }

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    if (!change) { return; }

    setTimeout(() => {
      getJobs();
    }, 10000);
  }, [change]);

  var chartOptions = {};

  return (
    <Card className="text-center h-100 overflow-auto">
      <CardBody style={{width: "100%", height: "100%", marginLeft: "auto", marginRight: "auto"}}>
        <div className="d-flex flex-column h-100">
          <CardTitle tag="h5" className="mb-0">upcoming jobs by field worker</CardTitle>
          {chartData &&
          <div className="flex-grow-1">
            <div className="d-flex h-100 align-items-center">
            <Pie 
              data={chartData} 
              options={chartOptions} 
              width={null} 
              height={null} 
              options={{
                // aspectRatio: 0,
                legend: {
                  display: false,
                }
              }}
            />
            </div>
          </div>
          }
        </div>
      </CardBody>
    </Card> 
  );
}

function TabDashboardPeopleWidget() {

  const {
    state: {
      fieldWorkers,
      serviceCoordinators,
    },
    dispatch
  } = useContext(Store);

  return (
    <Card className="h-100 overflow-auto">
      <CardBody>
        <CardTitle tag="h5">
          field workers{' '}
          <Button 
            onClick={() => {
              addTab('fieldWorkerTracker', {}, dispatch)
            }}
          >
            track
          </Button>     
        </CardTitle>
        <ListGroup className="mb-4">
        {fieldWorkers && fieldWorkers.map(fieldWorker => {
          return (
            <ListGroupActor key={fieldWorker.id} actor={fieldWorker} />
          );
        })}
        </ListGroup>
        <CardTitle tag="h5">service coordinators</CardTitle>
        <ListGroup>
        {serviceCoordinators && serviceCoordinators.map(serviceCoordinator => {
          return (
            <ListGroupActor key={serviceCoordinator.id} actor={serviceCoordinator} />
          );
        })}
        </ListGroup>                
      </CardBody>
    </Card>
  );
}

function TabDashboardQuickActionsWidget() {

  const {
    dispatch,
    state: {
      user,
      userRole
    }  
  } = useContext(Store);

  const [property, setProperty] = useState();
  const [jobId, setJobId] = useState();
  const [jobTemplatesModal, setJobTemplatesModal] = useState(false);

  const [invoiceId, setInvoiceId] = useState();
  const [invoiceJobsModal, setInvoiceJobsModal] = useState(false);

  const toggleInvoiceJobsModal = () => {
    setInvoiceJobsModal(!invoiceJobsModal);
  }

  return (
    <Card className="h-100">
      <CardBody>
        <CardTitle tag="h5">quick actions</CardTitle>
        <Row>
          <Col md={6} className="mb-2">
            <Card>
              <CardBody className="p-2">
                open property:
                <InputGroup className="mt-1">
                  <SelectProperty
                    className="dontDragMe"
                    onChange={val => setProperty(val[0])}
                  />
                  <InputGroupAddon addonType="append">
                    <Button 
                      onClick={() => {
                        property && addTab('showProperty', {propertyId: property.id}, dispatch)}
                      }
                    >go</Button>
                  </InputGroupAddon>
                </InputGroup>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} className="mb-2">
            <Card>
              <CardBody className="p-2">
                <Row>
                  <Col>
                    open job:<br />
                    <InputGroup className="mt-1">
                      <Input
                        className="dontDragMe"
                        name="job"
                        type="text"
                        onChange={e => setJobId(e.target.value)}
                        placeholder="job id"
                      />
                      <InputGroupAddon addonType="append">
                        <Button onClick={() => {jobId && addTab('editJob', {workOrderId: jobId}, dispatch)}}>go</Button>
                      </InputGroupAddon>
                    </InputGroup>                  
                  </Col>
                  <Col>
                    open invoice:<br />
                    <InputGroup className="mt-1">
                      <Input
                        className="dontDragMe"
                        name="job"
                        type="text"
                        onChange={e => setInvoiceId(e.target.value)}
                        placeholder="invoice id"
                      />
                      <InputGroupAddon addonType="append">
                        <Button onClick={toggleInvoiceJobsModal}>go</Button>
                        {invoiceJobsModal &&
                        <InvoiceModal invoiceId={invoiceId} toggle={toggleInvoiceJobsModal} />
                        }
                      </InputGroupAddon>
                    </InputGroup>                  
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {(userRole === 'serviceManager' || userRole === 'administrator' || user.tabsusername === 'bev') &&
          <Col md={6} className="mb-2">
            <Card>
              <CardBody className="p-2">
                financials:
                <Row className="mt-1">
                  <Col>
                    <Button 
                      onClick={() => {
                        addTab('completeJobs', {}, dispatch)
                      }}
                      block
                    >
                      financially complete jobs
                    </Button>                  
                  </Col>
                  <Col>
                    <Button 
                      onClick={() => {
                        addTab('invoiceJobs', {}, dispatch)
                      }}
                      block
                    >
                      owner invoices
                    </Button>                         
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>
                    <Button 
                      onClick={() => {
                        addTab('supplierInvoices', {}, dispatch)
                      }}
                      block
                    >
                      field worker invoices
                    </Button>               
                  </Col>
                  <Col>                      
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          }       
          <Col md={6} className="mb-2">
            <Card>
              <CardBody className="p-2">
                templates:          
                <Button onClick={() => setJobTemplatesModal(true)} block>
                  view templates
                </Button>
                {jobTemplatesModal &&
                <JobTemplatesModal toggle={() => setJobTemplatesModal(!jobTemplatesModal)} />
                }
              </CardBody>
            </Card>
          </Col>
          {/* {user.tabsusername === 'bev' &&
          <Col md={6} className="mb-2">
            <Card>
              <CardBody className="p-2">
              <Button 
                onClick={() => {
                  addTab('debug', {}, dispatch)
                }}
              >
                debug
              </Button>  
              </CardBody>
            </Card>
          </Col>
          } */}
        </Row>
      </CardBody>
    </Card>
  );
}

function ListGroupActor(props) {

  const { dispatch} = useContext(Store);

  const [popover, setPopover] = useState(false);

  const toggle = () => {
    setPopover(!popover)
  }

  const actor = props.actor;

  const actorName = [
    actor.title,
    actor.firstname,
    actor.surname
  ].join(' ');

  return (
    <ListGroupItem tag="a" href="#" onClick={toggle} className="skinny" action>
      <Row className="align-items-center">
        <Col>
          {actorName}<br />
          <small>{actor.id}</small>
        </Col>
        <Col xs="auto">
          <InitialsCircle size={40} id={'popover' + actor.id} firstname={actor.firstname} surname={actor.surname} />
          <Popover trigger="legacy" placement="right" isOpen={popover} target={'popover' + actor.id} toggle={toggle}>
            <PopoverHeader style={{height: '46px'}}>
              <Button className="float-right" size="sm" color="primary" onClick={() => { toggle(); addTab('showFieldWorker', {supplierId: actor.id}, dispatch); }}>
                <Icon icon="eye" />
              </Button>
              {actor.firstname + ' ' + actor.surname}
            </PopoverHeader>
            <PopoverBody>
              <strong>id: </strong> {actor.id}<br />
              <strong>tabs code:</strong> {actor.tabscode}<br />
              <strong>company name: </strong> {actor.companyname ? actor.companyname : 'N/A'}<br />
              <strong>TOCC employed: </strong> <Icon icon={actor.agencyemployed ? 'check' : 'times'} /><br />
              <strong>working hours:</strong> 9am - 5pm Mon-Fri
            </PopoverBody>
          </Popover>            
        </Col>
      </Row>
    </ListGroupItem>
  );


}

const stringToColour = (str) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
  }

  return colour;
}
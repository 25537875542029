import React from 'react';
import { 
  Col, 
  FormFeedback,
  FormGroup, 
  Input,
  Label } from 'reactstrap';

export default function Text(props) {

  let obj = {
    type: props.large ? "textarea" : "text",
    name: props.name,
    invalid: props.invalid,
    onChange: props.onChange,
    disabled: props.disabled,
    maxLength: props.maxLength,
  }

  if (props.innerRef) {
    obj.innerRef = props.innerRef;
    obj.defaultValue = props.defaultValue;
  } else {
    obj.value = props.value || '';
  }

  return (
    <FormGroup row>
      <Label sm={4} for={props.name}>
        {props.label || props.name}
        {props.labelSmall &&
        <small className="text-danger">{' '}{props.labelSmall}</small>
        }
      </Label>
      <Col sm={8}>
        <Input {...obj} />
        <FormFeedback>You must enter a {props.label || props.name}.</FormFeedback>
      </Col>
    </FormGroup>
  );  

}
import React from 'react';
import { isTest } from '../mixins/EnvironmentMixin';

export default function TabsButton(props) {
  return (
    <a
      className={"btn btn-secondary " + (props.className ? props.className : "")}
      color="secondary" 
      style={{padding: !props.size ? '2px' : (((props.size - 25 - 2) / 2) + 'px')}} 
      // href={"https://toccl." + (isTest() ? 'test.' : '') + "tabs2.co.uk/" + props.route}
      href={"https://" + (isTest() ? 'test' : 'toccl') + ".tabs2" + (isTest() ? '.hc-dev' : '') + ".co.uk/" + props.route}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img alt="tabs" height="25px" width="25px" src={process.env.PUBLIC_URL + "/tabs2.png"} />                  
    </a>
  );
}

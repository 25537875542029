import React from 'react';
import { client, common, Collection, FilterCollection } from 'plato-js-client';
import moment from 'moment';
import { getGroups } from './GroupUtilities';

import TabCompleteJobs from './TabCompleteJobs';
import TabDebug from './TabDebug';
import TabInvoiceJobs from './TabInvoiceJobs';
import TabSupplierInvoices from './TabSupplierInvoices';
import TabJob from './TabJob';
import TabShowJob from './TabShowJob';
import TabFieldWorker from './TabFieldWorker';
import TabFieldWorkerTracker from './TabFieldWorkerTracker';
import TabProperty from './TabProperty';

import { errorToast, infoToast } from './components/Toasties';

export const switchGroup = (id, dispatch) => {
  dispatch({type: "RESET", payload: {}});
  // TODO: make sure the above action completes before the shizzle below starts
  loadGroupAction(id, dispatch);
}

export const refreshGroup = (id, dispatch) => {
  getGroups().then(groups => {
    dispatch({type: "LOAD_GROUPS", payload: groups});

    const group = groups.find(g => {
      return g.id === id;
    });

    dispatch({type: "LOAD_GROUP", payload: group});

    loadFieldWorkers(group.fieldWorkers, 1, dispatch).then(value => {
      dispatch({type: "LOAD_FIELDWORKERS", payload: value});
    });

    loadTabsUsers(group.serviceCoordinators).then(value => {
      dispatch({type: "LOAD_SERVICECOORDINATORS", payload: value});
    });

    loadTabsUsers(group.serviceManagers).then(value => {
      dispatch({type: "LOAD_SERVICEMANAGERS", payload: value});
    });

    loadTabsUsers(group.administrators).then(value => {
      dispatch({type: "LOAD_ADMINISTRATORS", payload: value});
    });
  });
}

export const loadGroupAction = (id, dispatch) => {
  getGroups().then(groups => {
    dispatch({type: "LOAD_GROUPS", payload: groups});

    const group = groups.find(g => {
      return g.id === id;
    });

    dispatch({type: "LOAD_GROUP", payload: group});

    getUserId().then(userId => {
      let role = 'serviceCoordinator';
      if (group.serviceManagers && group.serviceManagers.includes(userId)) {
        role = 'serviceManager';
      }
      if (group.administrators && group.administrators.includes(userId)) {
        role = 'administrator';
      }
      dispatch({type: 'LOAD_USER_ROLE', payload: role});
    });

    loadFieldWorkers(group.fieldWorkers, 1, dispatch).then(value => {
      dispatch({type: "LOAD_FIELDWORKERS", payload: value});
    });

    loadProperties(group.brandingIds, group.propertyIds).then(value => {
      dispatch({type: "LOAD_PROPERTIES", payload: value});
    });

    loadTabsUsers(group.serviceCoordinators).then(value => {
      dispatch({type: "LOAD_SERVICECOORDINATORS", payload: value});
    });

    loadTabsUsers(group.serviceManagers).then(value => {
      dispatch({type: "LOAD_SERVICEMANAGERS", payload: value});
    });

    loadTabsUsers(group.administrators).then(value => {
      dispatch({type: "LOAD_ADMINISTRATORS", payload: value});
    });

    // loadUnscheduledJobs(group.brandingIds, group.propertyIds).then(value => {
    //   dispatch({type: "LOAD_UNSCHEDULEDJOBS", payload: value});
    // });

   
    let brandingPromises = [];
    group.brandingIds.forEach(brandingId => {
      brandingPromises.push(loadBranding(brandingId));
    });

    Promise.all(brandingPromises).then(brandings => {
      dispatch({type: "LOAD_BRANDINGS", payload: brandings});
    });

    let jobItemTemplatePromises = [];
    group.brandingIds.forEach(brandingId => {
      jobItemTemplatePromises.push(fetchCollection(
        'workorderexpensetemplate',
        common.WorkOrderExpenseTemplate,
        new common.Branding(brandingId)
      ));
    });

    Promise.all(jobItemTemplatePromises).then(values => {
      var arr = [];
      values.forEach(value => {
        arr = [...arr, ...value];
      });

      arr.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : -1);

      dispatch({type: "LOAD_JOBITEMTEMPLATES", payload: arr});
    });

    fetchCollection(
      'pmschecklist',
      common.PmsChecklist,
      undefined
    ).then(value => {
      dispatch({
        type: 'LOAD_CHECKLISTS',
        payload: value.filter(cl => group.brandingIds.includes(cl.branding.id))
      })
    });

    fetchCollection(
      'pmschecklist',
      common.PmsChecklist,
      undefined
    ).then(value => {
      dispatch({
        type: 'LOAD_OTHERBRANDCHECKLISTS',
        payload: value.filter(cl => ! group.brandingIds.includes(cl.branding.id))
      })
    });

    fetchCacheableCollection('workordersubstatus', common.WorkOrderSubStatus).then(value => {
      dispatch({type: "LOAD_WORKORDERSUBSTATUSES", payload: value});
    });

    fetchCacheableCollection('vatrate', common.VatRate).then(value => {
      dispatch({type: "LOAD_VATRATES", payload: value});
    });    

    fetchCacheableCollection('costitemcode', common.CostItemCode).then(value => {
      value.sort((a, b) => (a.description > b.description) ? 1 : -1);
      value = value.filter(v => v.inactive === false);
      dispatch({type: "LOAD_COSTITEMCODES", payload: value});
    });

    fetchCacheableCollection('chargingperiod', common.ChargingPeriod).then(value => {
      dispatch({type: "LOAD_CHARGINGPERIODS", payload: value});
    });

    fetchCacheableCollection('amountlimittype', common.AmountLimitType).then(value => {
      dispatch({type: "LOAD_AMOUNTLIMITTYPES", payload: value});
    });

    fetchCacheableCollection('ownerchargecode', common.OwnerChargeCode).then(value => {
      value.sort((a, b) => (a.description > b.description) ? 1 : -1);
      value = value.filter(v => v.inactive === false);
      dispatch({type: "LOAD_OWNERCHARGECODES", payload: value});
    });
    
    fetchCacheableCollection('ownerchargeamounttype', common.OwnerChargeAmountType).then(value => {
      dispatch({type: "LOAD_OWNERCHARGEAMOUNTTYPES", payload: value});
    });

    fetchCacheableCollection('worktype', common.WorkType).then(value => {
      value.sort((a, b) => (a.worktype > b.worktype) ? 1 : -1);
      dispatch({type: "LOAD_WORKTYPES", payload: value});
    });
  });
}

/**
 * Used to inform the store that a job is going to receive several updates
 * in a row. Prevents multiple 'changes' being emitted leading to repeat calls.
 * 
 * @param {*} job 
 * @param {*} dispatch 
 */
export const updateJobBegin = async (job, dispatch) => {
  dispatch({type: "UPDATE_JOB_BEGIN", payload: job});
};

export const updateJob = async (jobId, fields, dispatch, hookJob = false, skipChangeEmit = false) => {
  if (!hookJob) {
    dispatch({type: "IGNORE_HOOK_JOB", payload: jobId});
  }

  let oldJob = new common.WorkOrder(jobId);
  await oldJob.get(); 

  let job = new common.WorkOrder(jobId);

  try {
    if (!hookJob && Object.entries(fields).length > 0) {
      await job.update(fields);
    }
    await job.get();
    await job.property.get();
  } 
  catch(error) {
    showError("Error updating job", error);
  }

  if (!hookJob) {
    // add/update/cancel booking blocking availability (if necessary)
    await addBookingToProperty(job);

    // add/update/remove property warning about job (if necessary)
    try {
      await addWarningToProperty(job);
    }
    catch(error) {
      showError("Error adding warning to property!", error);  
    }
  }

  if (!skipChangeEmit) {
    dispatch({type: "UPDATE_JOB", payload: job, oldJob});
  }

  return job;
};

export const getVatRateFromBand = async (vatBand) => {
  var vr;

  const vatRates = await fetchCacheableCollection(
    'vatrate', 
    common.VatRate
  );

  vatRates.forEach(vRate => {
    if (vRate.vatband && 
        vRate.vatband.id === vatBand.id && 
        moment().isBetween(vRate.fromdate, vRate.todate, 'day', '[]')
    ) {
      vr = vRate;
    }
  });
  
  return vr ;
}  

export const updateJobSubStatus = async (
  jobId, 
  subStatusRef, 
  dispatch, 
  user = undefined, 
  note = undefined, 
  commsAssignees = undefined,
  sendAllComms = false,
  skipChangeEmit = false
) => {
  dispatch({type: "IGNORE_HOOK_JOB", payload: jobId});

  let oldJob = new common.WorkOrder(jobId);
  await oldJob.get();

  const userId = await getUserId();

  const workOrderSubStatuses = await fetchCacheableCollection(
    'workordersubstatus', 
    common.WorkOrderSubStatus
  );

  // get the current WorkOrderStatusHistory
  let WorkOrderStatusHistory = undefined;
  for (const status of oldJob.statushistory.collection.reverse()) {
    if (status.status === oldJob.status) {
      WorkOrderStatusHistory = status;
      break;
    }
  }

  // get the WorkOrderSubStatus we want
  let workOrderSubStatus = undefined;
  workOrderSubStatuses.forEach(woss => {
    if (woss.substatusreference === subStatusRef) {
      workOrderSubStatus = woss;
    }
  });

  let woshss = new common.WorkOrderStatusHistorySubStatus();
  woshss.parent = WorkOrderStatusHistory;
  woshss.workordersubstatus = workOrderSubStatus;
  woshss.fromdatetime = moment().format('YYYY-MM-DD HH:mm:ss');
  woshss.setbyactor = {id: userId};

  if (user) {
    woshss.assignedtoactor = user;
  }

  if (note) {
    woshss.workordernote = note;
  }

  try {
    await woshss.create();
  }
  catch (error) {
    showError("Error updating status of job", error);
  }

  // get the job AGAIN so we can update the jobs store...
  // TODO: find a way of not doing this because it's shit
  let job = new common.WorkOrder(jobId);
  await job.get();
  await job.property.get();

  // send communications if necessary
  if (commsAssignees && commsAssignees.length > 0) {
    await woshss.contact({assignees: commsAssignees.join(",")});
  } else if (sendAllComms) {
    const assignees = workOrderSubStatus.nextworkordersubstatus.templates.map(template => {
      return template.assignee.assignee;
    });
    await woshss.contact({assignees: assignees.join(",")});
  }

  if (!skipChangeEmit) {
    dispatch({type: "UPDATE_JOBSUBSTATUS", payload: job, oldJob});
  }

  return job;
};

/**
 * Used to inform the store that a job has finishes receiving updates,
 * so a change can be emitted covering all of them.
 * 
 * @param {*} job 
 * @param {*} dispatch 
 */
export const updateJobEnd = async (job, dispatch) => {
  dispatch({type: "UPDATE_JOB_END", payload: job});
}

export const loadUser = (user, dispatch) => {
  dispatch({type: "LOAD_USER", payload: user});
}

export const toggleTab = (ref, dispatch) => {
  dispatch({type: "TOGGLE_TAB", payload: ref});
}

export const addTab = (type, options, dispatch) => {

  let ref = '';
  let tab = undefined;
  const random = Math.random().toString(36).substr(2, 9);

  switch (type) {
    case 'createJob':
      ref = 'createJob' + random;

      tab = {
        ref: ref,
        name: 'new job',
        type: type,
        options: options,
        content: (
          <TabJob tabRef={ref} options={options} />
        )
      };
      break;
    case 'editJob':
      ref = 'editJob' + options.workOrderId;

      tab = {
        ref: ref,
        name: 'job ' + options.workOrderId,
        type: type,
        options: options,
        content: (
          <TabJob workOrderId={parseInt(options.workOrderId, 10)} options={options} />
        )
      };
      break;        
    case 'showJob':
      ref = 'job' + options.workOrderId;

      tab = {
        ref: ref,
        name: 'job ' + options.workOrderId,
        type: type,
        options: options,          
        content: (
          <TabShowJob workOrderId={options.workOrderId} />
        )
      };
      break;
    case 'showFieldWorker':
      ref = 'fieldWorker' + options.supplierId;

      tab = {
        ref: ref,
        name: 'field worker ' + options.supplierId,
        type: type,
        options: options,          
        content: (
          <TabFieldWorker supplierId={options.supplierId} />
        )
      };
      break;
    case 'showProperty':
      ref = 'property' + options.propertyId;

      tab = {
        ref: ref,
        name: 'property ' + options.propertyId,
        type: type,
        options: options,          
        content: (
          <TabProperty propertyId={options.propertyId} />
        )
      };
      break;
    case 'completeJobs':
      ref = 'completeJobs';

      tab = {
        ref: ref,
        name: 'complete jobs',
        type: type,
        options: options,          
        content: (
          <TabCompleteJobs />
        )
      };
      break;
    case 'invoiceJobs':
      ref = 'invoiceJobs';

      tab = {
        ref: ref,
        name: 'owner invoices',
        type: type,
        options: options,          
        content: (
          <TabInvoiceJobs />
        )
      };
      break;
    case 'supplierInvoices':
        ref = 'supplierInvoices';
  
        tab = {
          ref: ref,
          name: 'field worker invoices',
          type: type,
          options: options,          
          content: (
            <TabSupplierInvoices />
          )
        };
        break;
    case 'fieldWorkerTracker':
      ref = 'fieldWorkerTracker';

      tab = {
        ref: ref,
        name: 'field worker tracker',
        type: type,
        options: options,          
        content: (
          <TabFieldWorkerTracker />
        )
      };
      break;      
    case 'debug':
      ref = 'debug';

      tab = {
        ref: ref,
        name: 'debug',
        type: type,
        options: options,          
        content: (
          <TabDebug />
        )
      };
      break;
    default:
      
  }

  dispatch({type: "ADD_TAB", payload: tab});
}

export const closeTab = (ref, dispatch) => {
  dispatch({type: "CLOSE_TAB", payload: ref});
}

export const renameTab = (ref, newRef, newName, newType, dispatch) => {
  dispatch({
    type: "RENAME_TAB",
    payload: ref,
    newRef,
    newName,
    newType,
  });
}

export const loadTabs = async (dispatch) => {
  let tabs = JSON.parse(localStorage.getItem('tabs'));

  if (tabs) {
    for (const tab of tabs) {
      // addTab saves the tabs to the state, so use an old
      // fashioned for loop with await to ensure the tabs are 
      // loaded one-by-one (is this bad?) 
      await addTab(tab.type, tab.options, dispatch);
    }
  }
}

export const setQuickFilterCount = async (title, order, count, dispatch) => {
  dispatch({type: "SET_QUICK_FILTER_COUNT", title, order, payload: count});
}

export const setQuickFilter = async (title, dispatch) => {
  dispatch({type: "SET_QUICK_FILTER", payload: title});
}

export const setHighlightJob = async (jobId, dispatch) => {
  dispatch({type: "SET_HIGHLIGHT_JOB", payload: jobId});
}

const showError = (message, errorObj) => {
  errorToast(
    <div>
      <strong>{message}</strong>
      <br />
      {errorObj.message}
    </div>
  );
}

const addBookingToProperty = async job => {

  if (!job.preferredstartdatetime || !job.durationminutes) {
    // can't add booking because job hasn't been scheduled
    return;
  }

  let bookingCancelled = false;
  
  // decide if we need to cancel the booking
  if (job.availabilityblockingbooking.id) {
    if (!job.blockavailability ||                                          // job no longer needs availability blocked
        job.availabilityblockingbooking.property.id !== job.property.id || // job has been assigned to a different property
        job.cancelleddatetime ||                                           // job has been cancelled
        job.workordersupplier.supplier.title === 'PMS') {                  // job is assigned to holding supplier
      try {
        await job.availabilityblockingbooking.update({
          'cancelledbooking_reason': 'maintenance booking no longer required',
          cancelledbooking_priorityrebook: false,
        });
        await job.update({availabilityblockingbooking: new common.Booking(0)});
        bookingCancelled = true;
      }
      catch(error) {
        showError("Error cancelling booking", error);
      }
    }
  }

  if (job.blockavailability && job.workordersupplier.supplier.title !== 'PMS') { // TODO: GET HOLDING SUPPLIER ID
    
    let date = moment(job.preferredstartdatetime);

    if (date.hour() < 16) {
      // if job is scheduled for before 4pm, shift booking back a day
      date.subtract(1, 'days');
    }
    
    const fromDate = date.format('YYYY-MM-DD');
    const toDate = moment(fromDate)
    .add(job.durationminutes, 'minutes')
    .add(1, 'days')
    .format('YYYY-MM-DD');

    if (job.availabilityblockingbooking.id && !bookingCancelled) {
      // booking already exists, so move it if our new dates are different
      if (job.availabilityblockingbooking.fromdate !== fromDate || job.availabilityblockingbooking.todate !== toDate) {
        try {
          await job.availabilityblockingbooking.update({
            fromdate: fromDate,
            todate: toDate,
          });
        } catch(error) {
          showError("Error moving booking", error);
        }
      }
    } else {
      // create the booking
      // TODO: do an enquiry first?
      let booking = new common.Booking();
        
      let obj = {
        guesttype: 'None',
        fromdate: fromDate,
        todate: toDate,
        adults: 0,
        children: 0,
        infants: 0,
        pets: 0,
        propertyid: job.property.id,
        agencybookingtypeid: 5,
      };

      try {
        await booking.create(obj);
        await job.update({availabilityblockingbooking: booking});
      } catch(error) {
        if (error.message === 'Property is not available on the required dates') {
          infoToast(
            <div>
              <strong>Can't create booking to block availability!</strong>
              <br />
              A booking already exists covering the scheduled period.
            </div>
          );
        } else {
          showError("Error creating booking", error);
        }
        
      }
    }
  } else {
    if (job.availabilityblockingbooking.id) {
      // cancel whatever booking this job has because we no longer want to block availability
      try {
        await job.availabilityblockingbooking.update({
          'cancelledbooking_reason': 'maintenance booking no longer required',
          cancelledbooking_priorityrebook: false,
        });
        await job.update({availabilityblockingbooking: new common.Booking(0)});
      }
      catch(error) {
        showError("Error cancelling booking", error);
      }

    }
  }

  return true;
}

const addWarningToProperty = async job => {

  if (!job.preferredstartdatetime || !job.durationminutes) {
    // can't add warning because job hasn't been scheduled
    return;
  }  

  const fromDate = moment().format('YYYY-MM-DD');
  const toDate = moment(job.preferredstartdatetime)
    .add(job.durationminutes, 'minutes')
    .add(1, 'days') // ???
    .format('YYYY-MM-DD');
  const actorId = await getUserId();
  const subject = job.shortdescription;
  const text = `PMS${job.id}\n${job.fulldescription}`;
  
  // check if a note for this job already exists
  let notes = new FilterCollection({
    path: 'note',
    object: common.PropertyNote,
    parent: job.property
  });
  notes.addFilters([{
    notetext: '~PMS' + job.id,
  }]);
  notes.limit = 999;

  await notes.fetch();

  if (!job.addpropertywarning &&
      notes.collection.length > 0) {

    // delete the note we've found
    await notes.collection[0].update({archivedbyactorid: actorId});
  }

  if (job.addpropertywarning) {
    if (notes.collection.length === 0) {
      // create new note
      var note = new common.Note();
      note.notetype = {notetype: 'Warning'};
      note.subject = subject;
      note.createdby = {id: actorId};
      note.notetext_createdbyactorid = actorId;
      note.notetext_notetext = text;
      note.notetext_followupdatetime = undefined;
      note.notetext_actionedbyactorid = undefined;
      note.notetext_actioneddatetime = undefined;
      note.bookingid = undefined;
    
      var noteActor = new common.NoteActor();
      noteActor.parent = note;
      noteActor.actorid = actorId;
      noteActor.notifychanges = false;
      noteActor.reminderdate = undefined;
    
      var propertyNote = new common.PropertyNote();
      propertyNote.property = job.property;
      propertyNote.note = note;
      propertyNote.fromdate = fromDate;
      propertyNote.todate = toDate;
      propertyNote.requiresconfirmation = false;
      propertyNote.showonweb = false;
      propertyNote.showonavailability = false;
    
      await note.create();
      await noteActor.create();
      await propertyNote.create();
    } else {
      // update note
      let propertyNote = notes.collection[0]; // THERE SHOULD ONLY BE ONE!
      let note = propertyNote.note;
      let noteText = note.notetexts.collection[0];

      await note.update({subject: subject});
      await noteText.update({notetext: text});
    }
  }

  return true;
}

const getUserId = async () => {
  const response = await client.getInstance().whoAmiCacheable();
  return response.entity.id;
}

const loadFieldWorkers = async (ids, page, dispatch)  => {
  if (ids.length === 0) {
    return [];
    // TODO: I dunno..
  }

  let fieldWorkers = new FilterCollection({
    path: 'supplier',
    object: common.Supplier,
  });
  fieldWorkers.limit = 100;
  fieldWorkers.page = page;
  fieldWorkers.addFilters([{
    id: ids.join('|')
  }]);
  await fieldWorkers.fetch();

  let allFieldWorkers = fieldWorkers.collection;
  if(fieldWorkers.next !== null) {
    page = page + 1;
    await loadFieldWorkers(ids, page, dispatch).then(value => {
      allFieldWorkers = fieldWorkers.collection.concat(value);
    });
  }

  allFieldWorkers.sort((a, b) => {  
    return ids.indexOf(a.id) - ids.indexOf(b.id);
  });

  return allFieldWorkers;
};

const loadTabsUsers = async ids => {
  if (!ids || ids.length === 0) {
    return [];
    // TODO: I dunno..
  }

  let tabsUsers = new FilterCollection({
    path: 'tabsuser',
    object: common.TabsUser,
  });
  tabsUsers.limit = 100;
  tabsUsers.addFilters([{
    id: ids.join('|')
  }]);
  await tabsUsers.fetch();

  tabsUsers.sort((a, b) => (a.surname > b.surname) ? 1 : -1);

  return tabsUsers.collection;
};

const loadProperties = async (brandingIds, propertyIds) => {

    var properties = new FilterCollection({
      path: 'property',
      object: common.Property,
    });

    properties.addFilters({
      statusid: '1',
      brandingid: brandingIds.join('|'),
    });

    properties.limit = 9999;
    properties.fields = 'id:name:tabspropref:address';
    properties.orderBy = 'name';
    
    await properties.fetch();

    // fetch properties again but this time using primarybrandingid filter,
    // so we can ascertain which properties are non-let
    var propertiesPrimary = new FilterCollection({
      path: 'property',
      object: common.Property,
    });

    propertiesPrimary.addFilters({
      statusid: '1',
      primarypropertybrandingid: brandingIds.join('|'),
    });

    propertiesPrimary.limit = 9999;
    propertiesPrimary.fields = 'id';
    
    await propertiesPrimary.fetch();

    var propertiesKeep = [];
    
    properties.collection.forEach(property => {
      const propertyPrimary = propertiesPrimary.collection.find(
        p => p.id === property.id
      );
      property.nonlet = propertyPrimary ? true : false;

      if (Array.isArray(propertyIds) && propertyIds.length > 0) {
        if (propertyIds.includes(property.id)) {
          propertiesKeep.push(property);
        }
      } else {
        propertiesKeep.push(property);
      }
    });

    return propertiesKeep;
};

const loadBranding = async brandingId => {
  var branding = new common.Branding(brandingId);
  await branding.get();
  return branding;
}

const fetchCollection = (path, object, parent) => {
  return new Promise((resolve, reject) => {
    var collection = new Collection({
      path,
      object,
      parent,
    });

    collection.fetch().then(() => {
      resolve(collection.collection);
    });
  });  
}

const fetchCacheableCollection = (path, object) => {
  return new Promise((resolve, reject) => {
    var collection = new Collection({
      path,
      object,
    });

    collection.fetchCacheable().then(() => {
      resolve(collection.collection);
    });
  });  
}
import React, { Component } from 'react';
import { common, Collection } from 'plato-js-client';

class BookingRoomDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      propertyRooms: null,
      bookingRooms: null,
      loading: true
    };
  }

  componentDidMount = () => {
    let bookingRooms = new Collection({
      path: 'room',
      object: common.BookingRoom,
      parent: this.props.booking
    });

    let propertyRooms = new Collection({
      path: 'room',
      object: common.PropertyRoom,
      parent: this.props.booking.property.details
    });

    var proms = [bookingRooms.fetch(), propertyRooms.fetch()];

    Promise.all(proms).then(() => {
      this.setState({ bookingRooms: bookingRooms, propertyRooms: propertyRooms, loading: false });
    }, (error) => {
      this.setState({ error: error, loading: false });
    });
  }

  getRoomLayout(roomTypes) {
    const rooms = [];
    const prs = this.state.propertyRooms.collection;
    const bprs = this.state.bookingRooms.collection;

    for (const i in prs) {
      let found = false;
      for (const j in bprs) {
        if (bprs[j].room.id === prs[i].id) {
          found = true;
          rooms.push({
            name: bprs[j].room.name,
            description: bprs[j].roomtype.description,
            default: false
          });
        }
      }

      if (!found) {
        // find default roomtype
        const def = prs[i].roomtypes.filter((rt) => {
          return rt.default === true;
        });
        if (def && def.length > 0) {
          rooms.push({
            name: prs[i].name,
            description: def[0].roomtype.description,
            default: true
          });
        }
      }
    }
    return rooms.map((rt, i) => {
      return (
        <div key={ i }>
          { rt.name } - { rt.description }
        </div>
      );
    });
  }

  render() {
    if (!this.state.propertyRooms || this.state.propertyRooms.getTotal() === 0) {
      return null;
    }

    return (
      <div><strong>rooms: </strong>{ this.getRoomLayout() }</div>
    );
  }
};

export default BookingRoomDetail;
import React from 'react';
import { 
  Col, 
  FormFeedback,
  FormGroup, 
  Input,
  Label } from 'reactstrap';

export default function Number(props) {

  let obj = {
    type: "number",
    name: props.name,
    invalid: props.invalid,
    onChange: props.onChange,
    disabled: props.disabled,
  }

  const defaultValue = props.hasOwnProperty('defaultValue') 
    ? props.defaultValue 
    : "0";

  if (props.innerRef) {
    obj.innerRef = props.innerRef;
    obj.defaultValue = props.defaultValue;
  } else {
    obj.value = props.value || defaultValue;
  }

  return (
    <FormGroup row>
      <Label sm={4} for={props.name}>{props.label || props.name}</Label>
      <Col sm={8}>
        <Input {...obj} />
        <FormFeedback>You must enter a {props.label || props.name}.</FormFeedback>
      </Col>
    </FormGroup>
  );

}
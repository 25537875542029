import React, { useCallback, useContext, useEffect, useState } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import {
  Badge,
  Row,
  Button,
  ButtonGroup,
  Col,
  Card,
  CardImg,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from 'reactstrap';
import Icon from './Icon';
import moment from 'moment';
import { Store } from "../Store";
import nl2br from 'react-nl2br';
import WebSocks from '../WebSocks';
import { isTest } from '../mixins/EnvironmentMixin';

export default function Notes(props) {

  const {
    state: {
      user
    }
  } = useContext(Store);

  const [addNoteModal, setAddNoteModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [validate, setValidate] = useState({});
  const [saving, setSaving] = useState(false);
  const [notes, setNotes] = useState();
  const [page, setPage] = useState(1);
  const [fields, setFields] = useState({
    notetext: '',
    subject: '',
  });

  const getNotes = useCallback(() => {
    props.parent.get().then(() => {
      let notes = new FilterCollection({
        path: 'note',
        object: common.Note,
        parent: props.parent
      });

      notes.addFilters([{
        archivedby: null
      }]);

      notes.limit = 10;
      notes.page = page;

      notes.fetch().then(
        () => setNotes(notes)
      );
    });
  }, [page]);

  useEffect(() => {
    if (page) {
      getNotes();
    }
  }, [page, props.parent]);

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFields({...fields, [name]: value});
  }

  const toggleAddNoteModal = () => {
    setAddNoteModal(!addNoteModal);
  }

  const handleSelectedFile = (event) => {
    if (event.target.files[0]) {
      setFiles([...files, event.target.files[0]]);
    }
  }

  const removeFile = (name) => {
    let arr = [...files];
    arr.splice(arr.findIndex(file => {return file.name === name}), 1);
    setFiles(arr);
  }

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validateForm()) {
      return false;
    }

    setSaving(true);

    let filesForNoteText = [];
    let promises = [];

    files.forEach(file => {
      filesForNoteText.push('[' + file.name + ']');

      var documentBase = undefined;

      if (file.type.startsWith('image')) {
        documentBase = new common.Image();
      } else {
        documentBase = new common.Document();
        documentBase.name = file.name;
        documentBase.description = '';
      }

      documentBase.data = file;

      promises.push(
        documentBase.upload()
      );
    });

    let values = await Promise.all(promises);

    let wodPromises = [];

    values.forEach(documentBase => {
      var workOrderDocument = new common.WorkOrderDocument();
      workOrderDocument.parent = props.parent;
      workOrderDocument.document = documentBase;

      wodPromises.push(
        workOrderDocument.create()
      );
    });

    let wodValues = await Promise.all(wodPromises);

    let workOrderDocumentIds = wodValues.map(wod => {return wod.id});

    let noteText = fields.notetext;

    if (workOrderDocumentIds.length > 0) {
      noteText += `\n\n[documents:${workOrderDocumentIds.join(',')}]`;
    }

    var note = new common.Note();
    note.notetype = {notetype: 'Normal'};
    note.subject = fields.subject;
    note.createdby = {id: user.id};
    note.notetext_createdbyactorid = user.id;
    note.notetext_notetext = noteText;
    note.notetext_followupdatetime = undefined;
    note.notetext_actionedbyactorid = undefined;
    note.notetext_actioneddatetime = undefined;
    note.bookingid = undefined;

    await note.create();

    var noteActor = new common.NoteActor();
    noteActor.parent = note;
    noteActor.actorid = user.id;
    noteActor.notifychanges = false;
    noteActor.reminderdate = undefined;

    await noteActor.create();

    switch (props.mode) {
      case 'fieldWorker':
        var actorNote = new common.ActorNote();
        actorNote.workorder = props.parent;
        actorNote.note = note;

        await actorNote.create();

        break;
      case 'property':
        var propertyNote = new common.PropertyNote();
        propertyNote.property = props.parent;
        propertyNote.note = note;
        propertyNote.fromdate = moment().format('YYYY-MM-DD');
        propertyNote.todate = '2100-01-01';
        propertyNote.requiresconfirmation = false;
        propertyNote.showonweb = false;
        propertyNote.showonavailability = false;

        await propertyNote.create();

        break;
      case 'job':
        var workOrderNote = new common.WorkOrderNote();
        workOrderNote.workorder = props.parent;
        workOrderNote.note = note;

        await workOrderNote.create();

        break;
      case 'booking':
        var bookingNote = new common.BookingNote();
        bookingNote.booking = props.parent;
        bookingNote.note = note;

        break;
      default:
    }

    toggleAddNoteModal();
    setPage(undefined);
    setPage(1);
    setFields({
      notetext: '',
      subject: '',
    });
    setSaving(false);
    setFiles([]);

  }

  const validateForm = () => {
    let newValidate = {...validate};
    let valid = true;

    newValidate.subject = fields.subject.length > 0 ? true : false;
    newValidate.notetext = fields.notetext.length > 0 ? true : false;

    valid = !Object.values(newValidate).includes(false);

    setValidate(newValidate);

    return valid;
  }

  /**
   * This reacts to note updates applicable to the job being displayed
   * - the 'entityFilter' prop is used to make sure only WorkOrderNotes
   *   come through
   *
   * @param {*} data
   */
  const onMessage = data => {
    // if the note applies to the current workorder, reload them
    if (data.newdata.workorder==='/v2/workorder/'+props.parent.id) {
      props.parent.get().then(() => {
        let notes = new FilterCollection({
          path: 'note',
          object: common.Note,
          parent: props.parent
        });

        notes.addFilters([{
          archivedby: null
        }]);

        notes.limit = 10;
        notes.page = page;

        notes.fetch().then(
          () => setNotes(notes)
        );
      });
    }
  }

  if (!notes) {
    return <span>Loading...</span>;
  }

  const notesFrom = ((page - 1) * notes.limit) + 1;
  const notesTo = Math.min((((page - 1) * notes.limit) + notes.limit), notes.total);

  return (
    <React.Fragment>
      <WebSocks
        onMessage={ onMessage }
        entityFilter={ ['WorkOrderNote'] }
      />
      <Row className="mb-2">
        <Col>
          <ButtonGroup size="sm" className="float-right">
            <Button disabled={!notes.previous} onClick={() => setPage(page - 1) }>previous page</Button>
            <Button disabled={!notes.next} onClick={() => setPage(page + 1) }>next page</Button>
          </ButtonGroup>
          <div className="mr-2 p-1 float-right">
            {notes && notes.total > 0 &&
            'notes ' + notesFrom + ' to ' + notesTo + ' of ' + notes.total
            }
          </div>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          {notes.total === 0 &&
            <h4>No notes found.</h4>
          }
          <ListGroup>
            { notes.map(note =>
            <Note
              key={note.id}
              note={note}
              documents={props.parent.documents}
              getNotes={getNotes}
            />
            )}
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button color="primary" onClick={toggleAddNoteModal}>add note</Button>
        </Col>
      </Row>
      {addNoteModal &&
      <Modal isOpen={addNoteModal} toggle={toggleAddNoteModal} size="lg">
        <ModalHeader toggle={toggleAddNoteModal}>add note</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="subject">subject</Label>
            <Input
              type="text"
              name="subject"
              value={fields.subject}
              onChange={handleChange}
              invalid={validate.subject === false}
            />
            <FormFeedback>You must enter a subject.</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="notetext">note text</Label>
            <Input
              type="textarea"
              name="notetext"
              value={fields.notetext}
              onChange={handleChange}
              invalid={validate.notetext === false}
            />
            <FormFeedback>You must enter a note.</FormFeedback>
          </FormGroup>
          <FormGroup>
            {files && files.length > 0 &&
            <span>files: </span>
            }
            {files.map(file => {
              return (
                <React.Fragment key={file.name}>
                  <Badge color="secondary">
                    {file.name}
                    <Button close onClick={() => { removeFile(file.name) }} />
                  </Badge>{' '}
                </React.Fragment>
              );
            })}
          </FormGroup>
          <FormGroup>
            <Label className="btn btn-primary">
              add file <input type="file" onChange={handleSelectedFile} hidden />
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          {!saving && <Button color="primary" onClick={(e) => handleSubmit(e)}>save</Button>}
          {saving && <Button color="primary" disabled>saving...</Button>}
        </ModalFooter>
      </Modal>
      }
    </React.Fragment>
  );

}

const Note = React.memo(function Note(props) {

  const {
    state: {
      user
    }
  } = React.useContext(Store);

  const getDocumentsBlock = (noteText) => {

    let documents = [];
    let images = [];

    const regDocuments = noteText.match(/\[documents:.*?\]/g);

    if (regDocuments) {
      regDocuments.forEach(matches => {
        matches.replace("[documents:", "").replace("]", "").split(",").forEach(id => {

          let documentAssociation = props.documents.collection.find(document => {return document.id === parseInt(id, 10)});

          if (documentAssociation) {
            if (documentAssociation.document.id) {
              documents.push(
                <React.Fragment key={documentAssociation.document.id}>
                  <Badge className="mr-2" color="secondary">
                    {documentAssociation.document.filename}
                    <Button size="sm" onClick={() => {downloadDocument(documentAssociation.document)}}>
                      <Icon icon="download" />
                    </Button>
                  </Badge>{' '}
                </React.Fragment>
              );
            } else if (documentAssociation.image.id) {
              images.push(
                <Card className="mr-2" key={documentAssociation.image.id} style={{width: "200px", fontSize: "75%"}}>
                  <a
                    href={"https://" + (isTest() ? 'test.plato.hc-dev.' : 'toccl.api.tabs-software.') + 'co.uk/v2/image/' + documentAssociation.image.id + "/resize/width/1000/1000"}
                    alt={documentAssociation.image.id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardImg top width="100%" src={"https://" + (isTest() ? 'test.plato.hc-dev.' : 'toccl.api.tabs-software.') + "co.uk/v2/image/" + documentAssociation.image.id + "/resize/tocc/300/200"} alt={documentAssociation.image.id} />
                    {documentAssociation.image.filename}
                  </a>
                </Card>
              );
            }
          }

        });
      });
    }

    return (
      <React.Fragment>
        {images.length > 0 &&
        <Row className="m-0 mt-2">
          {images}
        </Row>
        }
        {documents.length > 0 &&
        <Row className="m-0 mt-2">
          {documents}
        </Row>
        }
      </React.Fragment>
    );

  }

  const downloadDocument = (doc) => {
    doc.file.get().then((res) => {
      var link = document.createElement('a');
      link.setAttribute('href', res.url);
      link.setAttribute('download', doc.filename);
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  const archiveNote = () => {
    let n = new common.Note(props.note.note.id);
    n.update({...props.note.note, archivedbyactorid: user.id}).then(() => {
      props.getNotes();
    });
  };

  let note = props.note.note;

  let noteText = note.notetexts.map(noteText => {return noteText.notetext}).join("\n");

  let documentsBlock = getDocumentsBlock(noteText);

  noteText = noteText.replace(/\[documents:.*?\]/g, "");

  return (
    <ListGroupItem>
      <ListGroupItemHeading tag="h6" className="font-weight-bold">
        {moment(note.created).format('DD/MM/YYYY HH:mm') + ' - ' + note.subject}{' '}
        <Icon
          icon="trash"
          onClick={() => {
            if (window.confirm('Are you sure you want to archive this note?')) {
              archiveNote();
            }
          }}
        />
        <span className="float-right">created by {note.createdby.name}</span>
      </ListGroupItemHeading>
      <ListGroupItemText className="mb-0">
        {nl2br(noteText)}
      </ListGroupItemText>
      {documentsBlock}
    </ListGroupItem>
  );

});